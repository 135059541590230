export enum WorkFlowStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Denied = 'Denied',
  Canceled = 'Canceled'
}

export enum WorkFlowTypes {
  payRateChange = 1,
  termination = 2
}

export type WorkFlowTask  = {
    workFlowType: number;
    targetEmpId: number;
    protectedEmpNo: string;
    change: string;
  }

export type WorkFlowTaskList = {
    workFlowTaskId: number,
    status: WorkFlowStatus,
    workFlowTypeId: number,
    workFlowType: string,
    targetEmpNo: number,
    targetEmpId: number,
    targetEmployeeName: string,
    startDate: Date,
    approvers: string[],
    initiator: string,
    dateCompleted: Date | null,
    notes: string,
    canApprove: boolean,
    details: WorkFlowListDetails[],
    changes: WorkFlowListChanges[]
}

export type WorkFlowListDetails = {
  status: WorkFlowStatus,
  user: string,
  changeDate: Date,
  levelDescription: string,
  workFlowLevelId: number,
  workFlowLevelUserId: number
}

export type WorkFlowListChanges = {
  fieldName: string,
  oldValue: string,
  newValue: string
}

  //Note: This is the Employee Pay Rate DTO but we need to have the properties capitalize and match the backend so when we convert the JSON it shows properly.
 export type EmpPayRateWorkFlow =  {
  RateId: number;
  EmpId: number;
  ClientNo: number;
  EmpNo: number;
  HourlyRate: number;
  SalaryRate: number;
  AnnualRate: number;
  TranDigit: number;
  Location: number;
  Department: number;
  SubDepartment: number;
  SubDepartment2: number;
  Description: string;
  Notes: string;
  EffectiveDate: Date | string | null;
  Area: string | null;
  Trade: string | null;
  Sub: string | null;
  OverScale: number | null;
  Clock: number;
  OriginalRateId: number;
}

export type EmpHireHistoryWorkFlow = {
  EmpId: number,
  ClientNo: number,
  EmpNo: number,
  EmpDateID: number,
  HireDate: Date | string | null,
  TermDate: Date | string | null,
  TermCodeId: number,
  TermCodeDesc: string,
  Notes: string,
  NotEligibleForRehire: boolean,
  IgnoreTermWhenPrProcessed: boolean,
}

export type WorkFlowTaskStatusRequest = {
  workFlowTaskId: number
  workFlowTypeId: number,
  targetEmpId: number,
  status: WorkFlowStatus,
  notes: string
}