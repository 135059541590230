import React, { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select, { GroupBase, InputActionMeta, OptionsOrGroups, StylesConfig } from 'react-select';
import DropdownAddEditModal from './DropdownAddEditModal';
import { Labels } from '..';
import styles from '../form-controls.module.scss';
import { FormMethods } from '../types';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

type PropTypes = {
  name: string;
  options: any[];
  control: FormMethods['control'];
  setValue?: any;
  id?: string;
  label?: string | string[];
  tallLabel?: boolean;
  labelClass?: string;
  groupClass?: string;
  groupStyle?: React.CSSProperties;
  errors?: any;
  errorMsg?: string;
  required?: boolean;
  visible?: boolean;
  value?: any;
  rules?: object;
  labelField?: string;
  valueField?: string;
  idField?: string;
  addEmptyValue?: boolean;
  noOption?: boolean;
  addOptionText?: string;
  showId?: boolean;
  idEditable?: boolean;
  isEditable?: boolean;
  formComponent?: React.FunctionComponent<any>;
  dropdownName?: string;
  modalTitle?: string;
  customFormComponent?: React.FunctionComponent<any>;
  disabled?: boolean;
  readOnly?: boolean;
  shouldDirty?: boolean;
  prependedOption?: { label: string, value: string };
  controlMinWidth?: string;
  controlMaxWidth?: string;
  selectOnEnter?: boolean;
  searchable?: boolean;
  onChange?: (e?: any) => void;
};

type StylesConfigType = StylesConfig<any, boolean, GroupBase<unknown>> | undefined;
type OptionGroupType = OptionsOrGroups<any, GroupBase<unknown>> | undefined;

export const SelectModalGrp: React.FC<PropTypes> = ({
  id,
  name,
  label,
  tallLabel = false,
  labelClass = '',
  groupClass,
  groupStyle,
  errors,
  errorMsg,
  required = false,
  visible = true,
  setValue,
  control,
  value,
  rules,
  options,
  labelField = 'description',
  valueField = 'id',
  idField,
  addEmptyValue = false,
  noOption = false,
  addOptionText = '',
  showId = false,
  formComponent,
  idEditable = false,
  isEditable = true,
  dropdownName = '',
  modalTitle,
  disabled = false,
  readOnly,
  prependedOption = { label: '', value: '' },
  controlMinWidth = '140px',
  controlMaxWidth,
  searchable = false,
  shouldDirty = true,
  selectOnEnter = false,
  customFormComponent: CustomFormComponent,
  onChange,
}) => {
  
  const compStyles: StylesConfigType = useMemo(() => ({
    control: (base) => {
      return {
        ...base,
        minHeight: '22px',
        height: '22px',
        minWidth: controlMinWidth,
        maxWidth: controlMaxWidth,
        borderRadius: '0.25rem',
        fontSize: '12px',
        color: 'black',
        cursor: 'pointer',
        '&:hover': {
          borderColor: '#0074D9',
        },
      };
    },
    valueContainer: (base) => {
      return {
        ...base,
        height: '22px',
        padding: '2px',
        top: '0',
        position: 'relative',
        overflow: 'hidden',
        width: 'fit-content',
        color: 'black',
      };
    },
    // only appears for searchable inputs
    singleValue: (base) => {
      return {
        ...base,
        marginBottom: 'auto',
      };
    },
    input: (base) => {
      return {
        ...base,
        marginTop: '-2px',
        paddingTop: '0',
      };
    },
    indicatorSeparator: (base) => {
      return {
        ...base,
        display: 'none',
      };
    },
    dropdownIndicator: (base) => {
      return {
        ...base,
        padding: '0px',
        paddingLeft: '5px',
        color: '#3a3a3a',
        '&:hover': {
          color: 'black',
        },
      };
    },
    menu: (base) => {
      return {
        ...base,
        background: '#FFF',
        width: 'fit-content',
      };
    },
    menuList: (base) => {
      return {
        ...base,
        fontSize: '12px',
        color: 'black',
        whiteSpace: 'nowrap',
      };
    },
    option: (base) => {
      return {
        ...base,
        cursor: 'pointer',
      };
    },
  }), []);
  
  const [query, setQuery] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [renderedVal, setRenderedVal] = useState<any>(null);

  const opts = structuredClone(options);

  const groupClass2 = styles['dm-form-group dm-form-modal-grp'] + ' ' + (groupClass ?? '');
  id = id || name;
  
  const errMsg: string = errors
    ? errors.type === 'validate'
      ? errorMsg
      : errors.message
    : '';

  const propName = `<no ${addOptionText.toLowerCase()}>`;
  const option = { ...opts.slice(0, 1).pop() };
  option.code = null;

  if (valueField) {
    option[valueField] = null;
    option[labelField] = propName;
  } else {
    option.id = null;
    option.description = propName;
  }

  const hasOption = opts.find(
    (op) => { return op.description === propName || op[labelField] === propName; },
  );

  if (noOption && !hasOption) {
    opts.unshift(option);
  }
  
  const generateList = () => {
    const renderedOpts = opts.map((opt) => {
      return {
        label: showId && opt[valueField] ? `${opt[valueField]} - ${opt[labelField]}` : `${opt[labelField]}`,
        value: opt[valueField],
      };
    });

    if (addOptionText.length) {
      renderedOpts.unshift({
        label: `<Add, change, or delete ${addOptionText.toLowerCase()}>`,
        value: 'openModal',
      });
    }
    if (addEmptyValue) {
      renderedOpts.unshift(prependedOption);
    }
    
    return renderedOpts;
  };

  const renderedOpts = generateList();
  
  useEffect(() => {
    if (value === null) return setRenderedVal(null);
    const newValue = renderedOpts.find((o) => {
      return `${o.value}`.trim() === `${value}`.trim();
    });
    setRenderedVal(newValue);
  }, [value]);

  const handleChange = (optionObject: { label: string, value: string | number }, _query: string) => {
    let newVal: string | null;
    
    if (optionObject?.value !== null && optionObject?.value !== undefined) { 
      if (typeof optionObject.value === 'string' && optionObject.value === 'openModal' && !_query.length) {
        setShowModal(true);
        return;
      }
      if (optionObject.value === 'openModal') return; // at this point we're bypassing open modal so we don't want to continue
      
      newVal = `${optionObject.value}`.trim();
    } else {
      newVal = null;
    }
    setRenderedVal(renderedOpts.find((o) => {
      return `${o.value}`.trim() === newVal;
    }));
    setValue(name, newVal, {
      shouldDirty: shouldDirty,
    });
    
    if (onChange) onChange(newVal);
  };  
  
  const filterOptions = (
    candidate: { label: string; value: string; data: any },
    input: string,
  ) => {
    if (input) {
      return candidate.value === 'openModal'
        || candidate.value.toLowerCase().trim().includes(input.toLowerCase().trim())
        || candidate.label.toLowerCase().trim().includes(input.toLowerCase().trim());
    }
    return true;
  };
  
  const handleInputChange = (newValue: string, _actionMeta: InputActionMeta, _query: string) => {
    setQuery(newValue);
    
    if (!newValue?.length) return;
    
    const filteredOpts = renderedOpts?.filter((opt) => opt.value !== 'openModal');
    const matchedVal = filteredOpts?.find((opt) =>
      String(opt.value).toLowerCase().trim().includes(newValue.toLowerCase().trim())
      || opt.label.toLowerCase().trim().includes(newValue.toLowerCase().trim()));
    
    if (!matchedVal || matchedVal.value === 'openModal') {
      setRenderedVal(filteredOpts?.find((opt) => opt.value === value) ?? filteredOpts?.[0]);
      return;
    }
    
    setRenderedVal(matchedVal);
  };
  
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, _query: string) => {
    if (!(e.key === 'Enter' || e.key === 'Return')) return;
    
    const newVal = addOptionText.length ? renderedOpts?.filter((opt) => opt.value !== 'openModal'
      && String(opt.value).toLowerCase().trim().includes(_query.toLowerCase().trim())
      || opt.label.toLowerCase().trim().includes(_query.toLowerCase().trim()))?.[0] : renderedOpts?.filter((opt) => opt.value !== 'openModal')?.[0];
    if (!newVal) {
      const valToRender = opts.find((opt) => String(opt[valueField]) === String(value));
      setRenderedVal({ label: valToRender[labelField], value: valToRender[valueField] }); // skip Add option
      return;
    }
    
    setRenderedVal(newVal); // skip Add option
    setValue(name, newVal.value, {
      shouldDirty: shouldDirty,
    });

    if (selectOnEnter && onChange) onChange();
  };
  
  return (
    <>
      {visible ? 
        (
          <>
            <div>            
              <Controller
                control={control}
                name={name}
                rules={rules}
                render={() => {
                  return (
                    <div
                      title={renderedOpts?.find((opt) => String(opt.value) === String(value))?.label}
                      className={groupClass2}
                      style={groupStyle}
                      aria-disabled={disabled || readOnly}
                    >
                      <Labels
                        label={label}
                        tallLabel={tallLabel}
                        labelClass={labelClass}
                        id={id}
                        hasError={!!errors}
                        required={required}
                      />
                      <Select
                        styles={compStyles as StylesConfigType}
                        options={renderedOpts as OptionGroupType}
                        value={renderedVal}
                        getOptionLabel={(optionData) => `${optionData?.label}`.trim()}
                        getOptionValue={(optionData) => `${optionData?.value}`.trim()}
                        onChange={(optObject) => { handleChange(optObject, query); }}
                        closeMenuOnSelect={true}
                        isSearchable={searchable}
                        isDisabled={disabled || readOnly}
                        isClearable={false}
                        required={required}
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        filterOption={filterOptions}
                        onInputChange={(newValue, actionMeta) => { handleInputChange(newValue, actionMeta, query); }}
                        onKeyDown={(e) => { handleKeyDown(e, query); }}
                      />
                    </div>
                  );
                }}
              />
              <small className="text-danger">{errors?.message}</small>
            </div>
            {showModal && formComponent && options ? (
              <DropdownAddEditModal
                options={opts.filter((o) => { return !o[labelField].includes('<no'); })}
                labelField={labelField}
                valueField={valueField}
                idField={idField ?? valueField}
                header={modalTitle}
                idEditable={idEditable}
                isEditable={isEditable}
                dropdownName={dropdownName}
                show={showModal}
                onHide={() => { return setShowModal(false); }}
                formComponent={formComponent}
              />
            ) : null}
            {showModal && CustomFormComponent ? (
              <CustomFormComponent
                show={showModal}
                onHide={() => { return setShowModal(false); }}
              />
            ) : null}
          </>
        ) : null}
    </>
  );
};
