
import Icon from 'core/components/shared/Icon';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SelectModalGrp } from 'core/components/form-controls';
import HistoryLabel from 'core/components/form-controls/HistoryLabel';
import DropdownOptionForm from 'core/components/form-controls/select-modal/DropdownOptionForm';
import EmpDateComponent from 'core/components/shared/EmpDateComponent';
import PanelHeader from 'core/components/shared/PanelHeader';
import SeniorityComponent from 'core/components/shared/Seniority.component';
import { HireHistory, HireHistoryWithRehireInfo } from 'core/models/HireHistory.model';
import { OtherDate } from 'core/models/OtherDate.model';
import { getHireHistories, getPayCategories, getReportOptions } from 'core/store/selectors';
import { getSelectedEmp } from 'core/store/selectors/employee.selector';
import { getOtherDates } from 'core/store/selectors/other-date.selector';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import CustomDate from '../../emp-detail/dates/CustomDate';
import HireHistoryItem from '../../emp-detail/dates/HireHistoryItem';
import AlertModal from 'core/components/modals/alert-modal.modal';
import {
  addHireHistoryWizard,
  deleteHireHistory,
  deleteOtherDate,
  loadEmpDate,
  loadHireHistories,
  loadOtherDates,
  updatePayrollInfo,
  storeHireHistoryWizardMessage,
} from 'core/store/actions';
import TerminationWorkFlowModal from 'features/emp-detail/dates/TerminationWorkFlow.modal';
import WorkFlowTaskSuccessModal from 'features/workflows/WorkFlowTaskSuccess.modal';
import { storeShowWorkTaskSuccessModal } from 'core/store/slices/work-flow.slice';
import WizardRadioButtonModal from 'core/components/shared/wizard-modals/WizardRadioButton.modal';
import WizardDateSelectorModal from 'core/components/shared/wizard-modals/WizardDateSelector.modal';
import WizardConfirmationModal from 'core/components/shared/wizard-modals/WizardConfirmation.modal';

type Props = {
  clientNo: string;
};

type HrParams = {
  protectedEmpNo: string;
};

type Form = {
  payCategoryId: string;
};

const HrEmployeeStatusDatesPage: React.FC<Props> = () => {
  const { protectedEmpNo } = useParams<HrParams>();
  const dispatch = useAppDispatch();

  const [otherDates, setOtherDates] = useState<OtherDate[]>([]);
  const [hireHistories, setHireHistories] = useState<HireHistory[]>([]);
  const [showRehire, setShowRehire] = useState<boolean>(false);
  const [rehireEmployee, setRehireEmployee] = useState<boolean>(true);
  const [showResendI9, setShowResendI9] = useState<boolean>(false);
  const [resendI9Form, setResendI9Form] = useState<boolean>(false);
  const [resendW4Form, setResendW4Form] = useState<boolean>(false);
  const [allowHireHistoryDelete, setAllowHireHistoryDelete] = useState<boolean>(false);
  const [termDate, setTermDate] = useState<Date | null>(null);
  const [hireDate, setHireDate] = useState<Date | null>(null);
  const [showTermDateError, setShowTermDateError] = useState(false);
  const [showSetHireDate, setShowSetHireDate] = useState(false);
  const [selectedHireHistory, setSelectedHireHistory] = useState<HireHistory | null>(null);
  const [showTermWorkFlowModal, setShowTermWorkFlowModal] = useState<boolean>(false);

  const selEmp = useSelector(getSelectedEmp);
  const hireHistoryItems = useSelector(getHireHistories);
  const otherDateItems = useSelector(getOtherDates);
  const payCategoryOpts = useSelector(getPayCategories);
  const empDate = useAppSelector(({ employees }) => { return employees.empDate; });
  const payrollInfo = useAppSelector(({ selEmployeeDetails }) => { return selEmployeeDetails.payrollInfo.payrollInfo; });
  const usesTerminationWorkFlow = useSelector(getReportOptions('TerminationWorkFlow'));
  const showWorkTaskSuccessModal = useAppSelector((state) => { return state.workFlow.showWorkTaskSuccessModal; });
  const showHireHistorySuccessMessage = useAppSelector((state) => { return state.selEmployeeDetails.hireHistory.hireHistoryWizardMessage; });

  const { control, errors, setValue, watch } = useForm<Form>({
    defaultValues: {
      payCategoryId: payrollInfo?.payCategoryId?.toString(),
    },
  });

  const payCategoryId = watch('payCategoryId');

  useEffect(() => {
    if (
      payCategoryId !== undefined &&
      payrollInfo &&
      payrollInfo.payCategoryId !== parseInt(payCategoryId)
    ) {
      dispatch(
        updatePayrollInfo({
          ...payrollInfo,
          payCategoryId: parseInt(payCategoryId),
        }),
      );
    }
  }, [payCategoryId]);

  const getDefaultHireDate = () => {
    if(hireDate && hireHistories?.length === 0) return hireDate;
    else if ((hireHistories[0]?.termDate as Date) > new Date()) return hireHistories[0].termDate || new Date();
    else return new Date();
  }

  const [newHireDate, setNewHireDate] = useState<Date | string>(getDefaultHireDate());

  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(loadEmpDate({ protectedEmpNo,
        updateKeys: [] }));
      dispatch(loadHireHistories(protectedEmpNo));
      dispatch(loadOtherDates(protectedEmpNo));
      setRehireEmployee(false);
      setResendI9Form(false);
      setResendW4Form(false);
      setNewHireDate(getDefaultHireDate());
    }
  }, [protectedEmpNo]);

  useEffect(() => {
    if (hireHistoryItems) {
      setHireHistories(hireHistoryItems);
      setAllowHireHistoryDelete(!!(hireHistoryItems.length > 1));
    }
  }, [hireHistoryItems]);

  useEffect(() => {
    otherDateItems && setOtherDates(otherDateItems);
  }, [otherDateItems]);

  const onAddCustomDate = () => {
    selEmp &&
      setOtherDates((prev: OtherDate[]) => {
        return [
          ...prev,
          new OtherDate(
            selEmp.empId,
            selEmp.clientNo,
            selEmp.empNo,
            protectedEmpNo,
          ),
        ];
      });
  };

  const onDeleteCustomDate = (data: OtherDate) => {
    data.otherDateID && dispatch(deleteOtherDate(data));
  };

  const onAddHireHistory = () => {
    if (hireHistories?.length > 0 && hireHistories?.find(x => x.termDate === null)) return setShowTermDateError(true);
    if(selEmp) setShowRehire(true);
  };

  const onSubmitWizard = () => {
    const priorHireDate = empDate?.hireDate;
    if (selEmp && priorHireDate) {      
      const newHireHistory = new HireHistoryWithRehireInfo(
        selEmp.empId,
        selEmp.clientNo,
        selEmp.empNo,
        protectedEmpNo,
        priorHireDate,
        rehireEmployee,
        resendI9Form,
        resendW4Form,
        //If they have no hire history record and add one and have a hireDate set then use that hire date else use todays date.
        { hireDate: newHireDate },
      );
      dispatch(addHireHistoryWizard(newHireHistory));
    }
  };

  const onDeleteHireHistory = (data: HireHistory) => {
    if (!(hireHistories?.length > 1 && data.empDateID)) return;
    //The protected Emp No is not saved on the HireHistory so if you add a record and not re-call GET it throws an error. So we will just assign the protected EmpNo
    if (selEmp) data.protectedEmpNo = selEmp.protectedEmpNo;
    dispatch(deleteHireHistory(data));

    //If we delete the term date update the hire/term date to the most recent date.
    updateHireAndTermDate(hireHistories[1], true);
  };

  const updateHireAndTermDate = (data: Partial<HireHistory>, updateAfterDelete = false) => {
    if (!hireHistories?.length && data.empDateID !== hireHistories[0].empDateID && !updateAfterDelete) return;
    setTermDate(data?.termDate as Date);
    setHireDate(data?.hireDate as Date);
  };

  return (
    <>
      <PanelHeader title="Dates"></PanelHeader>
      <div className="row">
        <div className="col-sm-8">
          {empDate && (
            <EmpDateComponent
              termDate={termDate}
              hireDate={hireDate}
              showHistory={true}
            />
          )}
        </div>
        <div className="col-sm-4">
          <PanelHeader
            title="Seniority"
            hrClass="hr-2"
          >
            <div className="dm-grid-title-subtext">
              As of {DateTime.now().toFormat('MM/dd/yyyy')}
            </div>
          </PanelHeader>
          <SeniorityComponent />
          <PanelHeader
            title="Payroll Info"
            hrClass="hr-2"
          ></PanelHeader>
          {payrollInfo && (
            <>
              <HistoryLabel
                labelTitle="Pay Categories"
                section="payrollInfo"
                field="payCategoryId"
                protectedEmpNo={protectedEmpNo}
              />
              <SelectModalGrp
                name="payCategoryId"
                noOption={true}
                modalTitle="PAY CATEGORIES"
                required={true}
                idEditable={true}
                dropdownName="PayCategory"
                formComponent={DropdownOptionForm}
                addOptionText="Pay Category"
                errors={errors.payCategoryId}
                control={control}
                value={payrollInfo.payCategoryId}
                options={payCategoryOpts}
                rules={{ required: 'Pay Category is required' }}
                setValue={setValue}
              />
            </>
          )}
        </div>
      </div>
      <PanelHeader
        title="Custom Dates"
        hrClass="hr-2"
      >
        <button
          type="button"
          className="btn btn-link dm-grid-action-title pb-0"
          onClick={onAddCustomDate}
        >
          Add Date <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>
      </PanelHeader>
      {otherDates.map((item: any, index: number) => {
        return (
          <div key={index}>
            {selEmp && (
              <CustomDate
                item={item}
                selEmp={selEmp}
                onDelete={onDeleteCustomDate}
              />
            )}
            <hr className="dm-panel-hr mt-0" />
          </div>
        );
      })}

      <PanelHeader
        title="Hire History"
        hrClass="hr-2"
      >
        <button
          type="button"
          className="btn btn-link dm-grid-action-title pb-0"
          onClick={onAddHireHistory}
        >
          Add Date
          <Icon
            name="plus-circle"
            className="fa-plus-circle"
          />
        </button>
      </PanelHeader>
      {hireHistories?.map((item: any, index: number) => {
        return (
          <div key={item.empDateID}>
            {selEmp && (
              <HireHistoryItem
                item={item}
                selEmp={selEmp}
                mainHireDate={hireDate}
                onDelete={onDeleteHireHistory}
                updateHireAndTermDate={updateHireAndTermDate}
                setShowTermWorkFlowModal={setShowTermWorkFlowModal}
                setSelectedHireHistory={setSelectedHireHistory}
                allowDelete={allowHireHistoryDelete}
                usesTerminationWorkFlow={usesTerminationWorkFlow?.showReport || false}
              />
            )}
            <hr className="dm-panel-hr mt-0" />
          </div>
        );
      })}
      {showTermDateError && (
        <AlertModal
          title="Data Error"
          message="Cannot add a new hire history date before adding a termination date to the previous hire history record."
          btnText="OK"
          show={showTermDateError}
          onHide={() => { return setShowTermDateError(false); }}
        />
      )}
      {showRehire && (
        <WizardRadioButtonModal
          show={showRehire}
          modalTitle='Step 1 of 3: Rehire Employee'
          question='Is this employee being rehired?'
          body='By selecting "Yes" this employee will be viewed as a new hire by DM Payroll Solutions and included in your next New Hire report.'
          radioButtons={[{defaultValue: rehireEmployee, setValue: setRehireEmployee, label: 'Rehire the Employee'}]}
          onHide={() => { return setShowRehire(false); }}
          next={() => {return [setShowResendI9(true), setShowRehire(false)]}}
        />
      )}
      {showResendI9 && (
        <WizardRadioButtonModal
        show={showResendI9}
        modalTitle='Step 2 of 3: Rehire Employee'
        question='Would you like to ask the employee to fill out a new I9 and/or W4 form?'
        body='By selecting "Yes" this employee will recive an email letting them know that they have to log into MyInfo and fill out new forms.'
        radioButtons={[{defaultValue: resendW4Form, setValue: setResendW4Form, label: 'Ask to fill out W4 File'}, {defaultValue: resendI9Form, setValue: setResendI9Form, label: 'Ask to fill out I9 File'}]}
        onHide={() => { return setShowResendI9(false); }}
        next={() => {return [setShowSetHireDate(true), setShowResendI9(false)]}}
        previous={() => {return [setShowRehire(true), setShowResendI9(false)]}}
      />
      )}
      {showSetHireDate && (
        <WizardDateSelectorModal
        show={showSetHireDate}
        modalTitle='Step 3 of 3: Rehire Employee'
        body='Please select a re-hire date for the employee:'
        datePickerLabel='Hire Date'
        defaultValue={newHireDate}
        setValue={setNewHireDate}
        onHide={() => { return setShowSetHireDate(false); }}
        submit={() => {return [onSubmitWizard(), setShowSetHireDate(false)]}}
        previous={() => {return [setShowResendI9(true), setShowSetHireDate(false)]}}
      />
      )}
      {selectedHireHistory ? 
      <TerminationWorkFlowModal
        show={showTermWorkFlowModal} 
        onHide={() => {setShowTermWorkFlowModal(false); setSelectedHireHistory(null);}}
        selectedHireHistory={selectedHireHistory}
        notEligible={empDate?.notEligibleForRehire || false}
        ignoreTermWhenPRProcessed={empDate?.ignoreTermWhenPRProcessed || false}
      /> : null}
      {showWorkTaskSuccessModal ? 
        <WorkFlowTaskSuccessModal 
          show={showWorkTaskSuccessModal}
          onHide={() => {dispatch(storeShowWorkTaskSuccessModal(false))}}/>
      : null}
      {showHireHistorySuccessMessage ? 
        <WizardConfirmationModal 
          show={showHireHistorySuccessMessage.length > 0}
          bodyMessage={showHireHistorySuccessMessage}
          question={'Would you like to go to the Pay Rate screen to update the employees pay rate?'}
          title={'Hire History Successful'}
          navigationPath={`/employee/detail/${protectedEmpNo}/payrate`}
          onHide={() => {dispatch(storeHireHistoryWizardMessage(''))}}
          buttonText={'Go To Pay Rate Screen'}
        />
      : null}
    </>
  );
};

export default HrEmployeeStatusDatesPage;
