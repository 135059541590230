import React, { useMemo } from 'react';
import { PayrollControlTotal } from 'core/models';
import { updatePayrollControlTotal } from 'core/store/actions';
import { useAppDispatch } from 'utilities/hooks';
import { ControlTotalViewProps, BodyField } from './types';
import CardView from './CardView';

const ControlTotalView = ({
  controlTotal,
  mobileView,
  description,
  showAuxInfo,
  isProcessed,
  isWaiting,
  hasSignedWireOnlyAgreement,
  setReactiveStyles,
}: ControlTotalViewProps) => {
  const dispatch = useAppDispatch();
  
  const bodyFields: BodyField[] = useMemo(() => {
    return [
      {
        title: 'Description',
        field: 'description',
        detail: description?.length ? description : 'N/A',
      },
      {
        title: 'Current user',
        field: 'currentUser',
        detail: controlTotal.currentUser?.length ? controlTotal.currentUser : 'N/A',
      },
      {
        title: 'Current user opened',
        field: 'currentUserOpenedDate',
        detail: controlTotal.currentUserOpenedDate?.length ? controlTotal.currentUserOpenedDate : 'N/A',
      },
      {
        title: 'Last user',
        field: 'lastUser',
        detail: controlTotal.lastUser?.length ? controlTotal.lastUser : 'N/A',
      },
      {
        title: 'Last user closed',
        field: 'lastUserClosedDate',
        detail: controlTotal.lastUserClosedDate?.length ? controlTotal.lastUserClosedDate : 'N/A',
      },
    
    ];
  }, [controlTotal]);
  
  if (showAuxInfo) {
    const match = bodyFields.find(({ field }) => {return ['loc', 'dept'].includes(field);});
    
    if (!match) {
      bodyFields.unshift(
        { title: 'Location',
          field: 'loc',
          detail: controlTotal.loc },
        { title: 'Department',
          field: 'dept',
          detail: controlTotal.dept },
      );
    }
  }
  
  const updatePayOption = (checked: boolean) => {
    const updatedControlTotal: PayrollControlTotal = { ...controlTotal,
      payControlTotal: checked,
    };
    
    dispatch(updatePayrollControlTotal({
      payrollHistoryId: controlTotal.payrollHistoryId,
      controlTotalId: controlTotal.controlTotalId,
      data: updatedControlTotal,
    }));
  };
  return (
    <div className="d-flex">
      <CardView
        id={controlTotal.controlTotalId}
        isProcessed={isProcessed}
        isBalanced={controlTotal.balanced}
        bodyFields={bodyFields}
        mobileView={mobileView}
        styleClass="sub-card-body"
        showPayInput={showAuxInfo}
        hasSignedWireOnlyAgreement={hasSignedWireOnlyAgreement}
        defaultChecked={controlTotal.payControlTotal}
        updatePayOption={updatePayOption}
        setReactiveStyles={setReactiveStyles}
      />
    </div>
  );
};

export default ControlTotalView;