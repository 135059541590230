import { DMDropdownDataModel, DMDropdownItemModel } from 'core/components/form-controls/DMDropDown';
import { EmpMasterAccesses, UserRoles } from 'core/models/oldstuff/UserRoles';
import HoursEarningsAnalysisPage from 'features/accounting-reports/HoursEarningsAnalysis/HoursEarningsAnalysisPage';
import BankDisbursementsReportsPage from 'features/accounting-reports/BankDisbursmentsReportsPage';
import AddEmpPage from 'features/add-emp/AddEmpPage';
import DashboardToolBar from 'features/dashboard/DashboardToolbar';
import Home from 'features/dashboard/Home';
import EEOUnitPage from 'features/eeo-unit/EEOUnitPage';
import CheckCalculatorSearch from 'features/emp-detail/check-calculator/CheckCalculatorSearch';
import DetailPageContainer from 'features/emp-detail/DetailPageContainer';
import EmployeeDocsPage from 'features/employee-docs/EmployeeDocs.page';
import EmployeeDocsToolbar from 'features/employee-docs/EmployeeDocsToolbar';
import EmployeeInformationAccruals from 'features/employee-information/Accruals';
import EmployeeInformationAccrualsToolbar from 'features/employee-information/AccrualsToolbar';
import EmployeeInformationEarnings from 'features/employee-information/Earnings';
import EmployeeInformationEarningsToolbar from 'features/employee-information/EarningsToolbar';
import ReviewMyInfoChanges from 'features/employee-master/EmployeeChanges';
import EmployeeMasterListPage from 'features/employee-master/EmployeeMasterListPage';
import W4Status from 'features/employee-master/W4Status';
import EmployeeToolbar from 'features/employee/EmployeeToolbar';
import HrProfilePage from 'features/hr-profile/HrProfilePage';
import EmployeeHRProfileToolbar from 'features/hr-profile/Toolbar';
import GlobalMasterDeductionsPage from 'features/master-deductions/GlobalMasterDeductionsPage';
import OnboardPage from 'features/onboard/OnboardPage';
import PayrollEmployeeAdjustment from 'features/payroll/employee-adjustment/EmployeeAdjustment';
import OpenCurrentTransmittal from 'features/payroll/open-current-transmittal/OpenCurrentTransmittal';
import OpenCurrentTransmittalToolbar from 'features/payroll/open-current-transmittal/OpenCurrentTransmittalToolbar';
import ProcessPayrollPage from 'features/payroll/process-payroll/ProcessPayrollPage';
import ProcessPayrollToolbar from 'features/payroll/process-payroll/ProcessPayrollToolbar';
import SalesTaxTransmittal from 'features/payroll/sales-tax-transmittal/SalesTaxTransmittalPage';
import UploadPayrollPage from 'features/payroll/upload-payroll/UploadPayrollPage';
import ManageI9Documents from 'features/payroll/w4_i9/ManageI9Documents';
import W4I9Toolbar from 'features/payroll/w4_i9/W4I9Toolbar';
import UploadEmpPage from 'features/upload-emp/UploadEmpPage';
import EmployeeAverageHoursReport from 'features/accounting-reports/EmployeeAverageHoursReport';
import CumulativeSeniorityReport from 'features/human-capital-reports/CumulativeSeniortyReport';
import NewJobTrainingCreditReport from 'features/accounting-reports/NewJobTrainingCreditReport';
import EmployeeAverageHoursWithStatusReport from 'features/accounting-reports/EmployeeAverageHoursWithStatusReport';
import FullTimeEquivalentReport from 'features/human-capital-reports/FullTimeEquivalentReport';
import EmployeeChangeReport from 'features/employee-reports/EmployeeChangeReport';
import PensionReportPage from 'features/payroll-reports/PensionReport/PensionReportPage';
import Payroll401kReport from 'features/payroll-reports/Payroll401kReport';
import Payroll4070TipReport from 'features/payroll-reports/Payroll4070TipReport';
import AnnualFICATipCreditReport from 'features/payroll-reports/AnnualFICATipCreditReport';
import CashFlowReport from 'features/payroll-reports/CashFlowReport';
import EmployeeNavigatorAuditReport from 'features/human-capital-reports/EmployeeNavigatorAuditReport';
import ScheduledDeductionsReport from 'features/payroll-reports/ScheduledDeductionsReport';
import WorkersCompensationReport from 'features/payroll-reports/WorkersCompensationReport';
import DeductionOptionsReport from 'features/payroll-reports/DeductionOptionsReport';
import GeneralLedgerReportPage from 'features/accounting-reports/GeneralLedger/GeneralLedgerPage';
import MyInfoDocStatusReport from 'features/human-capital-reports/MyInfoDocStatusReport';
import TaxReport from 'features/payroll-reports/TaxReport';
import DemographicAndTurnoverReport from 'features/human-capital-reports/DemographicAndTurnoverReport';
import MemoCashFlowReport from 'features/payroll-reports/MemoCashFlowReport';
import GeneralLedgerPdfViewer from 'features/accounting-reports/GeneralLedger/GeneralLedgerPdfViewer';
import ACAProofListReport from 'features/human-capital-reports/ACAProof';
import EmployeePayRateDownload from 'features/pay-rate-reports/EmployeePayRateDownload';
import DeductionShortagesReport from 'features/payroll-reports/DeductionShortagesReport';
import EmployeeLastCheckReport from 'features/payroll-reports/EmployeeLastCheckReport';
import EmployeeWageDetailDownload from 'features/earnings-reports/EmployeeWageDetailDownload';
import UnionDuesReport from 'features/payroll/UnionDuesReport';
import GrossToNetReport from 'features/payroll/GrossToNetReport';
import Employee3YearPayHistory from 'features/pay-rate-reports/Employee3YearPayHistory';
import DepartmentofLaborReportPage from '../features/human-capital-reports/DepartmentOfLaborReportPage/DepartmentofLaborReportPage';
import CARES_SBA_PPP_Report from 'features/accounting-reports/CARES_SBA_PPP_Report';
import OhioI29 from 'features/contractor-reports/OhioI29';
import WH347CertifiedReport from 'features/contractor-reports/WH347CertifiedReport';
import WeekMonthJTDjobCostReport from 'features/contractor-reports/WeekMonthJTDJobCostReport';
import MontlyCashFlowAnalysisDownloadReport from 'features/employee-reports/MonthlyCashFlowAnalysisDownloadReport';
import EmployeePayCategoryHistoryReport from 'features/employee-reports/EmployeePayCategoryHistoryReport';
import JobDetailReport from 'features/contractor-reports/JobDetail';
import FringeReport from 'features/contractor-reports/FringeReport';
import CARESCreditReport from 'features/payroll-reports/CARESCreditReport';
import ElectricalFringeReport from 'features/contractor-reports/ElectricalFringeReport';
import ApprovedJobsReports from 'features/contractor-reports/ApprovedJobsReport';
import NationalMaintenanceAgreementReport from 'features/contractor-reports/NationalMaintenanceAgreementReport';
import WorkersCompenationReport from 'features/contractor-reports/WorkersCompenationReport';
import EEOCC257 from 'features/contractor-reports/EEOCC257';
import JobToDateGrossSummaryReport from 'features/contractor-reports/JobToDateGrossSummaryReport';
import DownloaderPage from 'features/report-downloader/DownloaderPage';
import JobLaborReport from 'features/contractor-reports/JobLaborReport';
import JobGrossSummaryReport from 'features/contractor-reports/JobGrossSummaryReport';
import EmployeeTimeCardReport from 'features/contractor-reports/EmployeeTimeCardReport';
import RateMasterReport from 'features/contractor-reports/RateMasterReport';
import EmployeeGrossSummaryReport from 'features/contractor-reports/EmployeeGrossSummaryReport';
import OCIPReport from 'features/contractor-reports/OCIPReport';
import EEOReport from 'features/human-capital-reports/EEOReport/EEOReport';
import ContractorOverviewDownload from 'features/contractor-reports/ContractorOverviewDownload';
import ACAFullTimeCalculation from 'features/human-capital-reports/ACAFullTimeCalculation';
import ContractorFringeDownload from 'features/contractor-reports/ContractorFringeDownload';
import BambooHRReport from 'features/human-capital-reports/BambooHRReport';
import UserMapPage from 'features/report-downloader/UserMap/UserMapPage';
import ReportLibrary from 'features/client-library/ReportLibrary';
import HelpReferenceGuide from 'features/help-reference-guide/HelpReferenceGuide';
import ManageApplicants from 'features/applicant-tracking/applicants/ManageApplicants';
import ManageJobs from 'features/applicant-tracking/jobs/ManageJobs';
import Applicant from 'features/applicant-tracking/applicants/Applicant';
import CorrectionsPage from 'features/corrections/CorrectionsPage/CorrectionsPage';
import CorrectionsToolbar from 'features/corrections/CorrectionsToolbar';
import ApplicantToolbar from 'features/applicant-tracking/applicants/ApplicantToolbar';
import DeptAllocations from 'features/corrections/DeptAllocationsPage/DeptAllocations';
import ManageJob from 'features/applicant-tracking/jobs/ManageJob';
import DesignInterviewPage from 'features/applicant-tracking/design-interview/DesignInterviewPage';
import DesignAtsWebsitePage from 'features/applicant-tracking/design-ats-website/DesignAtsWebsitePage';
import HCMResourcesPage from 'features/hr-hcm-resources/HCMResourcesPage';
import ContractorTimeCard from 'features/payroll/timecards/ContractorTimeCardPage';
import TimeCardToolbar from 'features/payroll/timecards/TimeCardToolbar';
import TimeByDay from 'features/payroll/timecards/time-by-day/TimeByDay';
import JobRoster from 'features/payroll/timecards/job-roster/JobRoster';
import Diagnostics from 'features/payroll/timecards/diagnostics/Diagnostics';
import TimeSheetSummary from 'features/payroll/timecards/time-sheet-summary/TimeSheetSummary';
import WOTCReport from 'features/human-capital-reports/WOTCReport';
import TimeCardAdjustmentsPage from 'features/payroll/timecards/adjustments/TimeCardAdjustmentsPage';
import TimeCardAdjustmentDetails from 'features/payroll/timecards/adjustments/TimeCardAdjustmentDetails';
import WorkFlowPage from 'features/workflows/WorkFlowPage';
import WorkFlowTasks from 'features/workflows/WorkFlowTasks';

// TODO: add type args
type MainRoute = {
  route: string;
  exact: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toolbarComponent?: React.FC<any>;
  toolbarComponentName?: ToolbarComponentName;
  isMaster?: boolean;
  isDeptAllocations?: boolean; // TODO: find a better spot for this
  role?: UserRoles;
  access?: EmpMasterAccesses;
  groupId?: number;
  itemId?: number;
  workItemId?: number;
};

export enum ToolbarComponentName {
  'DASHBOARD_TOOLBAR' = 'DashboardToolBar',
  'EMPLOYEE_TOOLBAR' = 'EmployeeToolbar',
  'CURRENT_PAYROLL_TOOLBAR' = 'CurrentPayrollToolbar',
  'PROCESS_PAYROLL_TOOLBAR' = 'ProcessPayrollToolbar',
  'W4_I9_TOOLBAR' = 'W4I9Toolbar',
  'EMPLOYEE_INFORMATION_EARNINGS_TOOLBAR' = 'EmployeeInformationEarningsToolbar',
  'EMPLOYEE_INFORMATION_ACCRUALS_TOOLBAR' = 'EmployeeInformationProfileAccrualsToolbar',
  'EMPLOYEE_DOCS_TOOLBAR' = 'EmployeeDocsToolbar',
  'EMPLOYEE_HR_PROFILE_TOOLBAR' = 'EmployeeHRProfileToolbar',
  'ACCOUNTING_REPORTS_TOOLBAR' = 'AccountingReportsToolbar',
  'PAYROLL_REPORTS_TOOLBAR' = 'PayrollReportsToolbar',
  'CORRECTIONS_TOOLBAR' = 'CorrectionsToolbar',
  'APPLICANT_TOOLBAR' = 'ApplicantToolbar',
  'TIME_CARD_TOOLBAR' = 'TimeCardToolbar',
  'ANALYSIS_TOOLBAR' = 'AnalysisToolbar',
}

/* 
For security reasons, the EM routes cannot be caught by an inexact catch-all route anymore. They need to be explicitly
defined for each section so that the section's workItemId can be found when building the AuthRoutes from the mainRoute
array. 

GroupIds are for routes that fall under the "groups" section of the module access endpoint response, while itemIds are
given to those part of a specific group. WorkItemIds are used for all routes inside employee master for the same
purpose. 
*/
const emRouteData: { subdirectory: string, workItemId?: number }[] = [
  { subdirectory: 'snapshot', workItemId: 1 },
  { subdirectory: 'payrate', workItemId: 2 },
  { subdirectory: 'emergency-contact' },
  { subdirectory: 'realign-supervised-employees' },
  { subdirectory: 'taxes', workItemId: 4 },
  { subdirectory: 'check-calculator' },
  { subdirectory: 'direct-deposit', workItemId: 5 },
  { subdirectory: 'deductions', workItemId: 5 },
  { subdirectory: 'payroll-info', workItemId: 6 },
  { subdirectory: 'dates', workItemId: 7 },
  { subdirectory: 'ytd', workItemId: 8 },
  { subdirectory: 'client-rate-rules', workItemId: 9 },
  { subdirectory: 'earnings-records', workItemId: 10 },
  { subdirectory: 'company-benefits', workItemId: 16 },
  { subdirectory: 'recurring-earnings', workItemId: 22 },
  { subdirectory: 'accruals', workItemId: 24 },
  { subdirectory: 'hr-fte', workItemId: 26 },
  { subdirectory: 'hr-doc-comp', workItemId: 27 },
  { subdirectory: 'deferred-comp', workItemId: 30 },
  { subdirectory: 'group-term-life', workItemId: 32 },
  { subdirectory: 'self-serve', workItemId: 34 },
  { subdirectory: 'special-info', workItemId: 39 },
  { subdirectory: 'aca', workItemId: 40 },
  { subdirectory: 'contractor', workItemId: 42 },
  { subdirectory: 'visa-status', workItemId: 43 },
  { subdirectory: 'attendance-on-demand', workItemId: 44 },  
  { subdirectory: 'contractor-options', workItemId: 42 },
];

const emRoutes: MainRoute[] = emRouteData.map((route) => {
  return {
    route: `/employee/detail/:protectedEmpNo/${route.subdirectory}`,
    component: DetailPageContainer,
    toolbarComponent: EmployeeToolbar,
    toolbarComponentName: ToolbarComponentName.EMPLOYEE_TOOLBAR,
    exact: true,
    isMaster: true,
    groupId: 2,
    workItemId: route.workItemId,
  };
});

export const mainRoutes: MainRoute[] = [
  ...emRoutes,
  {
    route: '/home',
    component: Home,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: true,
  },
  {
    route: '/process-payroll',
    component: ProcessPayrollPage,
    toolbarComponent: ProcessPayrollToolbar,
    toolbarComponentName: ToolbarComponentName.PROCESS_PAYROLL_TOOLBAR,
    exact: true,
    groupId: 3,
    itemId: 5,
  },
  {
    route: '/sales-tax-transmittal',
    component: SalesTaxTransmittal,
    toolbarComponent: ProcessPayrollToolbar,
    toolbarComponentName: ToolbarComponentName.PROCESS_PAYROLL_TOOLBAR,
    exact: true,
    groupId: 3,
    itemId: 40,
  },
  {
    route: '/managei9',
    component: ManageI9Documents,
    toolbarComponent: W4I9Toolbar,
    toolbarComponentName: ToolbarComponentName.W4_I9_TOOLBAR,
    exact: true,
    groupId: 17,
    itemId: 142,
  },
  {
    route: '/open-current-transmittal',
    component: OpenCurrentTransmittal,
    toolbarComponent: OpenCurrentTransmittalToolbar,
    toolbarComponentName: ToolbarComponentName.PROCESS_PAYROLL_TOOLBAR,
    exact: true,
    groupId: 3,
    itemId: 38,
  },
  {
    route: '/open-current-transmittal/:payrollHistoryId',
    component: OpenCurrentTransmittal,
    toolbarComponent: OpenCurrentTransmittalToolbar,
    toolbarComponentName: ToolbarComponentName.PROCESS_PAYROLL_TOOLBAR,
    exact: true,
    groupId: 3,
    itemId: 38,
  },
  {
    route: '/contractor-time-sheets',
    component: ContractorTimeCard,
    toolbarComponent: TimeCardToolbar,
    toolbarComponentName: ToolbarComponentName.TIME_CARD_TOOLBAR,
    exact: true,
    groupId: 3,
    itemId: 80,
  },
  {
    route: '/contractor-time-sheets/:payrollHistoryId/:transmittalTimeCardId',
    component: ContractorTimeCard,
    toolbarComponent: TimeCardToolbar,
    toolbarComponentName: ToolbarComponentName.TIME_CARD_TOOLBAR,
    exact: true,
    groupId: 3,
    itemId: 80,
  },
  {
    route: '/timecard-adjust',
    component: TimeCardAdjustmentsPage,
    toolbarComponent: OpenCurrentTransmittalToolbar,
    toolbarComponentName: ToolbarComponentName.TIME_CARD_TOOLBAR,
    exact: true,
    groupId: 3,
    itemId: 5,
  },
  {
    route: '/timecard-adjust/:payrollHistoryId',
    component: TimeCardAdjustmentsPage,
    toolbarComponent: TimeCardToolbar,
    toolbarComponentName: ToolbarComponentName.TIME_CARD_TOOLBAR,
    exact: true,
    groupId: 3,
    itemId: 5,
  },
  {
    route: '/workflows',
    component: WorkFlowPage,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: true,
  },
  {
    route: '/workflow-tasks',
    component: WorkFlowTasks,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: true,
  },
  {
    route: '/timecard-adjust-detail', // /:payrollHistoryId/:adjustmentTimeCardId',
    component: TimeCardAdjustmentDetails,
    toolbarComponent: TimeCardToolbar,
    toolbarComponentName: ToolbarComponentName.TIME_CARD_TOOLBAR,
    exact: true,
    groupId: 3,
    itemId: 5,
  },
  {
    route: '/employee-adjust',
    component: PayrollEmployeeAdjustment,
    toolbarComponent: TimeCardToolbar,
    toolbarComponentName: ToolbarComponentName.PROCESS_PAYROLL_TOOLBAR,
    exact: true,
    groupId: 3,
    itemId: 5,
  },
  {
    route: '/employee-adjust/:payrollHistoryId',
    component: PayrollEmployeeAdjustment,
    toolbarComponent: OpenCurrentTransmittalToolbar,
    toolbarComponentName: ToolbarComponentName.PROCESS_PAYROLL_TOOLBAR,
    exact: true,
    groupId: 3,
    itemId: 5,
  },
  {
    route: '/global-master-deductions',
    component: GlobalMasterDeductionsPage,
    toolbarComponent: EmployeeToolbar,
    toolbarComponentName: ToolbarComponentName.EMPLOYEE_TOOLBAR,
    exact: true,
    isMaster: false,
  },
  {
    route: '/employee-master-list',
    component: EmployeeMasterListPage,
    toolbarComponent: EmployeeToolbar,
    toolbarComponentName: ToolbarComponentName.EMPLOYEE_TOOLBAR,
    exact: true,
    role: UserRoles.GRP_EMPMASTER,
    access: EmpMasterAccesses.LI_EMPLOYEELIST,
    isMaster: true,
    groupId: 2,
    itemId: 1,
  },
  {
    route: '/review-myinfo-changes',
    component: ReviewMyInfoChanges,
    toolbarComponent: W4I9Toolbar,
    toolbarComponentName: ToolbarComponentName.W4_I9_TOOLBAR,
    exact: true,
    role: UserRoles.GRP_EMPMASTER,
    access: EmpMasterAccesses.LI_EMPLOYEELIST,
    isMaster: true,
    groupId: 17,
    itemId: 42,
  },
  {
    route: '/employee/detail/0/add-employee',
    component: AddEmpPage,
    toolbarComponent: EmployeeToolbar,
    toolbarComponentName: ToolbarComponentName.EMPLOYEE_TOOLBAR,
    exact: true,
    isMaster: false,
    groupId: 2,
    itemId: 3,
  },
  {
    route: '/upload-payroll',
    component: UploadPayrollPage,
    toolbarComponent: ProcessPayrollToolbar,
    toolbarComponentName: ToolbarComponentName.PROCESS_PAYROLL_TOOLBAR,
    exact: true,
    groupId: 3,
    itemId: 6,
  },
  {
    route: '/employee-upload',
    component: UploadEmpPage,
    toolbarComponent: EmployeeToolbar,
    toolbarComponentName: ToolbarComponentName.EMPLOYEE_TOOLBAR,
    exact: false,
    isMaster: false,
    groupId: 2,
    itemId: 7,
  },
  {
    route: '/eeo-unit',
    component: EEOUnitPage,
    toolbarComponent: EmployeeToolbar,
    toolbarComponentName: ToolbarComponentName.EMPLOYEE_TOOLBAR,
    exact: true,
    isMaster: false,
    groupId: 5,
  },
  {
    route: '/workers-comp',
    component: CorrectionsPage,
    toolbarComponent: CorrectionsToolbar,
    toolbarComponentName: ToolbarComponentName.CORRECTIONS_TOOLBAR,
    exact: true,
    isMaster: false,
    groupId: 5,
    itemId: 18,
  },
  {
    route: '/eeo-info',
    component: CorrectionsPage,
    toolbarComponent: CorrectionsToolbar,
    toolbarComponentName: ToolbarComponentName.CORRECTIONS_TOOLBAR,
    exact: true,
    isMaster: false,
    groupId: 5,
    itemId: 164,
  },
  {
    route: '/dept-allocations',
    component: DeptAllocations,
    toolbarComponent: CorrectionsToolbar,
    toolbarComponentName: ToolbarComponentName.CORRECTIONS_TOOLBAR,
    exact: true,
    isDeptAllocations: true,
    isMaster: true,
    groupId: 5,
    itemId: 14,
  },
  {
    route: '/quick-onboard',
    component: OnboardPage,
    toolbarComponent: EmployeeToolbar,
    toolbarComponentName: ToolbarComponentName.EMPLOYEE_TOOLBAR,
    exact: true,
    isMaster: false,
    groupId: 2,
    itemId: 157,
  },
  {
    route: '/w4status',
    component: W4Status,
    toolbarComponent: EmployeeToolbar,
    toolbarComponentName: ToolbarComponentName.EMPLOYEE_TOOLBAR,
    exact: true,
    isMaster: true,
    groupId: 17,
  },
  {
    route: '/check-calculator-search',
    component: CheckCalculatorSearch,
    toolbarComponent: EmployeeToolbar,
    toolbarComponentName: ToolbarComponentName.EMPLOYEE_TOOLBAR,
    exact: true,
    isMaster: true,
    groupId: 5,
    itemId: 17,
  },
  {
    route: '/',
    component: Home,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: true,
  },
  {
    route: '/hr-profile/detail',
    component: HrProfilePage,
    toolbarComponent: EmployeeHRProfileToolbar,
    toolbarComponentName: ToolbarComponentName.EMPLOYEE_HR_PROFILE_TOOLBAR,
    exact: true,
    groupId: 6,
  },
  {
    route: '/hr-profile/detail/:protectedEmpNo',
    component: HrProfilePage,
    toolbarComponent: EmployeeHRProfileToolbar,
    toolbarComponentName: ToolbarComponentName.EMPLOYEE_HR_PROFILE_TOOLBAR,
    exact: false,
    groupId: 6,
  },
  {
    route: '/employee-information/earnings',
    component: EmployeeInformationEarnings,
    toolbarComponent: EmployeeInformationEarningsToolbar,
    toolbarComponentName:
      ToolbarComponentName.EMPLOYEE_INFORMATION_EARNINGS_TOOLBAR,
    exact: true,
    groupId: 10,
    workItemId: 10, // associated with both the access group and the employee earnings em section, so it has a groupId AND workItemId.
    itemId: 27,
  },
  {
    route: '/employee-information/accruals',
    component: EmployeeInformationAccruals,
    toolbarComponent: EmployeeInformationAccrualsToolbar,
    toolbarComponentName:
      ToolbarComponentName.EMPLOYEE_INFORMATION_ACCRUALS_TOOLBAR,
    exact: true,
    groupId: 10,
    workItemId: 24, // associated with both the access group and the accruals em section, so it has a groupId AND workItemId.
    itemId: 32,
  },
  {
    route: '/employee-docs/',
    component: EmployeeDocsPage,
    toolbarComponent: EmployeeDocsToolbar,
    toolbarComponentName:
      ToolbarComponentName.EMPLOYEE_INFORMATION_ACCRUALS_TOOLBAR,
    exact: false,
    groupId: 23,
  },
  {
    route: '/hours-dollars-analysis',
    component: HoursEarningsAnalysisPage,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: true,
    groupId: 4,
    itemId: 61,
  },
  {
    route: '/bank-disbursments-reports-page',
    component: BankDisbursementsReportsPage,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 175,
  },
  {
    route: '/employee-average-hours-report/',
    component: EmployeeAverageHoursReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 53,
  },
  {
    route: '/fulltime-equivalent-report',
    component: FullTimeEquivalentReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 179,
  },
  {
    route: '/aca-full-time-calculation-report',
    component: ACAFullTimeCalculation,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/cumulative-seniority-report/',
    component: CumulativeSeniorityReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 114,
  },
  {
    route: '/new-job-training-credit-report',
    component: NewJobTrainingCreditReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 163,
  },
  {
    route: '/emp-avg-hours-status',
    component: EmployeeAverageHoursWithStatusReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 85,
  },
  {
    route: '/employee-change-report',
    component: EmployeeChangeReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 52,
  },
  {
    route: '/pension-report',
    component: PensionReportPage,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 65,
  },
  {
    route: '/401k-report',
    component: Payroll401kReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 11,
  },
  {
    route: '/4070Tip-report',
    component: Payroll4070TipReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 98,
  },
  {
    route: '/cash-flow-report',
    component: CashFlowReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 10,
  },
  {
    route: '/annual-fica-tip-credit-report',
    component: AnnualFICATipCreditReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 106,
  },
  {
    route: '/employee-navigator-audit-report',
    component: EmployeeNavigatorAuditReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 166,
  },
  {
    route: '/scheduled-deductions-report',
    component: ScheduledDeductionsReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 168,
  },
  {
    route: '/workers-compensation-report',
    component: WorkersCompensationReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 122,
  },
  {
    route: '/deduction-options-report',
    component: DeductionOptionsReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 107,
  },
  {
    route: '/tax-report',
    component: TaxReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 74,
  },
  {
    route: '/general-ledger-report',
    component: GeneralLedgerReportPage,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 9,
  },
  {
    route: '/general-ledger-report-viewer',
    component: GeneralLedgerPdfViewer,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 9,
  },
  {
    route: '/myinfo-doc-status-report',
    component: MyInfoDocStatusReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 151,
  },
  {
    route: '/demogrpahic-and-turnover-report',
    component: DemographicAndTurnoverReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 101,
  },
  {
    route: '/union-dues-report',
    component: UnionDuesReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 64,
  },
  {
    route: '/memo-cash-flow-report',
    component: MemoCashFlowReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 13,
  },
  {
    route: '/wotc-report',
    component: WOTCReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 202,
  },
  {
    route: '/aca-proof-list-report',
    component: ACAProofListReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/employee-pay-rate-download',
    component: EmployeePayRateDownload,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/employee-last-check-report',
    component: EmployeeLastCheckReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 104,
  },
  {
    route: '/deduction-shortages-report',
    component: DeductionShortagesReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
  },
  {
    route: '/report-downloader/:downloaderType',
    component: DownloaderPage,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/employee-wage-detail-download',
    component: EmployeeWageDetailDownload,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/gross-to-net-report',
    component: GrossToNetReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 19,
  },
  {
    route: '/employee-3year-pay-history',
    component: Employee3YearPayHistory,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/department-of-labor-report',
    component: DepartmentofLaborReportPage,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 54,
  },
  {
    route: '/cares-sba-ppp-report',
    component: CARES_SBA_PPP_Report,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 177,
  },
  {
    route: '/ohio-I29-report',
    component: OhioI29,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 181,
  },
  {
    route: '/week-month-jtd-job-cost-report',
    component: WeekMonthJTDjobCostReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 119,
  },
  {
    route: '/wh347-certified-report',
    component: WH347CertifiedReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 120,
  },
  {
    route: '/monthly-cashflow-analysis-download-report',
    component: MontlyCashFlowAnalysisDownloadReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,

  },
  {
    route: '/employee-pay-category-history-report',
    component: EmployeePayCategoryHistoryReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/employee-pay-category-history-report',
    component: EmployeePayCategoryHistoryReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/job-detail-report',
    component: JobDetailReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 182,
  },
  {
    route: '/CARES-credit-report',
    component: CARESCreditReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 178,
  },
  {
    route: '/fringe-report',
    component: FringeReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 117,
  },
  {
    route: '/electrical-fringe-report',
    component: ElectricalFringeReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 124,
  },
  {
    route: '/approved-jobs-report',
    component: ApprovedJobsReports,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 180,
  },
  {
    route: '/national-maintenance-agreement-report',
    component: NationalMaintenanceAgreementReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 128,
  },
  {
    route: '/workers-compenation-report',
    component: WorkersCompenationReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 122,
  },
  {
    route: '/eeo-cc257-report',
    component: EEOCC257,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/job-gross-summary-report',
    component: JobGrossSummaryReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 116,
  },
  {
    route: '/job-labor-report',
    component: JobLaborReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 171,
  },
  {
    route: '/eeo-report',
    component: EEOReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.ACCOUNTING_REPORTS_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 81,
  },
  {
    route: '/job-to-date-gross-summary-report',
    component: JobToDateGrossSummaryReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 123,
  },
  {
    route: '/employee-timecard-report',
    component: EmployeeTimeCardReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 121,
  },
  {
    route: '/rate-master-report',
    component: RateMasterReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 152,
  },
  {
    route: '/ocip',
    component: OCIPReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 188,
  },
  {
    route: '/employee-gross-summary-report',
    component: EmployeeGrossSummaryReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
    groupId: 4,
    itemId: 115,
  },
  {
    route: '/contractor-overview-download',
    component: ContractorOverviewDownload,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/contractor-fringe-download',
    component: ContractorFringeDownload,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/bamboo-hr-report',
    component: BambooHRReport,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/user-map/:downloaderType/:userMapId',
    component: UserMapPage,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/report-library',
    component: ReportLibrary,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/manage-applicants',
    component: ManageApplicants,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  },
  {
    route: '/manage-applicants/:id',
    component: Applicant,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  },
  {
    route: '/manage-available-jobs',
    component: ManageJobs,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: false,
  },
  {
    route: '/manage-job',
    component: ManageJob,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  },
  {
    route: '/design-interview',
    component: DesignInterviewPage,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  }, 
  {
    route: '/design-ats-website',
    component: DesignAtsWebsitePage,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  }, 
  {
    route: '/design-ats-website/web-pages',
    component: DesignAtsWebsitePage,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  }, 
  {
    route: '/design-ats-website/cover-images',
    component: DesignAtsWebsitePage,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  }, 
  {
    route: '/design-ats-website/waivers',
    component: DesignAtsWebsitePage,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  }, 
  {
    route: '/design-interview/process',
    component: DesignInterviewPage,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  },
  {
    route: '/design-interview/templates',
    component: DesignInterviewPage,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  },
  {
    route: '/design-interview/questions',
    component: DesignInterviewPage,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  },
  {
    route: '/design-interview/locations',
    component: DesignInterviewPage,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  },
  {
    route: '/design-interview/custom-fields',
    component: DesignInterviewPage,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  },
  {
    route: '/design-interview/custom-emails',
    component: DesignInterviewPage,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  },
  {
    route: '/design-interview/scorecard-categories',
    component: DesignInterviewPage,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  },
  {
    route: '/design-interview/scorecard-traits',
    component: DesignInterviewPage,
    toolbarComponent: ApplicantToolbar,
    toolbarComponentName: ToolbarComponentName.APPLICANT_TOOLBAR,
    exact: true,
  },
  {
    route: '/help-reference-guide',
    component: HelpReferenceGuide,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/hr-hcm-resources',
    component: HCMResourcesPage,
    toolbarComponent: DashboardToolBar,
    toolbarComponentName: ToolbarComponentName.DASHBOARD_TOOLBAR,
    exact: false,
  },
  {
    route: '/time-by-day',
    component: TimeByDay,
    toolbarComponent: TimeCardToolbar,
    toolbarComponentName: ToolbarComponentName.TIME_CARD_TOOLBAR,
    exact: true,
  },
  {
    route: '/job-roster',
    component: JobRoster,
    toolbarComponent: TimeCardToolbar,
    toolbarComponentName: ToolbarComponentName.TIME_CARD_TOOLBAR,
    exact: true,
  },
  {
    route: '/diagnostics',
    component: Diagnostics,
    toolbarComponent: TimeCardToolbar,
    toolbarComponentName: ToolbarComponentName.TIME_CARD_TOOLBAR,
    exact: true,
  },
  {
    route: '/time-sheet-summary',
    component: TimeSheetSummary,
    toolbarComponent: TimeCardToolbar,
    toolbarComponentName: ToolbarComponentName.TIME_CARD_TOOLBAR,
    exact: true,
  },
];

export const employeesDD: DMDropdownDataModel = {
  id: 'employees',
  title: 'Employees',
  groupId: 2,
  items: [
    {
      id: 'employees-1',
      text: 'Employee List',
      href: '/employee-master-list',
      itemId: 1,
    },
    {
      id: 'employees-3',
      text: 'Add Employee',
      href: '/employee/detail/0/add-employee',
      itemId: 3,
    },
    {
      id: 'employees-4',
      text: 'Upload Employee',
      href: '/employee-upload',
      itemId: 7,
    },
    {
      id: 'employees-5',
      text: 'Quick Onboard',
      href: '/quick-onboard',
      itemId: 157,
    },
  ],
};

export const payrollDD: DMDropdownDataModel = {
  id: 'payroll',
  title: 'Payroll',
  groupId: 3,
  items: [
    {
      id: 'payroll-1',
      text: 'Process Payroll',
      href: '/process-payroll',
      itemId: 5,
    },
    {
      id: 'payroll-2',
      text: 'Open Current Transmittal',
      href: '#', // PI-8410: we need to load these if a user hasn't already... but also check in that same instant if there's more than one...
      itemId: 38,
    },
    {
      id: 'payroll-3',
      text: 'Payroll Upload',
      href: '/upload-payroll',
      itemId: 6,
    },
    {
      id: 'payroll-4',
      text: 'Sales Tax Transmittal',
      href: '/sales-tax-transmittal',
      itemId: 40,
    },
    {
      id: 'payroll-5',
      text: 'Time & Attendance',
      href: '',
      extLink: true,
      itemGroupId: 22,
    },
  ],
};

export const menuIds = {
  PAYROLL_SSO: 'payroll-5',
  EMPLOYEE_CHANGES_SUB_MENU_ID: 'reporting-2-8',
  EMPLOYEE_DEDUCTION_SHORTAGES_SUB_MENU_ID: 'reporting-3-11',
  ACA_FULL_TIME_CALC_REPORT: 'reporting-2-1',
  BAMBOO_HR_REPORT: 'reporting-2-3',
  NAVIGATOR_AUDIT_REPORT: 'reporting-2-9',
  MYINFO_CHANGES: 'manage-1',
  OPEN_CURRENT_TRANSMITTAL: 'payroll-2',
};

const downloaderDD: DMDropdownDataModel = {
  id: 'downloader-dd',
  title: 'Downloads',
  groupId: 12,
  items: [
    {
      id: 'reporting-4-1',
      text: 'Employee 3-Year Pay History Download',
      href: '/employee-3year-pay-history',
      itemId: 76,
    },
    {
      id: 'reporting-4-2',
      text: 'Employee Earnings Download',
      href: '/report-downloader/ee',
      itemId: 35,
    },
    {
      id: 'reporting-4-3',
      text: 'Employee Master Download',
      href: '/report-downloader/em',
      itemId: 36,
    },
    {
      id: 'reporting-4-4',
      text: 'Employee Pay Rate Download',
      href: '/employee-pay-rate-download',
      itemId: 113,
    },
    {
      id: 'reporting-4-5',
      text: 'Employee Wage Detail Download',
      href: '/employee-wage-detail-download',
      itemId: 134,
    },
    {
      id: 'reporting-4-6',
      text: 'Human Resources Download',
      href: '/report-downloader/hr',
      itemId: 156,
    },
    {
      id: 'reporting-4-7',
      text: 'Monthly CashFlow Analysis Download Report',
      href: '/monthly-cashflow-analysis-download-report',
      itemId: 79,
    },
    {
      id: 'reporting-4-8',
      text: 'Pay Category History Report ',
      href: '/employee-pay-category-history-report',
      itemId: 172,
    },
    {
      id: 'reporting-4-9',
      text: 'EEO CC-257',
      href: '/eeo-cc257-report',
      isContractorOnly: true,
    },
    {
      id: 'reporting-4-10',
      text: 'Contractor Overview Download',
      href: '/contractor-overview-download',
      itemId: 143,
      isContractorOnly: true,
    },
    {
      id: 'reporting-4-11',
      text: 'Contractor Fringe Download',
      href: '/contractor-fringe-download',
      itemId: 118,
      isContractorOnly: true,
    },
  ],
};

// TODO: Change Ids to reflect changes. Right now, (at least) a few are used for targeting nav items.
export const reportingDD: DMDropdownDataModel = {
  id: 'reporting',
  title: 'Reporting',
  groupId: 4,
  items: [
    {
      id: 'reporting-1',
      text: 'Accounting',
      itemId: 72,
      href: '#',
      subGroupId: 4,
      subItems: [
        {
          id: 'reporting-1-1',
          itemId: 175,
          text: 'Bank Disbursements Report',
          href: '/bank-disbursments-reports-page',
        },
        {
          id: 'reporting-1-7',
          itemId: 177,
          text: 'CARES SBA PPP Report',
          href: '/cares-sba-ppp-report/',
        },
        {
          id: 'reporting-1-3',
          itemId: 53,
          text: 'Employee Average Hours Report',
          href: '/employee-average-hours-report/',
        },
        {
          id: 'reporting-1-5',
          itemId: 85,
          text: 'Employee Average Hours Reports With Status Report',
          href: '/emp-avg-hours-status',
        },
        {
          id: 'reporting-1-6',
          itemId: 9,
          text: 'General Ledger Report',
          href: '/general-ledger-report/',
        },
        {
          id: 'reporting-1-2',
          itemId: 61,
          text: 'Hours/Dollars Analysis Report',
          href: '/hours-dollars-analysis',
        },
        {
          id: 'reporting-1-4',
          itemId: 163,
          text: 'New Job Training Credit Report',
          href: '/new-job-training-credit-report',
        },
      ],
    },
    {
      id: 'reporting-2',
      text: 'Human Capital Management',
      href: '#',
      subGroupId: 4,
      subItems: [
        {
          id: 'reporting-2-1',
          text: 'ACA Full-Time Calculation Report',
          href: '/aca-full-time-calculation-report',
          itemId: 96,
        },
        {
          id: 'reporting-2-2',
          text: 'ACA Proof List Report',
          href: '/aca-proof-list-report',
        }, {
          id: 'reporting-2-3',
          text: 'Bamboo HR Report',
          href: '/bamboo-hr-report',
        },
        {
          id: 'reporting-2-4',
          text: 'Cumulative Seniority Report',
          href: '/cumulative-seniority-report/',
          itemId: 114,
        },
        {
          id: 'reporting-2-5',
          text: 'Demographic & Turnover Report',
          href: '/demogrpahic-and-turnover-report',
          itemId: 101,
        },
        {
          id: 'reporting-2-6',
          text: 'Department of Labor Report',
          href: '/department-of-labor-report',
          itemId: 54,
        },
        {
          id: 'reporting-2-7',
          text: 'EEO Report',
          href: '/eeo-report',
          itemId: 81,
        },
        {
          id: 'reporting-2-8',
          text: 'Employee Change Report',
          href: '/employee-change-report',
          itemId: 52,
        },
        {
          id: 'reporting-2-9',
          text: 'Employee Navigator Audit Report',
          href: '/employee-navigator-audit-report',
          itemId: 166,
        },
        {
          id: 'reporting-2-10',
          text: 'FullTime Equivalent Report',
          href: '/fulltime-equivalent-report',
          itemId: 179,
        },
        {
          id: 'reporting-2-11',
          text: 'MyInfo Document Status Report',
          href: '/myinfo-doc-status-report',
          itemId: 151,
        },
        {
          id: 'reporting-2-12',
          text: 'WOTC For Form 5884',
          href: '/wotc-report',
          itemId: 202,
        },
      ],
    },
    {
      id: 'reporting-3',
      text: 'Payroll',
      href: '/',
      subItems: [
        {
          id: 'reporting-3-2',
          text: '401k Report',
          href: '/401k-report',
          itemId: 11,
        },
        {
          id: 'reporting-3-3',
          text: '4070 Tip Report',
          href: '/4070Tip-report',
          itemId: 98,
        },
        {
          id: 'reporting-3-5',
          text: 'Annual FICA Tip Credit Report',
          href: '/annual-fica-tip-credit-report',
          itemId: 106,
        },
        {
          id: 'reporting-3-15',
          text: 'CARES Credit Report',
          href: '/CARES-credit-report',
        },
        {
          id: 'reporting-3-4',
          text: 'Cash Flow Report',
          href: '/cash-flow-report',
          itemId: 10,
        },
        {
          id: 'reporting-3-8',
          text: 'Deductions Report',
          href: '/deduction-options-report',
          itemId: 107,
        },
        {
          id: 'reporting-3-11',
          text: 'Deduction Shortages Report',
          href: '/deduction-shortages-report',
        },
        {
          id: 'reporting-3-12',
          text: 'Employee Last Check Report',
          href: '/employee-last-check-report',
          itemId: 104,
        },
        {
          id: 'reporting-3-14',
          text: 'Gross To Net Report',
          href: '/gross-to-net-report',
          itemId: 19,
        },
        {
          id: 'reporting-3-10',
          text: 'Memo Cash Flow Report',
          href: '/memo-cash-flow-report',
          itemId: 13,
        },
        {
          id: 'reporting-3-1',
          text: 'Pension Report',
          href: '/pension-report',
        }, 
        {
          id: 'reporting-3-6',
          text: 'Scheduled Deductions Report',
          href: '/scheduled-deductions-report',
          itemId: 168,
        },
        {
          id: 'reporting-3-9',
          text: 'Tax Report',
          href: '/tax-report',
          itemId: 74,
        },
        {
          id: 'payroll-3-13',
          text: 'Union Dues Report',
          href: '/union-dues-report',
          itemId: 64,
        },
        {
          id: 'reporting-3-7',
          text: "Workers' Compensation Report",
          href: '/workers-compensation-report',
          itemId: 122,
        },
      ],
    },
    {
      id: 'reporting-4',
      text: 'Custom Report Writer',
      href: '#',
      subGroupId: downloaderDD.groupId,
      subItems: downloaderDD.items,
    },
    {
      id: 'reporting-5',
      text: 'Client Library',
      href: '/report-library',
    },
  ],
};

export const contractorDD: DMDropdownItemModel = {
  id: 'reporting-6',
  text: 'Contractor',
  href: '#',
  subGroupId: 4,
  subItems: [
    {
      id: 'reporting-5-5',
      text: 'Approved Jobs Report',
      href: '/approved-jobs-report',
      itemId: 182,
    },
    {
      id: 'reporting-5-7',
      text: 'Electrical Fringe Report',
      href: '/electrical-fringe-report',
      itemId: 124,
    },
    {
      id: 'reporting-5-18',
      text: 'Employee Gross Summary Report',
      href: '/employee-gross-summary-report',
      itemId: 115,
    },
    {
      id: 'reporting-5-14',
      text: 'Employee Time Cards Report',
      href: '/employee-timecard-report',
      itemId: 121,
    },
    {
      id: 'reporting-5-6',
      text: 'Fringe Report',
      href: '/fringe-report',
      itemId: 117,
    },
    {
      id: 'reporting-5-4',
      text: 'Job Detail Report',
      href: '/job-detail-report',
    },
    {
      id: 'reporting-5-10',
      text: 'Job Gross Summary Report',
      href: '/job-gross-summary-report',
      itemId: 116,
    },
    {
      id: 'reporting-5-13',
      text: 'Job-To-Date Gross Summary Report',
      href: '/job-to-date-gross-summary-report',
      itemId: 123,
    },
    {
      id: 'reporting-5-12',
      text: 'Job Labor Report',
      href: '/job-labor-report',
      itemId: 171,
    },
    {
      id: 'reporting-5-8',
      text: 'National Maintenance Agreement Report',
      href: '/national-maintenance-agreement-report',
      itemId: 128,
    },
    {
      id: 'reporting-5-16',
      text: 'OCIP',
      href: '/ocip',
      itemId: 188,
    },
    {
      id: 'reporting-5-1',
      text: 'Ohio I-29 Report',
      href: '/ohio-I29-report',
      itemId: 181,
    },
    {
      id: 'reporting-5-15',
      text: 'Rate Master Report',
      href: '/rate-master-report',
      itemId: 152,
    },
    {
      id: 'reporting-5-2',
      text: 'Week Month JTD Job Cost Report',
      href: '/week-month-jtd-job-cost-report',
      itemId: 119,
    },
    {
      id: 'reporting-5-3',
      text: 'WH347 Certffied Report',
      href: '/wh347-certified-report',
      itemId: 120,
    },
    {
      id: 'reporting-5-9',
      text: "Workers' Compenation Report",
      href: '/workers-compenation-report',
      itemId: 122,
    },
  ],
};

const employeeInformationDD: DMDropdownDataModel = {
  id: 'emp-info-dd',
  title: 'Employee Information',
  groupId: 10,
  items: [
    {
      id: 'employee-information-earnings',
      text: 'Employee Earnings',
      href: '/employee-information/earnings',
      itemId: 27,
      workItemId: 10,
    },
    {
      id: 'employee-information-accruals',
      text: 'Employee Accruals',
      href: '/employee-information/accruals',
      itemId: 32,
      workItemId: 24,
    },
  ],
};

const applicantDD: DMDropdownDataModel = {
  id: 'applicant-dd',
  title: 'Applicant Tracking',
  groupId: 18,
  items: [
    
    {
      id: 'manage-jobs',
      text: 'Manage Available Jobs',
      href: '/manage-available-jobs',
      itemId: 44,
    },
    {
      id: 'manage-applicants',
      text: 'Manage Applicants',
      href: '/manage-applicants',
      itemId: 55,
    },
    {
      id: 'design-interview',
      text: 'Design Interview',
      href: '/design-interview',
      itemId: 66,
    },
    {
      id: 'design-ats-website',
      text: 'Design ATS Website',
      href: '/design-ats-website',
      itemId: 77,
    },
  ],
};

export const humanCapitalManagementDD = (usesWorkFlow: boolean): DMDropdownDataModel => {
  const items = [
    {
      id: 'human-1',
      text: 'Employee Information',
      href: '#',
      subGroupId: employeeInformationDD.groupId,
      subItems: employeeInformationDD.items,
    },
    {
      id: 'human-2',
      text: 'Employee Docs',
      href: '/employee-docs',
    },
    {
      id: 'human-3',
      text: 'HR Profile',
      href: '/hr-profile/detail',
    },
    {
      id: 'human-4',
      text: 'Applicant Tracking',
      href: '#',
      subGroupId: applicantDD.groupId,
      subItems: applicantDD.items,
    },    
    {
      id: 'human-5',
      text: 'HCM Resources',
      href: '/hr-hcm-resources',
    },
  ];
  
  if (usesWorkFlow) {
    items.push(    {
      id: 'human-6',
      text: 'Workflows',
      href: '/workflows',
    });
  }
  
  const dropdown: DMDropdownDataModel = {
    id: 'human',
    title: 'Human Capital Management',
    items,
  };
  
  return dropdown;
};

export const accountsPayableDD: DMDropdownDataModel = {
  id: 'ap',
  title: 'Accounts Payable',
  groupId: 19,
  items: [
    {
      id: 'ap-1',
      text: 'Manage Vendors',
      href: '/',
      itemId: 97,
    },
    {
      id: 'ap-2',
      text: 'Enter Invoices',
      href: '/',
      itemId: 108,
    },
    {
      id: 'ap-3',
      text: 'Print/Preview Checks',
      href: '/',
      itemId: 110,
    },
    {
      id: 'ap-4',
      text: 'Chart of Accounts',
      href: '/',
      itemId: 127,
    },
    {
      id: 'ap-5',
      text: 'AP Reports',
      href: '/',
      itemId: 136,
    },
  ],
};

export const manageDD: DMDropdownDataModel = {
  id: 'manage-dd',
  groupId: 17,
  title: 'Manage',
  items: [
    {
      id: 'manage-1',
      itemId: 42,
      text: 'Pending MyInfo Changes',
      href: '/review-myinfo-changes',
    },
    {
      id: 'manage-2',
      text: 'W4 Status',
      href: '/w4status',
    },
    {
      id: 'manage-3',
      itemId: 142,
      text: 'Manage I-9 Documents',
      href: '/managei9',
    },
    {
      id: 'manage-4',
      itemId: 145,
      text: 'Federal E-Verify',
      href: '//e-verify.gov/employers',
      extLink: true,
    },
  ],
};

export const correctionsDD: DMDropdownDataModel = {
  id: 'corrections-dd',
  title: 'Corrections',
  groupId: 5,
  items: [
    {
      id: 'corrections-nav-1',
      itemId: 14,
      text: 'Dept. Allocations',
      href: '/dept-allocations',
    },
    {
      id: 'corrections-nav-3',
      itemId: 164,
      text: 'EEO Info Corrections',
      href: '/eeo-info',
    },
    {
      id: 'corrections-nav-2',
      itemId: 18,
      text: 'Workers Comp Corrections',
      href: '/workers-comp',
    },  
  ],
};

export const toolsDD: DMDropdownDataModel = {
  id: 'tools-dd',
  title: 'Tools',
  groupId: 5,
  items: [
    {
      id: 'tools-nav-1',
      itemId: 17,
      text: 'Calculate Check',
      href: '/check-calculator-search',
    },
    {
      id: 'tools-nav-2',
      text: correctionsDD.title,
      href: '#',
      subGroupId: correctionsDD.groupId,
      subItems: correctionsDD.items,
    },
    {
      id: 'tools-nav-3',
      text: 'EEO Unit',
      href: '/eeo-unit',
    },
    {
      id: 'tools-nav-4',
      text: 'Global Emp Master Ded Changes',
      href: '/global-master-deductions',
    },
    {
      id: 'tools-nav-5',
      itemId: 187,
      itemGroupId: 24,
      text: 'Help Center Reference Guide',
      href: '/help-reference-guide',
    },  
    {
      id: 'tools-nav-7',
      text: 'Online Support',
      href: 'https://dmpayroll.screenconnect.com/',
      extLink: true,
    },
    {
      id: 'tools-nav-6',
      text: 'View Integration Logs',
      href: '/',
      modal: true,
    },   
  ],
};

export const currentUserDD: DMDropdownDataModel = {
  id: 'current',
  title: 'Hello',
  items: [
    {
      id: 'current-change-client',
      text: 'Change Client',
      href: '/',
      modal: true,
    },
    {
      id: 'current-logout',
      text: 'Logout',
      href: '/logout',
    },
  ],
};
