import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CheckboxGrpInLine, ControlDatePickerGrp, InputGrp, SelectGrp, SelectModalGrp } from 'core/components/form-controls';
import DepartmentOptionForm from 'core/components/form-controls/select-modal/DepartmentOptionForm';
import LocationOptionForm from 'core/components/form-controls/select-modal/LocationOptionForm';
import SubDepartment2OptionForm from 'core/components/form-controls/select-modal/SubDepartment2OptionForm';
import SubDepartmentOptionForm from 'core/components/form-controls/select-modal/SubDepartmentOptionForm';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { PHONE_VALIDATION, SSN_INFO_MESSAGE, SSN_VALIDATION } from 'core/constants';
import { Employee, EmployeeMasterDtoTaxTaxEntity, EmployeePayRate, EmployeePayRateScreen, EmployeeTaxInfo, PayRate } from 'core/models';
import { CommonService, EmployeeService } from 'core/services';
import { addUpdateEmployeePayRatesScreen, createEmployee, createPayRate, loadNextEmployeeNumber, storeNext } from 'core/store/actions';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { formatPhone, formatSSN } from 'utilities/utilities';
import EmpPhotoUpload from './EmpPhotoUpload';
import { updateTaxes } from 'core/store/actions/tax.action';
import DefaultTaxNoticeModal from './DefaultTaxNotice.modal';
import {
  getForeignCountries,
  getGenders,
  getLocationDepartmentsWithoutHome,
  getMaritalStatuses,
  getNextEmpNo,
  getNextURL,
  getStates,
  getYesNoCMOption,
} from 'core/store/selectors';
import DuplicatSsnNoticeModal from '../DuplicatSsnNotice.modal';
import Confirm1099EmpModal from '../Confirm1099Emp.modal';

const fs: FieldInputSettings = {
  empNo: {
    name: 'empNo',
    label: 'EMP. NO.',
    groupClass: 'groupClass12',
    type: 'number',
  },
  firstName: {
    name: 'firstName',
    label: 'FIRST NAME',
    groupClass: 'groupClass25',
    required: true,
  },
  midName: {
    name: 'midName',
    label: 'MID. INIT.',
    groupClass: 'groupClass12',
  },
  lastName: {
    name: 'lastName',
    label: 'LAST NAME',
    groupClass: 'groupClass25',
    required: true,
  },
  suffix: { name: 'suffix',
    label: 'SUFFIX',
    groupClass: 'groupClass12' },

  ssn: {
    name: 'ssn',
    label: 'SOCIAL SECURITY',
    groupClass: 'groupClass15',
    required: true,
  },
  ssnisappliedFor: {
    name: 'ssnisappliedFor',
    label: 'SSN Applied For:',
    groupClass: 'groupClass14',
    groupStyle: { paddingTop: '22px' },
  },
  birthDate: {
    name: 'birthDate',
    label: 'BIRTH DATE',
    groupClass: 'groupClass13',
    required: true,
  },
  hireDate: {
    name: 'hireDate',
    label: 'HIRE DATE',
    groupClass: 'groupClass13',
    required: true,
  },
  maritalStatus: {
    name: 'maritalStatus',
    groupClass: 'groupClass15',
    label: 'MARITAL STATUS',
    modalTitle: 'MARITAL STATUS',
  },
  sex: { name: 'sex',
    label: 'GENDER',
    required: true,
    groupClass: 'groupClass15' },

  address: {
    name: 'address',
    label: 'STREET ADDRESS',
    groupClass: 'groupClass30',
    required: true,
  },
  city: {
    name: 'city',
    label: 'CITY',
    groupClass: 'groupClass20',
    required: true,
  },
  state: {
    name: 'state',
    label: 'STATE',
    groupClass: 'groupClass10',
    required: true,
  },
  zip: {
    name: 'zip',
    label: 'ZIP',
    groupClass: 'groupClass10',
    required: true,
  },
  zip4: { name: 'zip4',
    label: ' ',
    groupClass: 'groupClass06' },
  country: {
    name: 'country',
    label: 'COUNTRY',
    groupClass: 'groupClass15',
    required: true,
  },

  email: { name: 'email',
    label: 'EMAIL',
    groupClass: 'groupClass25' },
  email2: { name: 'email2',
    label: 'EMAIL 2',
    groupClass: 'groupClass25' },
  homePhone: {
    name: 'homePhone',
    label: 'HOME PHONE',
    groupClass: 'groupClass20',
  },
  cellPhone: {
    name: 'cellPhone',
    label: 'CELL PHONE',
    groupClass: 'groupClass20',
  },

  loc: {
    name: 'loc',
    label: 'LOCATION',
    groupClass: 'groupClass35',
    required: true,
    showId: true,
    modalTitle: 'LOCATION',
    formComponent: LocationOptionForm,
    labelField: 'locationDesc',
    valueField: 'locationCode',
    addOptionText: 'Location',
  },
  dept: {
    name: 'dept',
    label: 'DEPARTMENT',
    groupClass: 'groupClass35',
    required: true,
    showId: true,
    modalTitle: 'DEPARTMENTS',
    formComponent: DepartmentOptionForm,
    labelField: 'deptDesc',
    valueField: 'deptCode',
    addOptionText: 'Department',
  },
  subDept: {
    name: 'subDept',
    label: 'SUB DEPARTMENT',
    groupClass: 'groupClass30',
    required: true,
    showId: true,
    modalTitle: 'SUB DEPARTMENTS',
    formComponent: SubDepartmentOptionForm,
    labelField: 'subDeptDesc',
    valueField: 'subDeptCode',
    addOptionText: 'Sub Dept',
  },
  subDept2: {
    name: 'subDept2',
    label: 'SUB DEPARTMENT 2',
    groupClass: 'groupClass30',
    required: true,
    showId: true,
    modalTitle: 'SUB DEPARTMENTS 2',
    formComponent: SubDepartment2OptionForm,
    labelField: 'sub2Desc',
    valueField: 'sub2Code',
    addOptionText: 'Sub Dept 2',
  },
};

const commonTaxProperties = {
  year: new Date().getFullYear(),
  residency: '',
  filingStatus: 'S',
  exemptions: 0,
  fan: '',
  fanAmount: 0,
  workZipCode: '',
  exempT_FAMILY: false,
  exempT_MEDICAL: false,
  nY_MCTMT: false,
  rate: 0,
  exemptDependants: 0,
  creditDependants: 0,
  multipleJobs: false,
  deductionCredit: 0,
  otherIncome: 0,
  deductions: 0,
  extraWH: 0,
  irsLockOut: false,
  employeeTaxEntitiesId: 0,
  exemptSpouse: false,
  exemptionAmount: 0,
  firstTimeAdditionalExemption: 0,
  adoptedQualifyingDependent: 0,
  isClergy: false,
};

const EmpAddInformation = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [empPicData, setEmpPicData] = useState<string>('');
  const [isForeignAddress, setIsForeignAddress] = useState(false);
  const [newEmpState, setNewEmpState] = useState<Employee>();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showSsnNoticeModal, setShowSsnNoticeModal] = useState<boolean>(false);
  const [show1099SetupModal, setShow1099SetupModal] = useState<boolean>(false);

  const nextURL = useSelector(getNextURL);
  const stateOpts = useSelector(getStates);
  const foreignCountryOpts = useSelector(getForeignCountries);
  const maritalStatusOpts = useSelector(getMaritalStatuses);
  const genderOpts = useSelector(getGenders);
  const nextEmpNo = useSelector(getNextEmpNo);
  const { locationOpts, deptOpts, subdeptOpts, subdept2Opts } = useSelector(getLocationDepartmentsWithoutHome);
  const clientNo = useAppSelector(({ client }) => client.client!.clientNo);
  const minEmpAge = useAppSelector(({ client }) => client.clientOptions?.options?.[2527]?.optionValue);
  const existingEmpSsns = useAppSelector(({ employees }) => employees.employees)?.map(({ ssn }) => ssn);
  const showFutureHireDateMsg = useSelector(getYesNoCMOption(2482));
  
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    watch,
    control,
  } = useForm<Employee>({
    defaultValues: new Employee(clientNo, 0, {
      country: 'US',
    }),
  });
  const { birthDate, hireDate, loc, dept, subDept, subDept2, ssn, ssnisappliedFor, empNo } = watch([
    'birthDate',
    'hireDate',
    'loc',
    'dept',
    'subDept',
    'subDept2',
    'ssn',
    'ssnisappliedFor',
    'empNo',
  ]);
  

  useEffect(() => {
    if (!(nextURL && newEmpState)) return;
    setShowInfoModal(true);
    return () => { dispatch(storeNext('')); };
  }, [nextURL]);

  useEffect(() => {
    dispatch(loadNextEmployeeNumber());
  }, [dispatch]);

  useEffect(() => {
    if (nextEmpNo) {
      setValue('empNo', nextEmpNo);
    }
  }, [nextEmpNo]);

  const onCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIsForeignAddress(e.target.value !== 'US');
  };

  const onCancel = () => {
    history.push('/employee-master-list');
  };

  const onSSNAppliedFor = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formSsn = getValues('ssn');

    if (!(e.target.checked || formSsn)) {
      setError('ssn', { type: 'required', message: 'SSN is required' });
      return;
    }
    clearErrors('ssn');
  };

  const onSave = (data: Employee) => {
    const newEmp = new Employee(clientNo, data.empNo, data);
    
    const empAge = Math.abs((new Date().getFullYear()) - (new Date(newEmp?.birthDate ?? new Date()).getFullYear()));
    if (
      (!!newEmp?.birthDate)
      && (empAge < Number(minEmpAge))
      && !confirm(`The birth date entered indicates the employee is below the minimum age of ${minEmpAge}. Continue?`)
    ) {
      return;
    }
    if (
      showFutureHireDateMsg
      && (new Date(newEmp?.hireDate ?? new Date()) > new Date())
      && !confirm('Selected hire date is in the future. Continue?')
    ) return;
    
    setNewEmpState(newEmp); // we want to set this for the 1099 modal to use.
    
    if (empPicData) newEmp.employeePhoto = empPicData;
    if (newEmp.ssn !== '000000000' && existingEmpSsns?.includes(newEmp.ssn)) {
      setShowSsnNoticeModal(true);
      return;
    }
    
    dispatch(createEmployee({
      employee: newEmp,
      next: '/employee/detail/{empNo}/payrate',
    }));
  };
  
  const onConfirmAdding1099Emp = () => {
    setShowSsnNoticeModal(false);
    setShow1099SetupModal(true);
  };
  
  const closeModal = () => {
    setShowInfoModal(false);
    history.push(nextURL);
  };

  return (
    <>
      <div className="dm-panel dm-panel-border">
        <div className="dm-grid-title">Employee Information</div>
        <hr className="dm-panel-hr" />
        <form onSubmit={handleSubmit(onSave)}>
          <div className="d-flex flex-row">
            <div
              className="pt-3 "
              style={{ minWidth: '100px' }}
            >
              <EmpPhotoUpload
                empPicData={empPicData}
                setEmpPicData={setEmpPicData}
              />
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex flex-row flex-wrap w-100">
                <InputGrp
                  {...fs.empNo}
                  errors={errors.empNo}
                  ref={register}
                  // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  //   setValue('empNo', +e.target.value);
                  // }}
                />
                <InputGrp
                  {...fs.firstName}
                  errors={errors.firstName}
                  ref={register({
                    required: 'First Name is Required',
                  })}
                />
                <InputGrp
                  {...fs.midName}
                  errors={errors.midName}
                  ref={register}
                />
                <InputGrp
                  {...fs.lastName}
                  errors={errors.lastName}
                  ref={register({
                    required: 'Last Name is Required',
                  })}
                />
                <InputGrp
                  {...fs.suffix}
                  errors={errors.suffix}
                  ref={register}
                />
              </div>
              <div className="d-flex flex-row flex-wrap w-100">
                <InputGrp
                  {...fs.ssn}
                  errors={errors.ssn}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('ssn', formatSSN(e.target.value));
                  }}
                  detailMessage={SSN_INFO_MESSAGE}
                  required={!ssnisappliedFor}
                  ref={register({
                    required: !ssnisappliedFor,
                    pattern: {
                      value: ssnisappliedFor ? /0{3}-0{2}-0{4}$/ : SSN_VALIDATION,
                      message: 'Invalid SSN', 
                    },
                  })}
                />
                <CheckboxGrpInLine
                  {...fs.ssnisappliedFor}
                  errors={errors.ssnisappliedFor}
                  ref={register({
                    validate: (value) => {
                      return !!((value && !getValues('ssn')) || (!value && getValues('ssn')));
                    },
                  })}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onSSNAppliedFor(e);
                  }}
                />
                <ControlDatePickerGrp
                  {...fs.birthDate}
                  errors={errors.birthDate}
                  value={birthDate}
                  setValue={setValue}
                  control={control}
                  rules={{ required: true }}
                />
                <ControlDatePickerGrp
                  {...fs.hireDate}
                  errors={errors.hireDate}
                  value={hireDate}
                  setValue={setValue}
                  control={control}
                  rules={{ required: true }}
                />

                <SelectGrp
                  {...fs.maritalStatus}
                  options={maritalStatusOpts}
                  errors={errors.maritalStatus}
                  ref={register}
                  setValue={setValue}
                />
                <SelectGrp
                  {...fs.sex}
                  errors={errors.sex}
                  ref={register({
                    required: 'Gender is Required',
                  })}
                  options={genderOpts}
                />
              </div>
              {errors.ssnisappliedFor &&
                            errors.ssnisappliedFor.type === 'validate' && (
                            <div className="text-danger">
                  <small>
                                {' '}
                                Check the SSN Applied For box if the
                                Social Security number is unknown.
                  </small>
                </div>
              )}
              <div className="d-flex flex-row w-100">
                <InputGrp
                  {...fs.address}
                  errors={errors.address}
                  ref={register}
                />
                <InputGrp
                  {...fs.city}
                  errors={errors.city}
                  ref={register}
                />
                {isForeignAddress ? (
                  <InputGrp
                    {...fs.state}
                    label={'State/Province'}
                    errors={errors.state}
                    ref={register}
                  />
                ) : (
                  <SelectGrp
                    {...fs.state}
                    errors={errors.state}
                    ref={register}
                    options={stateOpts}
                  />
                )}
                <InputGrp
                  {...fs.zip}
                  errors={errors.zip}
                  groupStyle={{
                    paddingRight: !isForeignAddress && '0',
                  }}
                  ref={register({
                    required: 'Zip Code is Required',
                  })}
                />
                {!isForeignAddress && (
                  <>
                    <span
                      style={{
                        paddingTop: '22px',
                        paddingLeft: '3px',
                        paddingRight: '3px',
                      }}
                    >
                      -
                    </span>
                    <InputGrp
                      {...fs.zip4}
                      errors={errors.zip4}
                      ref={register}
                    />
                  </>
                )}
                <SelectGrp
                  {...fs.country}
                  errors={errors.country}
                  ref={register}
                  options={foreignCountryOpts}
                  onChange={onCountryChange}
                />
              </div>

              <div className="d-flex flex-row flex-wrap w-100">
                <InputGrp
                  {...fs.email}
                  errors={errors.email}
                  ref={register}
                />
                <InputGrp
                  {...fs.email2}
                  errors={errors.email2}
                  ref={register}
                />
                <InputGrp
                  {...fs.homePhone}
                  errors={errors.homePhone}
                  onChange={(e: any) => {
                    const { value } = e?.target;
                    e.target.value = formatPhone(value);
                  }}
                  ref={register({ pattern: PHONE_VALIDATION })}
                />
                <InputGrp
                  {...fs.cellPhone}
                  errors={errors.cellPhone}
                  onChange={(e: any) => {
                    const { value } = e?.target;
                    e.target.value = formatPhone(value);
                  }}
                  ref={register({ pattern: PHONE_VALIDATION })}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="dm-grid-title mt-4">
              Location &amp; Title
            </div>
            <hr className="dm-panel-hr" />
            <div className="d-flex flex-row flex-wrap">
              {locationOpts?.length > 0 && (
                <SelectModalGrp
                  {...fs.loc}
                  options={locationOpts}
                  errors={errors.loc}
                  control={control}
                  value={loc}
                  rules={{ required: 'Location is required' }}
                  setValue={setValue}
                />
              )}
            </div>
            <div className="d-flex flex-row flex-wrap">
              {deptOpts?.length > 0 && (
                <SelectModalGrp
                  {...fs.dept}
                  options={deptOpts}
                  errors={errors.dept}
                  control={control}
                  value={dept}
                  rules={{ required: 'Department is required' }}
                  setValue={setValue}
                />
              )}
              {subdeptOpts?.length > 0 && (
                <SelectModalGrp
                  {...fs.subDept}
                  options={subdeptOpts}
                  errors={errors.subDept}
                  control={control}
                  value={subDept}
                  rules={{
                    required: 'Sub Department is required',
                  }}
                  setValue={setValue}
                />
              )}
              {subdept2Opts?.length > 0 && (
                <SelectModalGrp
                  {...fs.subDept2}
                  options={subdept2Opts}
                  errors={errors.subDept2}
                  control={control}
                  value={subDept2}
                  rules={{
                    required: 'Sub Department 2 is required',
                  }}
                  setValue={setValue}
                />
              )}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-right">
              <button
                type="button"
                className="orange-outline-button"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="orange-button ml-2"
              >
                Save
              </button>
            </div>
          </div>
        </form>
        {showInfoModal && newEmpState && nextURL ? (
          <DefaultTaxNoticeModal
            empNo={newEmpState.empNo}
            show={showInfoModal}
            onHide={closeModal}
          />
        ) : null}
      </div>
      {showSsnNoticeModal && (
        <DuplicatSsnNoticeModal
          ssn={ssn.replace(/-/g, '')}
          show={showSsnNoticeModal}
          onHide={() => { setShowSsnNoticeModal(false); }}
          onConfirm={onConfirmAdding1099Emp}
        />
      )}
      {show1099SetupModal && newEmpState && (
        <Confirm1099EmpModal
          employee={newEmpState}
          show={show1099SetupModal}
          onHide={() => { setShow1099SetupModal(false); }}
          onCancel={() => { }}
        />
      )}
    </>
  );
};

export default EmpAddInformation;
