import React from 'react';
import styles from './styles.module.scss';
import sharedStyles from 'core/components/shared/shared.module.scss';
import { convToDateString } from 'utilities/utilities';
import { colorScheme, getStatusIcon } from './constants';
import Icon from 'core/components/shared/Icon';
import { WorkFlowListDetails, WorkFlowStatus, WorkFlowTaskList } from 'core/models/WorkFlow.model';

type Props = {
  workFlowTask: WorkFlowTaskList;
  status: WorkFlowStatus;
};

const ChangeRequestDetails = ({ workFlowTask, status }: Props) => {
  return (
    <div className={styles['change-request-details']}>
      <div className={sharedStyles['section-title-blue']}>
        Details
      </div>
      <hr className="dm-panel-hr" />
      <span>
          <span className="font-weight-bold">{workFlowTask.initiator}</span>&nbsp;made for&nbsp;
          <span className="font-weight-bold">{workFlowTask.targetEmployeeName} #({workFlowTask.targetEmpNo})</span>&nbsp;on&nbsp;
          <span className="font-weight-bold">{convToDateString(workFlowTask.startDate)}</span>
      </span>
        <table className={`base-table-white sortable ${styles['workflow-table']} mt-1`}>
            <tbody>
            {workFlowTask.details.map((detail) => {
              return (<tr key = {detail.workFlowLevelUserId}>
                <td>
                  <span className="mr-2">{getStatusIcon(detail.status)}</span>&nbsp;
                  <span className="font-weight-bold">{detail.status}</span> by {detail.user} ({detail.levelDescription}) on {convToDateString(detail.changeDate)}
                </td>
              </tr>);
            })}
            </tbody>
          </table>
    </div>
  );
};

export default ChangeRequestDetails;