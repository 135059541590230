import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckboxGrpInLine,
  ControlDatePickerGrp,
  InputGrpCurrency,
  SelectModalGrp,
} from 'core/components/form-controls';
import DropdownOptionForm from 'core/components/form-controls/select-modal/DropdownOptionForm';
import { FieldInputSettings } from 'core/components/form-controls/types';
import AlertModal from 'core/components/modals/alert-modal.modal';
import PanelHeader from 'core/components/shared/PanelHeader';
import { CURRENCY_VALIDATION, UNSAVED_MESSAGE, ControlIds } from 'core/constants';
import { EmpDate, HireHistory, HireHistoryWithRehireInfo, OtherDate, Rehire } from 'core/models';
import {
  createEmpRehire,
  addHireHistoryWizard,
  deleteHireHistory,
  deleteOtherDate,
  loadEmpDate,
  loadHireHistories,
  loadOtherDates,
  storeEmpDate,
  toggleBlockNavigation,
  updateEmpDate,
  storeHireHistoryWizardMessage,
} from 'core/store/actions';
import {
  getEmpDate,
  getHireHistories,
  getLayoffCodes,
  getOtherDateDescription,
  getSelectedEmp,
  getTermCode,
  getCineTrainJobTitle,
  getClient,
  getReportOptions,
} from 'core/store/selectors';
import { getOtherDates } from 'core/store/selectors/other-date.selector';
import { convToFormObject } from 'utilities/classUtils';
import CustomDate from './CustomDate';
import HireHistoryItem from './HireHistoryItem';

import Icon from 'core/components/shared/Icon';
import { useAppSelector } from 'utilities/hooks';
import { getAccess } from 'utilities/utilities';
import TerminationWorkFlowModal from './TerminationWorkFlow.modal';
import WorkFlowTaskSuccessModal from 'features/workflows/WorkFlowTaskSuccess.modal';
import { storeShowWorkTaskSuccessModal } from 'core/store/slices/work-flow.slice';
import WizardRadioButtonModal from 'core/components/shared/wizard-modals/WizardRadioButton.modal';
import WizardDateSelectorModal from 'core/components/shared/wizard-modals/WizardDateSelector.modal';
import WizardConfirmationModal from 'core/components/shared/wizard-modals/WizardConfirmation.modal';

const fs: FieldInputSettings = {
  hireDate: {
    name: 'hireDate',
    label: 'HIRE DATE',
    groupClass: 'groupClass12',
  },
  hireDate2: {
    name: 'hireDate2',
    label: 'HIRE DATE 2',
    groupClass: 'groupClass12',
  },
  birthDate: {
    name: 'birthDate',
    label: 'BIRTH DATE',
    groupClass: 'groupClass12',
  },
  reviewDate: {
    name: 'reviewDate',
    label: 'REVIEW DATE',
    groupClass: 'groupClass12',
  },
  benefitEligibleDate: {
    name: 'benefitEligibleDate',
    label: 'BENEFIT ELIGIBLE DATE',
    groupClass: 'groupClassAuto',
  },
  cobraStartDate: {
    name: 'cobraStartDate',
    label: 'COBRA START DATE',
    groupClass: 'groupClassAuto',
  },

  lastAnnual: {
    name: 'lastAnnual',
    label: 'LAST ANNUAL',
    groupClass: 'groupClass12',
  },
  termDate: {
    name: 'termDate',
    label: 'TERM DATE',
    groupClass: 'groupClass12',
  },
  termCode: {
    name: 'termCode',
    label: 'TERM REASON / DESCRIPTION',
    groupClass: 'gc12 mw125',
    addOptionText: 'Term Code',
    modalTitle: 'TERMINATION REASON MAINTENANCE',
    dropdownName: 'TermCode',
    formComponent: DropdownOptionForm,
  },
  notEligibleForRehire: {
    name: 'notEligibleForRehire',
    label: 'Not Eligible for Rehire',
    labelFirst: false,
    labelWidth: 100,
    labelWidthUnit: '%',
    groupClass: 'groupClass25 pt-3',
  },

  raiseDate: {
    name: 'raiseDate',
    label: 'RAISE DATE',
    groupClass: 'groupClass12',
  },
  lastSalary: {
    name: 'lastSalary',
    label: 'LAST SALARY',
    groupClass: 'groupClass12',
  },
  layOffDate: {
    name: 'layOffDate',
    label: 'LAYOFF DATE',
    groupClass: 'groupClass12',
  },
  layOffCode: {
    name: 'layOffCode',
    label: 'LAYOFF REASON / DESCRIPTION',
    groupClass: 'gc12 mw125',
    addOptionText: 'Layoff Reason',
    modalTitle: 'LAYOFF REASON MAINTENANCE',
    formComponent: DropdownOptionForm,
    dropdownName: 'LayoffCode',
  },
  ignoreTermWhenPRProcessed: {
    name: 'ignoreTermWhenPRProcessed',
    label: 'Do not show warning message for this Terminated Employee when Payroll is processed',
    labelFirst: false,
    labelWidth: 100,
    labelWidthUnit: '%',
    groupClass: 'groupClass25 pt-3',
  },

  lastHourly: {
    name: 'lastHourly',
    label: 'LAST HOURLY',
    groupClass: 'groupClass12',
  },
  otherDate: {
    name: 'otherDate',
    label: 'OTHER DATE',
    groupClass: 'groupClass12',
  },
  otherDateDescriptionID: {
    name: 'otherDateDescriptionID',
    label: 'OTHER REASON / DESCRIPTION',
    groupClass: 'groupClass25',
    addOptionText: 'Other Date',
    modalTitle: 'OTHER DATE DESC. MAINTENANCE',
    formComponent: DropdownOptionForm,
    dropdownName: 'OtherDateDescription',
  },
  cineTrainJobTitleID: {
    name: 'cineTrainJobTitleID',
    label: 'CINETRAIN JOB TITLE',
    groupClass: 'groupClass15',
  },
};

type RouteParams = {
  protectedEmpNo: string;
};

const DatesPage: React.FC = () => {
  const EMAGINE_CLIENTENTITYIDs: Array<number> = [22921, 22947, 89];
  const dispatch = useDispatch();
  const { protectedEmpNo } = useParams<RouteParams>();

  const [show, setShow] = useState(false);
  const [showRehire, setShowRehire] = useState<boolean>(false);
  const [rehireEmployee, setRehireEmployee] = useState<boolean>(false);
  const [showResendI9, setShowResendI9] = useState<boolean>(false);
  const [resendI9Form, setResendI9Form] = useState<boolean>(false);
  const [resendW4Form, setResendW4Form] = useState<boolean>(false);
  const [showSetHireDate, setShowSetHireDate] = useState(false);
  const [showTermDateError, setShowTermDateError] = useState(false);
  const [showTermWorkFlowModal, setShowTermWorkFlowModal] = useState<boolean>(false);
  const [hireHistories, setHireHistories] = useState<HireHistory[]>([]);
  const [selectedHireHistory, setSelectedHireHistory] = useState<HireHistory | null>(null);
  const [otherDates, setOtherDates] = useState<OtherDate[]>([]);
  const [allowHireHistoryDelete, setAllowHireHistoryDelete] = useState<boolean>(false);

  const selEmp = useSelector(getSelectedEmp);
  const hasMyInfoLoginInfo = (selEmp?.myInfoEmail?.length || 0) > 0;
  const empDate = useSelector(getEmpDate);
  const hireHistoryItems = useSelector(getHireHistories);
  const otherDateItems = useSelector(getOtherDates);
  const termCodeOpts = useSelector(getTermCode);
  const layoffCodeOpts = useSelector(getLayoffCodes);
  const otherDateDescriptionOpts = useSelector(getOtherDateDescription);
  const minEmpAge = useAppSelector(({ client }) => client.clientOptions?.options?.[2527]?.optionValue);
  const cineTrainJobTitleOpts = useSelector(getCineTrainJobTitle);
  const client = useSelector(getClient);
  const sectionAccess = useAppSelector((state) => {return state.app.moduleAccess?.employeeMasterSections;});
  const usesTerminationWorkFlow = useSelector(getReportOptions('TerminationWorkFlow'));
  const showWorkTaskSuccessModal = useAppSelector((state) => { return state.workFlow.showWorkTaskSuccessModal; });
  const showHireHistorySuccessMessage = useAppSelector((state) => { return state.selEmployeeDetails.hireHistory.hireHistoryWizardMessage; });

  const {
    setValue,
    getValues,
    errors,
    register,
    reset,
    handleSubmit,
    control,
    watch,
    formState,
  } = useForm<EmpDate>({
    defaultValues: {
      ...(convToFormObject(
        new EmpDate(
          selEmp?.empId ?? 0,
          selEmp?.clientNo ?? 0,
          selEmp?.empNo ?? 0,
          protectedEmpNo,
        ),
        EmpDate.convProps,
      ) as EmpDate),
    },
  });

  const { isDirty, dirtyFields } = formState;
  const dirtyFieldKeys = Object.keys(dirtyFields);

  const {
    hireDate,
    hireDate2,
    birthDate,
    reviewDate,
    benefitEligibleDate,
    cobraStartDate,
    termDate,
    raiseDate,
    layOffDate,
    otherDate,
    termCode,
    layOffCode,
    otherDateDescriptionID,
    cineTrainJobTitleID,
    notEligibleForRehire,
    ignoreTermWhenPRProcessed,
  } = watch([
    'hireDate',
    'hireDate2',
    'birthDate',
    'reviewDate',
    'benefitEligibleDate',
    'cobraStartDate',
    'termDate',
    'raiseDate',
    'layOffDate',
    'otherDate',
    'termCode',
    'layOffCode',
    'otherDateDescriptionID',
    'cineTrainJobTitleID',
    'notEligibleForRehire',
    'ignoreTermWhenPRProcessed',
  ]);

  const getDefaultHireDate = () => {
    if (hireDate && hireHistories?.length === 0) return hireDate;
    else if ((hireHistories[0]?.termDate as Date) > new Date()) return hireHistories[0].termDate || new Date();
    else return new Date();
  };
  
  const [newHireDate, setNewHireDate] = useState<Date | string>(getDefaultHireDate());

  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(loadEmpDate({ protectedEmpNo,
        updateKeys: [] }));
      dispatch(loadHireHistories(protectedEmpNo));
      dispatch(loadOtherDates(protectedEmpNo));
      setSelectedHireHistory(null);
      setRehireEmployee(false);
      setResendI9Form(false);
      setResendW4Form(false);
      setNewHireDate(getDefaultHireDate());
    }
  }, [protectedEmpNo]);

  useEffect(() => {
    if (hireHistoryItems) {
      setHireHistories(hireHistoryItems);
      setAllowHireHistoryDelete(!!(hireHistoryItems.length > 1));
    }
  }, [hireHistoryItems]);

  useEffect(() => {
    if (otherDateItems) {
      setOtherDates(otherDateItems);
    }
  }, [otherDateItems]);

  useEffect(() => {
    if (empDate) {
      reset({ ...empDate });
    }
  }, [empDate, selEmp?.empNo]);
  
  const onAddCustomDate = () => {
    if (!selEmp) return;
    
    setOtherDates((prev: OtherDate[]) => {
      return [
        ...prev,
        new OtherDate(
          selEmp.empId,
          selEmp.clientNo,
          selEmp.empNo,
          protectedEmpNo,
        ),
      ];
    });
  };

  const onDeleteCustomDate = (data: OtherDate) => {
    if (!data.otherDateID) return;
    
    dispatch(deleteOtherDate(data));
  };

  const onAddHireHistory = () => {
    if (hireHistories?.length > 0 && hireHistories?.find(x => x.termDate === null)) return setShowTermDateError(true);
    if (selEmp) setShowRehire(true);
  };

  const onSubmitWizard = () => {
    const priorHireDate = empDate?.hireDate;
    if (selEmp && priorHireDate) {      
      const newHireHistory = new HireHistoryWithRehireInfo(
        selEmp.empId,
        selEmp.clientNo,
        selEmp.empNo,
        protectedEmpNo,
        priorHireDate,
        rehireEmployee,
        resendI9Form,
        resendW4Form,
        //If they have no hire history record and add one and have a hireDate set then use that hire date else use todays date.
        { hireDate: newHireDate },
      );
      dispatch(addHireHistoryWizard(newHireHistory));
    }
  };

  const onDeleteHireHistory = (data: HireHistory) => {
    if (!(hireHistories?.length > 1 && data?.empDateID)) return;
    //The protected Emp No is not saved on the HireHistory so if you add a record and not re-call GET it throws an error. So we will just assign the protected EmpNo
    if (selEmp) data.protectedEmpNo = selEmp.protectedEmpNo;
    dispatch(deleteHireHistory(data));

    //If we delete the term date update the hire/term date to the most recent date.
    updateHireAndTermDate(hireHistories[1], true);
  };

  const updateHireAndTermDate = (data: Partial<HireHistory>, updateAfterAddOrDelete = false) => {
    if (!hireHistories?.length || (data?.empDateID !== hireHistories[0]?.empDateID && !updateAfterAddOrDelete)) return;
    setValue('termDate', data?.termDate, { shouldDirty: false });
    setValue('hireDate', data?.hireDate, { shouldDirty: false });
    setValue('termCode', data?.termCodeID, { shouldDirty: false });
    
    // PI-8410: We want to update this in the store if a hire history item changes.
    // This is because DatesPage relies on the empDate from the store, and if that has a termDate it won't update from a hire history item.
    const newEmpDate = new EmpDate(
      selEmp?.empId ?? 0,
      selEmp?.clientNo ?? 0,
      selEmp?.empNo ?? 0,
      protectedEmpNo,
      {
        ...empDate,
        termDate: data?.termDate ?? null,
        hireDate: data?.hireDate ?? null,
        termCode: data?.termCodeID ?? null,
      },
    );
    
    dispatch(storeEmpDate({ empDate: newEmpDate, updateKeys: [] }));
  };

  const validateHireHistoryDates = (): boolean => {
    if (hireHistories?.length < 1) return true;

    let valid = true;
    let prevHireDate = hireHistories[0].hireDate as Date;

    hireHistories.forEach((hh, index) => {
      if (index > 0) {
        valid = !hh.termDate || (hh.hireDate as Date)?.getTime() <= (hh.termDate as Date)?.getTime();
        valid = valid && prevHireDate?.getTime() >= (hh.termDate as Date)?.getTime();
        prevHireDate = hh.hireDate as Date;
      }
    });

    return valid;
  };

  const onSave = (data: EmpDate) => {
    if (empDate && selEmp) {
      if (!validateHireHistoryDates()) {
        setShow(true);
        return;
      }
      
      const empAge = Math.abs((new Date().getFullYear()) - (new Date(data?.birthDate ?? new Date()).getFullYear()));
      if (
        (!!data?.birthDate)
        && empAge < Number(minEmpAge)
        && !confirm(`The birth date entered indicates the employee is below the minimum age of ${minEmpAge}. Continue?`)
      ) {
        return;
      }

      setHireHistories((prev) => {
        return prev.map((hh, index) => {
          return !index
            ? {
              ...hh,
              termDate,
              termCodeID: getValues('termCode'),
            }
            : { ...hh };
        },
        );
      },
      );
      const newEmpDate = new EmpDate(
        empDate.empId,
        empDate.clientNo,
        empDate.empNo,
        protectedEmpNo,
        data,
      );
      dispatch(updateEmpDate({ empDate: newEmpDate,
        updateKeys: dirtyFieldKeys }));
    }
  };
  
  const handleDateChange = (target: keyof EmpDate, value: Date) => {   
    setValue(target, value);
    if (value) {
      setValue('termDate', null);
    }
  };

  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [isDirty]);
  
  return (
    <div>
      <div
        className="dm-panel dm-panel-border"
        style={{ minHeight: '300px' }}
      >
        <PanelHeader
          title="Dates"
          hrClass="hr-2 mb-1"
        />
        <form onSubmit={handleSubmit(onSave)}>
          <div className="d-flex flex-wrap">
            <ControlDatePickerGrp
              {...fs.hireDate}
              {...getAccess(sectionAccess, 7, ControlIds.hireDate, { readOnlyDefault: true })}
              errors={errors.hireDate}
              value={hireDate}
              control={control}
            />
            <ControlDatePickerGrp
              {...fs.hireDate2}
              {...getAccess(sectionAccess, 7, ControlIds.hireDate2)}
              value={hireDate2}
              onChange={(value: Date) => {
                handleDateChange('hireDate2', value);
              }}
              control={control}
            />
            <ControlDatePickerGrp
              {...fs.birthDate}
              {...getAccess(sectionAccess, 7, ControlIds.birthDate)}
              value={birthDate}
              setValue={setValue}
              control={control}
            />
            <ControlDatePickerGrp
              {...fs.reviewDate}
              {...getAccess(sectionAccess, 7, ControlIds.reviewDate)}
              value={reviewDate}
              setValue={setValue}
              control={control}
            />
            <ControlDatePickerGrp
              {...fs.benefitEligibleDate}
              {...getAccess(sectionAccess, 7)}
              value={benefitEligibleDate}
              setValue={setValue}
              control={control}
            />
            <ControlDatePickerGrp
              {...fs.cobraStartDate}
              {...getAccess(sectionAccess, 7)}
              value={cobraStartDate}
              setValue={setValue}
              control={control}
            />
            <SelectModalGrp
              {...fs.cineTrainJobTitleID}
              {...getAccess(sectionAccess, 7, undefined, { visibleDefault: EMAGINE_CLIENTENTITYIDs.indexOf(client?.clientEntityID ?? 0) != -1 })}
              errors={errors.cineTrainJobTitleID}
              options={cineTrainJobTitleOpts}
              noOption={true}
              modalTitle="CineTrain Job Title"
              formComponent={DropdownOptionForm}
              addOptionText="CineTrain Job Title"
              dropdownName="CineTrainJobTitle"
              control={control}
              value={cineTrainJobTitleID}
              setValue={setValue}
            />
          </div>
          <div className="d-flex flex-wrap">
            <div className="groupClass12"></div>
            <InputGrpCurrency
              {...fs.lastAnnual}
              {...getAccess(sectionAccess, 7, undefined, { readOnlyDefault: true, disabledSameAsReadOnly: true })}
              error={errors.lastAnnual}
              setValue={setValue}
              ref={register(CURRENCY_VALIDATION)}
            />
            <div className="groupClassVB"></div>
            <ControlDatePickerGrp
              {...fs.termDate}
              {...getAccess(sectionAccess, 7, ControlIds.termDate)}
              value={termDate}
              setValue={setValue}
              control={control}
              disabled={usesTerminationWorkFlow?.showReport || false}
            />
            <SelectModalGrp
              {...fs.termCode}
              {...getAccess(sectionAccess, 7)}
              options={termCodeOpts}
              errors={errors.termCode}
              control={control}
              value={termCode}
              setValue={setValue}
              disabled={usesTerminationWorkFlow?.showReport || false}
            />
            <div className="groupClass01"></div>
            <CheckboxGrpInLine
              {...fs.notEligibleForRehire}
              {...getAccess(sectionAccess, 7, undefined, { disabledSameAsReadOnly: true })}
              errors={errors.notEligibleForRehire}
              ref={register}
              disabled={usesTerminationWorkFlow?.showReport || false}
            />
          </div>
          <div className="d-flex flex-wrap">
            <ControlDatePickerGrp
              {...fs.raiseDate}
              {...getAccess(sectionAccess, 7, ControlIds.raiseDate)}
              value={raiseDate}
              setValue={setValue}
              control={control}
            />
            <InputGrpCurrency
              {...fs.lastSalary}
              {...getAccess(sectionAccess, 7, undefined, { readOnlyDefault: true, disabledSameAsReadOnly: true })}
              errors={errors.lastSalary}
              // onBlur={onBlurSalary}
              setValue={setValue}
              ref={register(CURRENCY_VALIDATION)}
            />
            <div className="groupClassVB"></div>
            <ControlDatePickerGrp
              {...fs.layOffDate}
              {...getAccess(sectionAccess, 7)}
              value={layOffDate}
              setValue={setValue}
              control={control}
            />
            <SelectModalGrp
              {...fs.layOffCode}
              {...getAccess(sectionAccess, 7)}
              options={layoffCodeOpts}
              errors={errors.layOffCode}
              control={control}
              setValue={setValue}
              value={layOffCode}
            />
            <div className="groupClass01"></div>
            {termDate && (
              <CheckboxGrpInLine
                {...fs.ignoreTermWhenPRProcessed}
                {...getAccess(sectionAccess, 7, undefined, { disabledSameAsReadOnly: true } )}
                errors={errors.ignoreTermWhenPRProcessed}
                ref={register}
                disabled={usesTerminationWorkFlow?.showReport || false}
              />
            )}
          </div>
          <div className="d-flex flex-wrap">
            <div className="groupClass12"></div>
            <InputGrpCurrency
              {...fs.lastHourly}
              {...getAccess(sectionAccess, 7, undefined, { readOnlyDefault: true, disabledSameAsReadOnly: true })}
              errors={errors.lastHourly}
              // onBlur={onBlurHourly}
              setValue={setValue}
              ref={register(CURRENCY_VALIDATION)}
            />
            <div className="groupClassVB"></div>
            <ControlDatePickerGrp
              {...fs.otherDate}
              {...getAccess(sectionAccess, 7, ControlIds.otherDate)}
              value={otherDate}
              setValue={setValue}
              control={control}
            />
            <SelectModalGrp
              {...fs.otherDateDescriptionID}
              {...getAccess(sectionAccess, 7)}
              options={otherDateDescriptionOpts}
              errors={errors.otherDateDescriptionID}
              control={control}
              setValue={setValue}
              value={otherDateDescriptionID}
            />
          </div>
          <div className="row">
            <div className="col-12 text-right">
              <button
                type="submit"
                className="btn orange-button-sm mr-3"
                {...getAccess(sectionAccess, 7, undefined, { disabledDefault: !isDirty, disabledSameAsReadOnly: true })}
              >
                Save
              </button>
            </div>
          </div>
        </form>
        <PanelHeader
          title="Custom Dates"
          hrClass="hr-2"
        >
          <button
            {...getAccess(sectionAccess, 7, undefined, { disabledSameAsReadOnly: true })}
            type="button"
            className="btn btn-link dm-grid-action-title pb-0"
            onClick={onAddCustomDate}
          >
            Add Date <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        {otherDates.map((item, index: number) => {
          return (
            <div key={index}>
              {selEmp && (
                <CustomDate
                  item={item}
                  selEmp={selEmp}
                  onDelete={onDeleteCustomDate}
                />
              )}
              <hr className="dm-panel-hr mt-0" />
            </div>
          );
        })}
        <PanelHeader
          title="Hire History"
          hrClass="hr-2"
        >
          <button
            {...getAccess(sectionAccess, 7, undefined, { disabledSameAsReadOnly: true })}
            type="button"
            className="btn btn-link dm-grid-action-title pb-0"
            onClick={onAddHireHistory}
          >
            Add Date
            <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        {hireHistories?.map((item) => {
          return (
            <div key={item.empDateID}>
              {selEmp && (
                <HireHistoryItem
                  item={item}
                  selEmp={selEmp}
                  mainHireDate={hireDate}
                  onDelete={onDeleteHireHistory}
                  updateHireAndTermDate={updateHireAndTermDate}
                  setShowTermWorkFlowModal={setShowTermWorkFlowModal}
                  setSelectedHireHistory={setSelectedHireHistory}
                  allowDelete={allowHireHistoryDelete}
                  usesTerminationWorkFlow={usesTerminationWorkFlow?.showReport || false}
                />
              )}
              <hr className="dm-panel-hr mt-0" />
            </div>
          );
        })}
      </div>
      {show && (
        <AlertModal
          title="Data Error"
          message="Hire Dates must be <= Term Dates with no overlap in Hire History"
          btnText="OK"
          show={show}
          onHide={() => { return setShow(false); }}
        />
      )}
      {showTermDateError && (
        <AlertModal
          title="Data Error"
          message="Cannot add a new hire history date before adding a termination date to the previous hire history record."
          btnText="OK"
          show={showTermDateError}
          onHide={() => { return setShowTermDateError(false); }}
        />
      )}
      {showRehire && (
        <WizardRadioButtonModal
          show={showRehire}
          modalTitle={(hasMyInfoLoginInfo) ? 'Step 1 of 3: Rehire Employee' : 'Step 1 of 2: Rehire Employee'} 
          question='Is this employee being rehired?'
          body='By selecting "Yes" this employee will be viewed as a new hire by DM Payroll Solutions and included in your next New Hire report.'
          radioButtons={[{defaultValue: rehireEmployee, setValue: setRehireEmployee, label: 'Rehire the Employee'}]}
          onHide={() => { return setShowRehire(false); }}
          next={() => {return (hasMyInfoLoginInfo) ? [setShowResendI9(true), setShowRehire(false)] : [setShowSetHireDate(true), setShowRehire(false)]}}
        />
      )}
      {showResendI9 && (
        <WizardRadioButtonModal
        show={showResendI9}
        modalTitle='Step 2 of 3: Rehire Employee'
        question='Would you like to ask the employee to fill out a new I9 and/or W4 form?'
        body='By selecting "Yes" this employee will recive an email letting them know that they have to log into MyInfo and fill out new forms.'
        radioButtons={[{defaultValue: resendW4Form, setValue: setResendW4Form, label: 'Ask to fill out W4 File'}, {defaultValue: resendI9Form, setValue: setResendI9Form, label: 'Ask to fill out I9 File'}]}
        onHide={() => { return setShowResendI9(false); }}
        next={() => {return [setShowSetHireDate(true), setShowResendI9(false)]}}
        previous={() => {return [setShowRehire(true), setShowResendI9(false)]}}
      />
      )}
      {showSetHireDate && (
        <WizardDateSelectorModal
        show={showSetHireDate}
        modalTitle={(hasMyInfoLoginInfo) ? 'Step 3 of 3: Rehire Employee' : 'Step 2 of 2: Rehire Employee'} 
        body='Please select a re-hire date for the employee:'
        datePickerLabel='Hire Date'
        defaultValue={newHireDate}
        setValue={setNewHireDate}
        onHide={() => { return setShowSetHireDate(false); }}
        submit={() => {return [onSubmitWizard(), setShowSetHireDate(false)]}}
        previous={() => {return (hasMyInfoLoginInfo) ? [setShowResendI9(true), setShowSetHireDate(false)] : [setShowRehire(true), setShowSetHireDate(false)]}}
      />
      )}
      {selectedHireHistory ? 
      <TerminationWorkFlowModal
        show={showTermWorkFlowModal} 
        onHide={() => {setShowTermWorkFlowModal(false); setSelectedHireHistory(null);}}
        selectedHireHistory={selectedHireHistory}
        notEligible={notEligibleForRehire}
        ignoreTermWhenPRProcessed={ignoreTermWhenPRProcessed}
      /> : null}
      {showWorkTaskSuccessModal ? 
        <WorkFlowTaskSuccessModal 
          show={showWorkTaskSuccessModal}
          onHide={() => {dispatch(storeShowWorkTaskSuccessModal(false))}}/>
      : null}
      {showHireHistorySuccessMessage ? 
        <WizardConfirmationModal 
          show={showHireHistorySuccessMessage.length > 0}
          bodyMessage={showHireHistorySuccessMessage}
          question={'Would you like to go to the Pay Rate screen to update the employees pay rate?'}
          title={'Hire History Successful'}
          navigationPath={`/employee/detail/${protectedEmpNo}/payrate`}
          onHide={() => {dispatch(storeHireHistoryWizardMessage(''))}}
          buttonText={'Go To Pay Rate Screen'}
        />
      : null}
    </div>
  );
};

export default DatesPage;
