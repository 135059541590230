import React, { useEffect, useState } from 'react';
import DmPageWrapper from 'core/components/shared/DmPageWrapper';
import styles from './styles.module.scss';
import sharedStyles from 'core/components/shared/shared.module.scss';
import { convToDateString } from 'utilities/utilities';
import { defaultFilterModel, getStatusIcon } from './constants';
import { useBreakpoint } from 'utilities/BreakpointProvider';
import DetailRow from 'core/components/shared/DetailRow';
import Icon from 'core/components/shared/Icon';
import { SelectGrp } from 'core/components/form-controls';
import ChangeRequestModal from './ChangeRequest.modal';
import {  useAppSelector } from 'utilities/hooks';
import { WorkFlowStatus, WorkFlowTaskList } from 'core/models/WorkFlow.model';
import useTableSort, { SortDirection } from 'hooks/useTableSort';
import { buildInitialState } from 'core/components/shared/table/utilities';
import { TableModel as Tm } from 'core/components/shared/table/types';
import SortableHeader from 'core/components/shared/table/SortableHeader';
import { filterModelOpts } from 'core/components/shared/table/constants';
import { workflowAggregateFilter } from './utilities';

type SelectedType = 'In-Progress' | 'Approved' | 'Denied' | 'Canceled' | 'All';

const typeOptions: { id: SelectedType, description: SelectedType }[] = [
  { id: 'In-Progress', description: 'In-Progress' },
  { id: 'Approved', description: 'Approved' },
  { id: 'Denied', description: 'Denied' },
  { id: 'Canceled', description: 'Canceled' },
  { id: 'All', description: 'All' },
];

const WorkFlowPage = () => {
  const workFlowTaskList = useAppSelector(state => state.workFlow.workFlowTaskList);

  const [rowData, setRowData] = useState<WorkFlowTaskList[]>(workFlowTaskList.filter((x) => x.status === WorkFlowStatus.Pending) ?? []);
  const [filterModel, setFilterModel] = useState<Tm.FilterModel<WorkFlowTaskList>>(defaultFilterModel);
  const [selectedType, setSelectedType] = useState<SelectedType>('In-Progress');
  const [selectedWorkflow, setSelectedWorkflow] = useState<WorkFlowTaskList | null>(null);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [activeCol, setActiveCol] = useState<keyof WorkFlowTaskList>('startDate');
  
  const { currentSortState, sortDispatch, sortRowsByColumn } = useTableSort(buildInitialState(defaultFilterModel));
  
  const breakpoints = useBreakpoint();

  useEffect(() => {
    const rows = sortRowsByColumn(workFlowTaskList.filter((x) => x.status === WorkFlowStatus.Pending) ?? [], [activeCol], currentSortState[activeCol], ['startDate'], 'DESC');
    setRowData(rows);
  }, [workFlowTaskList]);
    
  const onTypeFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedType(e.target.value as SelectedType);

    const sortedFilteredRows = sortRowsByColumn(
      workflowAggregateFilter(
        workFlowTaskList,
        filterModel,
        e.target.value as SelectedType,
      ),
      [activeCol],
      currentSortState[activeCol],
      ['startDate'],
      'DESC',
    );
    
    setRowData(sortedFilteredRows);
  };
  
  const onShowTaskDetail = (task: WorkFlowTaskList) => {
    setSelectedWorkflow(task);
    setShowDetailModal(true);
  };
  
  const onColumnSort = (column: keyof WorkFlowTaskList) => {
    sortDispatch({ type: 'TOGGLE_SORT', column });
    setActiveCol(column);
    
    // TODO: This is really ugly. We want the next one but it's not yet available from the reducer so determine it here.
    const currentSort: SortDirection = currentSortState?.[column] ?? 'UNSORTED';
    const nextSort: SortDirection = currentSort === 'ASC' ? 'DESC' : currentSort === 'DESC' ? 'UNSORTED' : 'ASC';
    const updatedRowData = sortRowsByColumn<WorkFlowTaskList>(rowData, [column], nextSort, ['startDate'], 'DESC');

    const updatedFilterModel: Tm.FilterModel<WorkFlowTaskList> = {
      ...structuredClone(filterModel),
      [column]: {
        ...(filterModel?.[column] ?? {}),
        sortOrder: nextSort,
      },
    };
    
    setRowData(updatedRowData);
    setFilterModel(updatedFilterModel);
  };
  
  const onColumnFilter = (columnQuery: string, column: keyof WorkFlowTaskList) => {
    const updatedFilterModel: Tm.FilterModel<WorkFlowTaskList> = {
      ...filterModel,
      [column]: {
        ...(filterModel?.[column] ?? {}),
        sortOrder: filterModel?.[column]?.sortOrder ?? 'UNSORTED',
        query: columnQuery,
      },
    };
    
    const newRowData = workflowAggregateFilter(workFlowTaskList, updatedFilterModel, selectedType);
    
    setFilterModel(updatedFilterModel);
    setRowData(newRowData);
  };
  
  const onColumnFilterTypeChange = (filter: Tm.FilterType, column: keyof WorkFlowTaskList) => {
    const updatedFilterModel: Tm.FilterModel<WorkFlowTaskList> = {
      ...filterModel,
      [column]: {
        ...(filterModel?.[column] ?? {}),
        sortOrder: filterModel?.[column]?.sortOrder ?? 'UNSORTED',
        query: ['Blank', 'Not blank'].includes(filter) ? '' : filterModel?.[column]?.query, // clear query if just checking for existence or absence of value
        filterType: filter,
      },
    };
    
    const newRowData = workflowAggregateFilter(workFlowTaskList, updatedFilterModel, selectedType);
    
    setFilterModel(updatedFilterModel);
    setRowData(newRowData);
  };
  
  const onUpdateCompareDate = (newDate: Date | null, column: keyof WorkFlowTaskList) => {
    const updatedFilterModel: Tm.FilterModel<WorkFlowTaskList> = {
      ...structuredClone(filterModel),
      [column]: {
        ...(filterModel?.[column] ?? {}),
        sortOrder: filterModel?.[column]?.sortOrder ?? 'UNSORTED',
        query: '', // clear query since we're adding a specific date range
        compareDate: String(newDate),
      },
    };
    
    const newRowData = workflowAggregateFilter(workFlowTaskList, updatedFilterModel, selectedType);
      
    setFilterModel(updatedFilterModel);
    setRowData(newRowData);
  };
  
  const onUpdateDateRange = (newDate: Date | null, property: keyof Pick<Tm.FilterData, 'dateFrom' | 'dateTo'>, column: keyof WorkFlowTaskList) => {
    const updatedFilterModel: Tm.FilterModel<WorkFlowTaskList> = {
      ...structuredClone(filterModel),
      [column]: {
        ...(filterModel?.[column] ?? {}),
        sortOrder: filterModel?.[column]?.sortOrder ?? 'UNSORTED',
        query: '', // clear query since we're adding a specific date range
        [property]: String(newDate),
      },
    };
    
    const newRowData = workflowAggregateFilter(workFlowTaskList, updatedFilterModel, selectedType);
      
    setFilterModel(updatedFilterModel);
    setRowData(newRowData);
  };
  
  const onClearFilter = () => {
    setFilterModel(defaultFilterModel);
    setRowData(sortRowsByColumn<WorkFlowTaskList>(workFlowTaskList.filter((x) => x.status === 'Pending'), ['startDate'], 'DESC', ['startDate']));
    setSelectedType('In-Progress');
    sortDispatch({ type: 'RESET', column: '' });
    setActiveCol('startDate');
  };
  
  return (
    <>
      <DmPageWrapper title="Workflows">
        <div className="d-flex">
          <div className="d-flex flex-column">
            <div className={`${sharedStyles['section-title-blue']} mr-3`}>{selectedType} workflows ({rowData.length} total)</div>
            <SelectGrp
              name="Workflow status"
              value={selectedType}
              options={typeOptions}
              onChange={onTypeFilterChange}
              groupClass="mw200"
            />
          </div>
          <button
            type="button"
            className="btn orange-button ml-3 my-auto"
            onClick={onClearFilter}
            style={{
              height: 'fit-content',
            }}
          >
            Clear Filters
          </button>
        </div>
        <div className={`dm-table-container ${styles['workflow-table-container']}`}>
          {/* render mobile view */}
          {breakpoints.sm ? (
            <div className="d-flex flex-column">
              {rowData.map((x, i) => {
                return (
                  <DetailRow
                    key={i}
                    expandByDefault
                    rowClick
                  >
                    <DetailRow.SummaryContainer>
                      <div className="d-flex">
                        <div className="mr-2">{getStatusIcon(x.status)}</div>
                        <span>{x.targetEmployeeName}, {convToDateString(x.startDate)}</span>
                      </div>
                    </DetailRow.SummaryContainer>
                    <DetailRow.DetailsContainer>
                      <div className="d-flex flex-column pb-2">
                        <div>
                          <span className="font-weight-bold">Workflows:&nbsp;</span>{x.workFlowType}
                        </div>
                        <div>
                          <span className="font-weight-bold">Employee:&nbsp;</span>{x.targetEmployeeName}
                        </div>
                        <div>
                          <span className="font-weight-bold">Initiators:&nbsp;</span>{x.initiator}
                        </div>
                        <div>
                          <span className="font-weight-bold">Approvers:&nbsp;</span>{x.approvers.join(', ')}
                        </div>
                        <div>
                          <span className="font-weight-bold">Started:&nbsp;</span>{convToDateString(x.startDate)}
                        </div>
                        <div>
                          <span className="font-weight-bold">Completed:&nbsp;</span>{x.dateCompleted ? convToDateString(x.dateCompleted) : (
                            <span>
                              Incomplete&nbsp;<Icon
                                name="warning"
                                color="#ecda15"
                              />
                            </span>
                          )}
                        </div>
                      </div>
                      <button
                        className="btn btn-link pl-0"
                        onClick={() => {
                          onShowTaskDetail(x);
                        }}
                      >
                        View Details
                      </button>
                    </DetailRow.DetailsContainer>
                  </DetailRow>
                );
              })}
            </div>
          ) : (
          /* render table */
            <table className={`base-table sortable ${styles['workflow-table']}`}>
              <colgroup>
                <col style={{ width: '8%', minWidth: '65px' }} />
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <SortableHeader<WorkFlowTaskList>
                    column="status"
                    label="Status"
                    sortState={currentSortState?.status ?? 'UNSORTED'}
                    onColumnSort={onColumnSort}
                    onColumnFilter={onColumnFilter}
                    onColumnFilterTypeChange={onColumnFilterTypeChange}
                    filterData={filterModel?.status ?? null}
                    filterOptions={filterModelOpts}  
                    onUpdateDateRange={onUpdateDateRange}
                    onUpdateCompareDate={onUpdateCompareDate}
                    leftBuffer={-30}
                  />
                  <SortableHeader<WorkFlowTaskList>
                    column="workFlowType"
                    label="Workflows"
                    sortState={currentSortState?.workFlowType ?? 'UNSORTED'}
                    onColumnSort={onColumnSort}
                    onColumnFilter={onColumnFilter}
                    onColumnFilterTypeChange={onColumnFilterTypeChange}
                    filterData={filterModel?.workFlowType ?? null}
                    filterOptions={filterModelOpts}  
                    onUpdateDateRange={onUpdateDateRange}
                    onUpdateCompareDate={onUpdateCompareDate}
                    leftBuffer={-30}
                  />
                  <SortableHeader<WorkFlowTaskList>
                    column="targetEmployeeName"
                    label="Employee Name"
                    sortState={currentSortState?.targetEmployeeName ?? 'UNSORTED'}
                    onColumnSort={onColumnSort}
                    onColumnFilter={onColumnFilter}
                    onColumnFilterTypeChange={onColumnFilterTypeChange}
                    filterData={filterModel?.targetEmployeeName ?? null}
                    filterOptions={filterModelOpts}  
                    onUpdateDateRange={onUpdateDateRange}
                    onUpdateCompareDate={onUpdateCompareDate}
                    leftBuffer={-30}
                  />
                  <SortableHeader<WorkFlowTaskList>
                    column="startDate"
                    label="Started"
                    sortState={currentSortState?.startDate ?? 'UNSORTED'}
                    onColumnSort={onColumnSort}
                    onColumnFilter={onColumnFilter}
                    onColumnFilterTypeChange={onColumnFilterTypeChange}
                    filterData={filterModel?.startDate ?? null}
                    filterOptions={filterModelOpts}  
                    onUpdateDateRange={onUpdateDateRange}
                    onUpdateCompareDate={onUpdateCompareDate}
                    leftBuffer={-30}
                  />
                  <SortableHeader<WorkFlowTaskList>
                    column="approvers"
                    label="Approver (latest)"
                    sortState={currentSortState?.approvers ?? 'UNSORTED'}
                    onColumnSort={onColumnSort}
                    onColumnFilter={onColumnFilter}
                    onColumnFilterTypeChange={onColumnFilterTypeChange}
                    filterData={filterModel?.approvers ?? null}
                    filterOptions={filterModelOpts}  
                    onUpdateDateRange={onUpdateDateRange}
                    onUpdateCompareDate={onUpdateCompareDate}
                    leftBuffer={-30}
                  />
                  <SortableHeader<WorkFlowTaskList>
                    column="initiator"
                    label="Initiator"
                    sortState={currentSortState?.initiator ?? 'UNSORTED'}
                    onColumnSort={onColumnSort}
                    onColumnFilter={onColumnFilter}
                    onColumnFilterTypeChange={onColumnFilterTypeChange}
                    filterData={filterModel?.initiator ?? null}
                    filterOptions={filterModelOpts}  
                    onUpdateDateRange={onUpdateDateRange}
                    onUpdateCompareDate={onUpdateCompareDate}
                    leftBuffer={-30}
                  />
                  <SortableHeader<WorkFlowTaskList>
                    column="dateCompleted"
                    label="Completed"
                    sortState={currentSortState?.dateCompleted ?? 'UNSORTED'}
                    onColumnSort={onColumnSort}
                    onColumnFilter={onColumnFilter}
                    onColumnFilterTypeChange={onColumnFilterTypeChange}
                    filterData={filterModel?.dateCompleted ?? null}
                    filterOptions={filterModelOpts}  
                    onUpdateDateRange={onUpdateDateRange}
                    onUpdateCompareDate={onUpdateCompareDate}
                    leftBuffer={-30}
                  />                   
                </tr>
              </thead>
              <tbody>
                {rowData.map((x, i) => {
                  return (
                    <tr
                      key={i}
                      onClick={() => {
                        onShowTaskDetail(x);
                      }}
                    >
                      <td>
                        <div className="d-flex">
                          <div>{getStatusIcon(x.status) ?? ''}</div>
                          <div className="ml-1">{x.status ?? 'Not available'}</div>
                        </div>
                      </td>
                      <td>{x.workFlowType}</td>
                      <td>{x.targetEmployeeName}</td>
                      <td>{convToDateString(x.startDate)}</td>
                      <td>{x.approvers?.[x.approvers.length - 1]}</td>
                      <td>{x.initiator}</td>
                      <td>{x.dateCompleted ? convToDateString(x.dateCompleted) : ''}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )
          }
        </div>
      </DmPageWrapper>
      {showDetailModal && selectedWorkflow && (
        <ChangeRequestModal
          show={showDetailModal}
          onHide={() => { setShowDetailModal(false); }}
          task={selectedWorkflow}
        />
      )}
    </>
  );
};

export default WorkFlowPage;