import React, { useEffect, useState, createContext, useRef } from 'react';
import './time-card-styles.scss';
import ContractorTimeSheet from './ContractorTimeSheet';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import {
  Employee,
  TimeCard,
  TimeCardDetail,
  TimeCardDeduction,
  TimeCardWithholding,
  TimeCardMainTotals,
  TimeCardContextType,
  DetailArrayField,
  DetailHourMap,
  TimeCardWithholdingRefObject,
} from 'core/models';
import DeductionsModal from './modals/Deductions.modal';
import {
  addTimeCard,
  deleteTimeCard,
  getAllCostCodes,
  getAllJobs,
  getAllSubs,
  getHomeAtsInfo,
  getTaxingCities,
  handleError,
  storeTimeCard,
  updateTimeCard,
} from 'core/store/actions';
import TimeCardHeader from './TimeCardHeader';
import TimeCardControlTotals from './TimeCardControlTotals';
import TimeCardPageTools from './TimeCardPageTools';
import {  useFieldArray, useForm } from 'react-hook-form';
import { getRateMaster } from 'core/store/slices/contractorReports.slice';
import { RateMasterRequest } from 'core/models/ContractorReports.model';
import { UNSAVED_MESSAGE } from 'core/constants';
import { Prompt } from 'react-router-dom';
import AddTimeCardModal from './modals/AddTimeCard.modal';
import {
  createDetailHourMap,
  createNewTimeSheet,
  getCurrentCheckCodes,
  getNextCheckCode,
  getTimeCardDetailTotalsTimeSheet,
  transformDeductions,
  transformDetails,
} from './utilities';
import { dateToString } from 'utilities/classUtils';
import PageSpinner from 'core/components/shared/PageSpinner';
import { TimeCardContext } from './TimeCardContext';

const ContractorTimeCardPage = () => {
  const { timeCards, currentTimeCard, loadingTimeCards, homeAtsInfo } = useAppSelector(({ contractor }) => contractor);
  const { latestPayrollId } = useAppSelector(({ payroll }) => payroll);
  const isBiWeeklyClient = useAppSelector(({ client }) => client.client?.clientPayrollFreq)?.toLowerCase() === 'bi-weekly';
  const { employees } = useAppSelector(({ employees }) => employees);
  const clientNo = useAppSelector(({ client }) => client.client?.clientNo);
  const { rateMaster } = useAppSelector(({ contractorReports }) => contractorReports);
  
  // since withholdings are tracked in local state and not the form, we need a manual flag for this
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showDeductionsModal, setShowDeductionsModal] = useState<boolean>(false);
  const [employee, setEmployee] = useState<Employee>();
  const [withholdingState, setWithholdingState] = useState<TimeCardWithholding[]>([]); // to initialize TimeCardHeader
  const [detailHoursState, setDetailHoursState] = useState<DetailHourMap>({} as DetailHourMap); // for notes; this gets tricky with field arrays otherwise
  const controlTotalFromStore = useAppSelector((state) => { return state.payroll.payrollControlTotal; });
  const [totals, setTotals] = useState<TimeCardMainTotals>({ hoursTotal: 0, details: [] });

  const formMethods = useForm<TimeCard>({ defaultValues: currentTimeCard ?? {} as TimeCard, shouldUnregister: false });
  const { register, control, watch, handleSubmit, reset } = formMethods;
  const transmittalTimeCardId = watch('transmittalTimeCardId');
  
  // destructuring the details field array because it's used in here. Leaving deductions as-is because it's passed in context
  const deductionFieldArrayMethods = useFieldArray<TimeCardDeduction>({ control, name: 'deductions' });
  const { fields: detailFieldArray, append: appendDetail, remove: removeDetail } = useFieldArray<TimeCardDetail>({ control, name: 'details' });
  const empRateRef = detailFieldArray?.[0]?.empRate ?? 0; // TODO: how can we get this from the ATS endpoint?
  
  const timeCardWithholdingsRef = useRef<TimeCardWithholdingRefObject>(null); // used to pass withholding getter up from child
  
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(getAllJobs()); 
    dispatch(getAllSubs());
    dispatch(getAllCostCodes());
    dispatch(getTaxingCities()); 
  }, []);

  useEffect(() => {
    const details: TimeCardDetail[] = transformDetails(currentTimeCard?.details ?? [], detailHoursState);
    setTotals(getTimeCardDetailTotalsTimeSheet(details));
  }, [detailHoursState]);
  
  useEffect(() => {
    if (!clientNo || rateMaster?.length) return;
    const request: RateMasterRequest = {
      clientNo: clientNo,
      onlyCurrent: true,
      includeFringes: true,
      onlyActive: false,
    };
    
    dispatch(getRateMaster(request)); // ...and the ratemaster
  }, [clientNo]);
  
  // this will handle the currentTimeCard updates from children for us, too :)
  useEffect(() => {
    if (!currentTimeCard) return;
    
    reset({
      ...currentTimeCard,
      details: currentTimeCard.details?.map((detail) => ({
        ...detail,
        hours: detail?.hours?.map((hour) => ({ ...hour })) ?? [],
        otherEarnings: detail?.otherEarnings?.map((earning) => ({ ...earning })) ?? [],
      })) ?? [],
    });
    
    matchEmployee(currentTimeCard.empNo);
    
    setWithholdingState(currentTimeCard.withholdings);
    setDetailHoursState(createDetailHourMap(currentTimeCard?.details));
  }, [currentTimeCard]);
  
  useEffect(() => {
    if (!employee) return;
    dispatch(getHomeAtsInfo({
      protectedEmpNo: employee.protectedEmpNo,
      effectiveDate: dateToString(new Date()) ?? '',
    }));
  }, [employee?.empNo]);
  
  const matchEmployee = (empNo: number) => {
    const matchingEmp = employees?.find((emp) => emp.empNo === empNo);
    if (!matchingEmp) return console.error(`Employee ${empNo} not found`);
    
    setEmployee(matchingEmp);
  };
  
  // reset state not managed by form directly
  const resetForm = (newData: TimeCard) => {
    dispatch(storeTimeCard(newData));
    
    matchEmployee(newData?.empNo);
    
    setWithholdingState(newData?.withholdings ?? []);
    setDetailHoursState(createDetailHourMap(newData?.details ?? []));
  };
  
  const updateDirtyState = (newVal: boolean) => {
    setIsDirty(newVal);
  };
  
  const updateDetailHourState = (newVal: DetailHourMap) => {
    setDetailHoursState(newVal);
  };
  
  const changeTimeCard = (direction: 'previous' | 'next') => {
    if (!timeCards?.length || (isDirty && !confirm('You have unsaved changes. Continue?'))) return;
    
    // targetIndex is the next time card; wrapIndex is the default if there isn't a next. Wraps to start or end of list.
    const currentPos = timeCards.findIndex((card) => card.transmittalTimeCardId === transmittalTimeCardId);

    //PI-8610 Add confirmation when moving from the first to last or last to first time card.
    if (currentPos === 0 && direction === 'previous')
      if (!confirm('You have reached the beginning of the list. Continue to last time card?')) return;

    if ((currentPos === (timeCards.length - 1)) && direction === 'next')
      if (!confirm('You have reached the end of the list. Continue to first time card?')) return;

    const targetIndex = direction === 'previous' ? (currentPos - 1) : (currentPos + 1);
    const wrapIndex = direction === 'previous' ? (timeCards?.length - 1) : 0;
    const resetData = timeCards?.[targetIndex] ? timeCards[targetIndex] : timeCards?.[wrapIndex];
    
    reset({
      ...resetData,
      details: resetData.details?.map((detail) => ({
        ...detail,
        hours: detail?.hours?.map((hour) => ({ ...hour })) ?? [],
        otherEarnings: detail?.otherEarnings?.map((earning) => ({ ...earning })) ?? [],
      })) ?? [],
    });
    resetForm(resetData);
    setIsDirty(false);
  };
  
  const handleAddTimeCard = (id: string | number) => {
    const addedEmp = employees?.find((emp) => emp.empNo === +id);
    if (!addedEmp) return dispatch(handleError(`Couldn't find employee ${id}`));
    
    if (!latestPayrollId || !controlTotalFromStore) return dispatch(handleError('Error adding time card to payroll: missing payroll ID'));
    
    const currentCheckCodes = getCurrentCheckCodes(timeCards, addedEmp.empNo)?.map((x) => isNaN(+x) ? 0 : +x);
    if (currentCheckCodes?.length >= 10) return dispatch(handleError('Employee already has 10 time cards on this payroll'));
    
    const nextCheckCode = getNextCheckCode(currentCheckCodes);
    if (nextCheckCode === '-1') return dispatch(handleError('Employee already has 10 time cards on this payroll')); 
    
    dispatch(getHomeAtsInfo({
      protectedEmpNo: addedEmp.protectedEmpNo,
      effectiveDate: dateToString(new Date()) ?? '',
    }));
    dispatch(addTimeCard({
      payrollHistoryId: latestPayrollId,
      controlTotalId: controlTotalFromStore?.controlTotalId,
      empNo: addedEmp.protectedEmpNo,
      nextCheckCode: nextCheckCode,
    })).then((_res) => {
      setShowAddModal(false);
    });
  };

  const addTimeSheet = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    
    const newTimeSheet = createNewTimeSheet(transmittalTimeCardId, homeAtsInfo, empRateRef);
    appendDetail(newTimeSheet);
    setIsDirty(true);
    
    e.stopPropagation();
  };
  
  const deleteTimeSheet = (id: string | undefined) => {
    if (!id) return dispatch(handleError('Error when deleting time sheet: no ID'));
    
    const timeSheetDeleteIndex = detailFieldArray?.findIndex((timeSheet: DetailArrayField) => timeSheet.id === id);
    if (timeSheetDeleteIndex < 0) return dispatch(handleError('Time sheet not found'));
    
    removeDetail(timeSheetDeleteIndex);
  };
  
  const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    
    if (!confirm('Delete time card? This cannot be undone.')) return;
    if (!currentTimeCard) return dispatch(handleError('Could not find time card to delete'));
    
    dispatch(deleteTimeCard({
      payrollHistoryId: currentTimeCard.payrollHistoryId,
      transmittalTimeCardId: currentTimeCard.transmittalTimeCardId,
    }));
    
    changeTimeCard('next');
    
    e.stopPropagation();
  };
  
  const onSave = (formData: TimeCard) => {
    if (!(currentTimeCard && employee && clientNo && controlTotalFromStore)) return dispatch(handleError('Cannot save time card. Please try again'));
    
    // grab withholdings from ref method mentioned above
    const withholdings = timeCardWithholdingsRef?.current?.getWithholdingValues();
    const deductions: TimeCardDeduction[] = transformDeductions(formData?.deductions);
    const details: TimeCardDetail[] = transformDetails(formData?.details, detailHoursState);
    
    const submitData: TimeCard = {
      ...formData,
      payrollHistoryId: currentTimeCard.payrollHistoryId,
      clientNo: clientNo,
      weekEnd: currentTimeCard.weekEnd,
      checkDate: currentTimeCard.checkDate,
      empId: employee.empId,
      empNo: employee.empNo,
      firstName: employee.firstName,
      midName: employee.midName,
      lastName: employee.lastName,
      suffix: employee.suffix,
      approved: currentTimeCard.approved,
      approvedBy: currentTimeCard.approvedBy,
      approvedDate: currentTimeCard?.approvedDate,
      withholdings: withholdings?.filter((x) => x !== null) as TimeCardWithholding[] ?? [],
      deductions: deductions,
      details: details,
    };
    
    setIsSaving(true);
    
    dispatch(updateTimeCard({
      payrollHistoryId: currentTimeCard.payrollHistoryId,
      controlTotalId: controlTotalFromStore.controlTotalId,
      transmittalTimeCardId: currentTimeCard.transmittalTimeCardId,
      empNo: employee?.protectedEmpNo,
      data: submitData,
    })).then((_res) => {
      setIsDirty(false);
    }).finally(() => {
      setIsSaving(false);
    });
  };
  
  const contextValue: TimeCardContextType = {
    formMethods,
    deductionFieldArrayMethods,
    defaultDetailHoursState: detailHoursState,
    updateDetailHourState,
    updateDirtyState,
    timeCardId: currentTimeCard?.transmittalTimeCardId ?? 0,
    isSaving,
    isDirty,
  };
  
  return (
    <main
      className="time-card-page"
      title={isSaving ? 'Saving...' : undefined}
      role="main"
      style={isSaving ? {
        pointerEvents: 'none',
        cursor: 'not-allowed',
      } : undefined}
      aria-disabled={isSaving}
    >
      {showAddModal && (
        <AddTimeCardModal
          show={showAddModal}
          onHide={() => { setShowAddModal(false); }}
          addTimeCard={handleAddTimeCard}
        />
      )}
      <Prompt
        when={isDirty}
        message={UNSAVED_MESSAGE}
      />
      {loadingTimeCards ? (
        <PageSpinner />
      ) : (
        <form onSubmit={handleSubmit(onSave)}>
          <input
            name="transmittalTimeCardId"
            type="hidden"
            value={transmittalTimeCardId}
            ref={register({ valueAsNumber: true })}
          />
          <input
            name="clientNo"
            type="hidden"
            value={clientNo}
            ref={register({ valueAsNumber: true })}
          />
          <TimeCardContext.Provider value={contextValue}>
            {showDeductionsModal && (
              <DeductionsModal
                show={showDeductionsModal}
                onHide={() => { setShowDeductionsModal(false); }}
              />
            )}
            <div className="d-flex align-items-start justify-content-between">
              <div className="dm-page-title">Contractor Time Sheet</div>
              <div className="d-flex mt-auto">
                <TimeCardPageTools
                  type="Time Card"
                  changeTimeCard={changeTimeCard}
                  setShowAddModal={(newVal: boolean) => { setShowAddModal(newVal); }}
                />
              </div>
            </div>
            <hr className="dm-page-hr" />
            {!timeCards?.length ? (
              <div className="add-entry">
                Click &ldquo;Add Time Card&rdquo;
              </div>
            ) : (
              <div className="dm-panel dm-panel-border p-0">
                {employee ? (
                  <TimeCardHeader
                    employee={employee}
                    withholdingState={withholdingState}
                    addNewTimeSheet={addTimeSheet}
                    onDelete={onDelete}
                    ref={timeCardWithholdingsRef}
                    totals={totals}
                  />
                ) : null}
                <div className={`${isBiWeeklyClient ? 'biweekly-list' : 'time-card-list'}`}>
                  {!detailFieldArray?.length ? (
                    <div className="add-entry">
                      Click &ldquo;Add Time Entry&rdquo;
                    </div>
                  ) : detailFieldArray?.map((timeSheet: DetailArrayField, index) => (
                    <ContractorTimeSheet
                      key={timeSheet.id}
                      index={index}
                      timeSheet={timeSheet}
                      deleteTimeSheet={deleteTimeSheet}
                    />
                  ))
                  }
                </div>
              </div>
            )}
          </TimeCardContext.Provider>
        </form>
      )}
      {currentTimeCard && controlTotalFromStore ? 
        <TimeCardControlTotals
          currentTimeCard={currentTimeCard}
          currentControlTotal={controlTotalFromStore}
        /> : null}
    </main>
  );
};

export default ContractorTimeCardPage;