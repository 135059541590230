import React, { useEffect, useState } from 'react';
import { Accordion, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PDFViewerModal from 'core/components/modals/pdf-viewer.modal';
import PanelHeader from 'core/components/shared/PanelHeader';
import { AccrualBalanceRecord, AccrualEarningsCode, AccrualRule, AccrualRuleRequest, Employee } from 'core/models';
import { loadAccrualEarningsCodes, loadAccrualRule, loadAccruals } from 'core/store/actions';
import { clearReport, loadAccuralTab } from 'core/store/actions/accrual-reports.action';
import { getAccrualEarningsCodes, getAccrualRule, getAccruals, getSelectedEmp } from 'core/store/selectors';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import AccrualAmountModal from './AccrualAmount.modal';
import AccrualBalanceItem from './AccrualBalanceItem';
import AccrualRuleItem from './AccrualRuleItem';
import ApprovedScheduledRequestsModal from './ApprovedScheduledRequestsModal';
import Icon from 'core/components/shared/Icon';
import { getAccess } from 'utilities/utilities';

type Props = {
  protectedEmpNo: string;
};

const AccrualsPage: React.FC<Props> = ({ protectedEmpNo }) => {
  const clientNo = useAppSelector((state) => state?.client?.client?.clientNo);
  const accrualReport = useAppSelector(({ accrualReports }) => accrualReports.report);
  
  const dispatch = useAppDispatch();

  const emp = useSelector(getSelectedEmp) as Employee;
  const accruals = useSelector(getAccruals) as AccrualBalanceRecord[];
  const accrualRule = useSelector(getAccrualRule) as AccrualRule;
  const accrualEarningsCodeOpts = useSelector(getAccrualEarningsCodes) as AccrualEarningsCode[];
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });
  
  const [key, setKey] = useState(accrualEarningsCodeOpts?.[0]?.earningsCode);
  const [balanceIds, setBalanceIds] = useState<string[]>([]);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showAmountModal, setShowAmountModal] = useState(false);
  const [type, setType] = useState('');

  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(loadAccruals(protectedEmpNo));
      dispatch(loadAccrualRule(protectedEmpNo));
      dispatch(loadAccrualEarningsCodes());
    }
  }, [protectedEmpNo]);
  
  useEffect(() => {
    setKey(accrualEarningsCodeOpts?.[0]?.earningsCode);
  }, [accrualEarningsCodeOpts?.length]);

  useEffect(() => {
    if (!accruals?.length) return;
    setBalanceIds(
      accruals
        .filter(({ earningsCode }) => earningsCode === key)
        .map(({ balanceId }) => String(balanceId)),
    );
  }, [key, accruals]);

  const onShowApproved = () => { return setShowScheduleModal(true); };
  const onShowEarnedAmountModal = () => {
    setType('Earned');
    setShowAmountModal(true);
  };
  const onShowTakenAmountModal = () => {
    setType('Adjustment Taken');
    setShowAmountModal(true);
  };

  const hidePdfViewer = () => {
    dispatch(clearReport());
  };

  const printCurrentTab = () => {
    if (clientNo) {
      dispatch(
        loadAccuralTab({
          clientNo,
          empNo: emp?.empNo ?? 0,
          protectedEmpNo: emp?.protectedEmpNo ?? '',
          earningsCode: key,
        }),
      );
    }
  };

  return (
    <>
      <div className="dm-panel dm-panel-border">
        <PanelHeader title="Accruals" />
        {accrualRule && <AccrualRuleItem item={accrualRule} />}

        <PanelHeader title="Accruals Details">
          <button
            className="btn btn-link dm-grid-action-title-blue p-0 mr-4 mb-2"
            onClick={onShowApproved}
          >
            Show Approved/Scheduled Request
          </button>
          <button
            className="btn btn-link dm-grid-action-title-blue p-0 mr-2 mb-2"
            onClick={printCurrentTab}
          >
            Print Current Tab
            <Icon
              name="print"
              className="fa-plus-circle"
            />
          </button>
        </PanelHeader>
        <div className="text-right pt-3 d-flex justify-content-end">
          <button
            {...getAccess(sectionAccess, 24, undefined, { disabledSameAsReadOnly: true })}
            className="btn btn-link dm-grid-action-title p-0 mr-4"
            onClick={onShowEarnedAmountModal}
          >
            Add Earned Amount{' '}
            <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
          <button
            {...getAccess(sectionAccess, 24, undefined, { disabledSameAsReadOnly: true })}
            className="btn btn-link dm-grid-action-title p-0 mr-2"
            onClick={onShowTakenAmountModal}
          >
            Add Taken Amount{' '}
            <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </button>
        </div>
        <Tabs
          activeKey={key}
          id="uncontrolled-tab-example"
          onSelect={(k: any) => { setKey(k); }}
        >
          {accrualEarningsCodeOpts.map(
            (code: AccrualEarningsCode) => {
              return (
                <Tab
                  key={code.earningsCode}
                  eventKey={code.earningsCode}
                  title={
                    code.earningsCode +
                    ' - ' +
                    code.shortDescription
                  }
                >
                  {accruals
                    .filter(({ earningsCode }) => earningsCode === code.earningsCode)
                    .map((balRec: AccrualBalanceRecord, index) => (
                      <AccrualBalanceItem
                        key={balRec.balanceId}
                        balRecIds={balanceIds}
                        index={index}
                        balRec={balRec}
                      />
                    ))}
                </Tab>
              );
            },
          )}
        </Tabs>
        {showScheduleModal && (
          <ApprovedScheduledRequestsModal
            selectedEmp={emp}
            show={showScheduleModal}
            onHide={() => { return setShowScheduleModal(false); }}
          />
        )}
        {showAmountModal && emp && key && (
          <AccrualAmountModal
            selectedEmp={emp}
            earningsCode={key}
            shortDescription={accrualEarningsCodeOpts.find((opt) => opt.earningsCode === key)?.shortDescription ?? ''}
            type={type}
            show={showAmountModal}
            onHide={() => { return setShowAmountModal(false); }}
          />
        )}
      </div>
      {accrualReport.length > 0 && (
        <PDFViewerModal
          show={accrualReport.length > 0}
          pdfData={accrualReport}
          onHide={hidePdfViewer}
          reportName="AccrualReport"
        />
      )}
    </>
  );
};

export default AccrualsPage;
