import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { ControlDatePickerGrp, SelectGrp } from 'core/components/form-controls';
import { FieldInputSettings, FormMethods } from 'core/components/form-controls/types';
import { Employee } from 'core/models';
import {
  getActiveStatusConditions,
  getInactiveStatusConditions,
  getHourlyStatuses,
  getPayClasses,
  getPayTypes,
  getSchedulePatterns,
  getClockGroups,
} from 'core/store/selectors';

const fs: FieldInputSettings = {
  activeStatusId: { name: 'activeStatusId',
    label: 'ACTIVE STATUS',
    groupClass: 'gc35 mw150' },
  inactiveStatusId: { name: 'inactiveStatusId',
    label: 'INACTIVE STATUS',
    groupClass: 'gc35 mw150' },
  activeStatusEffectiveDate: { name: 'activeStatusEffectiveDate',
    label: 'EFFECTIVE AS OF',
    groupClass: 'gc25 mw125' },

  hourlyStatusId: { name: 'hourlyStatusId',
    label: 'HOURLY STATUS',
    groupClass: 'gc40 mw250' },
  hourlyStatusEffectiveDate: { name: 'hourlyStatusEffectiveDate',
    label: ' ',
    groupClass: 'gc25 mw125' },

  payClassId: { name: 'payClassId',
    label: 'PAY CLASS',
    groupClass: 'gc40 mw250' },
  payClassEffectiveDate: { name: 'payClassEffectiveDate',
    label: ' ',
    groupClass: 'gc25 mw125' },

  payTypeId: { name: 'payTypeId',
    label: 'PAY TYPE',
    groupClass: 'gc40 mw250' },
  payTypeEffectiveDate: { name: 'payTypeEffectiveDate',
    label: ' ',
    groupClass: 'gc25 mw125' },

  schedulePatternId: { name: 'schedulePatternId',
    label: 'SCHEDULE PATTERN',
    groupClass: 'gc40 mw250' },
  schedulePatternEffectiveDate: { name: 'schedulePatternEffectiveDate',
    label: ' ',
    groupClass: 'gc25 mw125' },

  clockGroupId: { name: 'clockGroupId',
    label: 'CLOCK GROUP',
    groupClass: 'gc40 mw250' },
  clockGroupEffectiveDate: { name: 'clockGroupEffectiveDate',
    label: ' ',
    groupClass: 'gc25 mw125' },
};

type PropTypes = {
  formMethods: Omit<FormMethods, 'handleSubmit' | 'reset'>;
  selEmp: Employee;
};

const mwOverrideStlyes: CSSProperties = {
  maxWidth: '100%',
};

const AttendanceOnDemandView: React.FC<PropTypes> = ({ formMethods: { setValue, errors, register, watch, control }, selEmp }) => {
  const activeStatusConditionsOpts = useSelector(getActiveStatusConditions);
  const inactiveStatusConditionsOpts = useSelector(getInactiveStatusConditions);
  const hourlyStatusOpts = useSelector(getHourlyStatuses);
  const payClassOpts = useSelector(getPayClasses);
  const payTypeOpts = useSelector(getPayTypes);
  const schedulePatterOpts = useSelector(getSchedulePatterns);
  const clockGroupOpts = useSelector(getClockGroups);

  const {
    activeStatusEffectiveDate,
    hourlyStatusEffectiveDate,
    payClassEffectiveDate,
    payTypeEffectiveDate,
    schedulePatternEffectiveDate,
  } = watch([
    'activeStatusEffectiveDate',
    'hourlyStatusEffectiveDate',
    'payClassEffectiveDate',
    'payTypeEffectiveDate',
    'schedulePatternEffectiveDate',
  ]);

  return (
    <div className="dm-panel dm-panel-border" >
      <div className="dm-card-subtitle2 mb-2">Attendance On Demand</div>
      <div className="d-flex flex-row">
        <div
          className="d-flex flex-column mr-1"
          style={{ flex: '1' }}
        >
          <SelectGrp 
            {...fs.activeStatusId} 
            errors={errors.activeStatusId} 
            ref={register} 
            options={activeStatusConditionsOpts} 
            disabled={selEmp.termDate}
          />
          <SelectGrp
            {...fs.inactiveStatusId}
            errors={errors.inactiinactiveStatusIdveStatus}
            ref={register}
            options={inactiveStatusConditionsOpts}
            disabled={!selEmp.termDate}
          />
        </div>
        <div
          className="d-flex flex-column mr-1"
          style={{ flex: '1' }}
        >
          <ControlDatePickerGrp
            {...fs.activeStatusEffectiveDate}
            errors={errors.activeStatusEffectiveDate}
            value={activeStatusEffectiveDate}
            setValue={setValue}
            control={control}
          /></div>
      </div>
      <hr className="hr-2 my-2" />
      <div className="d-flex flex-row">
        <SelectGrp
          {...fs.hourlyStatusId} 
          errors={errors.hourlyStatusId} 
          ref={register} 
          options={hourlyStatusOpts}
        />
        <ControlDatePickerGrp
          {...fs.hourlyStatusEffectiveDate}
          errors={errors.hourlyStatusEffectiveDate}
          value={hourlyStatusEffectiveDate}
          setValue={setValue}
          control={control}
        />
      </div>
      <div className="d-flex flex-row">
        <SelectGrp 
          {...fs.payClassId} 
          errors={errors.payClassId} 
          ref={register} 
          options={payClassOpts}
        />
        <ControlDatePickerGrp
          {...fs.payClassEffectiveDate}
          errors={errors.payClassEffectiveDate}
          value={payClassEffectiveDate}
          setValue={setValue}
          control={control}
        />
      </div>
      <div className="d-flex flex-row">
        <SelectGrp 
          {...fs.payTypeId} 
          errors={errors.payTypeId} 
          ref={register} 
          options={payTypeOpts}
        />
        <ControlDatePickerGrp
          {...fs.payTypeEffectiveDate}
          errors={errors.payTypeEffectiveDate}
          value={payTypeEffectiveDate}
          setValue={setValue}
          control={control}
        />
      </div>
      <div className="d-flex flex-row">
        <SelectGrp 
          {...fs.schedulePatternId} 
          errors={errors.schedulePatternId} 
          ref={register} 
          options={schedulePatterOpts}
        />
        <ControlDatePickerGrp
          {...fs.schedulePatternEffectiveDate}
          errors={errors.schedulePatternEffectiveDate}
          value={schedulePatternEffectiveDate}
          setValue={setValue}
          control={control}
        />
      </div>
      <div className="d-flex flex-row w-100">
        <SelectGrp
          {...fs.clockGroupId}
          groupStyle={mwOverrideStlyes}
          errors={errors.clockGroupId}
          ref={register}
          options={clockGroupOpts}
        />
      </div>
    </div>
  );
};

export default AttendanceOnDemandView;
