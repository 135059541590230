import React from 'react';
import {
  convToDateString,
  ddLookup,
  determineGenderString,
  formatPhone,
  formatSSN,
  getAccess,
  getAllAccess,
} from 'utilities/utilities';
import { useSelector } from 'react-redux';
import { Employee } from 'core/models';
import {
  getLocationDepartmentsWithoutHome,
  getMaritalStatuses,
  getStates,
} from 'core/store/selectors';
import { useAppSelector } from 'utilities/hooks';
import { ControlIds } from 'core/constants';

interface PropTypes {
  employee: Employee;
}

const EmpInfoView: React.FC<PropTypes> = ({ employee: emp }) => {
  const { locationOpts, deptOpts, subdeptOpts, subdept2Opts } = useSelector(
    getLocationDepartmentsWithoutHome,
  );
  const stateOpts = useSelector(getStates);
  const maritalStatusOptions = useSelector(getMaritalStatuses);
  const sectionAccess = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections);
  
  
  //#region Module access definitions
  // get all fields' access configs
  const allAccess = getAllAccess(sectionAccess?.find((x) => x.workItemId === 1));
  // section(s)
  const empDatesAccess = getAccess(sectionAccess, 7);
  // fields with special condiions
  //#endregion

  return !emp ? (
    <div></div>
  ) : (
    <div className="row">
      <div className="col-12">
        <div className="row pb-2">
          {allAccess?.[ControlIds.address].visible && (
            <div className="col-12 col-sm-4">
              <div className="dm-form-label">ADDRESS</div>
              <div className="dm-form-text">{emp.address}</div>
              <div className="dm-form-text">
                {allAccess?.[ControlIds.city].visible ? emp.city + ', ' : ''}
                {allAccess?.[ControlIds.state].visible ? ddLookup(emp.state, stateOpts) : ''}
                {allAccess?.[ControlIds.zip].visible && emp.zip}
                {allAccess?.[ControlIds.zip4].visible && emp.zip4 && '-' + emp.zip4}
              </div>
              <div className="dm-form-text">{emp.country}</div>
            </div>
          )}
          {allAccess?.[ControlIds.ssn].visible && (
            <div className="col-12 col-sm-4 pt-2 pt-sm-0">
              <div className="dm-form-label">SOCIAL SECURITY</div>
              <div className="dm-form-text">{formatSSN(emp.ssn)}</div>
            </div>
          )}
          {allAccess?.[ControlIds.loc].visible && locationOpts?.length ? (
            <div className="col-12 col-sm-4 pt-2 pt-sm-0">
              <div className="dm-form-label">LOCATION</div>
              <div className="dm-form-text">
                {emp.loc} - {ddLookup(emp.loc, locationOpts, 'locationCode', 'locationDesc')}
              </div>
            </div>
          ) : null}
        </div>
        <div className="row pb-2">
          {allAccess?.[ControlIds.sex].visible && (
            <div className="col-12 col-sm-4 pt-2 pt-sm-0">
              <div className="dm-form-label">GENDER</div>
              <div className="dm-form-text">{determineGenderString(emp.sex)}</div>
            </div>
          )}
          {allAccess?.[ControlIds.maritalStatus].visible && (
            <div className="col-12 col-sm-4 pt-2 pt-sm-0">
              <div className="dm-form-label">MARITAL STATUS</div>
              <div className="dm-form-text">
                {ddLookup(emp.maritalStatus, maritalStatusOptions)}
              </div>
            </div>
          )}
          {allAccess?.[ControlIds.dept].visible && deptOpts?.length ? (
            <div className="col-12 col-sm-4 pt-2 pt-sm-0">
              <div className="dm-form-label">DEPARTMENT</div>
              <div className="dm-form-text">
                {emp.dept} - {ddLookup(emp.dept, deptOpts, 'deptCode', 'deptDesc')}
              </div>
            </div>
          ) : null}
        </div>
        <div className="row pb-2">
          {allAccess?.[ControlIds.cellPhone].visible && (
            <div className="col-12 col-sm-4">
              <div className="dm-form-label">CELL PHONE</div>
              <div className="dm-form-text">
                {formatPhone(emp.cellPhone)}
              </div>
            </div>
          )}
          {empDatesAccess.visible && allAccess?.[ControlIds.birthDate].visible && (
            <div className="col-12 col-sm-4 pt-2 pt-sm-0">
              <div className="dm-form-label">BIRTH DATE</div>
              <div className="dm-form-text">
                {convToDateString(emp.birthDate)}
              </div>
            </div>
          )}
          {allAccess?.[ControlIds.subDept].visible && subdeptOpts?.length ? (
            <div className="col-12 col-sm-4 pt-2 pt-sm-0">
              <div className="dm-form-label">SUB DEPARTMENT</div>
              <div className="dm-form-text">
                {emp.subDept} - {ddLookup(emp.subDept, subdeptOpts, 'subDeptCode', 'subDeptDesc')}
              </div>
            </div>
          ) : null}
        </div>
        <div className="row pb-2">
          {allAccess?.[ControlIds.homePhone].visible && (
            <div className="col-12 col-sm-4">
              <div className="dm-form-label">HOME PHONE</div>
              <div className="dm-form-text">
                {formatPhone(emp?.homePhone)}
              </div>
            </div>
          )}
          {empDatesAccess.visible && allAccess?.[ControlIds.hireDate].visible && (
            <div className="col-12 col-sm-4">
              <div className="dm-form-label">HIRE DATE</div>
              <div className="dm-form-text">
                {emp.hireDate?.toLocaleString()?.split(',')?.[0] ?? 'Not available'}
              </div>
            </div>
          )}
          {subdept2Opts?.length && allAccess?.[ControlIds.subDept2].visible ? (
            <div className="col-12 col-sm-4 col-sm-offset-4 pt-2 pt-sm-0">
              <div className="dm-form-label">
                SUB DEPARTMENT 2
              </div>
              <div className="dm-form-text">
                {emp.subDept2} - {ddLookup(emp.subDept2, subdept2Opts, 'sub2Code', 'sub2Desc')}
              </div>
            </div>
          ) : null}
        </div>
        <div className="row pb-2">
          {allAccess?.[ControlIds.email].visible && (
            <div className="col-12 col-sm-4">
              <div className="dm-form-label">EMAIL</div>
              <div
                className="dm-form-text"
                style={{
                  overflowWrap: 'break-word',
                }}
              >
                {emp.email}
              </div>
            </div>
          )}
          <div className="col-12 col-sm-4">
            <div className="dm-form-label">MYINFO EMAIL</div>
            <div className="dm-form-text">{emp.myInfoEmail}</div>
          </div>
        </div>
        <div className="row pb-2">
          {allAccess?.[ControlIds.email2].visible && (
            <div className="col-12 col-sm-4">
              <div className="dm-form-label">EMAIL 2</div>
              <div
                className="dm-form-text"
                style={{
                  overflowWrap: 'break-word',
                }}
              >
                {emp.email2}
              </div>
            </div>
          )}
          <div className="col-12 col-sm-4">
            <div className="dm-form-label">MYINFO LAST LOGIN</div>
            <div className="dm-form-text">{emp?.lastMyInfoLogin?.toLocaleString() ?? ''}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmpInfoView;
