import React, { useState, useEffect, useMemo } from 'react';
import { InputGrp, SelectGrp, CheckboxGrp, CheckboxGrpInLine } from 'core/components/form-controls';
import { ArrayField, FieldElement, RegisterOptions } from 'react-hook-form';
import {
  ClientMasterDtoCommonDropdown,
  ClientMasterDtoTaxEntitiesSpecialTaxField,
  EmployeeMasterDtoTaxTaxEntity,
} from 'core/models';
import { taxFanOptions } from 'dropdowns/taxFanOptions';
import { taxResidencyOptions } from 'dropdowns/taxResidencyOptions';
import { selectClientTaxEntitiesByAgency, selectClientAgencyDropDown } from 'core/store/selectors/tax.selector';
import { useAppSelector } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import { FormMethods } from 'core/components/form-controls/types';
import styles from 'core/components/form-controls/form-controls.module.scss';
import { getAccess } from 'utilities/utilities';

type PropTypes = {
  taxItem: Partial<ArrayField<EmployeeMasterDtoTaxTaxEntity, 'id'>>;
  name: string;
  getValues: FormMethods['getValues'];
  control: FormMethods['control'];
  errors: FormMethods['errors'];
  index: number;
  validate: boolean;
  suiDisabled?: () => boolean;
  whDisabled?: () => boolean;
  hasSUI?: boolean;
  onDelete: () => void;
  register(
    rules?: RegisterOptions
  ): FieldElement<EmployeeMasterDtoTaxTaxEntity[]> | any;
  updateField: <T>(id: string | undefined, index: number, key: string, newValue: T) => void;
};

const TaxItem: React.FC<PropTypes> = ({
  taxItem,
  onDelete,
  hasSUI,
  register,
  errors,
  index,
  name,
  validate,
  suiDisabled,
  whDisabled,
  updateField,
}) => {
  const isSuperUser = useAppSelector((state) => { return state.auth.userAccess?.superUser; });
  const sectionAccess = useAppSelector((state) => {
    return state.app.moduleAccess?.employeeMasterSections;
  });

  // States
  const [specialFields, setSpecialFields] = useState<
  ClientMasterDtoTaxEntitiesSpecialTaxField[] | undefined | null
  >([]);

  const getCurrentYear = new Date().getFullYear();
  const yearDropDown: ClientMasterDtoCommonDropdown[] = [];
  for (let i = getCurrentYear + 1; i >= taxItem!.year!; i--) {
    yearDropDown.push({
      id: i.toString(),
      description: i.toString(),
    });
  }
  if (taxItem.employeeTaxEntitiesId === 0 && isSuperUser) yearDropDown.push({ id: (getCurrentYear - 1).toString(), description: (getCurrentYear - 1).toString() });

  const taxEntities = useAppSelector((state) => {
    var year = taxItem.year!;
    if (year < 2019) year = 2019;
    return selectClientTaxEntitiesByAgency(state, taxItem.agency!, year);
  },
  );
  const agencyDropDown = useAppSelector((state) => {
    var year = taxItem.year!;
    if (year < 2019) year = 2019;
    return selectClientAgencyDropDown(state, taxItem.agency!, year);
  },
  );

  const [filingStatusDropDown, setFilingStatusDropDown] = useState<
  ClientMasterDtoCommonDropdown[]
  >(
    taxEntities.find((a) => { return a.entityId === taxItem.entityId; })
      ?.filingStatuses ?? [],
  );

  useEffect(() => {
    if (taxEntities.length > 0) {
      setAgencyInformation(taxItem.entityId ?? -1);
    }
  }, [taxEntities]);

  const setAgencyInformation = (entityId: number | string) => {
    if (typeof entityId === 'string') {
      entityId = parseInt(entityId);
    }
    /*
      To reproduce the bug that causes what this line is preventing: Edit an employee's taxes, switch clients, and 
      immediately add a state entity to an employee. This will cause the above effect to enter an infinite loop
      and clear the rendered state entities in the UI. This line prevents this function from setting invalid info
      on the entities, which I believe is triggering the taxEntities change (the only thing in the dependency array).
   */
    if (isNaN(entityId)) return; // return? or do something else?

    setSpecialFields(
      taxEntities.find((a) => { return a.entityId === entityId; })?.specialFields,
    );
    setFilingStatusDropDown(taxEntities.find((a) => { return a.entityId === entityId; })?.filingStatuses ?? []);
  };

  const checkForSpecialFields = (field: string) => {
    return specialFields?.find((x) => { return x.fieldName === field; })?.show;
  };

  const federalDisabled = taxItem.entityId === 1 && ((taxItem?.year ?? 0) < 2020 || taxItem.irsLockOut);
  const disableSUI = (suiDisabled && suiDisabled()) || federalDisabled || !hasSUI;
  const disableWH = whDisabled && whDisabled();

  return (
    <>
      {+taxItem.entityId! === 1 && taxItem.year! < 2020 && (
        <div
          className="alert alert-warning mb-2"
          role="alert"
        >
          Cannot change tax information for tax years 2019 or earlier.
          Please click the &apos;Add New Federal Tax&apos; link to add a new tax
          record.
        </div>
      )}
      {taxEntities.length > 0 && (
        <div className="d-flex flex-row flex-wrap">
          <input
            type="hidden"
            ref={register()}
            name={`${name}[${index}].employeeTaxEntitiesId`}
            defaultValue={taxItem.employeeTaxEntitiesId}
          />
          <input
            type="hidden"
            ref={register()}
            name={`${name}[${index}].agency`}
            defaultValue={taxItem.agency}
          />
          <SelectGrp
            {...getAccess(sectionAccess, 4)}
            errors={
              errors.taxes?.[index]?.entityId ||
              errors.employee?.taxes?.[index]?.entityId
            }
            name={`${name}[${index}].entityId`}
            ref={register({
              required: 'Agency is required',
              valueAsNumber: true,
            })}
            options={agencyDropDown}
            label="Agency"
            defaultValue={taxItem.entityId}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              updateField(taxItem.id, index, 'entityId', +e.target.value);
              setAgencyInformation(e.target.value);
            }
            }
            groupClass={'col-auto p-0 mr-2'}
            addEmptyValue={true}
            disabled={
              federalDisabled || taxItem.employeeTaxEntitiesId! > 0
            }
          />

          {checkForSpecialFields('year') ? (
            <SelectGrp
              {...getAccess(sectionAccess, 4)}
              errors={
                errors.taxes?.[index]?.year ||
                errors.employee?.taxes?.[index]?.year
              }
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                updateField(taxItem.id, index, 'year', +e.target.value);
              }}
              name={`${name}[${index}].year`}
              ref={register({ valueAsNumber: true })}
              options={yearDropDown}
              label="Year"
              groupClass={'col-auto p-0 mr-2'}
              defaultValue={taxItem.year ?? getCurrentYear}
              disabled={
                federalDisabled || taxItem.employeeTaxEntitiesId! > 0
              }
            />
          ) : (
            <input
              type="hidden"
              ref={register()}
              name={`${name}[${index}].year`}
              value=""
            />
          )}

          {checkForSpecialFields('residency') ? (
            <SelectGrp
              {...getAccess(sectionAccess, 4)}
              name={`${name}[${index}].residency`}
              errors={
                errors.taxes?.[index]?.residency ||
                errors.employee?.taxes?.[index]?.residency
              }
              options={taxResidencyOptions}
              ref={register()}
              label="Res."
              groupClass={'col-auto p-0 mr-2'}
              defaultValue={taxItem.residency}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                updateField(taxItem.id, index, 'residency', e.target.value);
              }}
              disabled={federalDisabled}
            />
          ) : (
            <input
              type="hidden"
              ref={register()}
              name={`${name}[${index}].residency`}
              defaultValue={taxItem.residency}
            />
          )}

          {checkForSpecialFields('filingStatus') ? (
            <SelectGrp
              {...getAccess(sectionAccess, 4)}
              addEmptyValue
              name={`${name}[${index}].filingStatus`}
              errors={
                errors.taxes?.[index]?.filingStatus ||
                errors.employee?.taxes?.[index]?.filingStatus
              }
              ref={register({
                required: {
                  value: true,
                  message: 'Filing status is required.',
                },
              })}
              options={filingStatusDropDown}
              label="Filing Status"
              groupClass={'col-auto p-0 mr-2'}
              labelClass={`${styles['dm-required']}`}
              defaultValue={taxItem.filingStatus}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                updateField(taxItem.id, index, 'filingStatus', e.target.value);
              }}
              disabled={federalDisabled}
            />
          ) : (
            <input
              type="hidden"
              ref={register()}
              name={`${name}[${index}].filingStatus`}
              defaultValue={taxItem.filingStatus}
            />
          )}

          <InputGrp
            {...getAccess(sectionAccess, 4, undefined, { readOnlyDefault : federalDisabled })}
            name={`${name}[${index}].exemptions`}
            errors={
              errors.taxes?.[index]?.exemptions ||
              errors.employee?.taxes?.[index]?.exemptions
            }
            ref={register({
              required: true,
              min: {
                value: 0,
                message: 'Must be greater than 0',
              },
              valueAsNumber: true,
            })}
            label={
              checkForSpecialFields('exemptions')
                ? 'Exemp. (a)'
                : ''
            }
            groupClass={'groupClass06 p-0 mr-2'}
            defaultValue={taxItem.exemptions ?? 0}
            type={
              checkForSpecialFields('exemptions')
                ? 'number'
                : 'hidden'
            }
            disabled={taxItem.agency === 'Federal' && (taxItem?.year ?? 0) >= 2020}
            aria-disabled={taxItem.agency === 'Federal' && (taxItem?.year ?? 0) >= 2020}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'exemptions', e.target.value);
            }}
          />

          {checkForSpecialFields('firstTimeAdditionalExemption') ? 
            (<InputGrp
              {...getAccess(sectionAccess, 4)}
              name={`${name}[${index}].firstTimeAdditionalExemption`}
              errors={
                errors.taxes?.[index]?.firstTimeAdditionalExemption ||
              errors.employee?.taxes?.[index]?.firstTimeAdditionalExemption
              }
              ref={register()}
              label={'1st Time Addtl. Dep'}
              groupClass={'col-auto p-0 mr-2'}
              defaultValue={taxItem.firstTimeAdditionalExemption}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateField(taxItem.id, index, 'firstTimeAdditionalExemption', e.target.value);
              }}
              type={'number'}
            />) : (
              <input
                type="hidden"
                ref={register()}
                name={`${name}[${index}].firstTimeAdditionalExemption`}
                defaultValue={taxItem.firstTimeAdditionalExemption}
              />
            )}

          {checkForSpecialFields('adoptedQualifyingDependent') ? 
            (<InputGrp
              {...getAccess(sectionAccess, 4)}
              name={`${name}[${index}].adoptedQualifyingDependent`}
              errors={
                errors.taxes?.[index]?.adoptedQualifyingDependent ||
              errors.employee?.taxes?.[index]?.adoptedQualifyingDependent
              }
              ref={register()}
              label={'Adopted Qualifying Dep'}
              groupClass={'col-auto p-0 mr-2'}
              defaultValue={taxItem.adoptedQualifyingDependent}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateField(taxItem.id, index, 'adoptedQualifyingDependent', e.target.value);
              }}
              type={'number'}
            />) : (
              <input
                type="hidden"
                ref={register()}
                name={`${name}[${index}].adoptedQualifyingDependent`}
                defaultValue={taxItem.adoptedQualifyingDependent}
              />
            )}

          {checkForSpecialFields('fan') ? (
            <SelectGrp
              {...getAccess(sectionAccess, 4)}
              name={`${name}[${index}].fan`}
              errors={
                errors.taxes?.[index]?.fan ||
                errors.employee?.taxes?.[index]?.fan
              }
              ref={register({
                validate: (value) => {
                  if (value === '' && (taxItem?.fanAmount ?? 0) > 0) {
                    return 'FAN% must be filled in if FAN% Amount is greater than 0';
                  }
                },
              })}
              errorMsg="FAN% must be filled in if FAN% Amount is greater than 0"
              options={taxFanOptions}
              label="FAN%"
              groupClass={'col-auto p-0 mr-2'}
              addEmptyValue={true}
              defaultValue={taxItem.fan}
              disabled={federalDisabled}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                updateField(taxItem.id, index, 'fan', e.target.value);
              }}
            />
          ) : (
            <input
              type="hidden"
              ref={register()}
              name={`${name}[${index}].fan`}
              defaultValue={taxItem.fan}
            />
          )}

          <InputGrp
            {...getAccess(sectionAccess, 4, undefined, { readOnlyDefault: federalDisabled || (taxItem?.fan?.trim() === '' || taxItem?.fan === 'N') })}
            name={`${name}[${index}].fanAmount`}
            errors={
              errors.taxes?.[index]?.fanAmount ||
              errors.employee?.taxes?.[index]?.fanAmount
            }
            ref={register({
              required: 'Required',
              validate: (value) => {
                const fan = taxItem?.fan?.trim();
                if (!validate) {
                  return true;
                }
                const noFAN = fan === '' || fan === 'N';

                if (!noFAN && fan !== 'F' && parseFloat(value) <= 0) {
                  return 'Amount cannot be zero.';
                } else if (noFAN && parseFloat(value) > 0) {
                  return 'Amount cannot be greater than zero if no FAN% value is selected.';
                }
              },
              valueAsNumber: true,
            })}
            label={
              checkForSpecialFields('fanAmount')
                ? 'FAN% Amount'
                : ''
            }
            groupClass={'col-1 p-0 mr-1'}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'fanAmount', (+e.target.value).toFixed(2));
            }
            }
            /* TODO: Clean this up */
            defaultValue={(taxItem?.fanAmount && !(taxItem?.fan?.trim() === '' || taxItem?.fan === 'N'))
              ? (+taxItem.fanAmount)?.toFixed(2)
              : (0).toFixed(2)}
            style={{ textAlign: 'right' }}
            type={
              checkForSpecialFields('fanAmount')
                ? 'number'
                : 'hidden'
            }
            step={0.01}
          />

          <CheckboxGrp
            {...getAccess(sectionAccess, 4, undefined, { disabledSameAsReadOnly: true, disabledDefault: disableSUI })}
            name={`${name}[${index}].suiState`}
            label="SUI St"
            ref={register()}
            groupClass={
              checkForSpecialFields('suiState')
                ? 'col-auto p-0 mr-2 text-center'
                : 'd-none'
            }
            checked={taxItem.suiState && !disableSUI}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'suiState', e.target.checked);
            }}
            aria-disabled={disableSUI}
          />

          <CheckboxGrp
            {...getAccess(sectionAccess, 4, undefined, { disabledSameAsReadOnly: true, disabledDefault: federalDisabled || disableWH })}
            name={`${name}[${index}].isWHState`}
            label="W/H St"
            ref={register()}
            groupClass={
              checkForSpecialFields('isWHState')
                ? 'col-auto p-0 mr-2 text-center'
                : 'd-none'
            }
            checked={taxItem.isWHState && (whDisabled && !whDisabled()) && !federalDisabled}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'isWHState', e.target.checked);
            }}
            aria-disabled={federalDisabled || disableWH}
          />

          <InputGrp
            {...getAccess(sectionAccess, 4, undefined, { readOnlyDefault: federalDisabled })}
            name={`${name}[${index}].workZipCode`}
            errors={
              errors.taxes?.[index]?.workZipCode ||
              errors.employee?.taxes?.[index]?.workZipCode
            }
            ref={register()}
            label={
              checkForSpecialFields('workZipCode')
                ? 'Wrk. Zip'
                : ''
            }
            groupClass={'col-auto p-0 mr-2'}
            defaultValue={taxItem.workZipCode}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'workZipCode', e.target.value);
            }}
            type={
              checkForSpecialFields('workZipCode')
                ? 'text'
                : 'hidden'
            }
          />

          <CheckboxGrp
            {...getAccess(sectionAccess, 4, undefined, { disabledSameAsReadOnly: true, disabledDefault: federalDisabled })}
            name={`${name}[${index}].exempT_FAMILY`}
            label="PFL EE Exempt"
            ref={register()}
            groupClass={
              checkForSpecialFields('exempT_FAMILY')
                ? 'col-auto p-0 mr-2 text-center'
                : 'd-none'
            }
            defaultChecked={taxItem.exempT_FAMILY}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'exempT_FAMILY', e.target.checked);
            }}
          />

          <CheckboxGrp
            {...getAccess(sectionAccess, 4, undefined, { disabledSameAsReadOnly: true, disabledDefault: federalDisabled })}
            name={`${name}[${index}].exempT_MEDICAL`}
            label="PML EE Exempt"
            ref={register()}
            groupClass={
              checkForSpecialFields('exempT_MEDICAL')
                ? 'col-auto p-0 mr-2 text-center'
                : 'd-none'
            }
            defaultChecked={taxItem.exempT_MEDICAL}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'exempT_MEDICAL', e.target.checked);
            }}
          />

          <CheckboxGrp
            {...getAccess(sectionAccess, 4, undefined, { disabledSameAsReadOnly: true, disabledDefault: federalDisabled })}
            name={`${name}[${index}].nY_MCTMT`}
            label="NY MCTMT"
            ref={register()}
            groupClass={
              checkForSpecialFields('nY_MCTMT')
                ? 'col-auto p-0 mr-2 text-center'
                : 'd-none'
            }
            defaultChecked={taxItem.nY_MCTMT}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'nY_MCTMT', e.target.checked);
            }}
          />
          
          <CheckboxGrp
            {...getAccess(sectionAccess, 4, undefined, { disabledSameAsReadOnly: true, disabledDefault: federalDisabled })}
            name={`${name}[${index}].isClergy`}
            label="Is Clergy"
            ref={register()}
            groupClass={
              checkForSpecialFields('isClergy')
                ? 'col-auto p-0 mr-2 text-center'
                : 'd-none'
            }
            defaultChecked={taxItem.isClergy}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'isClergy', e.target.checked);
            }}
          />
          
          <InputGrp
            {...getAccess(sectionAccess, 4, undefined, { readOnlyDefault: federalDisabled })}
            name={`${name}[${index}].rate`}
            errors={
              errors.taxes?.[index]?.rate ||
              errors.employee?.taxes?.[index]?.rate
            }
            ref={register({
              required: {
                value: checkForSpecialFields('rate') ?? true,
                message: 'Required',
              },
              min: {
                value: 0,
                message: 'Must be greater than 0',
              },
              valueAsNumber: true,
            })}
            label={checkForSpecialFields('rate') ? 'Rate' : ''}
            type={
              checkForSpecialFields('rate') ? 'number' : 'hidden'
            }
            step="0.0001"
            groupClass={'col-auto p-0 mr-2'}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'rate', +e.target.value);
            }
            }
            defaultValue={isNaN(taxItem?.rate ?? 0) ? (taxItem?.rate ?? '0.0000') : (taxItem?.rate ?? 0).toFixed(4)}
          />
          <InputGrp
            {...getAccess(sectionAccess, 4, undefined, { readOnlyDefault: federalDisabled })}
            name={`${name}[${index}].exemptDependants`}
            errors={
              errors.taxes?.[index]?.exemptDependants ||
              errors.employee?.taxes?.[index]?.exemptDependants
            }
            ref={register({
              required: 'Required',
              min: {
                value: 0,
                message: 'Must be greater than 0',
              },
              valueAsNumber: true,
            })}
            label={
              checkForSpecialFields('exemptDependants')
                ? 'Dep. (b)'
                : ''
            }
            type={
              checkForSpecialFields('exemptDependants')
                ? 'number'
                : 'hidden'
            }
            groupClass={'col-auto p-0 mr-2'}
            defaultValue={taxItem.exemptDependants}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'exemptDependants', +e.target.value);
            }}
          />

          <InputGrp
            {...getAccess(sectionAccess, 4, undefined, { readOnlyDefault: federalDisabled })}
            name={`${name}[${index}].creditDependants`}
            errors={
              errors.taxes?.[index]?.creditDependants ||
              errors.employee?.taxes?.[index]?.creditDependants
            }
            ref={register({
              required: 'Required',
              min: {
                value: 0,
                message: 'Must be greater than 0',
              },
              valueAsNumber: true,
            })}
            label={
              checkForSpecialFields('creditDependants')
                ? 'Dep. Credit(c)'
                : ''
            }
            groupClass={'col-auto p-0 mr-2'}
            defaultValue={taxItem.creditDependants}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'creditDependants', +e.target.value);
            }}
            type={
              checkForSpecialFields('creditDependants')
                ? 'text'
                : 'hidden'
            }
          />

          <CheckboxGrp
            {...getAccess(sectionAccess, 4, undefined, { disabledSameAsReadOnly: true, disabledDefault: federalDisabled })}
            name={`${name}[${index}].multipleJobs`}
            label="Mult Jobs"
            ref={register()}
            groupClass={
              checkForSpecialFields('multipleJobs')
                ? 'col-auto p-0 mr-2 text-center'
                : 'd-none'
            }
            defaultChecked={taxItem.multipleJobs}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'multipleJobs', e.target.checked);
            }}
          />

          <InputGrp
            {...getAccess(sectionAccess, 4, undefined, { readOnlyDefault: federalDisabled || !checkForSpecialFields('deductionCredit') })}
            name={`${name}[${index}].deductionCredit`}
            errors={
              errors.taxes?.[index]?.deductionCredit ||
              errors.employee?.taxes?.[index]?.deductionCredit
            }
            ref={register({
              min: {
                value: 0,
                message: 'Must be greater than 0',
              },
              valueAsNumber: true,
              required: {
                value:
                  checkForSpecialFields('deductionCredit') ??
                  true,
                message: 'Required',
              },
            })}
            label={
              checkForSpecialFields('deductionCredit')
                ? 'Dep. Credit'
                : ''
            }
            groupClass={'col-1 p-0 mr-1'}
            defaultValue={taxItem.deductionCredit?.toFixed(2)}
            type={
              checkForSpecialFields('deductionCredit')
                ? 'number'
                : 'hidden'
            }
            step=".01"
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'deductionCredit', parseFloat(e.target.value));
            }
            }
            style={{ textAlign: 'right' }}
          />

          <InputGrp
            {...getAccess(sectionAccess, 4, undefined, { readOnlyDefault: federalDisabled || !checkForSpecialFields('otherIncome') })}
            name={`${name}[${index}].otherIncome`}
            errors={
              errors.taxes?.[index]?.otherIncome ||
              errors.employee?.taxes?.[index]?.otherIncome
            }
            ref={register({
              min: {
                value: 0,
                message: 'Must be greater than 0',
              },
              valueAsNumber: true,
              required: {
                value:
                  checkForSpecialFields('otherIncome') ??
                  true,
                message: 'Required',
              },
            })}
            label={
              checkForSpecialFields('otherIncome')
                ? 'Other Income'
                : ''
            }
            groupClass={'col-1 p-0 mr-1'}
            defaultValue={(taxItem?.otherIncome ?? 0).toFixed(2)}
            type={
              checkForSpecialFields('otherIncome')
                ? 'number'
                : 'hidden'
            }
            step=".01"
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'otherIncome', +e.target.value);
            }
            }
            style={{ textAlign: 'right' }}
          />

          <InputGrp
            {...getAccess(sectionAccess, 4, undefined, { readOnlyDefault: federalDisabled || !checkForSpecialFields('deductions') })}
            name={`${name}[${index}].deductions`}
            errors={
              errors.taxes?.[index]?.deductions ||
              errors.employee?.taxes?.[index]?.deductions
            }
            ref={register({
              min: {
                value: 0,
                message: 'Must be greater than 0',
              },
              valueAsNumber: true,
              required: {
                value:
                  checkForSpecialFields('deductions') ?? true,
                message: 'Required',
              },
            })}
            label={
              checkForSpecialFields('deductions')
                ? 'Deductions'
                : ''
            }
            groupClass={'col-1 p-0 mr-1'}
            defaultValue={(taxItem.deductions ?? 0).toFixed(2)}
            type={
              checkForSpecialFields('deductions')
                ? 'number'
                : 'hidden'
            }
            step=".01"
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'deductions', +e.target.value);
            }
            }
            style={{ textAlign: 'right' }}
          />
          <InputGrp
            {...getAccess(sectionAccess, 4, undefined, { readOnlyDefault: federalDisabled || !checkForSpecialFields('extraWH') })}
            name={`${name}[${index}].extraWH`}
            errors={
              errors.taxes?.[index]?.extraWH ||
              errors.employee?.taxes?.[index]?.extraWH
            }
            ref={register({
              min: {
                value: 0,
                message: 'Must be greater than 0',
              },
              valueAsNumber: true,
              required: {
                value: checkForSpecialFields('extraWH') ?? true,
                message: 'Required',
              },
            })}
            label={
              checkForSpecialFields('extraWH') ? 'Extra WH' : ''
            }
            groupClass={'col-1 p-0 mr-1'}
            defaultValue={taxItem.extraWH?.toFixed(2)}
            type={
              checkForSpecialFields('extraWH')
                ? 'number'
                : 'hidden'
            }
            step=".01"
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateField(taxItem.id, index, 'extraWH', +e.target.value);
            }
            }
            style={{ textAlign: 'right' }}
          />
        </div>
      )}

      <div className="row">
        <div className="col-auto  mr-auto">
          {taxItem.agency === 'Federal' ? (
            <CheckboxGrpInLine
              {...getAccess(sectionAccess, 4, undefined, { disabledSameAsReadOnly: true })}
              name={`${name}[${index}].irsLockOut`}
              checked={taxItem.irsLockOut}
              ref={register()}
              label='IRS has issued a "lock-in" letter'
              labelWidth={200}
              labelWidthUnit="px"
              onChange={(
                e: React.ChangeEvent<HTMLInputElement>,
              ) => {
                updateField(taxItem.id, index, 'irsLockOut', e.target.checked);
              }}
            />
          ) : null}
        </div>
        <div className="col-auto">
          {!(taxItem.agency === 'Federal' && taxItem.year! < 2020) && (
            <button
              {...getAccess(sectionAccess, 4, undefined, { disabledSameAsReadOnly: true })}
              type="button"
              className="btn btn-link dm-grid-action-title"
              onClick={onDelete}
            >
              Delete Tax
              <Icon
                name="minus-circle"
                className="fa-minus-circle"
              />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default TaxItem;
