import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useAuth } from 'core/providers/authProvider';
import {
  employeesDD,
  payrollDD,
  reportingDD,
  humanCapitalManagementDD,
  accountsPayableDD,
  menuIds,
  manageDD,
  toolsDD,
  contractorDD,
} from 'navigation/app-main-nav';
import { DMDropdownMenu } from 'core/components/form-controls';
import { useSelector } from 'react-redux';
import { getSso } from 'core/store/selectors/sso.selector';
import { getClientOptionIsContractor, getOpenPayrolls, getReportOptions } from 'core/store/selectors';
import { DMDropdownDataModel, DMDropdownItemModel } from 'core/components/form-controls/DMDropDown';
import { loadSso } from 'core/store/actions/sso.action';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import MainNavMenuItemModal from 'core/components/modals/MainNavMenuItem.modal';
import { cloneDeep } from 'lodash';
import ActivationNoticeModal from 'core/components/modals/ActivationNotice.modal';
import IntegrationLogsModal from 'core/components/modals/tool-dropdown/IntegrationLogsModal';
import { getClientIntegrationLogs } from 'core/store/selectors/integration.selector';
import { loadCmTaxEntities } from 'core/store/actions/tax.action';
import { loadPayroll } from 'core/store/actions';
import ConfirmationModal from 'core/components/modals/confirmation.modal';

const currentYear = new Date().getFullYear();

type AppMainNavProp = {
  handleEmployeeChangeClick?: () => void;
  handleDeductionShortagesClick?: () => void;
};

type ModalProperties = {
  component: JSX.Element | null;
};

const AppMainNav: React.FC<AppMainNavProp> = (props) => {
  const auth = useAuth();
  const [, setModal] = useState<ModalProperties>({ component: null });
  const [showModal, setShowModal] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [showIntegrationLogsModal, setShowIntegrationLogsModal] = useState(false);
  const [toolDropDown, setToolDropDown] = useState<DMDropdownDataModel>(structuredClone(toolsDD));
  const [toTransmittal, setToTransmittal] = useState<boolean>(false);
  const [showPayrollModal, setShowPayrollModal] = useState<boolean>(false);
  
  const openPayrolls = useSelector(getOpenPayrolls);
  const openNewSystemPayrolls = openPayrolls?.filter((payroll) => payroll.useNewCheckRegister) ?? [];
  const bambooClientOptions = useSelector(getReportOptions('BambooReport'));
  const empNavClientOptions = useSelector(getReportOptions('EmployeeNavigatorReport'));
  const acaFullTimeCalcOption = useSelector(getReportOptions('AcaFullTimeReport'));
  const isContractor = useSelector(getClientOptionIsContractor);
  const getSsoUrl = useSelector(getSso);
  const clientNumber = useAppSelector((state) => { return state.client.client?.clientNo; });
  const moduleAccessLoading = useAppSelector((state) => { return state.app.moduleAccessLoading; });
  const clientIntegrationLogs = useSelector(getClientIntegrationLogs);
  const payrollLoading = useAppSelector((state) => state.payroll.loading);
  const client = useAppSelector((state) => state.client.client);
  const payRateWorkFlow = useSelector(getReportOptions('PayRateWorkFlow'));
  const terminationWorkFlow = useSelector(getReportOptions('TerminationWorkFlow'));

  const history = useHistory();

  const dispatch = useAppDispatch();

  const onSelect = (menuId: string) => {
    if (menuId === 'tools-nav-6') {
      setShowIntegrationLogsModal(true);
    }
  };

  useEffect(() => {
    dispatch(loadSso());
  }, [clientNumber]);
  
  useEffect(() => {
    if (!toTransmittal || payrollLoading) return;    
    if (!payrollLoading && !openNewSystemPayrolls?.length) {
      setToTransmittal(false);
      setShowPayrollModal(true);
      return;
    }
    if (openNewSystemPayrolls?.length > 1) {
      alert('You have more than one open transmittal date line. Please choose the correct date line to process.');
      history.push('/process-payroll');
      setToTransmittal(false);
      return;
    }
    if (!openNewSystemPayrolls?.length) {
      history.push('/process-payroll');
      setToTransmittal(false);
      return;
    }
    history.push('/open-current-transmittal');
    setToTransmittal(false);
  }, [toTransmittal, payrollLoading, openPayrolls]);

  //This will remove the Integration Log option if they have none or show it if they have some
  useEffect(() => {
    if (clientIntegrationLogs.length === 0) {
      const toolsDropDownCopy = structuredClone(toolsDD);
      toolsDropDownCopy.items = toolsDropDownCopy.items.filter(x => x.id !== 'tools-nav-6');
      setToolDropDown(toolsDropDownCopy);
    } else {
      setToolDropDown(structuredClone(toolsDD));
    }
  }, [clientIntegrationLogs]);

  useEffect(() => {
    const payrollIndex = payrollDD.items.findIndex(
      (x) => { return x.id === 'payroll-5'; },
    );

    if (!(payrollIndex > -1)) return;

    if (getSsoUrl) {
      payrollDD.items[payrollIndex].href = getSsoUrl.redirectURL;
    } else {
      payrollDD.items[payrollIndex].href = '#';
    }
  }, [getSsoUrl]);

  if (!auth.isAuthenticated()) return null;

  const onMenuSelect = (menuId: string) => {
    switch (menuId) {
      case menuIds.EMPLOYEE_CHANGES_SUB_MENU_ID:
        props?.handleEmployeeChangeClick?.();
        break;
      case menuIds.EMPLOYEE_DEDUCTION_SHORTAGES_SUB_MENU_ID:
        props?.handleDeductionShortagesClick?.();
        break;
      case menuIds.PAYROLL_SSO:
        if (getSsoUrl?.redirectURL === '') {
          setModal({
            component: (
              <MainNavMenuItemModal
                show={showModal}
                onHide={() => {
                  setShowModal(false);
                  setModal({ component: null });
                }}
              />
            ),
          });
          setShowModal(true);
        }
        break;
      case menuIds.BAMBOO_HR_REPORT:
        if (!bambooClientOptions?.showReport) {
          setShowActivationModal(true);
        }
        break;
      case menuIds.NAVIGATOR_AUDIT_REPORT:
        if (!empNavClientOptions?.showReport) {
          setShowActivationModal(true);
        }
        break; 
      case menuIds.ACA_FULL_TIME_CALC_REPORT:
        if (!acaFullTimeCalcOption?.showReport) {
          setShowActivationModal(true);
        }
        break;
      case menuIds.MYINFO_CHANGES:
        dispatch(loadCmTaxEntities());
        break;
      case menuIds.OPEN_CURRENT_TRANSMITTAL:
        setToTransmittal(true);
        
        if (!!openNewSystemPayrolls?.length && openNewSystemPayrolls[0].clientNo === +(clientNumber ?? 0)) return;
        
        dispatch(loadPayroll({
          beginDate: `${currentYear}-01-01T05:00:00.000Z`,
          endDate: `${currentYear}-12-31T05:00:00.000Z`,
          byCheckDate: true,
        }));
        break;
      default:
        break;
    }
  };

  const showNavModal = () => {
    return showModal;
  };

  const parsedReportingDDItems = useMemo(() => {
    if (!reportingDD?.items?.length) return;
    
    const reportingDDItems = cloneDeep(reportingDD.items).map(item => {
      if (isContractor) {
        return item;
      }

      if (item.id === 'reporting-4' && item?.subItems) {

        item.subItems = item?.subItems.filter(subItem => {
          return !subItem.isContractorOnly;
        });
      }

      return item;
    });

    if (isContractor) {
      return [...reportingDDItems, contractorDD];
    }

    return reportingDDItems;
  }, [isContractor, reportingDD?.items]);

  return (
    <>
      {showPayrollModal && (<ConfirmationModal
        title="DM Payroll"
        message={'You do not have an open Payroll.\bDo you want to insert a new payroll?'}
        show={showPayrollModal}
        backdrop={'static'}
        onConfirmed={(confirm: boolean) => {
          //If they deny adding a new dateline back to the process payroll screen
          if (confirm) history.push('/process-payroll'); 
          setShowPayrollModal(false);
        }}
        onHide={() => { 
          setShowPayrollModal(false);
        }}
      />
      )}
      <div className="app-toolbar-container">
        {moduleAccessLoading ? null : (
          <>
            <NavLink
              to="/home"
              style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                paddingRight: '8px',
              }}
            >
              <label className="app-home-label">
                <span className="dm-main-nav">Home</span>
              </label>
            </NavLink>
            <DMDropdownMenu
              data={employeesDD}
              toggleClass="dm-main-nav"
              onShowModal={() => { setShowModal(true); }}
              onHideModal={() => { setShowModal(false); }}
            />
            <DMDropdownMenu
              data={payrollDD}
              toggleClass="dm-main-nav"
              onShowModal={() => { setShowModal(true); }}
              onHideModal={() => { setShowModal(false); }}
              onSelect={onMenuSelect}
            />
            <DMDropdownMenu
              data={{
                id: reportingDD.id,
                title: reportingDD.title,
                groupId: reportingDD.groupId,
                items: parsedReportingDDItems as DMDropdownItemModel[],
              }}
              onSelect={onMenuSelect}
              onShowModal={() => { setShowModal(true); }}
              onHideModal={() => { setShowModal(false); }}
              toggleClass="dm-main-nav"
            />
            <DMDropdownMenu
              data={humanCapitalManagementDD((payRateWorkFlow?.showReport || false) || (terminationWorkFlow?.showReport || false))}
              toggleClass="dm-main-nav"
            />
            <DMDropdownMenu
              data={accountsPayableDD}
              toggleClass="dm-main-nav"
            />
            <DMDropdownMenu
              data={manageDD}
              toggleClass="dm-main-nav"
            />
            <DMDropdownMenu
              data={toolDropDown}
              toggleClass="dm-main-nav"
              onSelect={onSelect}
            />
          </>
        )}
      </div>
      {showNavModal() ? (
        <MainNavMenuItemModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
            setModal({ component: null });
          }}
        />
      ) : null}
      {showActivationModal ? (
        <ActivationNoticeModal
          show={showActivationModal}
          onHide={() => { setShowActivationModal(false);}}
        />
      ) : null}
      {showIntegrationLogsModal ? (
        <IntegrationLogsModal
          show={showIntegrationLogsModal}
          onHide={() => { setShowIntegrationLogsModal(false);}}
        />
      ) : null}
    </>
  );
};

export default AppMainNav;
