import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class EmpGroupTerm {
  empId = 0;

  protectedEmpNo = '';

  protectedClientNo = '';

  startDate: Date | string | null = null;

  multiplier = 0;

  amountOfIns = 0;

  age = 0;

  monthlyCostPerThousand = 0;

  excessIns = 0;

  annualCost = 0;

  perPayAmount = 0;

  earningsCode = '';

  payrollHistoryId = 0;

  autoAddToRecurringEarns = false;

  annualRate = 0;

  wageCap = 0;

  rounding = '';

  static readonly convProps: PropTypeLists = {
    dateProps: ['startDate'],
    numberProps: ['empId', 'age', 'payrollHistoryId'],
    currencyProps: ['amountOfIns', 'monthlyCostPerThousand', 'excessIns', 'annualCost', 'perPayAmount', 'annualRate', 'wageCap'],
    boolProps: ['autoAddToRecurringEarns'],
  };

  constructor(empId: string | number, protectedEmpNo: string, props?: { [key: string]: any }) {
    this.empId = (typeof empId === 'string') ? parseInt(empId) : empId;
    this.protectedEmpNo = protectedEmpNo;

    props && convToClass(this, props, EmpGroupTerm.convProps);
  }
}