/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState, MouseEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, RowNode } from '@ag-grid-community/core';
import { useDispatch } from 'react-redux';
import AGDeleteHeaderComponent from 'utilities/ag-grid-renderers/AGDeleteHeaderComponent';
import AGEditButtonRendererComponent from 'utilities/ag-grid-renderers/AGEditButtonRendererComponent';
import CheckboxRenderer from 'utilities/ag-grid-renderers/CheckboxRenderer';
import { useAppSelector } from 'utilities/hooks';
import { Dropdown, DropdownDeleteRequest } from 'core/models';
import { deleteDepartment, deleteDropdown, deleteLocation, deleteSubdepartment, deleteSubdepartment2, removeDepartment } from 'core/store/actions';
import Modal from 'core/components/modals/Modal';
import Icon from 'core/components/shared/Icon';

type PropTypes = {
  options: any[];
  header?: string;
  show: boolean;
  labelField?: string;
  valueField?: string;
  idField?: string;
  idEditable?: boolean;
  isEditable?: boolean;
  onHide: () => void;
  formComponent: React.FunctionComponent<any>;
  dropdownName?: string;
};

const DropdownAddEditModal: React.FC<PropTypes> = ({
  options,
  header,
  show,
  labelField = 'description',
  valueField = 'id',
  idField = 'id',
  idEditable = false,
  isEditable = true,
  formComponent: FormComponent,
  dropdownName = '',
  onHide,
}) => {
  const dispatch = useDispatch();
  
  const clientNo = useAppSelector(({ client }) => client.client?.clientNo);
  
  const transformedOpts = options
    .filter((o) => o[valueField] !== 'open-modal')
    ?.map((x) => ({
      ...x,
      delete: false,
    }));

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [addEditItem, setAddEditItem] = useState<any>(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [rowData, setRowData] = useState<any[]>(transformedOpts);

  useEffect(() => {
    setRowData(transformedOpts);
  }, [options]);

  const addItem = (e: MouseEvent) => {
    e?.stopPropagation();
    
    if (!clientNo) return;
    
    const x = new Dropdown(clientNo, '', {
      usesCode: options.length > 0 ? options[0].usesCode : false,
      dropdownName,
    });
    
    setAddEditItem(x);
    setShowAddForm(true);
  };

  const onEdit = (data: MouseEvent) => {
    setAddEditItem(data);
    setShowAddForm(true);
  };

  const columns: ColDef[] = [
    {
      field: 'code',
      headerName: 'Code',
      sortable: true,
      width: 100,
    },
    {
      field: labelField,
      headerName: 'Description',
      sortable: true,
      width: 300,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      cellRendererParams: { clickHandler: onEdit },
      cellRenderer: 'editButtonRendererComponent',
      cellClass: 'ag-cell-border',
      width: 50,
      hide: !isEditable,
    },
    {
      field: 'delete',
      headerComponent: AGDeleteHeaderComponent,
      cellRenderer: 'checkBoxRenderer',
      cellStyle: { paddingLeft: '16px' },
      width: 45,
    },
  ];

  const gridOptions: GridOptions = {
    columnDefs: columns,
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
    },
    components: {
      checkBoxRenderer: CheckboxRenderer,
      editButtonRendererComponent: AGEditButtonRendererComponent,
    },
    pagination: true,
    paginationPageSize: 10,
  };

  useEffect(() => {
    if (!gridColumnApi) return;
      
    gridColumnApi?.setColumnVisible(
      'code',
      options.length > 0 ? options[0].usesCode : false,
    );
  }, [gridColumnApi]);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  const onDeleteSelected = () => {
    if (!gridApi) return;
    
    const deleteRequests: DropdownDeleteRequest[] = [];
    
    gridApi.forEachNode((node: RowNode) => {
      const item = node.data;
      
      if (item.delete) {
        if (item[idField] && !['location', 'department', 'subdepartment', 'subdepartment2'].includes(dropdownName.toLowerCase())) {
          deleteRequests.push({ id: item[idField],
            dropdownName }); 
        } else {
          if (dropdownName.toLowerCase() === 'location') {
            dispatch(deleteLocation(+(item[idField])));
          }  else if (dropdownName.toLowerCase() === 'department') {
            dispatch(deleteDepartment(+(item[idField])));
          } else if (dropdownName.toLowerCase() === 'subdepartment') {
            dispatch(deleteSubdepartment(+(item[idField])));
          } else if (dropdownName.toLowerCase() === 'subdepartment2') {
            dispatch(deleteSubdepartment2(+(item[idField])));
          } 
        }
        setRowData((prev) => {
          const filteredOpts = prev.filter((x) => { return x[idField] !== item[idField]; });
          return filteredOpts;
        });
      }
    });
    
    if (deleteRequests?.length) {
      dispatch(deleteDropdown(deleteRequests));
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title={header}
    >
      {!showAddForm ? (
        <Row>
          <Col
            className="text-right pb-2"
            onClick={addItem}
          >
            <span
              className="dm-grid-action-title"
              role="button"
            >
              ADD
            </span>
            <Icon
              name="plus-circle"
              className="fa-plus-circle"
            />
          </Col>
        </Row>
      ) : (
        <FormComponent
          item={addEditItem}
          idEditable={idEditable}
          onHide={() => { setShowAddForm(false); }}
          options={options}
          dropdownName={dropdownName}
        />
      )}
      <Row>
        <Col
          xs={12}
          className="table-wrapper-wrapper ag-theme-balham"
          style={{ height: '400px' }}
        >
          <AgGridReact
            gridOptions={gridOptions}
            rowData={rowData}
            onGridReady={onGridReady}
          ></AgGridReact>
        </Col>
      </Row>
      <Row>
        <div className="col-12 text-right">
          <button
            type="button"
            className="btn btn-link pb-0"
            onClick={onDeleteSelected}
          >
            Delete Selected{' '}
            <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button>
        </div>
      </Row>
    </Modal>
  );
};
export default DropdownAddEditModal;
