import React, { useEffect, useState } from 'react';
import DmPageWrapper from 'core/components/shared/DmPageWrapper';
import styles from './styles.module.scss';
import sharedStyles from 'core/components/shared/shared.module.scss';
import { convToDateString } from 'utilities/utilities';
import ChangeRequestModal from './ChangeRequest.modal';
import { useBreakpoint } from 'utilities/BreakpointProvider';
import DetailRow from 'core/components/shared/DetailRow';
import { defaultFilterModel, getStatusIcon } from './constants';
import { WorkFlowTaskList } from 'core/models/WorkFlow.model';
import { useAppSelector } from 'utilities/hooks';
import { TableModel as Tm } from 'core/components/shared/table/types';
import SortableHeader from 'core/components/shared/table/SortableHeader';
import { filterModelOpts } from 'core/components/shared/table/constants';
import { workflowAggregateFilter } from './utilities';
import useTableSort, { SortDirection } from 'hooks/useTableSort';
import { buildInitialState } from 'core/components/shared/table/utilities';

const WorkFlowTasks = () => {
  const workFlowTaskList = useAppSelector(state => state.workFlow.myWorkFlowTaskList);

  const [selectedTask, setSelectedTask] = useState<WorkFlowTaskList | null>(null);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [rowData, setRowData] = useState<WorkFlowTaskList[]>(workFlowTaskList ?? []);
  const [filterModel, setFilterModel] = useState<Tm.FilterModel<WorkFlowTaskList>>(defaultFilterModel);
  const [activeCol, setActiveCol] = useState<keyof WorkFlowTaskList>('startDate');
  
  const breakpoints = useBreakpoint();
  
  const { currentSortState, sortDispatch, sortRowsByColumn } = useTableSort(buildInitialState(defaultFilterModel));

  useEffect(() => {
    setRowData(workFlowTaskList ?? []);
  }, [workFlowTaskList]);
  
  useEffect(() => {
    setRowData((prevState) => sortRowsByColumn(prevState, [activeCol], currentSortState[activeCol], ['startDate']));
  }, [currentSortState]);
  
  const handleShowTaskDetail = (task: WorkFlowTaskList) => {
    setSelectedTask(task);
    setShowDetailModal(true);
  };
  
  // TODO: create shared functions from there and those in WorkFlowPage (since they're the same). Just thinking about the best way.
  const onColumnSort = (column: keyof WorkFlowTaskList) => {
    sortDispatch({ type: 'TOGGLE_SORT', column });
    setActiveCol(column);
    
    // TODO: This is really ugly. We want the next one but it's not yet available from the reducer so determine it here.
    const currentSort: SortDirection = currentSortState?.[column] || 'UNSORTED';
    const nextSort: SortDirection = currentSort === 'ASC' ? 'DESC' : currentSort === 'DESC' ? 'UNSORTED' : 'ASC';
    const updatedRowData = sortRowsByColumn<WorkFlowTaskList>(rowData, [column], currentSortState[activeCol], ['startDate']);

    const updatedFilterModel: Tm.FilterModel<WorkFlowTaskList> = {
      ...structuredClone(filterModel),
      [column]: {
        ...(filterModel?.[column] ?? {}),
        sortOrder: nextSort,
      },
    };
    
    setRowData(updatedRowData);
    setFilterModel(updatedFilterModel);
  };
  
  const onColumnFilter = (columnQuery: string, column: keyof WorkFlowTaskList) => {
    const updatedFilterModel: Tm.FilterModel<WorkFlowTaskList> = {
      ...filterModel,
      [column]: {
        ...(filterModel?.[column] ?? {}),
        sortOrder: filterModel?.[column]?.sortOrder ?? 'UNSORTED',
        query: columnQuery,
      },
    };
    
    const newRowData = workflowAggregateFilter(workFlowTaskList, updatedFilterModel);
    
    setFilterModel(updatedFilterModel);
    setRowData(newRowData);
  };
  
  const onColumnFilterTypeChange = (filter: Tm.FilterType, column: keyof WorkFlowTaskList) => {
    const updatedFilterModel: Tm.FilterModel<WorkFlowTaskList> = {
      ...filterModel,
      [column]: {
        ...(filterModel?.[column] ?? {}),
        sortOrder: filterModel?.[column]?.sortOrder ?? 'UNSORTED',
        query: ['Blank', 'Not blank'].includes(filter) ? '' : filterModel?.[column]?.query, // clear query if just checking for existence or absence of value
        filterType: filter,
      },
    };
    
    const newRowData = workflowAggregateFilter(workFlowTaskList, updatedFilterModel);
    
    setFilterModel(updatedFilterModel);
    setRowData(newRowData);
  };
  
  const onUpdateCompareDate = (newDate: Date | null, column: keyof WorkFlowTaskList) => {
    const updatedFilterModel: Tm.FilterModel<WorkFlowTaskList> = {
      ...structuredClone(filterModel),
      [column]: {
        ...(filterModel?.[column] ?? {}),
        sortOrder: filterModel?.[column]?.sortOrder ?? 'UNSORTED',
        query: '', // clear query since we're adding a specific date range
        compareDate: String(newDate),
      },
    };
    
    const newRowData = workflowAggregateFilter(workFlowTaskList, updatedFilterModel);
      
    setFilterModel(updatedFilterModel);
    setRowData(newRowData);
  };
  
  const onUpdateDateRange = (newDate: Date | null, property: keyof Pick<Tm.FilterData, 'dateFrom' | 'dateTo'>, column: keyof WorkFlowTaskList) => {
    const updatedFilterModel: Tm.FilterModel<WorkFlowTaskList> = {
      ...structuredClone(filterModel),
      [column]: {
        ...(filterModel?.[column] ?? {}),
        sortOrder: filterModel?.[column]?.sortOrder ?? 'UNSORTED',
        query: '', // clear query since we're adding a specific date range
        [property]: String(newDate),
      },
    };
    
    const newRowData = workflowAggregateFilter(workFlowTaskList, updatedFilterModel);
      
    setFilterModel(updatedFilterModel);
    setRowData(newRowData);
  };
  
  const onClearFilter = () => {
    setRowData(workFlowTaskList);
    setFilterModel(defaultFilterModel);
    sortDispatch({ type: 'RESET', column: '' });
  };
  
  return (
    <>
      <DmPageWrapper title="Workflow Tasks">
        <div className="d-flex mb-2">
          <div className={`${sharedStyles['section-title-blue']} mr-3 mb-0 my-auto`}>Pending workflows ({rowData.length} total)</div>
          <button
            type="button"
            className="btn orange-button ml-3 my-auto"
            onClick={onClearFilter}
            style={{
              height: 'fit-content',
            }}
          >
            Clear Filters
          </button>
        </div>
        {/* on mobile render collapse rows, else render a table */}
        {breakpoints.xs ? (
          <div
            className="d-flex flex-column"
            style={{
              maxHeight: '80vh',
              overflow: 'auto',
            }}
          >
            {rowData.map((x) => {
              return (
                <DetailRow
                  key={x.workFlowTaskId}
                  rowClick
                  expandByDefault
                >
                  <DetailRow.SummaryContainer>
                    <div className="d-flex flex-column">
                      <div className={`font-weight-bold ${sharedStyles['summary-title']}`}>
                        {x.workFlowType}&nbsp;
                        {getStatusIcon(x.status)}
                      </div>
                      <div className={`${sharedStyles['summary-subtitle']}`}>
                        Intiated on {convToDateString(x.startDate)}
                      </div>
                    </div>
                  </DetailRow.SummaryContainer>
                  <DetailRow.DetailsContainer>
                    <div className="d-flex flex-column">
                      <div>
                        <span className="font-weight-bold">Type:&nbsp;</span>{x.workFlowType}
                      </div>
                      <div>
                        <span className="font-weight-bold">Initiator:&nbsp;</span>{x.initiator}
                      </div>
                      <div>
                        <span className="font-weight-bold">Initiated for:&nbsp;</span>{x.targetEmployeeName}
                      </div>
                    </div>
                    <button
                      className="btn btn-link pl-0"
                      onClick={() => {
                        handleShowTaskDetail(x);
                      }}
                    >
                      View Details
                    </button>
                  </DetailRow.DetailsContainer>
                </DetailRow>
              );
            })}
          </div>
        ) : (
          <div className={`dm-table-container ${styles['workflow-table-container']}`}>
            <table className={`base-table sortable ${styles['workflow-table']}`}>
              <colgroup>
                <col style={{ width: '15%', minWidth: '100px' }} />
                <col />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  {/* why is this labeled details and not just status? */}
                  <SortableHeader<WorkFlowTaskList>
                    column="status"
                    label="Details"
                    sortState={currentSortState?.status ?? 'UNSORTED'}
                    onColumnSort={onColumnSort}
                    onColumnFilter={onColumnFilter}
                    onColumnFilterTypeChange={onColumnFilterTypeChange}
                    filterData={filterModel?.status ?? null}
                    filterOptions={filterModelOpts}  
                    onUpdateDateRange={onUpdateDateRange}
                    onUpdateCompareDate={onUpdateCompareDate}
                    leftBuffer={-30}
                  />
                  <SortableHeader<WorkFlowTaskList>
                    column="workFlowType"
                    label="Type"
                    sortState={currentSortState?.workFlowType ?? 'UNSORTED'}
                    onColumnSort={onColumnSort}
                    onColumnFilter={onColumnFilter}
                    onColumnFilterTypeChange={onColumnFilterTypeChange}
                    filterData={filterModel?.workFlowType ?? null}
                    filterOptions={filterModelOpts}  
                    onUpdateDateRange={onUpdateDateRange}
                    onUpdateCompareDate={onUpdateCompareDate}
                    leftBuffer={-30}
                  />
                  <SortableHeader<WorkFlowTaskList>
                    column="initiator"
                    label="Initiator"
                    sortState={currentSortState?.initiator ?? 'UNSORTED'}
                    onColumnSort={onColumnSort}
                    onColumnFilter={onColumnFilter}
                    onColumnFilterTypeChange={onColumnFilterTypeChange}
                    filterData={filterModel?.initiator ?? null}
                    filterOptions={filterModelOpts}  
                    onUpdateDateRange={onUpdateDateRange}
                    onUpdateCompareDate={onUpdateCompareDate}
                    leftBuffer={-30}
                  />
                  <SortableHeader<WorkFlowTaskList>
                    column="startDate"
                    label="Initiated On"
                    sortState={currentSortState?.startDate ?? 'UNSORTED'}
                    onColumnSort={onColumnSort}
                    onColumnFilter={onColumnFilter}
                    onColumnFilterTypeChange={onColumnFilterTypeChange}
                    filterData={filterModel?.startDate ?? null}
                    filterOptions={filterModelOpts}  
                    onUpdateDateRange={onUpdateDateRange}
                    onUpdateCompareDate={onUpdateCompareDate}
                    leftBuffer={-30}
                  />
                  <SortableHeader<WorkFlowTaskList>
                    column="targetEmployeeName"
                    label="Initiated For"
                    sortState={currentSortState?.targetEmployeeName ?? 'UNSORTED'}
                    onColumnSort={onColumnSort}
                    onColumnFilter={onColumnFilter}
                    onColumnFilterTypeChange={onColumnFilterTypeChange}
                    filterData={filterModel?.targetEmployeeName ?? null}
                    filterOptions={filterModelOpts}  
                    onUpdateDateRange={onUpdateDateRange}
                    onUpdateCompareDate={onUpdateCompareDate}
                    leftBuffer={-30}
                  />
                </tr>
              </thead>
              <tbody>
                {rowData.map((x, i) => {
                  return (
                    <tr
                      key={i}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleShowTaskDetail(x);
                      }}
                    >
                      <td>
                        {getStatusIcon(x.status)}&nbsp;
                        {x.status}
                      </td>
                      <td>{x.workFlowType}</td>
                      <td>{x.initiator}</td>
                      <td>{convToDateString(x.startDate)}</td>
                      <td>{x.targetEmployeeName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </DmPageWrapper>
      {showDetailModal && selectedTask && (
        <ChangeRequestModal
          show={showDetailModal}
          onHide={() => { setShowDetailModal(false); }}
          task={selectedTask}
        />
      )}
    </>
  );
};

export default WorkFlowTasks;