import React, { } from 'react';

import {
  ControlDatePickerGrp,
  InputGrp,
  InputGrpDecimal,
  SelectGrp,
  SelectModalGrp,
} from '../../../core/components/form-controls';
import { ArrayField } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { HRLicense } from '../../../core/models/HREmployee.model';
import DropdownOptionForm from '../../../core/components/form-controls/select-modal/DropdownOptionForm';
import { getLicenseRenewalTypes, getStates } from '../../../core/store/selectors';

import Icon from 'core/components/shared/Icon';

type PropTypes = {
  item: Partial<ArrayField<HRLicense>>;
  index: number;
  control: any;
  formMethods: any,
  onDelete: (item: HRLicense) => void;
};

const LicenseItem: React.FC<PropTypes> = ({
  item,
  index,
  control,
  formMethods: { errors, setValue, register },
  onDelete,
}) => {


  const stateOpts = useSelector(getStates);
  const licenseTypes = useSelector(getLicenseRenewalTypes);

  return (

    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap">
        <input
          type="hidden"
          name={`licenseItems[${index}].empLicenseId`}
          defaultValue={item.empLicenseId}
          ref={register({ valueAsNumber: true })}
        />
        <InputGrp
          name={`licenseItems[${index}].agency`}
          defaultValue={item.agency}
          label="ISSUING AGENCY"
          groupClass="gc12 mw125"
          ref={register()} />
        <InputGrp
          name={`licenseItems[${index}].issuingCity`}
          defaultValue={item.issuingCity}
          label="ISSUING CITY"
          groupClass="gc12 mw125"
          ref={register()} />
        <SelectGrp
          name={`licenseItems[${index}].issuingState`}
          label="ISSUING STATE"
          groupClass="gc12 mw125"
          ref={register({ valueAsNumber: true })}
          options={stateOpts} />

        <ControlDatePickerGrp
          name={`licenseItems[${index}].startDate`}
          label="START DATE"
          groupClass="gc12 mw125"
          errors={errors.startDate}
          value={item.startDate}
          setValue={setValue}
          control={control} />
        <ControlDatePickerGrp
          name={`licenseItems[${index}].expirationDate`}
          label="EXPIRATION DATE"
          groupClass="gc12 mw125"
          errors={errors.expirationDate}
          value={item.expirationDate}
          setValue={setValue}
          control={control} />
        <ControlDatePickerGrp
          name={`licenseItems[${index}].renewalDate`}
          label="RENEWAL DATE"
          groupClass="gc12 mw125"
          errors={errors.renewalDate}
          value={item.renewalDate}
          setValue={setValue}
          control={control} />
        <InputGrpDecimal
          name={`licenseItems[${index}].employeeFees`}
          defaultValue={item.employeeFees}
          label="EMPLOYEE FEES"
          groupClass="gc12 mw125"
          ref={register({
            valueAsNumber: true,
          })} />
        <InputGrpDecimal
          name={`licenseItems[${index}].companyFees`}
          defaultValue={item.companyFees}
          label="COMPANY FEES"
          groupClass="gc12 mw125"
          ref={register({
            valueAsNumber: true,
          })} />
        <SelectModalGrp
          noOption={true}
          groupClass="gc12 mw125"
          label={'LICENSE TYPE'}
          modalTitle="LICENSE TYPE"
          formComponent={DropdownOptionForm}
          addOptionText="License Type"
          name={`licenseItems[${index}].renewalTypeId`}
          dropdownName="LicenseRenewalTypes"
          options={licenseTypes}
          control={control}
          value={item.renewalTypeId}
          setValue={setValue} />
        <InputGrp
          name={`licenseItems[${index}].licenseNumber`}
          defaultValue={item.licenseNumber}
          label="LICENSE NUMBER"
          groupClass="gc12 mw125"
          ref={register()} />
        <InputGrp
          name={`licenseItems[${index}].otherCertOrLicense`}
          defaultValue={item.otherCertOrLicense}
          label="OTHER CERTIFICATION OR LICENSE"
          groupClass="gc12 mw150"
          errors={errors.otherCertOrLicense}
          ref={register()} />
        <InputGrp
          name={`licenseItems[${index}].title`}
          defaultValue={item.title}
          label="TITLE"
          groupClass="gc12 mw125"
          ref={register()} />
        <InputGrp
          name={`licenseItems[${index}].comment`}
          defaultValue={item.comment}
          label="COMMENT"
          groupClass="gc12"
          ref={register()} />
      </div>
      <div className="row">
        <div className="col-12 text-right">
          <button
            type="button"
            className="btn btn-link dm-grid-action-title"
            onClick={() => { return onDelete(item as HRLicense); }}
          >
            Delete License
            <Icon name="minus-circle"
              className="fa-minus-circle" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default LicenseItem;
