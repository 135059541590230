import React, { CSSProperties, useContext } from 'react';
import Icon from 'core/components/shared/Icon';
import '../time-card-styles.scss';
import { InputGrpDecimal, SelectGrp } from 'core/components/form-controls';
import { DetailOtherEarning } from 'core/models';
import { TimeSheetSummaryContext } from './TimeSheetSummary';
import { useAppSelector } from 'utilities/hooks';

type Props = {
  otherEarning: DetailOtherEarning;
  timeCardId: number;
  detailIndex: number;
  timeCardIndex: number;
  earningIndex: number;
  showLabel?: boolean;
  canEdit?: boolean;
  removeEarnings: (timeCardId: number, timeCardDetailId: number, timeCardEarningId: number) => void,
};

const SummaryEarningsItem = ({
  otherEarning,
  timeCardId,
  detailIndex,
  timeCardIndex,
  removeEarnings,
  showLabel,
  earningIndex,
  canEdit = true,
}: Props) => {
  const infoGrpStyle: CSSProperties = {
    marginBottom: '0',
    maxWidth: canEdit ? '150px' : 'unset',
    marginLeft: '24px',
  };
  
  const { updateTimeCardState } = useContext(TimeSheetSummaryContext);
  const { earningsCode } = useAppSelector(({ dropdown }) => dropdown);
  
  function updateEarning(field: keyof DetailOtherEarning, value: any) {
    updateTimeCardState({
      type: 'UPDATE_DETAIL_EARNING',
      timeCardId: timeCardId,
      detailId: otherEarning.transmittalTimeCardDetailId,
      detailEarningId: otherEarning.transmittalTimeCardDetailOtherEarningsId,
      field,
      value,
    });
  }
  
  return (
    <div className="earning-item">
      <div style={{ width: canEdit ? '25%' : '33%' }}>
        <SelectGrp
          label={ showLabel ? 'Earnings Code' : undefined}
          name={`timeCardItems[${timeCardIndex}].details[${detailIndex}].otherEarnings[${earningIndex}].earningsCode`}
          options={earningsCode}
          defaultValue={otherEarning?.earningsCode}
          onChange={(e: any) => {
            updateEarning('earningsCode', e.target.value);
          }}
          selectStyles={{ height: '26px' }}
          groupStyle={{ marginBottom: '0' }}
          showId
        />
      </div>
      <div className={canEdit ? 'earning-code-col' : ''}>
        <div className="d-flex align-items-center">
          <InputGrpDecimal
            name={`timeCardItems[${timeCardIndex}].details[${detailIndex}].otherEarnings[${earningIndex}].amount`}
            defaultValue={otherEarning?.amount ?? 0}
            onChange={(e: any) => {
              updateEarning('amount', e.target.value);
            }}
            label={ showLabel ? 'Amount' : undefined}
            groupStyle={infoGrpStyle}
            inputStyle={{ height: '30px' }}
          />
        </div>
      </div>
      {canEdit && (
        <div
          className="d-flex flex-column"
          style={{ width: 'auto', flex: '1' }}
        >
          <div className="d-flex justify-content-end">
            <button
              className="section-action btn btn-link dm-grid-action-title p-0 m-0"
              onClick={() => { removeEarnings(timeCardId, otherEarning.transmittalTimeCardDetailId, otherEarning.transmittalTimeCardDetailOtherEarningsId); }}
            >
              Delete&nbsp;<Icon
                name="minus-circle"
                className="fa-minus-circle"
                title="delete earning"
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SummaryEarningsItem;