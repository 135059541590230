import { convToClass, PropTypeLists } from '../../utilities/classUtils';

export class EmpDate {
  empId = 0;

  clientNo = 0;

  empNo = 0;

  protectedEmpNo = '';

  hireDate: Date | string | null = null;

  raiseDate: Date | string | null = null;

  reviewDate: Date | string | null = null;

  hireDate2: Date | string | null = null;

  noHireDate: Date | string | null = null;

  noHireSent: Date | string | null = null;

  otherDate: Date | string | null = null;

  birthDate: Date | string | null = null;

  termDate: Date | string | null = null;

  termCode = 0;

  seniorityDate: Date | string | null = null;

  hireActStartDate: Date | string | null = null;

  hireActQualified = false;

  layOffDate: Date | string | null = null;

  layOffCode = 0;

  ignoreTermWhenPRProcessed = false;

  otherDateDescriptionID = 0;

  cineTrainJobTitleID = null;

  benefitEligibleDate: Date | string | null = null;

  cobraStartDate: Date | string | null = null;

  notEligibleForRehire = false;

  lastAnnual = 0;

  lastSalary = 0;

  lastHourly = 0;

  static readonly convProps: PropTypeLists = {
    dateProps: [
      'hireDate',
      'raiseDate',
      'reviewDate',
      'hireDate2',
      'noHireDate',
      'noHireSent',
      'otherDate',
      'birthDate',
      'termDate',
      'seniorityDate',
      'hireActStartDate',
      'layOffDate',
      'benefitEligibleDate',
      'cobraStartDate',
    ],
    numberProps: [
      'empID',
      'clientNo',
      'empNo',
      'termCode',
      'layOffCode',
      'otherDateDescriptionID',
      'cineTrainJobTitleID',
    ],
    currencyProps: ['lastAnnual', 'lastSalary', 'lastHourly'],
  };

  constructor(
    empId: string | number,
    clientNo: string | number,
    empNo: string | number,
    protectedEmpNo: string,
    props?: { [key: string]: any },
  ) {
    this.empId = typeof empId === 'string' ? parseInt(empId) : empId;
    this.clientNo =
            typeof clientNo === 'string' ? parseInt(clientNo) : clientNo;
    this.empNo = typeof empNo === 'string' ? parseInt(empNo) : empNo;
    this.protectedEmpNo = protectedEmpNo;
    props && convToClass(this, props, EmpDate.convProps);
  }
}
