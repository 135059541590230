import React, { useState } from 'react';
import { AllModules, ColDef, ColumnApi, GridApi, GridOptions } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from '@ag-grid-community/react';
import { WeekBeginDate } from '../../../../core/models';
import { agDateRenderer } from '../../../../utilities/ag-grid-renderers';
import Modal from 'core/components/modals/Modal';

const columns: ColDef[] = [{
  field: 'loc',
  headerName: 'Loc',
  width: 200,
}, {
  field: 'dept',
  headerName: 'Earn Code',
  width: 200,
}, {
  field: 'weekBeginDate',
  headerName: 'Week Beginning Date',
  cellRenderer: 'dateRenderer',
  width: 200,
}];

const gridOptions: GridOptions = {
  domLayout: 'autoHeight',
  columnDefs: columns,
  defaultColDef: {
    suppressMenu: true,
    resizable: true,
    singleClickEdit: true,
    cellClass: 'ag-cell-left-border',
    headerClass: 'grid-header',
  },
  rowSelection: 'single',
  components: {
    dateRenderer: agDateRenderer,
  },
};

type PropTypes = {
  weekBeginningDates: WeekBeginDate[];
  show: boolean;
  onHideDates: () => void;
};

const WeekBeginningDatesModal: React.FC<PropTypes> = ({ weekBeginningDates, show, onHideDates: onHide }) => {
  const [, setGridApi] = useState<GridApi | null>(null);
  const [, setGridColumnApi] = useState<ColumnApi | null>(null);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Week Beginning Dates"
    >
      {(closeModal) => {
        return (
          <>
            <div className="row">
              <div className="col-12 table-wrapper-wrapper ag-theme-balham">
                <AgGridReact
                  gridOptions={gridOptions}
                  rowData={weekBeginningDates}
                  modules={AllModules}
                  onGridReady={onGridReady} />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 text-center">
                You must press save before attempting to submit payroll if using different Week Beginning Dates
              </div>
              <div className="col-12 text-right">
                <button type="button"
                  className="btn btn-primary orange-outline-button mr-2"
                  onClick={closeModal}>Cancel</button>
                <button type="button"
                  className="btn btn-primary orange-button"
                  onClick={closeModal}>Save</button>
              </div>
            </div>
          </>
        );
      }}
    </Modal>
  );
};
export default WeekBeginningDatesModal;
