import { ofType } from 'redux-observable';
import { Observable, forkJoin, from, iif, of } from 'rxjs';
import {
  PayrollControlTotalDetail,
  PayrollControlTotalRequest,
} from '../../models/PayrollControlTotalDetail';
import { catchError, endWith, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  HttpResponse,
  Payroll,
  PayrollControlTotal,
  PayrollMessage,
  TransmittalCheck,
  TransmittalEmployee,
  PayrollHistoryDeductionStatus,
  PayrollOptions,
  PayrollControllTotalUpdateRequest,
  TransmittalEmployeeParams,
  TransmittalEmployeeCheckParams,
  PayrollVoidSearchParams,
  PayrollAdjustmentTransaction,
  PayrollAdjustmentVoidTransaction,
  PayrollPreview,
  PayrollValidateRequest,
  ApplyTimeOffRequest,
  MoveTimeOffRequest,
  PayrollDateline,
  TransmittalAutoFill,
  TransmittalParams,
  clientRecurringEarning,
  RecoverPayrollRequest,
  CanRecoverPayrollResponse,
  CreateControlTotalBookRequest,
  TransmittalFuturePayRates,
  TransmittalSortOrder,
  AutoCalcParams,
  WireOnlyAgreement,
  UpdateCheckNoRequest,
} from '../../models';
import { AppService, PayrollService } from '../../services';
import { handleError, handlePending, handleSuccess, loadModuleAccessWithoutLoadingAppData } from '../actions/app.action';
import {
  createPayroll,
  createPayrollMessage,
  loadPayroll,
  loadPayrollMessages,
  printCheck,
  storePayroll,
  storePayrollMessage,
  storePayrollMessages,
  storePayrolls,
  storePayrollsForTimeOffRequestModal,
  storePrintedCheck,
  updatePayrollMessage,
  postTransmittalAutofill,
  storeTransmittalAutofillMessages,
  updatePayrollMessageOrder,
  loadTransmittalEmployee,
  loadTransmittalEmployees,
  storeTransmittalEmployee,
  storeTransmittalEmployees,
  createTransmittalEmployee,
  storeTransmittalEmployeeCheck,
  updateTransmittalEmployeeCheck,
  deleteTransmittalEmployeeCheck,
  loadPayrollControlTotals,
  loadPayrollControlTotal,
  storePayrollControlTotals,
  storePayrollControlTotal,
  loadPayrollControlTotalDetail,
  storePayrollControlTotalDetail,
  loadPayrollHistoryDeductionStatuses,
  storePayrollHistoryDeductionStatuses,
  updatePayrollHistoryDeductionStatuses,
  deletePayroll,
  deletePayrollFromState,
  updatePayrollControlTotal,
  loadPayrollUnpaidEmployeesReport,
  loadPayrollTransmittalReport,
  storePayrollReport,
  loadPayrollOptions,
  storePayrollOptions,
  getBlankTransmittalEmployeeCheck,
  loadEmployeeVoidSearch,
  storeEmployeeVoidSearch,
  postAdjustmentVoid,
  updateAutoCalcs,
  showVoidCheckWindow,
  storePayrollPreviewPdf,
  postPayrollValidate,
  storePayrollValidate,
  loadPayrollTimeOffRequests,
  storePayrollTimeOffRequests,
  applyPayrollTimeOffRequests,
  movePayrollTimeOffRequest,
  postPayrollMarkAsSubmitted,
  storePayrollReportDates,
  updatePayrollPreview,
  updatePayrollOptions,
  loadWireOnlyAgreement,
  storeWireOnlyAgreement,
  updateWireOnlyAgreement,
  deleteWireOnlyAgreement,
  submitWireOnlyAgreement,
  downloadWireOnlyAgreementReport,
  postPayrollCheckRegister,
  storePayrollCheckRegister,
  postPendingAccrualReport,
  postApprovedAccrualReport,
  storePendingAccrualReport,
  storeApprovedAccrualReport,
  sendUserPDFEmail,
  storeUserPDFEmailMessage,
  loadClientRecurringEarnings,
  storeClientRecurringEarnings,
  putPayrollDateline,
  storePayrollDateline,
  recoverProcessedPayroll,
  storeCanRecoverPayroll,
  canRecoverPayroll,
  updateControlTotalOnPayrollInReduxStore,
  addBook,
  storeBook,
  loadAdjustmentStateInfo,
  storeAdjustmentStateInfo,
  loadPayrollPayRateValidate,
  storePayrollPayRateValidate,
  putPayrollPayRateValidate,
  storeLatestPayroll,
  toggleTransmittalEmpLoadingState,
  storeWireOnlyAgreementReport,
  toggleWireOnlyAgreementLoadingState,
  clearWireOnlyAgreement,
  toggleWireOnlyAgreementPreviewSuccess,
  toggleWireOnlyAgreementSubmissionSuccess,
  PayrollUpdateRequest,
  updateSqlMescMainCheckNo,
} from '../actions/payroll.action';
import { Epic, Actions } from './types';

const currentYear = new Date().getFullYear();

type LoadPayrollOutputAction<T> = {
  payload: T;
  type: string;
};

const loadPayroll$ = (
  action$: Observable<
  Actions<{
    beginDate: string;
    endDate: string;
    byCheckDate: boolean;
    protectedClientNo: string;
  }>
  >,
) => {
  return action$.pipe(
    ofType(loadPayroll.type),
    switchMap((action: { payload: { beginDate: string; endDate: string; byCheckDate: boolean; protectedClientNo: string; }; }) => {
      return from(
        PayrollService.getPayroll(
          action.payload.beginDate ?? `01/01/${currentYear}`,
          action.payload.endDate ?? `12/31/${currentYear}`,
          action.payload.byCheckDate,
          action.payload.protectedClientNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: Payroll[]) => {
          const outputStream: LoadPayrollOutputAction<any>[] = [
            storePayrolls(res),
            storePayrollReportDates(res),
          ];
                    
          return outputStream;
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadPayrollForTimeOffRequestModal$ = (
  action$: Observable<
  Actions<{
    beginDate: string;
    endDate: string;
    byCheckDate: boolean;
    protectedClientNo: string;
  }>
  >,
) => {
  return action$.pipe(
    ofType(loadPayroll.type),
    switchMap((action: { payload: { beginDate: string; endDate: string; byCheckDate: boolean; protectedClientNo: string; }; }) => {
      return from(
        PayrollService.getPayroll(
          action.payload.beginDate ?? `01/01/${currentYear}`,
          action.payload.endDate ?? `12/31/${currentYear}`,
          action.payload.byCheckDate,
          action.payload.protectedClientNo,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: Payroll[]) => {
          return [
            storePayrollsForTimeOffRequestModal(res),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createPayroll$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(createPayroll.type),
    switchMap((action: { payload: PayrollDateline; }) => {
      return from(PayrollService.postPayroll(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<Payroll>) => { return storePayroll(res.value); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deletePayroll$ = (action$: Observable<Actions<number>>) => {
  return action$.pipe(
    ofType(deletePayroll.type),
    mergeMap((action: { payload: number }) => {
      return from(PayrollService.deletePayroll(action.payload)).pipe(
        map(() => { return deletePayrollFromState(action.payload); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getClientRecurringEarnings$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadClientRecurringEarnings.type),
    mergeMap(() => {
      return from(PayrollService.getClientRecurringEarnings()).pipe(
        map((res: any) => { return res.data; }),
        map((res: clientRecurringEarning[]) => { return storeClientRecurringEarnings(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)];}),
      );
    }),
  );
};

const putPayrollDateline$ = (action$: Observable<Actions<PayrollUpdateRequest>>) => {
  return action$.pipe(
    ofType(putPayrollDateline.type),
    mergeMap((action: { payload: PayrollUpdateRequest }) => {
      return from(PayrollService.putPayrollDateline(
        action.payload.payrollHistoryId,
        action.payload.data,
        action.payload?.removeRecurringEarnings,
        action.payload?.addRecurringEarnings,
      )).pipe(
        map((res) => { return res.data; }),
        mergeMap((res) => {
          return [
            storePayrollDateline(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)];}),
      );
    }),
  );
};

const loadPayrollOptions$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadPayrollOptions.type),
    switchMap((action: { payload: number; }) => {
      return from(PayrollService.getPayrollOptions(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: PayrollOptions) => { return storePayrollOptions(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updatePayrollOptions$ = (action$: Observable<Actions<{ data: PayrollOptions, payrollHistoryId: number; }>>) => {
  return action$.pipe(
    ofType(updatePayrollOptions.type),
    switchMap((action: { payload: { data: PayrollOptions; payrollHistoryId: number; }; }) => {
      return from(PayrollService.putPayrollOptions(action.payload.data, action.payload.payrollHistoryId)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return storePayrollOptions(res.value); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadWireOnlyAgreement$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadWireOnlyAgreement.type),
    switchMap((action: { payload: number; }) => {
      return from(PayrollService.getWireOnlyAgreement(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: WireOnlyAgreement) => { return storeWireOnlyAgreement(res); }),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            toggleWireOnlyAgreementLoadingState(false), 
          ];
        }),
      );
    },
    ),
  );
};

const updateWireOnlyAgreement$ = (action$: Observable<Actions<WireOnlyAgreement>>) => {
  return action$.pipe(
    ofType(updateWireOnlyAgreement.type),
    switchMap((action: { payload: WireOnlyAgreement }) => {
      return from(PayrollService.putWireOnlyAgreement(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            toggleWireOnlyAgreementPreviewSuccess(true),
            handleSuccess(res.messages),
            loadModuleAccessWithoutLoadingAppData(),
          ];
        }),
        catchError((err: HttpResponse<any>) => { 
          return [
            handleError(err),
            toggleWireOnlyAgreementPreviewSuccess(false),
          ]; 
        }),
      );
    }),
  );
};

const deleteWireOnlyAgreement$ = (action$: Observable<Actions<number>>) => {
  return action$.pipe(
    ofType(deleteWireOnlyAgreement.type),
    mergeMap((action: { payload: number }) => {
      return from(PayrollService.deleteWireOnlyAgreement(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => { 
          return [
            clearWireOnlyAgreement(),
            toggleWireOnlyAgreementPreviewSuccess(true),
            handleSuccess(res.messages),
            loadModuleAccessWithoutLoadingAppData(),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const submitWireOnlyAgreement$ = (action$: Observable<Actions<WireOnlyAgreement>>) => {
  return action$.pipe(
    ofType(submitWireOnlyAgreement.type),
    switchMap((action: { payload: WireOnlyAgreement }) => {
      return from(PayrollService.putWireOnlyAgreement(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            clearWireOnlyAgreement(),
            toggleWireOnlyAgreementSubmissionSuccess(true),
            loadModuleAccessWithoutLoadingAppData(),
          ];
        }),
        catchError((err: HttpResponse<any>) => { 
          return [
            handleError(err),
            toggleWireOnlyAgreementSubmissionSuccess(false),
          ]; 
        }),
      );
    }),
  );
};

const downloadWireOnlyAgreementReport$ = (action$: Observable<Actions<WireOnlyAgreement>>) => {
  return action$.pipe(
    ofType(downloadWireOnlyAgreementReport.type),
    switchMap((action: { payload: WireOnlyAgreement }) => {
      return from(PayrollService.postWireOnlyAgreementReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return storeWireOnlyAgreementReport(res.value); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    }),
  );
};

const updatePayrollPreview$ = (action$: Observable<Actions<{ data: PayrollPreview, payrollHistoryId: number; }>>) => {
  return action$.pipe(
    ofType(updatePayrollPreview.type),
    switchMap((action: { payload: { data: PayrollPreview; payrollHistoryId: number; }; }) => {
      return from(PayrollService.putPayrollPreview(action.payload.data, action.payload.payrollHistoryId)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return storePayrollPreviewPdf(res.value); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postEmailUserPDFPassword$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(sendUserPDFEmail.type),
    switchMap((action: { payload: number; }) => {
      return from(AppService.postEmailUserPDFPassword(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<any>) => {
          return [
            handleSuccess('Email sent'),
            storeUserPDFEmailMessage(res.messages[0]),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postPayrollCheckRegister$ = (action$: Observable<Actions<{ payrollHistoryId: number, isPayrollSubmission: boolean }>>) => {
  return action$.pipe(
    ofType(postPayrollCheckRegister.type),
    switchMap((action: { payload: { payrollHistoryId: number; isPayrollSubmission: boolean; }; }) => {
      return from(PayrollService.postPayrollCheckRegister(action.payload.payrollHistoryId, action.payload.isPayrollSubmission)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return storePayrollCheckRegister(res.value); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadPayrollControlTotals$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadPayrollControlTotals.type),
    switchMap((action: { payload: { payrollHistoryId: number; }; }) => {
      return from(
        PayrollService.getPayrollControlTotals(
          action.payload.payrollHistoryId,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: PayrollControlTotal[]) => { return storePayrollControlTotals(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadPayrollControlTotal$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadPayrollControlTotal.type),
    switchMap((action: { payload: { payrollHistoryId: number; controlTotalId: number; preventPayrollsUpdate?: boolean }; }) => {
      return from(
        PayrollService.getPayrollControlTotal(
          action.payload.payrollHistoryId,
          action.payload.controlTotalId,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: PayrollControlTotal[]) => {
          return [
            storePayrollControlTotal({
              controlTotals: res,
              preventPayrollsUpdate: action.payload.preventPayrollsUpdate,
              payrollHistoryId: action.payload.payrollHistoryId,
            }),
            storeLatestPayroll(action.payload.payrollHistoryId),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const addBook$ = (action$: Observable<Actions<CreateControlTotalBookRequest>>) => {
  return action$.pipe(
    ofType(addBook.type),
    switchMap((action: { payload: CreateControlTotalBookRequest }) => {
      return from(
        PayrollService.postPayrollControlTotal(action.payload),
      ).pipe(
        map((res: any) => res.data),
        mergeMap((res: any) => {
          return [
            storeBook(res.value),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: any) => { return [handleError(err)]; }),
      );
    }),
  );
};

const updatePayrollControlTotal$ = (action$: Observable<Actions<PayrollControllTotalUpdateRequest>>) => {
  return action$.pipe(
    ofType(updatePayrollControlTotal.type),
    switchMap((action: { payload: PayrollControllTotalUpdateRequest }) => {
      return from(
        PayrollService.putPayrollControlTotal(
          action.payload.payrollHistoryId,
          action.payload.controlTotalId,
          action.payload.data,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap(() => {
          return [
            updateControlTotalOnPayrollInReduxStore(action.payload.data),
            loadPayrollControlTotal({
              payrollHistoryId: action.payload.payrollHistoryId,
              controlTotalId: action.payload.controlTotalId,
              preventPayrollsUpdate: action.payload.preventPayrollsUpdate,
            }),
            storeLatestPayroll(action.payload.payrollHistoryId),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadPayrollMessages$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadPayrollMessages.type),
    switchMap(() => {
      return from(PayrollService.getPayrollMessages()).pipe(
        map((res: any) => { return res.data; }),
        map((res: PayrollMessage[]) => { return storePayrollMessages(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const createPayrollMessage$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(createPayrollMessage.type),
    switchMap((action: { payload: PayrollMessage; }) => {
      return from(PayrollService.postPayrollMessage(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<PayrollMessage>) => { return storePayrollMessage(res.value); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updatePayrollMessage$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(updatePayrollMessage.type),
    switchMap((action: { payload: PayrollMessage; }) => {
      return from(PayrollService.putPayrollMessage(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<PayrollMessage>) => {
          return storePayrollMessage(res.value);
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const updatePayrollMessageOrder$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(updatePayrollMessageOrder.type),
    switchMap((action: { payload: PayrollMessage[]; }) => {
      return forkJoin(
        action.payload.map((payload: PayrollMessage) => {
          return from(PayrollService.putPayrollMessage(payload)).pipe(
            map((res: any) => { return res.data; }),
            map((res: HttpResponse<PayrollMessage>) => {
              return loadPayrollMessages();
            }),
            catchError((err: HttpResponse<any>) => {
              return [
                handleError(err),
              ];
            }),
          );
        }),
      ).pipe(
        map(() => { return loadPayrollMessages(); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const printCheckPayrolls$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(printCheck.type),
    switchMap((action: { payload: { weekEnding: string; checkDate: string; }; }) => {
      return from(
        PayrollService.getPrintPayrolls(
          action.payload.weekEnding,
          action.payload.checkDate,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storePrintedCheck(res); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postPayrollAutoFill$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(postTransmittalAutofill.type),
    switchMap((action: { payload: { params: TransmittalParams; data: TransmittalAutoFill; }; }) => {
      return from(
        PayrollService.postPayrollTransmittalAutofill(
          action.payload.params,
          action.payload.data,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<string[]>) => { return storeTransmittalAutofillMessages(res.messages); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getPayrollControlTotalDetail$ = (
  action$: Observable<Actions<PayrollControlTotalRequest>>,
) => {
  return action$.pipe(
    ofType(loadPayrollControlTotalDetail.type),
    switchMap((action: { payload: PayrollControlTotalRequest; }) => {
      return from(
        PayrollService.getPayrollControlTotalDetail(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: PayrollControlTotalDetail[]) => { return storePayrollControlTotalDetail(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadTransmittalEmployee$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadTransmittalEmployee.type),
    switchMap((action: { payload: { employee: TransmittalEmployeeParams, orderBy: TransmittalSortOrder }; }) => {
      return from(PayrollService.getTransmittalEmployee(action.payload.employee)).pipe(
        map((res: any) => { return res.data; }),
        map((res: TransmittalEmployee) => { return storeTransmittalEmployee({ employee: res, orderBy: action.payload.orderBy }); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadTransmittalEmployees$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadTransmittalEmployees.type),
    switchMap((action: { payload: { params: TransmittalParams, orderBy: TransmittalSortOrder } }) => {
      return from(PayrollService.getTransmittalEmployees(action.payload.params)).pipe(
        map((res: any) => { return res.data; }),
        map((res: TransmittalEmployee[]) => {
          return storeTransmittalEmployees({ employees: res, orderBy: action.payload.orderBy });
        },
        ),
        catchError((err: HttpResponse<any>) => {
          return [
            handleError(err),
            toggleTransmittalEmpLoadingState('error'), 
          ];
        }),
      );
    },
    ),
  );
};

const createTransmittalEmployee$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(createTransmittalEmployee.type),
    mergeMap((action: { payload: { params: TransmittalEmployeeParams; data: TransmittalEmployee; orderBy: TransmittalSortOrder; }; }) => {
      return from(
        PayrollService.postTransmittalEmployee(
          action.payload.params,
          action.payload.data,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: HttpResponse<TransmittalEmployee>) => {
          return [
            storeTransmittalEmployee({ employee: res.value, orderBy: action.payload.orderBy }),
            loadPayrollControlTotal({
              payrollHistoryId: action.payload.params.payrollHistoryId,
              controlTotalId: action.payload.params.controlTotalId,
            }),
          ];
        },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getBlankTransmittalEmployeeCheck$ = (
  action$: Observable<Actions<TransmittalEmployeeParams>>,
) => {
  return action$.pipe(
    ofType(getBlankTransmittalEmployeeCheck.type),
    switchMap((action: { payload: TransmittalEmployeeParams; }) => {
      return from(
        PayrollService.getBlankTransmittalEmployeeCheck(action.payload),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: TransmittalCheck) => {
          return [
            // TODO: We need a way to ID blank checks for clean updates, so replace this PUT trigger from the GET result
            handleSuccess('Blank check added'),
            handlePending('Adding default earnings...'), // kick off pending notification
            updateTransmittalEmployeeCheck({
              protectedEmpNo: action.payload.protectedEmpNo,
              data: res,
              showSuccess: true,
              fromModal: true,
              blankCheck: true,
              params: {
                protectedEmpNo: action.payload.protectedEmpNo,
                transmittalCheckId: 0,
                payrollHistoryId: action.payload.payrollHistoryId,
                controlTotalId: action.payload.controlTotalId,
              },
            }),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const deleteTransmittalEmployeeCheck$ = (action$: Observable<Actions<TransmittalEmployeeCheckParams>>) => {
  return action$.pipe(
    ofType(deleteTransmittalEmployeeCheck.type),
    mergeMap((action: { payload: TransmittalEmployeeCheckParams; }) => {
      // If it isn't a new check, call endpoint as normal. Else let the reducer remove it and in either case load the CT
      return iif(
        () => { return action.payload.transmittalCheckId > 0; },
        from(
          PayrollService.deleteTransmittalEmployeeCheck(action.payload),
        ).pipe(
          map((res) => { return res.data; }),
          catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
        ),
        // Return an observable of null if transmittalCheckId is 0, which just lets the reducer remove it from the store
        of(null), 
      ).pipe(
        mergeMap(() => {
          return [
            loadPayrollControlTotal(({ ...action.payload, preventPayrollsUpdate: true })),
          ];
        }),
        catchError((err) => { return [handleError(err)]; }),
      );
    }),
  );
};

const getPayrollHistoryDeductionStatuses$ = (
  action$: Observable<Actions<number>>,
) => {
  return action$.pipe(
    ofType(loadPayrollHistoryDeductionStatuses.type),
    switchMap((action: { payload: number; }) => {
      return from(
        PayrollService.getPayrollHistoryDeductionStatuses(
          action.payload,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        map((res: PayrollHistoryDeductionStatus[]) => { return storePayrollHistoryDeductionStatuses(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

type DeductionStatusParams = {
  params: number;
  data: PayrollHistoryDeductionStatus[]
};

const putPayrollHistoryDeductionStatuses$ = (
  action$: Observable<
  Actions<DeductionStatusParams>
  >,
) => {
  return action$.pipe(
    ofType(updatePayrollHistoryDeductionStatuses.type),
    switchMap((action: { payload: DeductionStatusParams }) => {
      return from(
        PayrollService.putPayrollHistoryDeductionStatuses(
          action.payload.params,
          action.payload.data,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap(
          (res: HttpResponse<PayrollHistoryDeductionStatus[]>) => {
            return [
              storePayrollHistoryDeductionStatuses(res.value),
              handleSuccess(res.messages),
            ];
          },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postUnpaidEmployeesReport$ = (
  action$: Observable<
  Actions<{
    payrollHistoryId: number;
    controlTotalId: number;
    reportType: string;
  }>
  >,
) => {
  return action$.pipe(
    ofType(loadPayrollUnpaidEmployeesReport.type),
    switchMap((action: { payload: { payrollHistoryId: number; controlTotalId: number; reportType: string; }; }) => {
      return from(PayrollService.postUnpaidEmployeesReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return storePayrollReport(res.value); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postTransmittalReport$ = (
  action$: Observable<
  Actions<{
    payrollHistoryId: number;
    controlTotalId: number;
    reportType: string;
    isFinishedPayroll: boolean;
  }>
  >,
) => {
  return action$.pipe(
    ofType(loadPayrollTransmittalReport.type),
    switchMap((action: { payload: { payrollHistoryId: number; controlTotalId: number; reportType: string; isFinishedPayroll: boolean }; }) => {
      return from(PayrollService.postTransmittalReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return storePayrollReport(res.value); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const getEmployeeVoidSearch$ = (
  action$: Observable<Actions<PayrollVoidSearchParams>>,
) => {
  return action$.pipe(
    ofType(loadEmployeeVoidSearch.type),
    switchMap((action: { payload: PayrollVoidSearchParams; }) => {
      return from(PayrollService.getPayrollVoidSearch(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: PayrollAdjustmentTransaction[]) => { return storeEmployeeVoidSearch(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const putSqlMescMainCheckNo$ = (action$: Observable<Actions<{ checkNoRequest: UpdateCheckNoRequest, searchRequest: PayrollVoidSearchParams }>>) => {
  return action$.pipe(
    ofType(updateSqlMescMainCheckNo.type),
    switchMap((action: { payload: { checkNoRequest: UpdateCheckNoRequest, searchRequest: PayrollVoidSearchParams }; }) => {
      return from(
        PayrollService.putSqlMescMainCheckNo(action.payload.checkNoRequest.mescId, action.payload.checkNoRequest),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            loadEmployeeVoidSearch(action.payload.searchRequest),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};
const postAdjustmentVoid$ = (
  action$: Observable<
  Actions<{
    payrollHistoryId: number;
    protectedEmpNo: string;
    params: PayrollAdjustmentVoidTransaction;
  }>
  >,
) => {
  return action$.pipe(
    ofType(postAdjustmentVoid.type),
    switchMap((action: { payload: { payrollHistoryId: number; protectedEmpNo: string; params: PayrollAdjustmentVoidTransaction; }; }) => {
      return from(
        PayrollService.postAdjustVoid(
          action.payload.payrollHistoryId,
          action.payload.protectedEmpNo,
          action.payload.params,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            showVoidCheckWindow(false),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const putUpdateAutoCalcs$ = (action$: Observable<Actions<AutoCalcParams>>) => {
  return action$.pipe(
    ofType(updateAutoCalcs.type),
    switchMap((action: { payload: AutoCalcParams; }) => {
      return from(
        PayrollService.putUpdateAutoCalcs({
          payrollHistoryId: action.payload.payrollHistoryId,
          controlTotalId: action.payload.controlTotalId,
          earningsCodeAutoCalculations:
            action.payload.earningsCodeAutoCalculations,
        }),
      ).pipe(
        map(() => {
          return loadTransmittalEmployees({
            params: {
              payrollHistoryId: action.payload.payrollHistoryId,
              controlTotalId: action.payload.controlTotalId,
            },
            orderBy: action.payload.orderBy,
          });
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postPayrollValidate$ = (action$: Observable<Actions<PayrollValidateRequest>>) => {
  return action$.pipe(
    ofType(postPayrollValidate.type),
    switchMap((action: { payload: PayrollValidateRequest; }) => {
      return from(PayrollService.postPayrollValidate(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => {
          return storePayrollValidate(res.value || {
            warnings: [],
            errors: [],
          });
        },
        ),
        catchError((err: HttpResponse<any>) => {
          return [
            storePayrollValidate({
              warnings: [],
              errors: [],
            }),
            handleError(err),
          ];
        }),
      );
    },
    ),
  );
};

const loadPayrollPayRateValidates$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadPayrollPayRateValidate.type),
    switchMap((action: { payload: number; }) => {
      return from(PayrollService.getPayrollPayRateValidation(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storePayrollPayRateValidate(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const putPayrollPayRateValidate$ = (action$: Observable<Actions<{ request:TransmittalFuturePayRates[], payrollHistoryId: number }>>) => {
  return action$.pipe(
    ofType(putPayrollPayRateValidate.type),
    switchMap((action: { payload: { request:TransmittalFuturePayRates[], payrollHistoryId: number }; }) => {
      return from(
        PayrollService.putPayrollPayRateValidation(
          action.payload.payrollHistoryId,
          action.payload.request,
        ),
      ).pipe(
        map((res: any) => { return res.data; }),
        mergeMap((res: any) => {
          return [
            //Return any records that were not applied as they did not change.
            storePayrollPayRateValidate(action.payload.request.filter(x => !x.futurePayRatesDetail.apply)),
            handleSuccess(res.messages),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const loadPayrollTimeOffRequests$ = (action$: Observable<Actions<any>>) => {
  return action$.pipe(
    ofType(loadPayrollTimeOffRequests.type),
    switchMap((action: { payload: number; }) => {
      return from(PayrollService.getPayrollTimeOffRequests(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: any) => { return storePayrollTimeOffRequests(res); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const applyPayrollTimeOffRequests$ = (action$: Observable<Actions<ApplyTimeOffRequest>>) => {
  return action$.pipe(
    ofType(applyPayrollTimeOffRequests.type),
    switchMap((action: { payload: ApplyTimeOffRequest; }) => {
      return from(PayrollService.postPayrollApplyTimeOffRequests(action.payload)).pipe(
        mergeMap((res: any) => {
          return [
            loadTransmittalEmployee({ 
              params: { protectedEmpNo: action.payload.protectedEmpNo, controlTotalId: action.payload.controlTotalId, payrollHistoryId: action.payload.payrollHistoryId },
              orderBy: 'Alphabetically', // UPDATE THIS WHEN YOU COME BACK
            }),
            handleSuccess(res?.data?.messages[0]),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      ).pipe(
        mergeMap(() => {
          return [
            loadPayrollTimeOffRequests(action.payload.payrollHistoryId),
          ];
        }),
        catchError((err) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const movePayrollTimeOffRequest$ = (action$: Observable<Actions<MoveTimeOffRequest>>) => {
  return action$.pipe(
    ofType(movePayrollTimeOffRequest.type),
    switchMap((action: { payload: MoveTimeOffRequest; }) => {
      return from(PayrollService.postPayrollMoveTimeOffRequest(action.payload)).pipe(
        mergeMap((res: any) => {
          return [
            loadTransmittalEmployee({
              params: { protectedEmpNo: action.payload.protectedEmpNo, controlTotalId: action.payload.oldControlTotalId, payrollHistoryId: action.payload.oldPayrollHistoryId },
              orderBy: 'Alphabetically', // UPDATE THIS WHEN YOU COME BACK
            }),
            handleSuccess(res?.data?.messages[0]),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      ).pipe(
        mergeMap(() => {
          return [
            loadPayrollTimeOffRequests(action.payload.oldPayrollHistoryId),
          ];
        }),
        catchError((err) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postPayrollMarkAsSubmitted$ = (action$: Observable<Actions<{ payrollHistoryId: number, data: PayrollOptions }>>) => {
  return action$.pipe(
    ofType(postPayrollMarkAsSubmitted.type),
    switchMap((action: { payload: { payrollHistoryId: number, data: PayrollOptions } }) => {
      return from(PayrollService.postPayrollMarkedAsSubmitted(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return handleSuccess(res.messages); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postApprovedAccrualReport$ = (action$: Observable<Actions<{ payrollHistoryId: number }>>) => {
  return action$.pipe(
    ofType(postApprovedAccrualReport.type),
    mergeMap((action: { payload: { payrollHistoryId: number } }) => {
      return from(PayrollService.postApprovedAccrualReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return storeApprovedAccrualReport(res.value); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const postPendingAccrualReport$ = (action$: Observable<Actions<{ payrollHistoryId: number }>>) => {
  return action$.pipe(
    ofType(postPendingAccrualReport.type),
    mergeMap((action: { payload: { payrollHistoryId: number } }) => {
      return from(PayrollService.postPendingAccrualReport(action.payload)).pipe(
        map((res: any) => { return res.data; }),
        map((res: HttpResponse<any>) => { return storePendingAccrualReport(res.value); },
        ),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    },
    ),
  );
};

const canRecoverPayroll$ = (action$: Observable<Actions<number>>) => {
  return action$.pipe(
    ofType(canRecoverPayroll.type),
    switchMap((action: any) => {
      return from(PayrollService.canRecoverPayroll(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: CanRecoverPayrollResponse) => {
          return [
            handleSuccess(res),
            storeCanRecoverPayroll(res),
          ];
        }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    }),
  );
};

const recoverProcessedPayroll$ = (action$: Observable<Actions<RecoverPayrollRequest>>) => {
  return action$.pipe(
    ofType(recoverProcessedPayroll.type),
    switchMap((action: any) => {
      return from(PayrollService.recoverProcessedPayroll(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        map((res: HttpResponse<any>) => { return handleSuccess(res.messages); }),
        catchError((err: HttpResponse<any>) => { return [handleError(err)]; }),
      );
    }),
  );
};

const loadAdjustmentStateInfo$ = (action$: Observable<Actions<number>>) => {
  return action$.pipe(
    ofType(loadAdjustmentStateInfo.type),
    switchMap((action: { payload: number }) => {
      return from(PayrollService.getAdjustmentStateInfo(action.payload)).pipe(
        map((res: any) => {return res.data;}),
        mergeMap((res: any) => {
          return [
            storeAdjustmentStateInfo(res),
          ];
        }),
      );
    }),
  );
};

// TODO: Stricter epic typing
export const epics: Epic[] = [
  loadPayroll$,
  loadPayrollForTimeOffRequestModal$,
  createPayroll$,
  deletePayroll$,
  getClientRecurringEarnings$,
  loadPayrollOptions$,
  updatePayrollOptions$,
  loadWireOnlyAgreement$,
  updateWireOnlyAgreement$,
  deleteWireOnlyAgreement$,
  submitWireOnlyAgreement$,
  downloadWireOnlyAgreementReport$,
  updatePayrollPreview$,
  postPayrollCheckRegister$,
  loadPayrollControlTotals$,
  loadPayrollControlTotal$,
  addBook$,
  updatePayrollControlTotal$,
  loadPayrollMessages$,
  createPayrollMessage$,
  updatePayrollMessage$,
  updatePayrollMessageOrder$,
  printCheckPayrolls$,
  postPayrollAutoFill$,
  loadTransmittalEmployees$,
  loadTransmittalEmployee$,
  putPayrollDateline$,
  createTransmittalEmployee$,
  deleteTransmittalEmployeeCheck$,
  getPayrollControlTotalDetail$,
  getPayrollHistoryDeductionStatuses$,
  putPayrollHistoryDeductionStatuses$,
  postUnpaidEmployeesReport$,
  postTransmittalReport$,
  getBlankTransmittalEmployeeCheck$,
  getEmployeeVoidSearch$,
  postAdjustmentVoid$,
  putUpdateAutoCalcs$,
  postPayrollValidate$,
  loadPayrollTimeOffRequests$,
  applyPayrollTimeOffRequests$,
  movePayrollTimeOffRequest$,
  postPayrollMarkAsSubmitted$,
  postPendingAccrualReport$,
  postApprovedAccrualReport$,
  postEmailUserPDFPassword$,
  canRecoverPayroll$,
  recoverProcessedPayroll$,
  loadAdjustmentStateInfo$,
  loadPayrollPayRateValidates$,
  putPayrollPayRateValidate$,
  putSqlMescMainCheckNo$,
];
