import React, { useEffect, useRef, useState } from 'react';
import { Employee, Dropdown, PayrollAdjustmentEmployee, PayrollAdjustmentSummary } from 'core/models';
import { ArrayField } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { currencyFormatter, formatWithCommas } from 'utilities/utilities';
import { deleteBlankCheck, deletePayrollAdjustmentCheck } from 'core/store/actions';
import AdjustmentTable from './AdjustmentTable';
import AdjustmentModalTable from './AdjustmentModalTable';
import AdjustmentEarnings from '../AdjustmentEarnings';
import AdjustmentDeduction from '../AdjustmentDeduction';
import AdjustmentStateWithholding from '../AdjustmentStateWithholding';
import AdjustmentLocalWithholding from '../AdjustmentLocalWithholding';
import SummaryFields from './SummaryFields';
import Icon from 'core/components/shared/Icon';
import { useSelector } from 'react-redux';
import { getIsFinishedPayroll } from 'core/store/selectors';
import { InputGrp, InputGrpDecimal } from 'core/components/form-controls';
import { FormMethods } from 'core/components/form-controls/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type TotalFunction<T> = (check: PayrollAdjustmentSummary) => T;
type Props = {
  item: Partial<ArrayField<PayrollAdjustmentSummary, 'id'>>;
  check: PayrollAdjustmentSummary;
  tabKey: number;
  index: number;
  employee: Employee;
  employeeAdjustment: PayrollAdjustmentEmployee | null;
  currentEmployee: Employee;
  isDirty: boolean;
  disabled: boolean;
  voidDisabled: boolean;
  adjustmentCodeOpts: Dropdown[];
  payrollHistoryId: number | undefined;
  control: any;
  errors: FormMethods['errors'];
  register: FormMethods['register'];
  setValue: FormMethods['setValue'];
  watch: FormMethods['watch'];
  getValues: FormMethods['getValues'];
  handleSubmit: FormMethods['handleSubmit'];
  totalDeduction: TotalFunction<string>;
  totalAmount: TotalFunction<number>;
  onPrint: (adjustmentId: number) => void;
  onSave: () => boolean;
  closeModal: (e?: any) => void;
  onAdjustmentTypeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const AdjustmentCheckItem = ({
  item,
  check,
  tabKey,
  index,
  employee,
  currentEmployee,
  employeeAdjustment,
  control,
  handleSubmit,
  register,
  errors,
  isDirty,
  setValue,
  getValues,
  watch,
  disabled,
  voidDisabled,
  adjustmentCodeOpts,
  payrollHistoryId,
  totalDeduction,
  totalAmount,
  onPrint,
  onSave,
  closeModal,
  onAdjustmentTypeChange,
}: Props) => {
  const dispatch = useAppDispatch();

  const divRef = useRef<HTMLDivElement | null>(null);
  
  const isFinishedPayroll =  useSelector(getIsFinishedPayroll);

  const [totals, setTotals] = useState({
    earnings: '$0.00',
    deductions: '$0.00',
  });

  const formObjProps = {
    control,
    register,
    errors,
    index,
    setValue,
    getValues,
    watch,
    payrollHistoryId: payrollHistoryId ?? 0,
  };
  
  const navKeys = ['ArrowDown', 'ArrowUp'];
  
  const totalEarnings: string = currencyFormatter(totalAmount(check));
  const totalDeductions: string = currencyFormatter(totalDeduction(check));

  const netPayIndex = check.employeeTaxes?.findIndex(({ description }) => description === 'Net Pay'); 
  const netPayPath = `items[${index}].employeeTaxes[${(netPayIndex ?? -1)}].withholding`;
  const loadingValidation = useAppSelector((state) => state.payrollAdjustment.loadingValidation);
  const savingAdjustmentCheck = useAppSelector((state) => state.payrollAdjustment.savingAdjustmentCheck);
  const netPayFormState = watch(netPayPath);
  
  useEffect(() => {
    updateTotals();
  }, [totalEarnings, totalDeductions]);

  const updateTotals = () => {
    if (totalEarnings !== '$0.00' || (check?.earnings?.length ?? null) === 0) {
      setTotals((prev) => {
        return {
          ...prev,
          earnings: totalEarnings,
        };
      });
    }
    if (totalDeductions !== '$0.00' || (check?.deductions?.length ?? null) === 0) {
      setTotals((prev) => {
        return {
          ...prev,
          deductions: totalDeductions,
        };
      });
    }
  };

  const onDeleteAdjustment = (check: PayrollAdjustmentSummary) => {
    if (!(check && payrollHistoryId && currentEmployee) || isFinishedPayroll) return;
    if (check.adjustmentId && check.adjustmentId > 0) {
      dispatch(
        deletePayrollAdjustmentCheck({
          payrollHistoryId,
          empNo: currentEmployee.empNo,
          protectedEmpNo: currentEmployee.protectedEmpNo,
          adjustmentId: check.adjustmentId,
        }),
      );
    } else {
      dispatch(deleteBlankCheck(currentEmployee.empNo));
    }
  };

  const handleKeyPress = (key: string, step = 1) => {
    const activeInput = document?.activeElement;

    if (!activeInput || !navKeys.includes(key) || !divRef.current) return;

    const inputFields = divRef.current
      ? Array.from(
        divRef.current.querySelectorAll(
          "input[class*='form-controls_dm-form-control'][type='text']",
        ),
      )
      : [];
    const activeIndex = inputFields.indexOf(activeInput);

    if (activeIndex < 0) return;

    const increment = key === 'ArrowDown' ? step : -step;
    const target = inputFields[activeIndex + increment];

    if (!target) return;

    (target as HTMLElement).focus();
  };

  return (
    <form
      key={item.id}
      onSubmit={handleSubmit(onSave)}
      className="p-3"
      style={{ backgroundColor: '#EDF3F9' }}
    >
      <fieldset>
        <input
          type="hidden"
          name={`items[${index}].adjustmentId`}
          defaultValue={check.adjustmentId}
          ref={register({
            valueAsNumber: true,
          })}
        />
        <div className="text-right">
          <button
            type="button"
            className="btn btn-link dm-grid-action-title"
            onClick={() => { return onPrint(check.adjustmentId); }}
          >
            Print <Icon
              name="print"
              className="fa-print"
            />
          </button>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column w-100">
            ADJUSTMENTS
            <AdjustmentTable
              register={register}
              index={index}
              control={control}
              errors={errors}
              payrollHistoryId={payrollHistoryId ?? 0}
              check={check}
              employeeAdjustment={employeeAdjustment}
              disabled={disabled}
              voidDisabled={voidDisabled}
              adjustmentCodeOpts={adjustmentCodeOpts}
              adjustmentType={item.adjustmentType}
              onAdjustmentTypeChange={onAdjustmentTypeChange}
            />
          </div>
          <div className="d-flex flex-column w-100">
            EARNINGS &amp; DEDUCTIONS
            <AdjustmentModalTable
              check={check}
              tabKey={tabKey}
              List={AdjustmentEarnings}
              type={'Earnings'}
              formObj={formObjProps}
              handleKeyPress={handleKeyPress}
              isOpen={true}
              ref={divRef}
              isDirty={isDirty}
            >
              <div>
                <strong>{totals.earnings}</strong>
              </div>
            </AdjustmentModalTable>
            <AdjustmentModalTable
              check={check}
              tabKey={tabKey}
              List={AdjustmentDeduction}
              type={'Deductions'}
              formObj={formObjProps}
              handleKeyPress={handleKeyPress}
              ref={divRef}
              isDirty={isDirty}
            >
              <div>
                <strong>{totals.deductions}</strong>
              </div>
            </AdjustmentModalTable>
          </div>
          <div className="d-flex flex-column w-100">
            TAXES &amp; WITHHOLDINGS
            <AdjustmentModalTable
              tabKey={tabKey}
              List={AdjustmentStateWithholding}
              type={'State Withholdings'}
              formObj={formObjProps}
              handleKeyPress={handleKeyPress}
              ref={divRef}
              isDirty={isDirty}
            />
            <AdjustmentModalTable
              tabKey={tabKey}
              List={AdjustmentLocalWithholding}
              type={'Local Withholdings'}
              formObj={formObjProps}
              handleKeyPress={handleKeyPress}
              ref={divRef}
              isDirty={isDirty}
            />
          </div>
          <div className="d-flex flex-column w-100">
            <div className="dm-panel dm-panel-border mt-1">
              <div className="d-flex justify-content-between font-weight-bold">
                <div>
                  <strong>Net Pay</strong>
                </div>
                <div className="d-flex">
                  $
                  <InputGrp
                    groupClass="gc03 number-underline mw75 mb-0"
                    type="text"
                    groupStyle={{ width: '100px', marginLeft: '5px' }}
                    name={netPayPath}
                    ref={register({
                      valueAsNumber: true,
                    })}
                    defaultValue={formatWithCommas(netPayFormState ?? 0)}
                    setValue={setValue}
                    formatNumber
                    hiddenValue={netPayFormState ?? 0}
                    hiddenRef={register({
                      valueAsNumber: true,
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column w-100">
            SUMMARY
            <AdjustmentModalTable
              check={check}
              tabKey={tabKey}
              List={SummaryFields}
              type={'Adjustment Summary'}
              formObj={formObjProps}
              handleKeyPress={handleKeyPress}
              ref={divRef}
              isDirty={isDirty}
            />
          </div>
        </div>
        <div className="d-flex mt-3">
          <button
            type="button"
            className="orange-outline-button ml-auto mr-2"
            onClick={closeModal}
          >
            Cancel
          </button>
          {!isFinishedPayroll ? <>
            <button
              type="button"
              className="orange-outline-button mr-2"
              onClick={() => { return onDeleteAdjustment(check); }}
            >
              Delete
            </button>
            <button
              type="submit"
              className="orange-button mr-2"
              disabled={!isDirty || loadingValidation || savingAdjustmentCheck}
            >
              
              {(loadingValidation || savingAdjustmentCheck) ? <FontAwesomeIcon icon="spinner" spinPulse /> : "Save"}
            </button>
          </> : null}
        </div>
      </fieldset>
    </form>
  );
};

export default AdjustmentCheckItem;
