import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { InputGrpInLine } from '..';
import { Dropdown, DropdownRequest } from '../../../models';
import { createDropdown, updateDropdown } from '../../../store/actions';
import { FieldInputSettings } from '../types';

const fs: FieldInputSettings = {
  code: { name: 'code', label: 'Code: ', labelWidth: 25 },
  description: {
    name: 'description',
    label: 'Description',
    labelWidth: 25,
    required: true,
  },
};

type PropTypes = {
  item: Dropdown;
  dropdownName: string;
  onHide: any;
};

const DropdownOptionForm: React.FC<PropTypes> = ({
  item,
  dropdownName,
  onHide,
}) => {
  const dispatch = useDispatch();
  const { register, errors, handleSubmit, reset } = useForm<any>({
    defaultValues: item,
  });

  useEffect(() => {
    reset({ ...item });
  }, [item, dropdownName]);

  const onSubmit = (data: any, event?: React.BaseSyntheticEvent) => {
    event?.stopPropagation();
    const updatedItem = new DropdownRequest(
      item.clientNo ?? 0,
      item.id === '' ? '0' : item.id,
      data.description,
      data.code,
      item.dropdownName ?? dropdownName,
    );
    item?.id && item.id.trim() !== ''
      ? dispatch(updateDropdown(updatedItem))
      : dispatch(createDropdown(updatedItem));
    onHide(true);
    return false;
  };

  return (
    <div className="dm-panel dm-panel-border d-flex flex-column flex-nowrap">
      <div className="dm-grid-action-title">Add/Edit</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {item.usesCode && (
          <InputGrpInLine
            {...fs.code}
            errors={errors.code}
            ref={register(item.usesCode ? { required: 'Code is Required' } : {})}
          />
        )}
        <InputGrpInLine
          {...fs.description}
          errors={errors.description}
          ref={register({ required: 'Description is Required' })}
        />
        <div style={{ display: 'flex' }}>
          <button
            type="button"
            className="btn btn-primary orange-outline-button ml-auto mr-2"
            onClick={() => {return onHide(false);}}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="orange-button mr-2"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default DropdownOptionForm;
