import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PayrollInfoSpecialReporting from './PayrollInfoSpecialReporting';
import PayrollInfoTimeKeeping from './PayrollInfoTimeKeeping';
import PayrollInfoTaxInfo from './PayrollInfoTaxInfo';
import PayrollIinfoControls from './PayrollInfoControls';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPayrollInfo } from 'core/store/selectors';
import { PayrollInfo } from 'core/models';
import PayrollInfoTimeAndAttendance from './PayrollInfoTimeAndAttendance';
import PayrollInfoMiscInformation from './PayrollInfoMiscInformation';
import { useAppSelector } from 'utilities/hooks';
import { EmployeeMasterSection } from 'core/models/ModuleAccess.model';
import { FormMethods } from 'core/components/form-controls/types';
import {
  loadPayrollInfo,
  loadSupervisors,
  loadTaxes,
  loadCmTaxEntities,
  loadWCEffectiveDates,
  updatePayrollInfo,
  toggleBlockNavigation,
} from 'core/store/actions';
import { loadEarningCodes } from 'core/store/actions/earning-codes.action';
import { getAccess } from 'utilities/utilities';
import { UNSAVED_MESSAGE } from 'core/constants';

type RouteParams = {
  protectedEmpNo: string;
};

export type BaseSectionProps = {
  sectionVisible: boolean;
  sectionReadOnly: boolean;
  sectionAccess: EmployeeMasterSection[] | undefined,
  methods: {
    register: FormMethods['register'];
    setValue: FormMethods['setValue'];
    getValues: FormMethods['getValues'];
    errors: FormMethods['errors'];
    control: FormMethods['control'];
    watch: FormMethods['watch'];
  };
};

const PayrollInfoPage: React.FC = () => {
  const dispatch = useDispatch();

  const { protectedEmpNo } = useParams<RouteParams>();

  const isNovaClient = useAppSelector(({ client }) => client.isNovaClient);
  const sectionAccess = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections);
  const payrollInfo = useSelector(getPayrollInfo);

  const payrollInfoSection = sectionAccess?.find((s) => {
    return s.description === 'PAYROLLINFO';
  }) as EmployeeMasterSection;
  const [fields, visible, readOnly] = [payrollInfoSection?.fields, payrollInfoSection?.visible, payrollInfoSection?.readOnly];

  const { handleSubmit, reset, formState, ...methods } = useForm<any>();
  const { isDirty } = formState;


  const formSectionProps = {
    methods: methods,
    fields: fields,
    sectionVisible: visible,
    sectionReadOnly: readOnly,
  };
  
  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(loadPayrollInfo(protectedEmpNo));
      dispatch(loadTaxes({ protectedEmpNo }));
      loadCmTaxEntities();
      dispatch(loadEarningCodes());
      dispatch(loadWCEffectiveDates());
      dispatch(loadSupervisors());
    }
  }, [protectedEmpNo]);

  useEffect(() => {
    if (payrollInfo) reset(payrollInfo);
  }, [payrollInfo]);

  const onFailValidation = (_data: any, e: any) => {
    const classes = e?.nativeEvent.submitter.className;
    if (!classes.includes('payroll-info-submit')) {
      e.stopPropagation();
      e.preventDefault();
      methods.clearErrors();
      return false;
    }
  };

  const onSave = (data: Partial<PayrollInfo>, e: any) => {
    const classes = e?.nativeEvent.submitter.className;
    if (!classes.includes('payroll-info-submit')) {
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    // @ts-ignore
    const x = new PayrollInfo(
      payrollInfo?.payrollInfo?.empId,
      payrollInfo?.payrollInfo?.clientNo,
      payrollInfo?.payrollInfo?.empNo,
      protectedEmpNo,
      data,
    );
    //If eeoUnitNo has a number then convert it to a string else dont as we dont want 'null' to be sent back we want null.
    if (x.eeoUnitNo) x.eeoUnitNo = '' + x.eeoUnitNo;
    dispatch(updatePayrollInfo(x));
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [isDirty]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSave, onFailValidation)}>
        <PayrollInfoSpecialReporting
          {...formSectionProps}
          sectionAccess={sectionAccess}
        />
        <PayrollInfoTimeKeeping
          {...formSectionProps}
          sectionAccess={sectionAccess}
        />
        {isNovaClient ? (
          <PayrollInfoTimeAndAttendance
            {...formSectionProps}
            sectionAccess={sectionAccess}
          />
        ) : null}
        <PayrollInfoMiscInformation
          {...formSectionProps}
          sectionAccess={sectionAccess}
        />
        <PayrollInfoTaxInfo
          {...formSectionProps}
          sectionAccess={sectionAccess}
        />
        <PayrollIinfoControls
          {...formSectionProps}
          sectionAccess={sectionAccess}
        />
        <div className="row my-4">
          <div className="col-12 text-right">
            <button
              {...getAccess(sectionAccess, 2, undefined, { disabledSameAsReadOnly: true, disabledDefault: !isDirty })}
              type="submit"
              className="orange-button ml-2 payroll-info-submit"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PayrollInfoPage;
