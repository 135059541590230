import { SelectGrp } from 'core/components/form-controls/SelectGrp';
import { PayRateDropdown, TransmittalEarning } from 'core/models/Payroll.model';
import { getEarningsCodes, getLocalTaxEntity, getLocationDepartmentsWithoutHome, getShiftPremiums } from 'core/store/selectors/dropdown.selector';
import { useSelector } from 'react-redux';
import { InputGrp } from 'core/components/form-controls';
import React, { useEffect, useState, useContext, CSSProperties } from 'react';
import { cleanAmount, currencyFormatter, formatWithCommas } from 'utilities/utilities';
import { getPayrollsUserOptions, getShowAddtl, getYesNoCMOption } from 'core/store/selectors';
import { ArrayField } from 'react-hook-form';
import Icon from 'core/components/shared/Icon';
import { FormMethods } from 'core/components/form-controls/types';
import { ManualChangeArgs } from './CheckItem';
import { FormContext } from './FormContext';
import ChangeCode from './transmittal-modal/ChangeCode.modal';
import { Dropdown, EarningCode } from 'core/models';
import ShiftPremiumTable from './transmittal-modal/ShiftPremiumTable';
import DepartmentOptionForm from 'core/components/form-controls/select-modal/DepartmentOptionForm';
import SubDepartmentOptionForm from 'core/components/form-controls/select-modal/SubDepartmentOptionForm';
import SubDepartment2OptionForm from 'core/components/form-controls/select-modal/SubDepartment2OptionForm';
import { ShiftCodeSelect } from 'core/components/form-controls/ShiftCodeSelect';
import { SelectModalGrpUncontrolled } from 'core/components/form-controls/select-modal/SelectModalGrpUncontrolled';
import { SelectGrpUncontrolled } from 'core/components/form-controls/SelectGrpUncontrolled';
import { filteredRateOptions } from '../shared/payrollUtilities';

const addtlFieldsContainerStyles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  padding: '0',
  gap: '5px',
  maxWidth: '30%',
  margin: '0 10px',
};
const addtlFieldsWrapperStyles: CSSProperties = {
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  gap: '10px',
};

type PropTypes = {
  checkIndex: number;
  index: number;
  earningIndex: number;
  fieldArrayId: string | undefined;
  empNo: number;
  errors: any;
  isReadOnly: boolean;
  watch: FormMethods['watch'];
  getValues: FormMethods['getValues'];
  setValue: FormMethods['setValue'];
  setError: FormMethods['setError'];
  formStateErrors: any;
  register: any; // Can't use FormMethods['register'] type when passing object...
  payRateDropdown: PayRateDropdown[];
  earning: Partial<ArrayField<TransmittalEarning, 'id'>>;
  total: number;
  earningCodeSettings: EarningCode[];
  totalAmount: () => void;
  remove: (id?: string | undefined) => void;
  saveChanges: (unregisteredInput?: boolean, args?: ManualChangeArgs) => Promise<any> | undefined;
};

export type CodeType = 'Department' | 'Sub Department' | 'Sub Department 2' | 'City' | 'Shift Code';
export type Property = 'dept' | 'subDept' | 'subDept2' | 'cityTaxEntityId' | 'shiftPremiumId';

const TransmittalEarningsItem = ({
  index,
  fieldArrayId,
  earningIndex,
  checkIndex,
  empNo,
  errors,
  isReadOnly,
  register,
  watch,
  setValue,
  setError,
  payRateDropdown,
  earning,
  total,
  earningCodeSettings,
  totalAmount,
  remove,
  saveChanges,
}: PropTypes) => {
  const payrollsUserOptions = useSelector(getPayrollsUserOptions);
  const earningsCodes = useSelector(getEarningsCodes);
  const showRatesOnTransmittal = useSelector(getYesNoCMOption(36));
  const showAddtlFields = useSelector(getShowAddtl);
  const cityCodes: Dropdown[] = useSelector(getLocalTaxEntity);
  const { deptOpts, subdeptOpts, subdept2Opts } = useSelector(getLocationDepartmentsWithoutHome);
  const clientShiftPremiums = useSelector(getShiftPremiums);
  
  // if hiding employee rates, alt rate is the next option
  const [hideAltRate, setHideAltRate] = useState<boolean>((earning?.altRate ?? 0) === 0);
  const [trackedHours, setTrackedHours] = useState<boolean>(earning.tracked?.toString() === 'true');
  const [hideTracked, setHideTracked] = useState<boolean>(!(earning.rateId === null) && (earning.rateId === -1));
  const [showCodeChangeModal, setShowCodeChangeModal] = useState<boolean>(false);
  const [codeType, setCodeType] = useState<CodeType | null>();
    
  const earningsItemPath = `earnings[${earningIndex}]`;
  const earningsCode = watch(`${earningsItemPath}.earningsCode`);
  const watchedDept = watch(`${earningsItemPath}.dept`);
  const watchedSubDept = watch(`${earningsItemPath}.subDept`);
  const watchedSubDept2 = watch(`${earningsItemPath}.subDept2`);
  const watchedCityCode = watch(`${earningsItemPath}.cityTaxEntityId`);
  const watchedShiftPremium = watch(`${earningsItemPath}.shiftPremiumId`);
  const watchedAmount = watch(`${earningsItemPath}.amount`);
  const watchedAltRate = watch(`${earningsItemPath}.altRate`);
  const watchedRateId = watch(`${earningsItemPath}.rateId`);
  
  //PI-8637 Updated it so we handle both masked and unmasked rates in this method so we can format the salaryDescription for earings code 2 and 3
  const payRateOptions = filteredRateOptions(payRateDropdown ?? [], earningCodeSettings, earningsCode, earning.tranDigit || 2, showRatesOnTransmittal);
  
  const renderedRates = (payRateOptions).filter((v, i, a) => {
    return (
      a.findIndex((o) => {
        return o.rateId === v.rateId;
      }) === i
    );
  });
  
  const {
    formInfo: { triggerSave },
    earningsError,
    updateActiveElement,
    setDirtyCheck,
    setEarningsErrorCallback,
    manualSaveTriggerCallback,
  } = useContext(FormContext);

  const setTrackedFields = (value: boolean) => {
    setValue(`${earningsItemPath}.tracked`, value);
    setTrackedHours(value);
  };
  
  const setAdditionalField = (property: Property, newValue: string | number) => {
    setValue(`${earningsItemPath}.${property}`, +newValue);
    // hacky but need to do this so we can show the name as the input value while still sending the entity code.
    validateAndSave(property === 'cityTaxEntityId'); 
  };
  
  const setHiddenFields = (newVal: string) => {
    setHideAltRate(newVal !== '0');
    setHideTracked(newVal === '1');
    //Only clear the tracked value if changing to earnings only
    if (newVal === '-1') {
      setTrackedFields(false);
    }
  };

  const readOnly = (_earning: TransmittalEarning | undefined) => {
    return !(
      _earning === undefined ||
      _earning.autoCalcResultId === null ||
      _earning.shiftPremiumId === null
    );
  };

  useEffect(() => {
    if ((earning?.altRate ?? 0) > 0) {
      setHideAltRate(false);
    }
  }, []);
  
  useEffect(() => {
    if (!triggerSave) return;
    validateEarnings();
  }, [triggerSave]);
  
  useEffect(() => {
    // blur the additional input once we're done so it doesn't get changed
    if (showCodeChangeModal) return;
    Array.from(document.querySelectorAll('input')).forEach(el => el.blur());
  }, [showCodeChangeModal]);
  
  const generateLabel = (_earning: Partial<ArrayField<TransmittalEarning, 'id'>>) => {
    if (_earning.tranDigit === 1) {
      return showRatesOnTransmittal || ((_earning?.rateId ?? 999) <= 0) ? 'salaryDescription' : 'salaryMask';
    }
    return showRatesOnTransmittal || ((_earning?.rateId ?? 999) <= 0) ? 'hourlyDescription' : 'hourlyMask';
  };
  
  const validateEarnings = () => {
    if (!earningsCode && watchedAmount !== 0) {
      setError(`${earningsItemPath}.earningsCode`, {
        type: 'validate',
        message: 'Enter an earnings code',
      });
      setEarningsErrorCallback({ empNo,
        checkIndex,
        earningsItemId: earning.transmittalEarningsId ?? -1 });
      return;
    }
    setEarningsErrorCallback({ empNo: -1,
      checkIndex: -1,
      earningsItemId: -1 });
  };

  const checkAutoTracked = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const newEarningsCode = earningCodeSettings.find(x => x.earnCode == ev.target.value);
    if (newEarningsCode?.isAutoTracked) {
      setValue(`${earningsItemPath}.tracked`, true);
      setValue(`${earningsItemPath}.tranDigit`, 2);
    }
  };
  
  const validateAndSave = (unregisteredInput?: boolean, args?: ManualChangeArgs) => {
    validateEarnings();
    saveChanges(unregisteredInput, args);
  };
  
  const handleDeleteEarning = () => {
    if ((earning.transmittalEarningsId ?? -1) === earningsError.earningsItemId) {
      setEarningsErrorCallback({
        empNo: -1,
        earningsItemId: 0,
        checkIndex: -1,
      });
    }
    remove(fieldArrayId);
  };
  
  const onRateChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    setHiddenFields(ev.target.value);
    setValue(`${earningsItemPath}.rateId`, parseInt(ev.target.value)); 
    
    if (ev.target.value === '0') return; // don't update this until we actually have an altRate.}
    setValue(`${earningsItemPath}.nonAdjustedRateId`, parseInt(ev.target.value)); // if it isn't zero, update the nonAdjustedRateId to sync them
    validateAndSave();
  };
  
  /**
   * Curried function to update amount field so we only save if it's changed.
   * @param fieldName 
   * @returns 
   */
  const onBlurAmount = (fieldName: string, field: keyof TransmittalEarning, places = 2) => (e: React.FocusEvent<HTMLInputElement, Element>) => {  
    const formatResult = Number(cleanAmount(e.target.value));
    if (formatResult === earning?.[field]) return; // don't update if nothing has changed
    
    setValue?.(fieldName, formatResult);
    e.target.value = formatWithCommas(e.target.value, places);
    validateAndSave();
  };
  
  const updateShiftCode = (newVal: string | null) => {
    const matchingShiftPremium = clientShiftPremiums.find((x) => x.shiftPremiumId === +(newVal ?? -99));
    
    if (!matchingShiftPremium || matchingShiftPremium?.earningsCode?.length) {
      setValue(`${earningsItemPath}.altRate`, 0);
    }
    
    validateAndSave(true);
    manualSaveTriggerCallback(false);
  };
  
  return (
    <>
      <div
        className="row transmittal-earnings-item-row"
        key={earning.id}
        style={hideTracked ? { backgroundColor: '#fff8bd' } : { }}
      >
        <input
          type="hidden"
          name={`${earningsItemPath}.tranDigit`}
          defaultValue={earning.tranDigit}
          ref={register({
            valueAsNumber: true,
          })}
        />
        <input
          type="hidden"
          name={`${earningsItemPath}.nonAdjustedRateId`}
          defaultValue={earning?.nonAdjustedRateId ?? 0}
          ref={register({
            valueAsNumber: true,
          })}
        />
        <input
          type="hidden"
          name={`${earningsItemPath}.transmittalEarningsId`}
          defaultValue={earning?.transmittalEarningsId ?? 0}
          ref={register({
            valueAsNumber: true,
          })}
        />
        <input
          type="hidden"
          name={`${earningsItemPath}.tracked`}
          value={`${trackedHours}`}
          ref={register()}
        />
        <>
          <input
            type="hidden"
            name={`${earningsItemPath}.dept`}
            value={`${watchedDept ?? earning.dept ?? 0}`}
            ref={register({
              valueAsNumber: true,
            })}
          />
          <input
            type="hidden"
            name={`${earningsItemPath}.subDept`}
            value={`${watchedSubDept ?? earning.subDept ?? 0}`}
            ref={register({
              valueAsNumber: true,
            })}
          />
          <input
            type="hidden"
            name={`${earningsItemPath}.subDept2`}
            value={`${watchedSubDept2 ?? earning.subDept2 ?? 0}`}
            ref={register({
              valueAsNumber: true,
            })}
          />
          <input
            type="hidden"
            name={`${earningsItemPath}.cityTaxEntityId`}
            value={`${watchedCityCode ?? earning.cityTaxEntityId ?? 0}`}
            ref={register({
              valueAsNumber: true,
            })}
          />
          <input
            type="hidden"
            name={`${earningsItemPath}.shiftPremiumId`}
            value={`${watchedShiftPremium ?? earning.shiftPremiumId ?? 0}`}
            ref={register({
              valueAsNumber: true,
            })}
          />
        </>
        {showAddtlFields && (
          <div
            className="col"
            style={addtlFieldsContainerStyles}
          >
            <div style={addtlFieldsWrapperStyles}>
              {deptOpts?.length ? (
                <SelectModalGrpUncontrolled 
                  label="Dept"
                  id={`dept-${earning.transmittalEarningsId}`}
                  showId={true}
                  modalTitle="DEPARTMENTS"
                  addOptionText="Department"
                  name={`${earningsItemPath}.dept`}
                  value={+(watchedDept ?? earning?.dept ?? -1)}
                  options={deptOpts}
                  labelField="deptDesc"
                  valueField="deptCode"
                  idField="deptId"
                  dropdownName="Department"
                  formComponent={DepartmentOptionForm}
                  setValue={setValue}
                  onFocus={(_e: React.FocusEvent<HTMLInputElement, Element>) => {
                    updateActiveElement(`dept-${earning.transmittalEarningsId}`);
                  }}
                  onChange={() => {
                    validateAndSave(true);
                    manualSaveTriggerCallback(false);
                  }}
                  controlMinWidth="85px"
                  controlMaxWidth="85px"
                  searchable
                  selectOnEnter
                  disabled={isReadOnly}
                />
              ) : null}
              {subdeptOpts?.length ? (
                <SelectModalGrpUncontrolled 
                  label="Sub Dept"
                  name={`${earningsItemPath}.subDept`}
                  id={`subDept-${earning.transmittalEarningsId}`}
                  showId={true}
                  modalTitle="SUB DEPARTMENTS"
                  addOptionText="Sub Dept"
                  value={+(watchedSubDept ?? earning?.subDept ?? -1)}
                  options={subdeptOpts}
                  labelField="subDeptDesc"
                  valueField="subDeptCode"
                  idField="subDeptID"
                  dropdownName="SubDepartment"
                  formComponent={SubDepartmentOptionForm}
                  setValue={setValue}
                  onFocus={(_e: React.FocusEvent<HTMLInputElement, Element>) => {
                    updateActiveElement(`subDept-${earning.transmittalEarningsId}`);
                  }}
                  onChange={() => {
                    validateAndSave(true);
                    manualSaveTriggerCallback(false);
                  }}
                  controlMinWidth="85px"
                  controlMaxWidth="85px"
                  searchable
                  selectOnEnter
                  disabled={isReadOnly}
                />
              ) : null}
              {subdept2Opts?.length ? (
                <SelectModalGrpUncontrolled 
                  label="Sub Dept 2"
                  name={`${earningsItemPath}.subDept2`}
                  id={`subDept2-${earning.transmittalEarningsId}`}
                  showId={true}
                  modalTitle="SUB DEPARTMENTS 2"
                  addOptionText="Sub Dept 2"
                  value={+(watchedSubDept2 ?? earning?.subDept2 ?? -1)}
                  options={subdept2Opts}
                  labelField="sub2Desc"
                  valueField="sub2Code"
                  idField="subDept2ID"
                  dropdownName="SubDepartment2"
                  formComponent={SubDepartment2OptionForm}
                  setValue={setValue}
                  onFocus={(_e: React.FocusEvent<HTMLInputElement, Element>) => {
                    updateActiveElement(`subDept2-${earning.transmittalEarningsId}`);
                  }}
                  onChange={() => {
                    validateAndSave(true);
                    manualSaveTriggerCallback(false);
                  }}
                  controlMinWidth="85px"
                  controlMaxWidth="85px"
                  searchable
                  selectOnEnter
                  disabled={isReadOnly}
                />
              ) : null}
              <SelectGrpUncontrolled
                name={`${earningsItemPath}.cityTaxEntityId`}
                id={`cityTaxEntityId-${earning.transmittalEarningsId}`}
                label="City"
                defaultValue={watchedCityCode}
                options={cityCodes}
                addEmptyValue
                groupStyle={{ width: '85px' }}
                onFocus={(_e: React.FocusEvent<HTMLSelectElement, Element>) => {
                  updateActiveElement(`cityTaxEntityId-${earning.transmittalEarningsId}`);
                }}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  setValue(`${earningsItemPath}.cityTaxEntityId`, +e.target.value);
                  validateAndSave(true);
                }}
                disabled={isReadOnly}
              />
              {/* hidden input for city code since we show the city name in the UI input. */}
              <input
                type="hidden"
                name={`${earningsItemPath}.cityTaxEntityId`}
                defaultValue={watchedCityCode ?? 0}
                ref={register({
                  valueAsNumber: true,
                })}
              />
              <ShiftCodeSelect
                name={`${earningsItemPath}.shiftPremiumId`}
                id={`shiftPremiumId-${earning.transmittalEarningsId}`}
                label="Shift Code"
                value={watchedShiftPremium ?? null}
                errors={errors}
                setValue={setValue}
                onChange={updateShiftCode}
                onFocus={(_e: React.FocusEvent<HTMLInputElement, Element>) => {
                  updateActiveElement(`city-${earning.transmittalEarningsId}`);
                }}
                onBlur={(_e: React.FocusEvent<HTMLInputElement, Element>) => {
                  updateActiveElement(`shiftPremiumId-${earning.transmittalEarningsId}`);
                }}
                readonly={isReadOnly}
              />
            </div>
          </div>
        )}
        <div
          className="col"
          style={showAddtlFields ? { maxWidth: '15%' } : { maxWidth: '30%' }}
        >
          <SelectGrp
            options={earningsCodes}
            name={`${earningsItemPath}.earningsCode`}
            id={`earningsCode-${earning.transmittalEarningsId}`}
            addEmptyValue={true}
            readOnly={isReadOnly}
            errorMsg="Required"
            showId={true}
            errors={errors?.earnings?.[earningIndex]?.earningsCode}
            ref={register({
              required: 'Please choose an Earnings Code',
            })}
            disabled={readOnly(earning as TransmittalEarning)}
            defaultValue={earning.earningsCode}
            onChange={(x: React.ChangeEvent<HTMLSelectElement>) => {
              checkAutoTracked(x);
              totalAmount();
              validateAndSave();
            }}
            onFocus={(_e: React.FocusEvent<HTMLInputElement, Element>) => {
              updateActiveElement(`earningsCode-${earning.transmittalEarningsId}`);
            }}
          />
        </div>
        <div
          className={`col ${showAddtlFields ? 'p-0' : ''}`}
          style={showAddtlFields ? { maxWidth: '17%', left: '1%' } : { maxWidth: '25%', marginLeft: '-6%' }}
        >
          <div className="d-flex flex-wrap justify-content-between">
            <div
              className="flex-fill mr-2"
              style={{ maxWidth: 'fit-content' }}
            >
              {hideTracked ? null : (
                <button
                  type="button"
                  id={`track-${earning.transmittalEarningsId}`}
                  className={`btn orange-${trackedHours ? '' : 'outline-'}button-xs`}
                  style={trackedHours ? { minWidth: '113px' } : undefined}
                  title={trackedHours ?
                    'Click here to stop tracking these hours' :
                    'Click here to track the hours entered on this line'}
                  disabled={isReadOnly}
                  onClick={() => {
                    setValue(`${earningsItemPath}.tracked`, !trackedHours);
                    validateAndSave(true);
                  }}
                  onFocus={(_e: React.FocusEvent<HTMLButtonElement, Element>) => {
                    updateActiveElement(`track-${earning.transmittalEarningsId}`);
                  }}
                >
                  {trackedHours ? 'Tracking Hours' : (<br />) }
                </button>
              )}
            </div>
            <div
              className="flex-fill"
              style={{ maxWidth: '80px' }}
            >
              <InputGrp
                validateAndSave={validateAndSave}
                groupClass="number-underline mb-0"
                name={`${earningsItemPath}.amount`}
                id={`amount-${earning.transmittalEarningsId}`}
                type={'text'}
                readOnly={isReadOnly}
                ref={register({
                  valueAsNumber: true,
                  required: 'Please enter an amount',
                  minValue: 0,
                })}
                errors={
                  errors?.items?.[index]?.checks?.[checkIndex]
                    ?.earnings?.[earningIndex]?.amount
                }
                disabled={readOnly(earning as TransmittalEarning)}
                places={payrollsUserOptions?.hoursDecimals}
                defaultValue={formatWithCommas(watchedAmount, payrollsUserOptions?.hoursDecimals)}
                onChange={() => {
                  totalAmount();
                  setDirtyCheck(true);
                }}
                setValue={setValue}
                onBlur={onBlurAmount(`${earningsItemPath}.amount`, 'amount')}
                formatNumber
                hiddenValue={watchedAmount ?? 0}
                hiddenRef={register({
                  valueAsNumber: true,
                })}
                onFocus={(_e: React.FocusEvent<HTMLInputElement, Element>) => {
                  updateActiveElement(`amount-${earning.transmittalEarningsId}`);
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="col"
          style={{ maxWidth: '15%' }}
        >
          <InputGrp
            id={`altRate-${earning.transmittalEarningsId}`}
            validateAndSave={() => {
              validateAndSave();
            }}
            groupClass={`number-underline ${hideAltRate ? 'd-none' : ''}`}
            name={`${earningsItemPath}.altRate`}
            ref={register({
              valueAsNumber: true,
            })}
            disabled={readOnly(earning as TransmittalEarning)}
            readOnly={isReadOnly}
            defaultValue={formatWithCommas(watchedAltRate, 4)}
            places={4}
            onChange={() => {
              totalAmount();
              setDirtyCheck(true);
            }}
            setValue={setValue}
            formatNumber
            hiddenValue={watchedAltRate ?? 0}
            hiddenRef={register({
              valueAsNumber: true,
            })}
            onFocus={(_e: React.FocusEvent<HTMLInputElement, Element>) => {
              updateActiveElement(`altRate-${earning.transmittalEarningsId}`);
            }}
            onBlur={onBlurAmount(`${earningsItemPath}.altRate`, 'altRate', 4)}
          />
          <SelectGrp
            options={renderedRates}
            name={`${earningsItemPath}.rateId`}
            id={`rateId-${earning.transmittalEarningsId}`}
            valueField="rateId"
            labelField={generateLabel(earning)}
            readOnly={isReadOnly}
            ref={register({
              valueAsNumber: true,
            })}
            disabled={readOnly(earning as TransmittalEarning)}
            value={(watchedRateId === null) ? 0 : watchedRateId}
            onChange={onRateChange}
            onFocus={(_e: React.FocusEvent<HTMLInputElement, Element>) => {
              updateActiveElement(`rateId-${earning.transmittalEarningsId}`);
            }}
          />
        </div>
        <div
          className="col font-weight-bold text-right"
          style={showAddtlFields ? { maxWidth: '15%' } : { maxWidth: '20%' }}
        >
          {earningIndex === 0 && showRatesOnTransmittal && currencyFormatter(total)}
          {!isReadOnly ? <button
            type="button"
            className="btn btn-link dm-grid-action-title"
            disabled={isReadOnly}
            onClick={handleDeleteEarning}
          >
            Delete <Icon
              name="minus-circle"
              className="fa-minus-circle"
            />
          </button> : null}
        </div>
      </div>
      {showCodeChangeModal && codeType && codeType !== 'Shift Code' ? (
        <ChangeCode 
          type={codeType}
          show={showCodeChangeModal}
          onHide={() => {
            setShowCodeChangeModal(false);
            window.blur();
            setCodeType(null);
          }}
          onSelectProp={(property: Property, newValue: any) => {
            setAdditionalField(property, newValue);
          }}
        />
      ) : showCodeChangeModal && codeType && codeType === 'Shift Code' ? (
        <ShiftPremiumTable
          show={showCodeChangeModal}
          onHide={() => {
            setShowCodeChangeModal(false);
            window.blur();
            setCodeType(null);
          }}
          onSelectProp={(property: Property, newValue: any) => {
            setAdditionalField(property, newValue);
          }}
          previousShiftCode={earning.shiftPremiumId ?? -1}
        />
      ) : null}
    </>
  );
};

export default TransmittalEarningsItem;
