import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ControlDatePickerGrp, InputGrp, RadioGrp } from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import PanelHeader from 'core/components/shared/PanelHeader';
import { ChangeMyInfoAccountEmail, Employee, EmpSelfService } from 'core/models';
import { convToDateString } from 'utilities/utilities';
import AlertModal from 'core/components/modals/alert-modal.modal';
import MyInfoChangeHistoryModal from 'core/components/modals/myinfochanges-history/ChangeHistory.modal';
import { convToFormObject } from 'utilities/classUtils';
import { useRadioButtonChange } from 'utilities/hooks';
import Icon from 'core/components/shared/Icon';
import {
  changeMyInfoAccountEmail,
  deleteMyInfoAccount,
  loadEmployeeSelfService,
  loadMyInfoChanges,
  loadMyInfoChangesHistory,
  resetMyInfoAccountPW,
  storeMyInfoAccountMessage,
  toggleBlockNavigation,
  unlockMyInfoAccount,
  updateEmployeeSelfService,
} from 'core/store/actions';
import {
  getEmpSelfService,
  getMyInfoAccountResponseMessage,
  getMyInfoChanges,
  getMyInfoChangesHistory,
  getSelectedEmp,
} from 'core/store/selectors';
import MyInfoChangesModal from 'core/components/modals/MyInfoChanges.modal';
import { UNSAVED_MESSAGE } from 'core/constants';

const SelfServicePage = () => {
  const dispatch = useDispatch();

  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();

  const selEmp = useSelector(getSelectedEmp) as Employee;
  const groupSelfServiceFromStore = useSelector(getEmpSelfService);
  const messageFromStore = useSelector(getMyInfoAccountResponseMessage);
  const myInfoChangesHistory = useSelector(getMyInfoChangesHistory);
  const myInfoChange = useSelector(getMyInfoChanges);

  const [myInfoAccountMessage, setMessage] = useState('');
  const [showMyInfoPending, setShowMyInfoPending] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [printVoucherDisabled, setPrintVoucherDisabled] = useState<boolean>(false);
  const [oldMyInfoEmail, setOldMyInfoEmail] = useState('');
  const [newMyInfoEmail, setNewMyInfoEmail] = useState('');
  const [fs, setFs] = useState<FieldInputSettings>({
    employerWantsSelfService: {
      name: 'employerWantsSelfService',
      label: 'EMPLOYER WANTS SELF SERVICE',
      groupClass: 'gc17 mw150 mb-0',
    },
    employeeWantsSelfService: {
      name: 'employeeWantsSelfService',
      label: 'EMPLOYEE WANTS SELF SERVICE',
      groupClass: 'gc17 mw150 mb-0',
    },
    printVoucher: {
      name: 'printVoucher',
      label: 'PRINT VOUCHER',
      groupClass: 'gc11 mw100 mb-0',
    },
    employeeWantsVoucherNotification: {
      name: 'employeeWantsVoucherNotification',
      label: 'EMPLOYEE WANTS VOUCHER NOTIFICATION',
      groupClass: 'gc25 mw250 mb-0',
    },
    myInfoUserName: {
      name: 'myInfoUserName',
      label: 'USER NAME',
      groupClass: 'gc12 mw125',
    },
    myInfoEmail: {
      name: 'myInfoEmail',
      label: 'MYINFO EMAIL',
      groupClass: 'gc12 mw125',
    },
    myInfoLastLogin: {
      name: 'myInfoLastLogin',
      label: 'MYINFO LAST LOGIN',
      groupClass: 'gc12 mw125',
    },
  });

  const { errors, register, handleSubmit, setValue, reset, control, formState: { isDirty } } =
    useForm<EmpSelfService>();

  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(
        loadEmployeeSelfService({ protectedEmpNo }),
      );
      dispatch(loadMyInfoChanges({ protectedEmpNo }));
      dispatch(
        loadMyInfoChangesHistory({ protectedEmpNo }),
      );
    }
    return () => {
      dispatch(storeMyInfoAccountMessage(undefined));
    };
  }, [protectedEmpNo]);

  useEffect(() => {
    setOldMyInfoEmail(groupSelfServiceFromStore.myInfoEmail);
    const selfService = {
      ...groupSelfServiceFromStore,
    };
    groupSelfServiceFromStore &&
      reset(convToFormObject(selfService, EmpSelfService.convProps));
    setNewMyInfoEmail(groupSelfServiceFromStore.myInfoEmail);
    const currentState = { ...fs };
    currentState.employerWantsSelfService.defaultChecked =
      groupSelfServiceFromStore.employerWantsSelfService;
    currentState.employeeWantsSelfService.defaultChecked =
      groupSelfServiceFromStore.employeeWantsSelfService;
    currentState.printVoucher.defaultChecked =
      groupSelfServiceFromStore.printVoucher;
    currentState.employeeWantsVoucherNotification.defaultChecked =
      groupSelfServiceFromStore.employeeWantsVoucherNotification;
    setPrintVoucherDisabled(
      !groupSelfServiceFromStore.employeeWantsSelfService,
    );
    setFs(currentState);
  }, [groupSelfServiceFromStore]);

  useEffect(() => {
    setMessage(messageFromStore);
  }, [messageFromStore]);

  useEffect(() => {
    if (myInfoAccountMessage && myInfoAccountMessage !== '') {
      setShowMessage(true);
    }
    return () => { return setShowMessage(false); };
  }, [myInfoAccountMessage]);

  const onMyInfoChangeHistory = () => {
    setShowHistory(true);
  };

  const onUnlockUserAccount = () => {
    dispatch(unlockMyInfoAccount({ protectedEmpNo }));
  };

  const onResetPassword = () => {
    dispatch(resetMyInfoAccountPW({ protectedEmpNo }));
  };

  const onDeleteUserAccount = () => {
    dispatch(deleteMyInfoAccount({ protectedEmpNo }));
  };

  const onChangeEmail = (e: any) => {
    if (
      newMyInfoEmail &&
      newMyInfoEmail !== '' &&
      oldMyInfoEmail !== newMyInfoEmail
    ) {
      const sendEmail: ChangeMyInfoAccountEmail = {
        protectedEmpNo,
        oldEmail: oldMyInfoEmail,
        newEmail: newMyInfoEmail,
      };
      dispatch(changeMyInfoAccountEmail(sendEmail));
    } else {
      setMessage(
        'Cannot change this employees MyInfo Email to what it currently is. Enter the new email into the MyInfo Email field.',
      );
      setShowMessage(true);
    }
  };

  //I am keeping this incase we ever need it but MyInfo Email was changed to read only per PI-7654
  const handleMyInfoChangeEmail = (e: any) => {
    setNewMyInfoEmail(e.target.value);
  };

  const handleEmployeeWantsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const disabled = e.target.value === 'false';
    setPrintVoucherDisabled(disabled);
    if (disabled) {
      setValue('myInfoUserName', '');
      setValue('myInfoEmail', '');
      setNewMyInfoEmail('');
      const currentState = { ...fs };
      currentState.printVoucher.defaultChecked = 'true';
      setFs(currentState);
    }
    handleRadioButtonChange(e);
  };

  const onSave = (data: any) => {
    const selfService = new EmpSelfService(
      selEmp.empId,
      selEmp.protectedEmpNo,
      data,
    );

    dispatch(updateEmployeeSelfService({ ...selfService }));
  };

  const [handleRadioButtonChange] = useRadioButtonChange(fs);
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [isDirty]);


  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Self Service" />
      <form onSubmit={handleSubmit(onSave)}>
        <div className="d-flex">
          <div style={{ flex: '0 1 70%' }}>
            <div className="d-flex flex-wrap mt-3">
              <RadioGrp
                {...fs.employerWantsSelfService}
                errors={errors.employerWantsSelfService}
                ref={register}
                disabled = {true}
                onChange={handleRadioButtonChange}
              />
              <RadioGrp
                {...fs.employeeWantsSelfService}
                errors={errors.employeeWantsSelfService}
                ref={register}
                onChange={handleEmployeeWantsChange}
              />
              <RadioGrp
                {...fs.printVoucher}
                errors={errors.printVoucher}
                ref={register}
                disabled={printVoucherDisabled}
                onChange={handleRadioButtonChange}
              />
              <RadioGrp
                {...fs.employeeWantsVoucherNotification}
                errors={errors.employeeWantsVoucherNotification}
                ref={register}
                disabled = {true}
                onChange={handleRadioButtonChange}
              />
            </div>
            <div className="d-flex flex-wrap mt-3">
              <InputGrp
                {...fs.myInfoUserName}
                errors={errors.myInfoUserName}
                ref={register}
                readOnly={true}
              />
              <InputGrp
                {...fs.myInfoEmail}
                errors={errors.myInfoEmail}
                ref={register}
                readOnly={true}
                //onChange={handleMyInfoChangeEmail}
              />
              <ControlDatePickerGrp
                {...fs.myInfoLastLogin}
                errors={errors.myInfoLastLogin}
                value={
                  new Date(
                    groupSelfServiceFromStore.myInfoLastLogin ||
                    0,
                  )
                }
                setValue={setValue}
                control={control}
                disabled={true}
              />
            </div>
          </div>
          <div
            className="d-flex flex-column pl-4 pt-4"
            style={{ flex: '0 1 30%' }}
          >
            <div>
              <Icon name="eye" />
              <button
                type="button"
                className="btn btn-link"
                onClick={onMyInfoChangeHistory}
              >
                View Myinfo Change History
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => { return setShowMyInfoPending(true); }}
              >
                Review/Approve New Changes
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-right">
            <button
              type="submit"
              className="btn orange-button-sm mr-3"
              disabled={!isDirty}
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <div className="d-flex">
        <div
          className="mr-4"
          style={{ width: '70%' }}
        >
          <PanelHeader title="Employee Documents" />
          <table className="table table-bordered table-hover w-100">
            <colgroup>
              <col style={{ width: '10%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '10%' }} />
            </colgroup>
            <thead className="thead-light">
              <tr>
                <th>File Name</th>
                <th>Accepted</th>
                <th>Date Accepted</th>
              </tr>
            </thead>
            <tbody>
              {groupSelfServiceFromStore?.documents?.map(
                (doc) => {
                  return (
                    <tr key={doc.docId}>
                      <td style={{ textAlign: 'left' }}>
                        {doc.fileName}
                      </td>
                      <td style={{ textAlign: 'left' }}>
                        <input
                          type="checkbox"
                          defaultChecked={doc.accepted}
                          disabled={true}
                        />
                      </td>
                      <td style={{ textAlign: 'left' }}>
                        {convToDateString(doc.dateAccepted)}
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        </div>
        <div
          className="ml-3"
          style={{ width: '30%' }}
        >
          <PanelHeader title="User Account Options" />
          <div
            className="d-flex flex-column pl-4 pt-4"
            style={{ flex: '0 1 30%' }}
          >
            <div>
              <Icon
                name="unlock"
                className="fa-unlock"
              />
              <button
                type="button"
                className="btn btn-link"
                onClick={onUnlockUserAccount}
              >
                Unlock User Account
              </button>
            </div>
            <div>
              <Icon
                name="check"
                className="fa-check"
              />
              <button
                type="button"
                className="btn btn-link"
                onClick={onResetPassword}
              >
                Reset Account Password
              </button>
            </div>
            <div>
              <Icon
                name="times"
                className="fa-times"
              />
              <button
                type="button"
                className="btn btn-link"
                onClick={onDeleteUserAccount}
              >
                Delete User Account
              </button>
            </div>
            <div>
              <Icon
                name="envelope"
                className="fa-envelope"
              />
              <button
                type="button"
                className="btn btn-link"
                onClick={onChangeEmail}
              >
                Change MyInfo Email
              </button>
            </div>
          </div>
        </div>
      </div>
      {showHistory && (
        <MyInfoChangeHistoryModal
          data={myInfoChangesHistory}
          show={showHistory}
          onHide={() => { return setShowHistory(false); }}
        />
      )}
      {showMessage && myInfoAccountMessage ? (
        <AlertModal
          title="My Account Info Message"
          message={myInfoAccountMessage}
          btnText="Okay"
          show={showMessage}
          onHide={() => {
            dispatch(storeMyInfoAccountMessage(undefined));
            setShowMessage(false);
          }}
        />
      ) : null}
      {showMyInfoPending && myInfoChange && (
        <MyInfoChangesModal
          myInfoChange={myInfoChange}
          show={showMyInfoPending}
          onHide={() => { setShowMyInfoPending(false); }}
        />
      )}
    </div>
  );
};

export default SelfServicePage;
