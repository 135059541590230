import React, { useEffect, useState } from 'react';
import { InputGrp } from 'core/components/form-controls';
import {
  filterConditionOpts, UserMapFont, UserMapFormat,
  formatDateOpts, formatNumericOpts,
  formatTextOpts, justifyOpts, logicOpts,
  pdfGroupByFNOpts, pdfGroupByOpts,
  pdfShowColumnOpts, UserMapSelectedField,
  sortTypeOpts, styleOpts, UserMapItem,
} from 'core/models/Downloader.model';
import { putUserMap, postUserMap, getAvailableFonts } from 'core/store/slices/downloader.slice';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  CellClassParams,
  CellClickedEvent,
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  CellValueChangedEvent,
  ColDef,
  Column,
  ColumnApi,
  EditableCallbackParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellEditorParams,
  ICellRendererParams,
  IHeaderComp,
  IHeaderParams,
  RowClassParams,
} from '@ag-grid-enterprise/all-modules';
import { agLookupRenderer } from 'utilities/ag-grid-renderers';
import { agSelectEditor } from 'utilities/ag-grid-editors';
import { AgGridReact } from '@ag-grid-community/react';
import { arrayMoveIndex, toTitleCase } from 'utilities/utilities';
import SetFontModal from './SetFont.modal';
import Icon from 'core/components/shared/Icon';
import { camelCase } from 'lodash';

type Props = {
  userMapItem: UserMapItem;
  closeModal: () => void;
  onCheckDirty: (isDirty: boolean) => void;
};

const pdfFields = ['groupBy',
  'groupByFunction',
  'groupByHeaderDesc',
  'groupBySubFooterDesc',
  'groupByTotalFooterDesc',
  'columnWidth',
  'showColumn'];

const disableWhenGroupByFalse = ['groupByHeaderDesc', 'groupBySubFooterDesc', 'groupByTotalFooterDesc'];
const disableWhenShowColumnFalse = ['groupBy', 'groupByFunction', 'groupByHeaderDesc', 'groupBySubFooterDesc', 'groupByTotalFooterDesc', 'columnWidth'];
const excelColumns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
  'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW',
  'AX', 'AY', 'AZ'];

const selectFields = ['justification', 'style', 'customFormatting',
  'sortDirection', 'filterCondition', 'filterLogic', 'excelJustification',
  'excelTextCasing', 'groupBy', 'groupByFunction', 'showColumn', 'filterCondition'];

const formulaDisable = ['style', 'customFormatting',
  'sortDirection', 'filterLogic', 'filterValue', 'groupBy', 'groupByFunction', 'showColumn', 'filterCondition'];

const breakAfterRowIndex = [3, 6, 9, 13, 14];

const FormatExcelForm: React.FC<Props> = ({ userMapItem, closeModal, onCheckDirty }) => {

  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<UserMapSelectedField[]>([]);
  const [headerNames, setHeaderNames] = useState([]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const { register, getValues, setValue, reset, formState } = useForm<UserMapItem>({
    defaultValues:
    {
      ...userMapItem,
      excelFormula: '',
    },
  });
  const [isHeaderFont, setIsHeaderFont] = useState<boolean>(false);
  const [showFontModal, setShowFontModal] = useState<boolean>(false);
  const [userMap, setUserMap] = useState<UserMapItem>(userMapItem);
  const [selectedField, setSelectedField] = useState<UserMapSelectedField>();
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [formulaColumnNumber, setFormulaColumnNumber] = useState<number>(1);
  const [currentformulaColumn, setCurrentformulaColumn] = useState<string | undefined>(undefined);
  const [gridIsDirty, setGridIsDirty] = useState(false);

  useEffect(() => {
    dispatch(getAvailableFonts());
  }, []);

  useEffect(() => {
    reset(userMapItem);
    setUserMap(
      {
        ...userMapItem,
        excelFormula: '',
      });
    setCurrentformulaColumn(undefined);
  }, [userMapItem]);

  const buildRowData = (um: UserMapItem) => {
    const fieldFormat = um?.selectedFields
      ?.map(f => {
        return {
          ...f.format,
          fieldName: `${f.fieldName}`,
          selectedAreaId: f.selectedAreaId,
          sortOrder: (f.format?.sortOrder == 0) ? undefined : f.format?.sortOrder,
          columnWidth: (f.format?.columnWidth == 0) ? 50 : f.format?.columnWidth,
        };
      })
      .sort((a, b) => { return a?.order! - b?.order!; })
      .map(({ order, ...f }) => { return f; }) as UserMapSelectedField[];

    const rowDescriptions = Object.keys(fieldFormat[0]);

    const rd = rowDescriptions
      .map((desc: string) => {

        const descValues = {
          description: toTitleCase(pdfFields.includes(desc) ? 'PDF' + toTitleCase(desc) :
            desc === 'columnHeaderSetter' ? 'Set Header Font' :
              desc),
          field: desc === 'columnHeaderSetter' ? 'setFont' : desc,
        } as any;

        fieldFormat.forEach((f: any) => {
          if (f.columnHeader === 'Formula') {
            descValues[camelCase(f.columnHeader) + f.selectedAreaId] = f[desc];
          } else {
            descValues[camelCase(f.columnHeader)] = f[desc];
          }
        });

        return descValues;
      });
    const rowDataUpdated = [...rd, { description: 'Set Header Font', field: 'setFont' }];

    setHeaderNames(rd.find(r => { return r.field === 'fieldName'; }));
    const filteredRowData = rowDataUpdated.filter(r => { return r.field !== 'fieldName' && r.field !== 'description'; });

    //Manually moved these to the top since they should be by the Column Header. TODO: Will fix this when we re-work this page.
    arrayMoveIndex(filteredRowData, filteredRowData.findIndex(x => x.field === 'setFont'), 1);
    arrayMoveIndex(filteredRowData, filteredRowData.findIndex(x => x.field === 'excelTextCasing'), 2);
    arrayMoveIndex(filteredRowData, filteredRowData.findIndex(x => x.field === 'excelJustification'), 3);

    setRowData(filteredRowData);
  };

  useEffect(() => {
    if (userMap?.selectedFields?.length) {
      buildRowData(userMap);
    }
  }, [userMap]);

  useEffect(() => {
    onCheckDirty(formState.isDirty || gridIsDirty);
  }, [gridIsDirty, formState.isDirty]);
  
  const getRowData = (headerName: string) => {
    const colRowData = gridApi?.getRenderedNodes().map(n => { return n.data; }) as any[];
    const rd = colRowData?.map(d => {
      let value = d[headerName];
      if (['groupBy', 'showColumn'].includes(d.field)) {
        value = ((value === 'true' || value === true) ? true : false);
      }
      const ret = { [d.field]: value };
      return ret;
    });
    if (!rd) return rd;
    const format = rd.reduce((acc, obj) => { return Object.assign(acc, obj); }, {}) as unknown as UserMapFormat;

    return format;
  };

  const IsCellEditable = (params : any, headerName: string) => {
    let isEditable = params.data.field !== 'setFont' && params.data.field !== 'formula';
    const rowData = getRowData(params.column.colId);
    if (headerName.includes('Formula')) {
      isEditable = !formulaDisable.includes(params.data.field);
    }
    if (disableWhenShowColumnFalse.includes(params.data.field)) {
      isEditable = rowData?.showColumn ?? false;
    }
    if (disableWhenGroupByFalse.includes(params.data.field)) {
      if(!rowData.showColumn) isEditable = false;
      else isEditable = rowData?.groupBy ?? false;
    }
    
    return isEditable;
  };

  const buildColumns = () => {

    const columns: ColDef[] = [{
      headerName: 'Row Description',
      field: 'description',
      pinned: 'left',
      suppressMovable: true,
      editable: false,
    }];

    for (const [key, value] of Object.entries(headerNames)) {
      const headerName = value as string;
      if (key !== 'description' && key !== 'field') {
        columns.push({
          field: key,
          headerName: headerName,
          hide: key === 'selectedAreaId' || !key,
          lockPinned: true,
          editable: function (params) {
            return IsCellEditable(params, headerName);
          },
          headerComponent: (params: any) => {
            return !params.displayName.includes('Formula') ?
              <div className="w-100 py-2">
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => {
                    onSetExcelFormula(params);
                  }}
                >{params.displayName}
                </button>
              </div> :
              <div
                className="w-100 py-2"
                onClick={() => {
                  setCurrentformulaColumn(params.column.getColId());
                  onSetFormulaValue(params);
                }}
              >
                <button
                  type="button"
                  className="btn btn-link mr-2 p-0"
                  onClick={() => { return onDeleteFormula(params); }}
                >
                  <Icon
                    name="times"
                    className="modal-close-btn"
                  />
                </button>
                {params.displayName}
              </div>
            ;
          },
          cellRendererSelector: (params: ICellRendererParams) => {
            return cellRendererSelector(params);
          },
          cellRendererParams: (params: ICellRendererParams) => {
            return cellRendererParams(params);
          },
          cellEditorSelector: (params: ICellEditorParams) => {
            return cellEditorSelector(params);
          },
          cellEditorParams: (params: ICellEditorParams) => {
            return cellEditorParams(params);
          },
        });
      }
    }

    setColumnDefs(columns);
  };

  useEffect(() => {
    if (headerNames && gridApi) {
      buildColumns();
    }
  }, [headerNames, gridApi]);

  const getFormattedData = () => {
    const selectedFields: UserMapSelectedField[] = [];
    const colRowData = gridApi?.getRenderedNodes().map(n => { return n.data; }) as any[];
    const cols = columnApi!.getAllGridColumns();

    const colToNameFunc = (col: Column, index: number) => {
      return {
        index,
        field: col.getId(),
        headerName: col.getColDef().headerName,
      };
    };

    const colNames = cols?.map(colToNameFunc);

    const rowData: any[] = [];

    colRowData?.forEach(d => {
      rowData.push(Object.entries(d));
    });

    colNames?.forEach((c: any) => {

      const rd = colRowData?.map(d => {
        let value = d[c.field];
        if (['groupBy', 'showColumn'].includes(d.field)) {
          value = ((value === 'true' || value === true) ? true : false);
        }
        const ret = { [d.field]: value };
        return ret;
      });

      const index = columnApi!.getAllGridColumns()?.findIndex(col => { return col.getColId() === c.field; });
      const order = { 'order': index };

      rd.push(order);

      const format = rd.reduce((acc, obj) => { return Object.assign(acc, obj); }, {}) as unknown as UserMapFormat;

      let areaId = 0;
      for (const [key, value] of Object.entries(format)) {
        if (key === 'selectedAreaId') areaId = value;
      }

      const field = userMap?.selectedFields?.find(s => { return s.selectedAreaId === areaId; });

      const sf: UserMapSelectedField = {
        selectedAreaId: field?.selectedAreaId ?? 0,
        section: field?.section ?? '',
        fieldName: field?.fieldName ?? '',
        format: {
          ...format,
          columnWidth: (format.columnWidth) ? +format.columnWidth : 0,
        },
        font: {
          font: field?.font?.font ?? 'Calibri',
          fontSize: field?.font?.fontSize ?? 11,
          fontStyle: field?.font?.fontStyle ?? 'Regular',
          underline: field?.font?.underline ?? false,
          effects: field?.font?.effects ?? false,
        },
        filter: field?.filter ?? null,
      };
      selectedFields.push(sf);
    });

    return selectedFields;
  };

  const onSubmit = () => {
    const data = getValues() as any;
    const selectedFields = getFormattedData();
    
    delete userMap.excelFormula;
    const request: UserMapItem = {
      userMapId: userMap.userMapId,
      mapId: userMap.mapId,
      isEarningsDownload: userMap.isEarningsDownload,
      isHrDownload: userMap.isHrDownload,
      updateExportMapFields: true,
      earningsMapType: userMap.earningsMapType,
      userName: userMap.userName,
      description: userMap.description,
      downloadSchedule: userMap.downloadSchedule,
      font: {
        font: userMap.font?.font ?? 'Calibri',
        fontSize: userMap.font?.fontSize ?? 11,
        fontStyle: userMap.font?.fontStyle ?? 'Regular',
        underline: userMap.font?.underline ?? false,
        effects: userMap.font?.effects ?? false,
      },
      emails: userMap.emails,
      selectedFields: selectedFields.filter(s => { return s.fieldName !== ''; }),
      headerFooter: data.headerFooter,
    };
    setGridIsDirty(false);
    
    request.userMapId ?
      dispatch(putUserMap(request)) :
      dispatch(postUserMap(request));
  };

  const onSetDownloadFont = (font: UserMapFont, areaId?: number) => {

    const applyAll = font.applyAll;
    delete font.applyAll;
    let request = {
      ...userMap,
      headerFooter: {
        headerCenter: userMap?.headerFooter?.headerCenter ?? '',
        headerLeft: userMap?.headerFooter?.headerLeft ?? '',
        headerRight: userMap?.headerFooter?.headerRight ?? '',
        footerCenter: userMap?.headerFooter?.footerCenter ?? '',
        footerLeft: userMap?.headerFooter?.footerLeft ?? '',
        footerRight: userMap?.headerFooter?.footerRight ?? '',
      },
    };

    if (!isHeaderFont) {
      request = {
        ...request,
        font: font,
      };
    } else if (applyAll) {
      const selectedFields = userMap?.selectedFields?.map((f: UserMapSelectedField) => {
        return {
          ...f,
          font: font,
        };
      });
      request = {
        ...request,
        selectedFields: selectedFields,
      };
    } else if (areaId) {
      const field = userMap?.selectedFields?.find(f => { return f.selectedAreaId === areaId; });
      const fields = userMap?.selectedFields?.filter(f => { return f.selectedAreaId !== areaId; });

      field && fields?.push({
        ...field,
        font: font,
      });

      request = {
        ...request,
        selectedFields: fields,
      };
    }

    request && dispatch(putUserMap(request));
  };

  const insertFormula = () => {
    let formulaColumn: string | undefined;
    setCurrentformulaColumn((prevState) => {
      formulaColumn = prevState;
      return prevState;
    });
    if (formulaColumn) {
      const values = getValues();
      let formulaIndex;

      gridApi?.forEachNode(n => {
        if (n.data.field === 'formula') {
          formulaIndex = n.id;
        }
      });

      if (formulaIndex) {
        const rowNode = gridApi?.getRowNode('' + formulaIndex);
        rowNode?.setDataValue(formulaColumn, values.excelFormula);
      }
    }
  };

  const onSetFormulaValue = (params: IHeaderParams) => {
    let nodeValue = '';
    params.api.forEachNode(n => {
      if (n.data.description === 'Formula') {
        nodeValue = n.data[params.column.getColId()] ?? '';
      }
    });
    setValue('excelFormula', nodeValue);
  };

  const onSetExcelFormula = (params: ICellEditorParams) => {
    let formulaColumn: string | undefined;
    setCurrentformulaColumn((prevState) => {
      formulaColumn = prevState;
      return prevState;
    });
    if (formulaColumn) {
      const index = params.columnApi?.getAllGridColumns()
        ?.findIndex((col: { getColId: () => string; }) => {
          return col.getColId() === params.column.getColId();
        });

      let formulaIndex;
      let nodeValue = '';
      params.api.forEachNode(n => {
        if (n.data.description === 'Formula') {
          formulaIndex = n.id;
          nodeValue = n.data[formulaColumn!] ?? '';
        }
      });

      if (formulaIndex) {
        const rowNode = params.api.getRowNode('' + formulaIndex);
        const value = `${nodeValue}${excelColumns[(index ?? 0) - 1]}{ROWINDEX}`;
        setValue('excelFormula', value);
        rowNode?.setDataValue(formulaColumn, value);
      }
    }
  };

  const onInsertNewExcelFormula = () => {
    if (userMap?.selectedFields?.length) {
      delete userMap.excelFormula;
      const format: UserMapFormat = {
        order: 1000 + formulaColumnNumber,
        columnHeader: 'Formula',
        columnHeaderSetter: true,
        justification: 'LeftJustify',
        style: 'Formula',
        customFormatting: '',
        sortDirection: 'NotUsed',
        sortOrder: 0,
        sortFunction: '',
        filterCondition: '',
        filterValue: '',
        filterLogic: '',
        filterOverride: '',
        formula: '',
        groupBy: false,
        groupByHeaderDesc: '',
        groupBySubFooterDesc: '',
        groupByTotalFooterDesc: '',
        groupByFunction: 0,
        columnWidth: 50,
        showColumn: true,
        excelTextCasing: 'NotUsed',
        excelJustification: 'LeftJustify',
      };
      const field: UserMapSelectedField = {
        selectedAreaId: 0,
        section: 'Excel Formula',
        fieldName: 'Formula',
        format: format,
        font: {
          font: 'Calibri',
          fontSize: 11,
          fontStyle: 'Regular',
          underline: false,
          effects: false,
        },
        filter: null,
      };
      setUserMap({
        ...userMap,
        selectedFields: [
          ...userMap.selectedFields, field,
        ],
      });

      dispatch(putUserMap({
        ...userMap,
        selectedFields: [
          ...userMap.selectedFields, field,
        ],
      }));

      setFormulaColumnNumber((prevState) => {
        return prevState + 1;
      });
    }
  };

  const onDeleteFormula = (params: IHeaderParams) => {
    const columns = params.columnApi.getAllGridColumns().filter(c => { return c.getColId() !== params.column.getColId(); });
    const cDefs = columns.map(c => { return c.getColDef(); });
    gridApi?.setColumnDefs(cDefs);
  };

  const onShowFontModal = (isHeader: boolean, field?: UserMapSelectedField) => {
    setIsHeaderFont(isHeader);
    field && setSelectedField(field);
    setShowFontModal(true);
  };

  const onShowDownloadFontModal = (isHeader: boolean) => {
    setIsHeaderFont(isHeader);
    setShowFontModal(true);
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  };

  const onCellValueChanged = (e: CellValueChangedEvent) => {
    setGridIsDirty(e.oldValue !== e.newValue);
    e.api.refreshCells();
  };

  const cellRendererSelector = (params: ICellRendererParams) => {
    const headerName = params?.colDef?.headerName ?? '';
    const field = userMap?.selectedFields?.find(s => { return (s.fieldName.replace(' ', '').toLowerCase().includes(headerName?.replace(' ', '')?.toLowerCase()));});

    if (selectFields.includes(params.data.field)) {
      return {
        component: agLookupRenderer,
      };
    }
    if (params.data.field === 'setFont') {
      return {
        component: () => {
          return <button
            type="button"
            className="btn btn-link p-0"
            onClick={() => {
              onShowFontModal(true, field);
            }}
          >Set Font</button>;
        },
      };
    }   
  };

  const cellRendererParams = (params: ICellRendererParams) => {
    const propName = params?.data?.field;
    if (propName === 'justification') {
      return {
        options: justifyOpts,
        addEmptyValue: true,
      };
    }
    if (propName === 'style') {
      return {
        options: styleOpts,
      };
    }
    if (propName === 'customFormatting') {
      let opts = formatTextOpts;
      if (gridApi) {
        const gridData = gridApi?.getRenderedNodes().map(n => { return n.data; });
        const styleTypes = gridData?.find(g => { return g.field === 'style'; });
        const style = styleTypes[params.column?.getColId()!];

        if (style === 'Text') {
          opts = formatTextOpts;
        } else if (style === 'Numeric') {
          opts = formatNumericOpts;
        } else if (style === 'Date') {
          opts = formatDateOpts;
        }
      }

      return {
        options:
          opts,
        addEmptyValue: true,
      };
    }
    if (propName === 'sortDirection') {
      return {
        options: sortTypeOpts,
        showId: false,
      };
    }
    if (propName === 'filterLogic') {
      return {
        options: logicOpts,
        addEmptyValue: true,
      };
    }
    if (propName === 'excelJustification') {
      return {
        options: justifyOpts,
        addEmptyValue: true,
      };
    }
    if (propName === 'excelTextCasing') {
      return {
        options: formatTextOpts,
      };
    }
    if (propName === 'groupBy') {
      return {
        options: pdfGroupByOpts,
      };
    }
    if (propName === 'groupByFunction') {
      return {
        options: pdfGroupByFNOpts,
        addEmptyValue: true,
      };
    }
    if (propName === 'showColumn') {
      return {
        options: pdfShowColumnOpts,
      };
    }
    if (propName === 'filterCondition') {
      return {
        options: filterConditionOpts,
        addEmptyValue: true,
      };
    }
  };

  const cellEditorSelector = (params: ICellEditorParams) => {
    if (selectFields.includes(params?.data?.field)) {
      return {
        component: agSelectEditor,
      };
    }
    if (params.data.field.includes('formula') && params.column?.getColId().includes('formula')) {
      const colId = params.column?.getColId();
      setCurrentformulaColumn(colId);
      setValue('excelFormula', params.value); 
    }
  };

  const cellEditorParams = (params: ICellEditorParams) => {
    if (params.data.field === 'justification') {
      return {
        options: justifyOpts,
        addEmptyValue: true,
      };
    }
    if (params.data.field === 'style') {
      return {
        options: styleOpts,
      };
    }
    if (params.data.field === 'customFormatting') {
      const gridData = gridApi?.getRenderedNodes().map(n => { return n.data; });
      const style = gridData?.find(g => { return g.field === 'style'; })[params.column?.getColId()];
      const opts = style === 'Text' ? formatTextOpts :
        style === 'Numeric' ? formatNumericOpts :
          style === 'Date' ? formatDateOpts :
            [];
      return {
        options:
          opts,
        addEmptyValue: true,
      };
    }
    if (params.data.field === 'sortDirection') {
      return {
        options: sortTypeOpts,
      };
    }
    if (params.data.field === 'filterLogic') {
      return {
        options: logicOpts,
        addEmptyValue: true,
      };
    }
    if (params.data.field === 'excelJustification') {
      return {
        options: justifyOpts,
        addEmptyValue: true,
      };
    }
    if (params.data.field === 'excelTextCasing') {
      return {
        options: formatTextOpts,
      };
    }
    if (params.data.field === 'groupBy') {
      return {
        options: pdfGroupByOpts,
      };
    }
    if (params.data.field === 'groupByFunction') {
      return {
        options: pdfGroupByFNOpts,
      };
    }
    if (params.data.field === 'showColumn') {
      return {
        options: pdfShowColumnOpts,
      };
    }
    if (params.data.field === 'filterCondition') {
      return {
        options: filterConditionOpts,
        addEmptyValue: true,
      };
    }
  };

  const onCellClicked = (params: CellClickedEvent) => {
    if (!params.column?.getColId().includes('formula')) {
      setValue('excelFormula', '');
      setCurrentformulaColumn(undefined);
    }
  };

  const lastRowBorder = (params: RowClassParams) => {
    if (params.data.field === 'selectedAreaId') return { 'display': 'none', 'border-bottom': '' };
    else if (breakAfterRowIndex.includes(params.rowIndex)) return { 'display': 'inline', 'border-bottom': '#d6d6d6 5px solid' };
    else return undefined;
  };

  const gridOptions: GridOptions = {
    defaultColDef: {
      suppressMenu: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
      singleClickEdit: true,
      resizable: true,
      editable: true,
    },
    enableCellChangeFlash: true,
    getRowStyle: lastRowBorder,
    rowSelection: 'single',
    components: {
      lookupRenderer: agLookupRenderer,
      selectEditor: agSelectEditor,
    },
  };

  return (
    <form>
      <div className="d-flex justify-content-end mt-2 mb-2">
        <div className="d-flex flex-row">
          <button
            type="button"
            className="btn btn-primary orange-outline-button mr-2"
            onClick={() => { closeModal(); }}
          >Cancel</button>
          <button
            type="button"
            onClick={() => { return onSubmit(); }}
            disabled={!formState.isDirty && !gridIsDirty}
            className="btn btn-primary orange-button"
          >Save</button>
        </div>
      </div>
      <div className="dm-panel dm-panel-border">
        <div className="d-flex flex-row justify-content-between">
          <div className="flex-grow-1 mr-4 text-center">
            <div>Excel Only Left Header</div>
            <InputGrp
              name="headerFooter.headerLeft"
              ref={register()}
            />
          </div>
          <div className="flex-grow-1 mr-4 text-center">
            <div>Excel Only Center Header</div>
            <InputGrp
              name="headerFooter.headerCenter"
              ref={register()}
            />
          </div>
          <div className="flex-grow-1 text-center">
            <div>Excel Only Right Header</div>
            <InputGrp
              name="headerFooter.headerRight"
              ref={register()}
            />
          </div>
        </div>
        <div className="d-flex flex-row">
          <div>
            <h3>Modify Format</h3>
            <ul>
              <li>Select a cell to Customize</li>
              <li>Re-order columns by dragging column headings</li>
            </ul>
          </div>
          <div>
            <div className="w-100 py-3 px-5">
              <button
                type="button"
                className="btn btn-link dm-grid-action-title py-0"
                onClick={() => { onShowDownloadFontModal(false); }}
              >
                Set Download Font{' '}
                <Icon
                  name="font"
                  style={{
                    height: '15px',
                  }}
                />
              </button>
            </div>
          </div>
          <div>
            <div
              className="w-100 py-3 px-5"
              onClick={onInsertNewExcelFormula}
            >
              <button
                type="button"
                className="btn btn-link dm-grid-action-title py-0"
              >
                Insert Excel Formula{' '}
                <Icon
                  name="function"
                  style={{
                    height: '15px',
                  }}
                />
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row mb-2">
          <div
            className="mr-2"
            style={{ width: 185 }}
          >Preview Excel Formula</div>
          <InputGrp
            groupClass="w-100"
            name="excelFormula"
            onChange={insertFormula}
            disabled={!currentformulaColumn}
            ref={register()}
          />
        </div>
        <div
          className="ag-theme-balham mb-4"
          style={{ height: '420px' }}
        >
          {rowData?.length ?
            <AgGridReact
              columnDefs={columnDefs}
              gridOptions={gridOptions}
              rowData={rowData}
              onGridReady={onGridReady}
              onCellClicked={onCellClicked}
              onCellValueChanged={onCellValueChanged}
              stopEditingWhenCellsLoseFocus={true}
            /> : null}
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="flex-grow-1 mr-4 text-center">
            <div>Excel Only Left Footer</div>
            <InputGrp
              name="headerFooter.footerLeft"
              ref={register()}
            />
          </div>
          <div className="flex-grow-1 mr-4 text-center">
            <div>Excel Only Center Footer</div>
            <InputGrp
              name="headerFooter.footerCenter"
              ref={register()}
            />
          </div>
          <div className="flex-grow-1 text-center">
            <div>Excel Only Right Footer</div>
            <InputGrp
              name="headerFooter.footerRight"
              ref={register()}
            />
          </div>
        </div>
      </div>
      {showFontModal ? <div>
        <SetFontModal
          isHeaderFont={isHeaderFont}
          show={showFontModal}
          onHide={() => { return setShowFontModal(false); }}
          setFont={(font) => { return onSetDownloadFont(font, selectedField?.selectedAreaId); }}
          field={selectedField}
          font={userMap.font}
        />
      </div> : null}

    </form>
  );
};

export default FormatExcelForm;