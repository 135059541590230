import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ControlDatePickerGrp,
  InputGrp,
  InputGrpCurrency,
  RadioGrp,
  SelectGrp,
} from 'core/components/form-controls';
import { FieldInputSettings } from 'core/components/form-controls/types';
import PanelHeader from 'core/components/shared/PanelHeader';
import { CURRENCY_VALIDATION, ControlIds, UNSAVED_MESSAGE } from 'core/constants';
import { EmpGroupTerm, Employee } from 'core/models';
import {
  loadEmployeeGroupTerm,
  toggleBlockNavigation,
  updateEmployeeGroupTerm,
} from 'core/store/actions';
import {
  getGroupTermLifeEarningsCodes,
  getEmpGroupTerm,
  getSelectedEmp,
} from 'core/store/selectors';
import { convToFormObject } from 'utilities/classUtils';
import { useAppSelector, useRadioButtonChange } from 'utilities/hooks';
import { getAccess } from 'utilities/utilities';
import SaveButton from 'core/components/shared/SaveButton';

const GroupTermLifePage: React.FC<any> = () => {
  const [fs, setFs] = useState<FieldInputSettings>({
    startDate: {
      name: 'startDate',
      label: 'START DATE',
      groupClass: 'groupClass10',
      controlId: 200,
    },
    multiplier: {
      name: 'multiplier',
      type: 'number',
      step: '0.01',
      label: 'MULTIPLE OF SALARY',
      groupClass: 'groupClass12',
      inputStyle: { textAlign: 'right' },
      controlId: 201,
    },
    amountOfIns: {
      name: 'amountOfIns',
      label: 'AMOUNT OF GROUP TERM LIFE',
      groupClass: 'groupClass15',
      controlId: 202,
    },
    age: {
      name: 'age',
      type: 'number',
      label: 'AGE AT END OF YEAR',
      groupClass: 'groupClass10',
      inputStyle: { textAlign: 'right' },
      controlId: 203,
    },
    monthlyCostPerThousand: {
      name: 'monthlyCostPerThousand',
      label: 'MONTHLY COST PER 1000',
      groupClass: 'groupClass12',
      controlId: 204,
    },
    excessIns: {
      name: 'excessIns',
      label: 'EXCESS GROUP TERM LIFE INS',
      groupClass: 'groupClass12',
      controlId: 205,
    },
    annualCost: {
      name: 'annualCost',
      label: 'ANNUAL COST',
      groupClass: 'groupClass12',
      controlId: 206,
    },
    perPayAmount: {
      name: 'perPayAmount',
      label: 'DEDUCTION PER PAY',
      groupClass: 'groupClass15',
      controlId: 207,
    },
    earningsCode: {
      name: 'earningsCode',
      label: 'EARNINGS CODE',
      groupClass: 'groupClass20',
      controlId: 208,
    },
    autoAddToRecurringEarns: {
      name: 'autoAddToRecurringEarns',
      label: 'AUTOMATICALLY ADD TO RECURRING EARNINGS',
      groupClass: 'groupClass20',
      controlId: 209,
    },
  });

  const [handleRadioButtonChange] = useRadioButtonChange(fs);

  const dispatch = useDispatch();
  
  const { protectedEmpNo } = useParams<any>();
  
  const groupTermLifeFromStore = useSelector(getEmpGroupTerm);
  const selEmp = useSelector(getSelectedEmp) as Employee;
  const earningsCodeOpts = useSelector(getGroupTermLifeEarningsCodes);
  const { saving } = useAppSelector((state) => state.selEmployeeDetails.empGroupTerm);
  const sectionAccess = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections);
  const visible = getAccess(sectionAccess, 32)?.visible;
  const [addRecurringDisabled, setDisableAddRecurring] = useState<boolean>(true);

  const {
    errors,
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    control,
    formState,
  } = useForm<EmpGroupTerm>({
    defaultValues: groupTermLifeFromStore,
  });
  const { isDirty } = formState;
  const { startDate } = watch(['startDate']);

  useEffect(() => {
    if (protectedEmpNo) {
      dispatch(loadEmployeeGroupTerm({ protectedEmpNo }));
    }
  }, [protectedEmpNo]);

  useEffect(() => {
    groupTermLifeFromStore &&
            reset({
              ...convToFormObject(
                groupTermLifeFromStore,
                EmpGroupTerm.convProps,
              ),
            } as EmpGroupTerm);
    setDisableAddRecurring(
      !groupTermLifeFromStore?.earningsCode ||
                groupTermLifeFromStore?.earningsCode === ' ',
    );

    const currentState = { ...fs };
    currentState.autoAddToRecurringEarns.defaultChecked =
            groupTermLifeFromStore.autoAddToRecurringEarns;
    setFs(currentState);
  }, [groupTermLifeFromStore]);

  const onSave = (data: any) => {
    const groupTermLife = {
      ...new EmpGroupTerm(selEmp.empId, selEmp.protectedEmpNo, data),
    };

    groupTermLife.annualRate = groupTermLifeFromStore.annualRate;
    groupTermLife.wageCap = groupTermLifeFromStore.wageCap;
    groupTermLife.rounding = groupTermLifeFromStore.rounding;
    groupTermLife.protectedClientNo =
            groupTermLifeFromStore.protectedClientNo;
    groupTermLife.multiplier = +groupTermLife.multiplier;

    dispatch(updateEmployeeGroupTerm(groupTermLife));
  };

  const handleEarningChange = (e: any) => {
    const disabled = e.target.value === null || e.target.value === '';
    setDisableAddRecurring(disabled);
    if (disabled) {
      const currentState = { ...fs };
      currentState.autoAddToRecurringEarns.defaultChecked = 'false';
      setFs(currentState);
    }
  };
  
  useEffect(() => {
    dispatch(toggleBlockNavigation({
      block: isDirty,
      message: UNSAVED_MESSAGE,
      type: 'confirmation',
    }));
  }, [isDirty]);

  return (
    <div className="dm-panel dm-panel-border">
      {visible && (
        <>
          <PanelHeader title="Group Term Life" />
          <form
            onSubmit={handleSubmit(onSave)}
            autoComplete="off"
          >
            <div className="d-flex flex-wrap mt-3">
              <ControlDatePickerGrp
                {...fs.startDate}
                {...getAccess(sectionAccess, 32, ControlIds.startDate)}
                errors={errors.startDate}
                setValue={setValue}
                control={control}
              />
              <InputGrp
                {...fs.multiplier}
                {...getAccess(sectionAccess, 32, ControlIds.multiplier)}
                errors={errors.multiplier}
                ref={register}
              />
              <InputGrpCurrency
                {...fs.amountOfIns}
                {...getAccess(sectionAccess, 32, ControlIds.amountOfIns)}
                errors={errors.amountOfIns}
                setValue={setValue}
                ref={register(CURRENCY_VALIDATION)}
              />
              <InputGrp
                {...fs.age}
                {...getAccess(sectionAccess, 32, ControlIds.age, { readOnlyDefault: true })}
                errors={errors.age}
                ref={register}
              />
              <InputGrpCurrency
                {...fs.monthlyCostPerThousand}
                {...getAccess(sectionAccess, 32, ControlIds.monthlyCostPerThousand, { readOnlyDefault: true })}
                errors={errors.monthlyCostPerThousand}
                setValue={setValue}
                ref={register(CURRENCY_VALIDATION)}
              />
              <InputGrpCurrency
                {...fs.excessIns}
                {...getAccess(sectionAccess, 32, ControlIds.excessIns, { readOnlyDefault: true })}
                errors={errors.excessIns}
                setValue={setValue}
                ref={register(CURRENCY_VALIDATION)}
              />
              <InputGrpCurrency
                {...fs.annualCost}
                {...getAccess(sectionAccess, 32, ControlIds.annualCost, { readOnlyDefault: true })}
                errors={errors.annualCost}
                setValue={setValue}
                ref={register(CURRENCY_VALIDATION)}
              />
              <InputGrpCurrency
                {...fs.perPayAmount}
                {...getAccess(sectionAccess, 32, ControlIds.perPayAmount, { readOnlyDefault: true })}
                errors={errors.perPayAmount}
                setValue={setValue}
                ref={register(CURRENCY_VALIDATION)}
              />
            </div>
            <div className="d-flex flex-wrap mt-3">
              <SelectGrp
                {...fs.earningsCode}
                {...getAccess(sectionAccess, 32, ControlIds.earningsCode)}
                errors={errors.earningsCode}
                ref={register}
                onChange={handleEarningChange}
                options={earningsCodeOpts}
                addEmptyValue={true}
              />
              <RadioGrp
                {...fs.autoAddToRecurringEarns}
                {...getAccess(sectionAccess, 32, ControlIds.autoAddToRecurringEarnings)}
                errors={errors.autoAddToRecurringEarns}
                ref={register}
                disabled={addRecurringDisabled}
                onChange={handleRadioButtonChange}
              />
            </div>
            <div className="row">
              <div className="col-12 text-right">
                <SaveButton
                  {...getAccess(sectionAccess, 32, undefined, { disabledDefault: !isDirty, disabledSameAsReadOnly: true })}
                  type="submit"
                  classes="btn orange-button-sm mr-3"
                  disabled={!isDirty}
                  saving={saving}
                />
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default GroupTermLifePage;
