import React, {
  CSSProperties,
  Dispatch,
  SetStateAction,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
  ChangeEvent,
  FocusEvent,
} from 'react';
import { InputGrp, SelectGrp } from 'core/components/form-controls';
import EmpPhoto from 'core/components/shared/EmpPhoto';
import Icon from 'core/components/shared/Icon';
import {
  Employee,
  TaxCode,
  TimeCardMainTotals,
  TimeCardWithholding,
  TimeCardWithholdingRefObject,
} from 'core/models';
import DeductionsModal from './modals/Deductions.modal';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import DetailHoursNotesModal from './modals/DetailHoursNotes.modal';
import CheckCodeModal from './modals/CheckCode.modal';
import { Modal, Spinner } from 'react-bootstrap';
import { getCurrentCheckCodes } from './utilities';
import { Link } from 'react-router-dom';
import { storePrevTimeCardLink } from 'core/store/actions';
import { formatWithCommas } from 'utilities/utilities';
import { TimeCardTotal } from './TimeCardTotal';
import { TimeCardContext } from './TimeCardContext';

const infoGrpStyle: CSSProperties = {
  marginBottom: '0',
  maxWidth: '100px',
  minWidth: '70px',
};

type Props = {
  employee: Employee;
  withholdingState: TimeCardWithholding[];
  addNewTimeSheet: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  totals: TimeCardMainTotals;
};

const frequencyOpts = [
  { id: 'W', description: 'Weekly' },
  { id: 'B', description: 'Biweekly' },
  { id: 'S', description: 'Semi-monthly' },
  { id: 'M', description: 'Monthly' },
];

const fanOpts = [
  { id: null },
  { id: 'F' },
  { id: 'A' },
  { id: 'N' },
  { id: '%' },
];

const updateWithholding = (
  property: keyof TimeCardWithholding,
  newValue: string | number,
  set: Dispatch<SetStateAction<TimeCardWithholding | null>>,
) => {
  set((prevState) => {
    if (!prevState?.taxCode) return null;
    return {
      ...prevState,
      transmittalTimeCardId: prevState?.transmittalTimeCardId ?? 0,
      transmittalTimeCardWithholdingId: prevState?.transmittalTimeCardWithholdingId ?? 0,
      amount: prevState?.amount ?? 0, // keep this formatted in other updates
      [property]: newValue,
    };
  });
};

const TimeCardHeader = forwardRef<TimeCardWithholdingRefObject, Props>(({ employee, withholdingState, addNewTimeSheet, onDelete, totals }, ref) => {
  const { timeCards, currentTimeCard, homeAtsInfo } = useAppSelector(({ contractor }) => contractor);
  const { latestPayrollId } = useAppSelector(({ payroll }) => payroll);

  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  const [showCheckCodeModal, setShowCheckCodeModal] = useState<boolean>(false);
  const [showDeductionsModal, setShowDeductionsModal] = useState<boolean>(false);
  const [fedWithholding, setFedWithholding] = useState<TimeCardWithholding | null>(null);
  const [stateWithholding, setStateWithholding] = useState<TimeCardWithholding | null>(null);
  const [localWithholding, setLocalWithholding] = useState<TimeCardWithholding | null>(null);
  const [showTotalDetail, setShowTotalDetail] = useState<boolean>(false);
  
  const dispatch = useAppDispatch();
  
  /* will give the parent the updated withholding values. See comment on TimeCardWithholdingRefObject in
  ContractorTimeCardPage for more details. */
  useImperativeHandle(ref, () => {
    return {
      getWithholdingValues() {
        return [fedWithholding, stateWithholding, localWithholding];
      },
    };
  }, [fedWithholding, stateWithholding, localWithholding]); // we want to rebuild that returned array whenever these change
  
  const { formMethods, updateDirtyState, isSaving, isDirty } = useContext(TimeCardContext);
  const { register, watch } = formMethods;
  
  const watchedCheckCode: string = watch('checkCode');
  
  const newWithholding = (taxCode: TaxCode): TimeCardWithholding => {
    return {
      transmittalTimeCardId: currentTimeCard?.transmittalTimeCardId ?? 0,
      transmittalTimeCardWithholdingId: 0,
      taxCode: taxCode,
      fan: '',
      amount: 0,
    };
  };
  
  useEffect(() => {
    const fedWh = withholdingState?.find((x) => x.taxCode === 'FD') ?? newWithholding('FD');
    const stateWh = withholdingState?.find((x) => x.taxCode === 'ST') ?? newWithholding('ST');
    const localWh = withholdingState?.find((x) => x.taxCode === 'CT') ?? newWithholding('CT');
    
    setFedWithholding({ ...fedWh, amount: formatWithCommas(parseFloat(String(fedWh.amount))) });
    setStateWithholding({ ...stateWh, amount: formatWithCommas(parseFloat(String(stateWh.amount))) });
    setLocalWithholding({ ...localWh, amount: formatWithCommas(parseFloat(String(localWh.amount))) });
  }, [withholdingState]);
  
  const formatOnBlur = (e: FocusEvent<HTMLInputElement, Element>, withholdingType: keyof TimeCardWithholding, setWithholding: React.Dispatch<React.SetStateAction<TimeCardWithholding | null>>) => {
    if (isNaN(parseFloat(e.target.value))) {
      e.target.value = '0.00';
      return;
    }

    //PI-8596 Set the value as the formatted value so it does not reset to the unformatted version when changining a diffrent field. 
    e.target.value = formatWithCommas(parseFloat(e.target.value));
    updateWithholding(withholdingType, e.target.value, setWithholding);
  };
  
  return (
    <>
      {showCheckCodeModal && (
        <CheckCodeModal
          show={showCheckCodeModal}
          onHide={() => { setShowCheckCodeModal(false); }}
          currentEmpCheckCodes={getCurrentCheckCodes(timeCards, employee?.empNo)}
        />
      )}
      {showDeductionsModal && (
        <DeductionsModal
          show={showDeductionsModal}
          onHide={() => { setShowDeductionsModal(false); }}
        />
      )}
      {showNotesModal && (
        <DetailHoursNotesModal
          show={showNotesModal}
          onHide={() => { setShowNotesModal(false); }}
        />
      )}
      <div className="time-card-header">
        <div className="card-header-row">
          <div className="emp-main-info">
            <EmpPhoto
              empPicData={employee.employeePhoto}
              empName={`${employee.firstName} ${employee.lastName}`}
              scaleFactor="65"
            />
            <div className="d-flex flex-column">
              {/* 
                Makes the employee's name a link; this'll store the link back to the time card in redux and watch the
                route with NavigationMonitor.tsx. That said, the way currentTimeCard is stored this isn't strictly
                necessary (for now). Just adding it so all of the routes match and in case we change things in the 
                future.
              */}
              {latestPayrollId ? (
                <Link
                  to={{
                    pathname: `/employee/detail/${employee.protectedEmpNo}/snapshot`,
                    state: { prevPath: `/contractor-time-sheets?payrollHistoryId=${latestPayrollId}` },
                  }}
                  onClick={() => {
                    dispatch(storePrevTimeCardLink(`/contractor-time-sheets?payrollHistoryId=${latestPayrollId}`));
                  }}
                  style={{ textDecoration: 'underline' }}
                >
                  <span className="dm-grid-title emp-name mb-1">{employee.firstName}&nbsp;{employee.lastName}</span>
                </Link>
              ) : (
                <span className="dm-grid-title emp-name mb-1">{employee.firstName}&nbsp;{employee.lastName}</span>
              )}
              <div className="header-info-section">
                <span className="font-weight-bold">Emp No:&nbsp;{employee.empNo}</span>
                <span className="font-weight-bold">Area:&nbsp;{homeAtsInfo?.area ?? 'N/A'}</span>
                <span className="font-weight-bold">Trade:&nbsp;{homeAtsInfo?.trade ?? 'N/A'}</span>
                <span className="font-weight-bold mr-3">Sub:&nbsp;{homeAtsInfo?.sub ?? 'N/A'}</span>
                <span className="font-weight-bold">{homeAtsInfo?.description ?? 'N/A'}</span>
              </div>
            </div>
          </div>
          <div className="d-flex ml-auto mb-auto mt-2">
            <button
              className="btn orange-button mr-3"
              style={{ width: '105px' }}
              type="submit"
              disabled={!isDirty || isSaving || !timeCards?.length}
            >
              {isSaving ? (
                <>
                  <span className="mr-1">Saving&nbsp;</span>
                  <Spinner
                    animation={'border'}
                    size="sm"
                  />
                </>
              ) : 'Save'}
            </button>
            <button
              className="btn orange-outline-button mr-3"
              onClick={onDelete}
              disabled={isSaving || !timeCards?.length}
              style={{ width: '105px' }}
            >
              Delete
            </button>
          </div>
        </div>
        <div className="d-flex">
          <div className="card-header-row justify-content-between">
            <div className="header-info-section">
              <InputGrp
                name="checkCode"
                label="Check Code"
                ref={register()}
                value={watchedCheckCode}
                groupStyle={infoGrpStyle}
                inputStyle={{ height: '26px' }}
                onClick={() => { setShowCheckCodeModal(true); }}
              />
              <SelectGrp
                name="frequency"
                label="Frequency"
                ref={register()}
                defaultValue={currentTimeCard?.frequency}
                options={frequencyOpts}
                onChange={(e: any) => { updateDirtyState(true); }}
                groupStyle={{ ...infoGrpStyle, maxWidth: '110px' }}
                selectStyles={{ height: '26px' }}
              />
              <SelectGrp
                label="Fed FAN%"
                options={fanOpts}
                labelField="id"
                groupStyle={infoGrpStyle}
                selectStyles={{ height: '26px' }}
                value={fedWithholding?.fan ?? null}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateWithholding('fan', e.target.value, setFedWithholding);
                  updateDirtyState(true);
                }}
                required={(fedWithholding?.amount && +fedWithholding.amount !== 0) ? true : false}
              />
              <InputGrp
                label="Fed Tax"
                groupStyle={infoGrpStyle}
                inputStyle={{ height: '26px', textAlign: 'right' }}
                value={fedWithholding?.amount ?? ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateWithholding('amount', e.target.value, setFedWithholding);
                  updateDirtyState(true);
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => formatOnBlur(e, 'amount', setFedWithholding)}
              />
              <SelectGrp
                label="State FAN%"
                options={fanOpts}
                labelField="id"
                groupStyle={infoGrpStyle}
                selectStyles={{ height: '26px' }}
                value={stateWithholding?.fan ?? null}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateWithholding('fan', e.target.value, setStateWithholding);
                  updateDirtyState(true);
                }}
                required={(stateWithholding?.amount && +stateWithholding.amount !== 0) ? true : false}
              />
              <InputGrp
                label="State Tax"
                groupStyle={infoGrpStyle}
                inputStyle={{ height: '26px', textAlign: 'right' }}
                value={stateWithholding?.amount ?? ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateWithholding('amount', e.target.value, setStateWithholding);
                  updateDirtyState(true);
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => formatOnBlur(e, 'amount', setStateWithholding)}
              />
              <SelectGrp
                label="Local FAN%"
                options={fanOpts}
                labelField="id"
                groupStyle={infoGrpStyle}
                selectStyles={{ height: '26px' }}
                value={localWithholding?.fan ?? null}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateWithholding('fan', e.target.value, setLocalWithholding);
                  updateDirtyState(true);
                }}
                required={(localWithholding?.amount && +localWithholding.amount !== 0) ? true : false}
              />
              <InputGrp
                label="Local Tax"
                groupStyle={infoGrpStyle}
                inputStyle={{ height: '26px', textAlign: 'right' }}
                value={localWithholding?.amount ?? ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateWithholding('amount', e.target.value, setLocalWithholding);
                  updateDirtyState(true);
                }}
                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => formatOnBlur(e, 'amount', setLocalWithholding)}
              />
              <div
                className="d-flex flex-column"
                style={{ minWidth: '50px' }}
              >
                <span className="dm-form-label mt-1">No Fica</span>
                <input
                  type="checkbox"
                  name="blockFica"
                  className="mr-3 mt-1"
                  defaultChecked={currentTimeCard?.blockFica}
                  ref={register()}
                  onChange={() => { updateDirtyState(true); }}
                />
              </div>
              <div className="d-flex flex-column pt-2">
                <div className="time-sheet-total-header">
                  <strong>
                    <button
                      type="button"
                      className="btn-link link-button font-weight-bold"
                      onClick={() => {
                        setShowTotalDetail(true);
                      }}
                    >
                      Total Hours: {formatWithCommas(totals.hoursTotal)}
                    </button>
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap w-50 justify-content-end">
            <button
              className="btn btn-link dm-grid-action-title pb-0"
              onClick={(e) => {
                e.preventDefault();
                setShowDeductionsModal(true);
                e.stopPropagation();
              }}
            >
              View Deductions
            </button>
            <button
              className="btn btn-link dm-grid-action-title pb-0"
              onClick={(e) => {
                e.preventDefault();
                setShowNotesModal(true);
                e.stopPropagation();
              }}
            >
              Show Notes <Icon name="notes" />
            </button>            
            <button
              className="btn btn-link dm-grid-action-title pb-0"
              onClick={addNewTimeSheet}
            >
              Add Time Entry <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={showTotalDetail}
        backdrop="static"
        centered={true}
        onHide={() => {return setShowTotalDetail(false);}}
        size="sm"
      >
        <Modal.Header className="border-0">
          <button
            type="button"
            className="modal-close-btn"
            onClick={() => {return setShowTotalDetail(false);}}
          >
            <Icon name="times" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <TimeCardTotal
            details={totals}
            onHide={() => {return setShowTotalDetail(false);}}
          />
        </Modal.Body>
      </Modal>
    </>
  );
});

TimeCardHeader.displayName = 'TimeCardHeader';

export default TimeCardHeader;