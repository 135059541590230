/* eslint-disable object-property-newline */
import { AllModules, ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent, RowClickedEvent, RowNode, RowSelectedEvent,  ValueSetterParams } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from '@ag-grid-community/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { createApClientVendor, loadApClientVendors, updateApClientVendor } from 'core/store/actions';
import { ApClientVendor } from 'core/models';
import { getApClientVendors, getGarnishmentEntity, getStates } from 'core/store/selectors';
import { useForm } from 'react-hook-form';
import { FieldInputSettings } from 'core/components/form-controls/types';
import { CheckboxGrpInLine, InputGrp, SelectGrp, SelectGrpInLine } from 'core/components/form-controls';
import Icon from 'core/components/shared/Icon';
import CheckboxRenderer from 'utilities/ag-grid-renderers/CheckboxRenderer';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';

const fs: FieldInputSettings = {
  name: {
    name: 'name',
    label: 'PAY TO',
    groupClass: 'groupClass95',
    labelWidth: 15,
    required: true,
  },
  address1: {
    name: 'address1',
    label: 'MAIL TO',
    modalTitle: 'ENTITIES',
    groupClass: 'groupClass95',
    labelWidth: 15,
    required: true,
  },
  address2: {
    name: 'address2',
    label: 'ADDRESS',
    groupClass: 'groupClass95',
    labelWidth: 15,
    required: true,
  },
  city: {
    name: 'city',
    label: 'CITY',
    groupClass: 'groupClass95',
    labelWidth: 15,
    required: true,
  },
  state: {
    name: 'state',
    label: 'STATE',
    groupClass: 'groupClass95',
    labelWidth: 15,
    required: true,
  },
  zipCode: {
    name: 'zipCode',
    label: 'ZIP CODE',
    groupClass: 'groupClass30',
    labelWidth: 15,
    required: true,
  },
  zipCodePlus4: {
    name: 'zipCodePlus4',
    label: ' ',
    groupClass: 'groupClass10 pr-4',
    labelWidth: 10,
  },
  apVendorId: {
    name: 'apVendorId',
    label: 'Currently Selected Vendor ID:',
    groupClass: 'groupClass95',
    labelWidth: 60,
  },
  agencyId: {
    name: 'agencyId',
    label: 'AGENCY',
    groupClass: 'groupClass95',
    labelWidth: 30,
  },
  categoryId: {
    name: 'categoryId',
    label: 'CATEGORY',
    groupClass: 'groupClass95',
    labelWidth: 30,
    noOption: true,
    addOptionText: 'Category',
  },
  entityId: {
    name: 'entityId',
    label: 'ENTITY',
    groupClass: 'groupClass95',
    labelWidth: 30,
    noOption: true,
    addOptionText: 'Entity',
  },
  visible: {
    name: 'visible',
    label: 'Visible:',
    groupClass: 'groupClass20',
  },
};

type PropTypes = {
  apid: number;
  categoryId: number | undefined;
  entityId: number;
  categoryOpts: any[];
  show: boolean;
  onHide: (apid?: number) => void;
};

const APVendorsModal: React.FC<PropTypes> = ({ apid, categoryId, entityId, categoryOpts, show, onHide }) => {
  const dispatch = useAppDispatch();
  
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const [rowData, setRowData] = useState<ApClientVendor[]>([]);
  const [selectedApVendor, setSelectedApVendor] = useState<ApClientVendor | null>(null);
  const [apVendorId, setApVendorId] = useState(apid || '0');
  const [addVendor, setAddVendor] = useState(false);
  const [showViewAll, setShowViewAll] = useState(false);
  
  const apClientVendors = useSelector(getApClientVendors);
  const stateOpts = useSelector(getStates);
  const entityOpts = useSelector(getGarnishmentEntity);
  const isSuperUser = useAppSelector((state) => { return state.auth.userAccess?.superUser; });
  
  const { register, errors, handleSubmit, reset, setValue, watch } = useForm<ApClientVendor>({
    defaultValues: {
      categoryId: categoryId,
      entityId: entityId,
    },
  });
  
  const visible = watch('visible');
  
  // TODO: a cleaner way to handle this will need a bigger rewrite, but this'll behave the same way. 
  const updatingVisibleRef = useRef<boolean>(false); // for tracking visibility updates for super users
  
  const updateVendorVisibility = (vendorId: number, newValue: boolean) => {
    // needs to be done inside state setter callback to match values (see dept. allocations for a similar case)
    setRowData((prevState) => {
      const matchedVendor = prevState.find((vendor) => { return vendor.apVendorId === vendorId; });
      if (!matchedVendor) {
        console.error('Error: Vendor not found when updating row');
        return prevState;
      }
      dispatch(updateApClientVendor({ ...matchedVendor, visible: newValue }));
      return prevState;
    });
    updatingVisibleRef.current = true;
    return true; // tell valueSetter to update the value
  };
  
  const columns: ColDef[] = useMemo(() => {
    return [{
      field: 'apVendorId',
      headerName: 'Vendor ID',
      width: 110,
      sortable: true,
    }, {
      field: 'name',
      headerName: 'Pay To',
      width: 210,
      sortable: true,
    }, {
      field: 'address1',
      headerName: 'Address 1',
      width: 210,
      sortable: true,
    }, {
      field: 'address2',
      headerName: 'Address 2',
      width: 160,
      sortable: true,
    }, {
      field: 'city',
      headerName: 'city',
      width: 110,
      sortable: true,
    }, {
      field: 'state',
      headerName: 'State',
      width: 80,
      sortable: true,
    }, {
      field: 'zipCode',
      headerName: 'Zip',
      width: 80,
      sortable: true,
    }, {
      field: 'zipCodePlus4',
      headerName: 'Zip 4',
      width: 80,
      sortable: true,
    }, {
      field: 'agencyId',
      headerName: 'Agency ID',
      hide: true, // just hidden by default and added conditionally for superusers
      width: 100,
      sortable: true,
    }, {
      field: 'categoryId',
      headerName: 'Category ID',
      hide: true, 
      width: 115,
      sortable: true,
    }, {
      field: 'entityId',
      headerName: 'Entity ID',
      hide: true, 
      width: 100,
      sortable: true,
    }, {
      field: 'visible',
      headerName: 'Visible',
      hide: true, 
      sortable: true,
      cellRenderer: 'checkboxRenderer',
      valueSetter: (params: ValueSetterParams) => {
        const vendor: ApClientVendor = params.data;
        return updateVendorVisibility(vendor.apVendorId, params.newValue);
      },
      width: 90,
    }];
  }, []);

  const gridOptions: GridOptions = {
    columnDefs: columns,
    defaultColDef: {
      suppressMenu: false,
      resizable: true,
      singleClickEdit: true,
      cellClass: 'ag-cell-left-border',
      headerClass: 'grid-header',
      filter: 'agTextColumnFilter',
    },
    components: {
      checkboxRenderer: CheckboxRenderer,
    },
    rowSelection: 'single',
    pagination: true,
    paginationPageSize: 10,
  };

  useEffect(() => {
    dispatch(loadApClientVendors());
  }, []);

  useEffect(() => {
    if (apClientVendors?.length && !updatingVisibleRef?.current) {
      setRowData(apClientVendors);
      if (categoryId || entityId) {
        setShowViewAll(!!isSuperUser);
        setValue('categoryId', categoryId);
        setValue('entityId', entityId);
        const data = apClientVendors.filter(v => {
          return (isSuperUser ? true : v.visible) && v.entityId === entityId && v.categoryId === categoryId;
        });
        setRowData(data);
      }
      const apClientVendor = apClientVendors.find(v => { return v.apVendorId === apid; });
      if (apClientVendor) {
        reset({ ...apClientVendor, categoryId: categoryId, entityId: entityId });
        setSelectedApVendor(apClientVendor);
        setApVendorId('' + apClientVendor.apVendorId);
        setShowViewAll(!!isSuperUser);
      }
    }
  }, [apClientVendors]);

  useEffect(() => {
    if (!(columnApi && gridApi)) return;
    columnApi.setColumnsVisible(['agencyId', 'categoryId', 'entityId', 'visible'], !!isSuperUser);
    gridApi.sizeColumnsToFit();
  }, [gridApi, columnApi, isSuperUser]);

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
    setColumnApi(event.columnApi);
  };

  const onFirstDataRendered = () => {
    gridOptions?.api?.forEachNode((node: RowNode) => {
      if (node.data.apVendorId === apid) {
        node.setSelected(true);
      }
    });
  };

  const onRowSelected = (event: RowClickedEvent) => {
    const newVendor: ApClientVendor = event.node.data;
    setSelectedApVendor(newVendor);
    setApVendorId('' + newVendor.apVendorId);
    setAddVendor(false);
    reset({ ...newVendor });
  };

  const onRowDoubleClicked = (event: RowSelectedEvent) => {
    setSelectedApVendor(event.node.data);
    onHide(selectedApVendor?.apVendorId);
  };

  const onVendorIdClicked = () => {
    onHide(selectedApVendor?.apVendorId);
  };

  const onSave = (data: ApClientVendor) => {
    updatingVisibleRef.current = false;
    
    if (addVendor) {
      const newVendor = new ApClientVendor(selectedApVendor?.apVendorId || 0, selectedApVendor?.apClientId || 0, data);
      newVendor.agencyId = 10;
      if (!isSuperUser) {
        newVendor.categoryId = categoryId ?? 0;
        newVendor.entityId = entityId;
      }
      newVendor.visible = visible;
      dispatch(createApClientVendor(newVendor));
      setAddVendor(false);
    }
  };

  const onAdd = () => {
    setAddVendor(true);
    const newVendor = new ApClientVendor(0, selectedApVendor?.apClientId || 0);
    setSelectedApVendor(newVendor);
  };

  const onViewAll = () => {
    setRowData(apClientVendors);
    setShowViewAll(false);
  };

  const onViewCurrent = () => {
    setShowViewAll(true);
    const vendors = apClientVendors
      .filter(v => {
        return v.agencyId === 10 &&
          (v.categoryId === categoryId || 0) && (v.entityId === entityId || 0);
      });
    setRowData(vendors);
  };

  const closeModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onHide(selectedApVendor?.apVendorId);
  };

  return (
    <div onClick={(e) => { e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        animation={false}
      >
        <div className="col-12 text-right">        
          <button
            onClick={closeModal}
            className="modal-close-btn"
          >
            <Icon name="times" />
          </button>
        </div>
        <div className="modal-header d-flex flex-row" >
          <div className="d-flex flex-wrap flex-column dm-card-title">Deduction AP Vendor</div>
          {isSuperUser ? (
            <>
              {showViewAll ? (
                <div className="d-flex flex-wrap flex-column" >
                  <button
                    type="button"
                    className="btn btn-link dm-grid-action-title d-flex align-items-center"
                    onClick={() => { return onViewAll(); }}
                  >
                    View All
                    <Icon
                      name="eye"
                      className="fa-eye p-0 ml-1"
                    />
                  </button>
                </div>
              ) : (
                <div className="d-flex flex-wrap flex-column" >
                  <button
                    type="button"
                    className="btn btn-link dm-grid-action-title d-flex align-items-center"
                    onClick={() => { return onViewCurrent(); }}
                  >
                    View Current
                    <Icon
                      name="eye"
                      className="fa-eye p-0 ml-1"
                    />
                  </button>
                </div>)}
            </>
          ) : null}
          <div className="d-flex flex-wrap flex-column text-right" >
            <button
              type="button"
              className="btn btn-link dm-grid-action-title mr-4 d-flex align-items-center"
              onClick={() => { return onAdd(); }}
            >Add New AP Vendor
              <Icon
                name="plus-circle"
                className="fa-plus-circle p-0 ml-1"
              />
            </button>
          </div>
        </div>
        <Modal.Body>
          <form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSave)(e);
            e.stopPropagation();
          }}
          >
            <div className="d-flex flex-row">
              <div
                className="d-flex flex-wrap flex-column"
                style={{ flex: '2' }}
              >
                <span className="dm-card-subtitle2 pb-2">{addVendor ? 'Add New AP Vendor Information' : 'Selected AP Vendor Information'}</span>
                <InputGrp
                  {...fs.name}
                  errors={errors.name}
                  ref={register({ required: 'Pay To is required' })}
                />
                <InputGrp
                  {...fs.address1}
                  errors={errors.address1}
                  ref={register({ required: 'Mail To is required' })}
                />
                <InputGrp
                  {...fs.address2}
                  errors={errors.address2}
                  ref={register({ required: 'Address is required' })}
                />
                <div className="d-flex flex-row pr-3">
                  <InputGrp
                    {...fs.city}
                    errors={errors.city}
                    ref={register({ required: 'City is required' })}
                  />
                  <SelectGrp
                    {...fs.state}
                    errors={errors.state}
                    ref={register({ required: 'State is required' })}
                    options={stateOpts}
                  />
                  <InputGrp
                    {...fs.zipCode}
                    errors={errors.zipCode}
                    ref={register}
                  />
                  <InputGrp
                    {...fs.zipCodePlus4}
                    errors={errors.zipCodePlus4}
                    ref={register}
                  />
                </div>
              </div>
              <div
                className="d-flex flex-wrap flex-column justify-content-between"
                style={{ flex: '1' }}
              >
                <span className="dm-card-subtitle2 pb-4">Current Selected AP Vendor ID : {apVendorId > '0' ? <button
                  className="link-button"
                  title="Select AP Vendor"
                  style={{ textDecoration: 'underline' }}
                  onClick={onVendorIdClicked}
                >{apVendorId}</button> : apVendorId}</span>
                {isSuperUser ? (
                  <div style={{
                    backgroundColor: 'lightgray',
                    padding: '10px',
                  }}
                  >
                    <label className="form-label pr-4 pb-3">AGENCY</label><span >10 - Garnishment</span>
                    <SelectGrpInLine
                      {...fs.categoryId}
                      defaultValue={categoryId}
                      errors={errors.categoryId}
                      ref={register}
                      options={categoryOpts}
                      disabled={!addVendor}
                      showId
                    />
                    <SelectGrpInLine
                      {...fs.entityId}
                      defaultValue={entityId}
                      errors={errors.entityId}
                      ref={register}
                      options={entityOpts}
                      disabled={!addVendor}
                      showId
                    />
                    {addVendor ? (
                      <CheckboxGrpInLine
                        {...fs.visible}
                        ref={register}
                      />
                    ) : null}
                  </div>
                ) : null}
                <div className="d-flex flex-row py-3" >
                  <button
                    type="submit"
                    className="btn orange-button-sm mr-3"
                    disabled={!addVendor}
                  >Save Selection</button>
                </div>
              </div>
            </div>
          </form>
          <div className="row">
            <div
              className="col-12 table-wrapper-wrapper ag-theme-balham ap-vendor-grid"
              style={{ height: '350px' }}
            >
              <AgGridReact
                gridOptions={gridOptions}
                rowData={rowData}
                modules={AllModules}
                onGridReady={onGridReady}
                onRowClicked={onRowSelected}
                onRowDoubleClicked={onRowDoubleClicked}
                onFirstDataRendered={onFirstDataRendered}
              ></AgGridReact>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default APVendorsModal;
