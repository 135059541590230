import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AgGridReact } from '@ag-grid-community/react';
import { orderBy, xorBy } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CheckboxGrp, ControlDatePickerGrp, RadioGrp, SelectGrp } from 'core/components/form-controls';
import CheckCalculatorModal from 'core/components/modals/check-calculator/CheckCalculator.modal';
import PanelHeader from 'core/components/shared/PanelHeader';
import { agSelectEditor } from 'utilities/ag-grid-editors';
import { agLookupRenderer } from 'utilities/ag-grid-renderers';
import AGDeleteButtonRendererComponent from 'utilities/ag-grid-renderers/AGDeleteButtonRendererComponent';
import { convToFormObject } from 'utilities/classUtils';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import { AccessMap, convDateTimeToDate, currencyFormatter, ddLookup, getAccess, getAllAccess, keySearch } from 'utilities/utilities';
import CompanyBenfitItem from '../company-benefits/CompanyBenefitItem';
import EmpDeductionItem from '../deductions/EmpDeductionItem';
import EmployeeGarnishmentItem from '../deductions/EmployeeGarnishmentItem';
import RecurringEarningsItem from '../recurring-earnings/RecurringEarningsItem';
import TaxComponent from '../tax/TaxComponent';
import { GridApi } from '@ag-grid-enterprise/all-modules';
import Icon from 'core/components/shared/Icon';
import { Id, toast } from 'react-toastify';
import { payrollFrequencies, payrollFrequenciesCheckCalculator } from 'dropdowns/payrollFrequencies';
import {
  CheckCalculateRequest,
  CheckCalculator,
  Deduction,
  Dropdown,
  Employee,
  HoursDollar,
  PayRatesForDropDown,
  RecurringEarnings,
  Tax,
} from 'core/models';
import {
  calculateCheck,
  clearCalculateCheckPdf,
  handleError,
  loadCheckCalculator,
  loadCheckCodes,
  loadPayroll,
} from 'core/store/actions';
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  ICellEditorParams,
  ICellRendererParams,
  ModuleRegistry,
  NewValueParams,
  ValueFormatterParams,
  ValueSetterParams,
} from '@ag-grid-community/core';
import { loadCmTaxEntities } from 'core/store/actions/tax.action';
import {
  getCompanyBenefitDeductionCodes,
  getDeductionFrequencies,
  getDeductionStatuses,
  getDeductionUnits,
  getEarningsCodes,
  getOpenPayrolls,
  getPayPeriods,
  getSelectedEmp,
  getShiftCodes,
} from 'core/store/selectors';
import { ControlIds } from 'core/constants';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

// TODO: Replace with omitted mapped type?
type InputHoursItem = {
  empId: number;
  loc: number;
  dept: number;
  subDept: number;
  subDept2: number;
  shiftCode: number;
  amount: number;
  tranDigit: number;
  rateId: number;
  altRate: number;
  hours: number;
  earningsCode: string;
  id: string;
};

const numericValueSetter = (params: ValueSetterParams) => {
  if (+params.oldValue !== +params.newValue) {
    params.data[params.colDef.field ?? ''] = +params.newValue;
    return true;
  }
  return false;
};

const gridOptions: GridOptions = {
  defaultColDef: {
    suppressMenu: true,
    headerClass: 'grid-header',
    singleClickEdit: true,
    resizable: true,
    width: 100,
    suppressMovable: true,
    cellClass: 'ag-cell-left-border',
  },
  components: {
    agSelectEditor: agSelectEditor,
    agLookupRenderer: agLookupRenderer,
    aGDeleteButtonRendererComponent: AGDeleteButtonRendererComponent,
  },
  stopEditingWhenCellsLoseFocus: true,
};

const buildPayRatesColumnDefs = (accessMap: AccessMap | null, displayOptions: Dropdown[]): ColDef[] => {
  const cols: ColDef[] = [];
  
  if (accessMap?.[ControlIds.annualRate]?.visible ?? true) {
    cols.push({
      field: 'annualRate',
      cellClass: 'ag-cell-left-border text-right',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? currencyFormatter(params.value) : '';
      },
    });
  }
  if (accessMap?.[ControlIds.salaryRate]?.visible ?? true) {
    cols.push({
      field: 'salaryRate',
      cellClass: 'ag-cell-left-border text-right',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? currencyFormatter(params.value) : '';
      },
    });
  }    
  if (accessMap?.[ControlIds.hourlyRate]?.visible ?? true) {
    cols.push({
      field: 'hourlyRate',
      cellClass: 'ag-cell-left-border text-right',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? currencyFormatter(params.value, 6) : '';
      },
    });
  }    
  
  cols.push({ field: 'description' });

  if (accessMap?.[ControlIds.tranDigit]?.visible ?? true) {
    cols.push({
      field: 'tranDigit',
      headerName: 'Display',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? (displayOptions.find((a) => parseInt(a.id) === params.value)?.description ?? '') : '';
      },
    });
  } 
  
  return cols;
};

const radioStyles = {
  margin: '5px 10px',
};

const gridOptionsPayRate = { ...gridOptions };

const CheckCalculatorPage = () => {
  const dispatch = useAppDispatch();

  const {
    checkCalculator,
    checkCalculation: checkCalculateResult,
    calculating,
  } = useAppSelector(({ selEmployeeDetails }) => selEmployeeDetails.checkCalculator);
  const displayOnTransmittalOpts = useAppSelector(({ dropdown }) => dropdown.displayOnTransmittal);
  const clientPayPeriod = useAppSelector(({ client }) => client.client?.clientPayrollFreq);
  const shiftCodes = useSelector(getShiftCodes);
  const earningsCodes = useSelector(getEarningsCodes);
  const payPeriodOpts = useSelector(getPayPeriods);
  const selEmp = useSelector(getSelectedEmp) as Employee;
  const openPayrolls = useSelector(getOpenPayrolls);
  const dedCodeOpts = useSelector(getCompanyBenefitDeductionCodes);
  const dedStatusOpts = useSelector(getDeductionStatuses);
  const dedFrequencyOpts = useSelector(getDeductionFrequencies);
  const dedUnitOpts = useSelector(getDeductionUnits);
  
  // #region module access definitions
  const sectionAccess = useAppSelector(({ app }) => app.moduleAccess?.employeeMasterSections);
  
  const payRateSectionAccess = getAccess(sectionAccess, 2);
  
  // get access map for all fields
  const allPayRateAccessMap = getAllAccess(sectionAccess?.find((x) => x.workItemId === 2));
  // #endregion

  const [activeKey, setActiveKey] = useState('deductionItems');
  const [nestedTabErrors, setNestedTabErrors] = useState<string[]>();
  const [validForm, setValidForm] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deductions, setDeductions] = useState<Deduction[]>([]);
  const [hoursDollars, setHoursDollars] = useState<HoursDollar[]>([]);
  const [recurringEarnings, setRecurringEarnings] = useState<RecurringEarnings[]>([]);
  const [payRatesDropDown, setPayRatesDropDown] = useState<PayRatesForDropDown[]>([]);
  const [companyBenefits, setCompanyBenefits] = useState<Deduction[]>([]);
  const [checkCalculatorInfo, setCheckCalculatorInfo] = useState<CheckCalculator>(Object);

  const { protectedEmpNo } = useParams<{ protectedEmpNo: string }>();
  
  const empDeductions = deductions.filter((deduction) => { return deduction.deductionType.toLowerCase() === 'deduction'; });
  const empGarnishments = deductions.filter((deduction) => { return deduction.deductionType.toLowerCase() === 'garnishment'; });
  
  const payRatesColumnDefs = buildPayRatesColumnDefs(allPayRateAccessMap, displayOnTransmittalOpts);

  const onDeleteInputHours = (e: any) => {
    gridOptions.api?.applyTransaction({ remove: [e] });
  };

  const hoursGridRef = useRef<AgGridReact>(null);
  const saveClicked = useRef<boolean>(false);
  const toastId = useRef<Id | null>(null);

  const [hoursColumnDefs, setHoursColumnDefs] = useState<ColDef[]>([
    {
      field: 'loc',
      editable: true,
    },
    {
      field: 'dept',
      editable: true,
    },
    {
      field: 'subDept',
      editable: true,
    },
    {
      field: 'subDept2',
      editable: true,
    },
    {
      field: 'altRate',
      editable: true,
      cellClass: 'ag-cell-left-border text-right',
      valueFormatter: (params: ValueFormatterParams) => {
        const value = params.value !== '' ? params.value : '0';
        return parseFloat(value).toFixed(6);
      },
      onCellValueChanged: (params: NewValueParams) => {
        const rowIndex = params?.node?.rowIndex ?? 0;
        params.api.stopEditing();
        if (params.newValue !== '0') {
          params.data.rateId = '0';
        } else {
          if (!checkCalculator) return console.error('No check calc in state');
          params.data.rateId = checkCalculator.hoursDollars[rowIndex].rateId.toString();
        }
        params.api.applyTransaction({ update: [params.data] });
        params.api.startEditingCell({
          rowIndex: rowIndex,
          colKey: 'rateId',
        });
      },
    },
    {
      field: 'rateId',
      headerName: 'Rate/Earnings',
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'shiftCode',
      editable: true,
      cellEditor: 'agSelectEditor',
      cellEditorParams: {
        options: shiftCodes,
        addEmptyValue: true,
      },
      cellRenderer: agLookupRenderer,
      cellRendererParams: {
        options: shiftCodes,
        addEmptyValue: true,
      },
    },
    {
      field: 'amount',
      editable: true,
      headerName: 'Amount',
      valueFormatter: (params: ValueFormatterParams) => {
        return parseFloat(params.value).toFixed(2);
      },
      valueSetter: numericValueSetter,
      cellClass: 'ag-cell-left-border text-right',
    },
    {
      field: 'earningsCode',
      editable: true,
      headerName: 'Code',
      cellEditor: 'agSelectEditor',
      cellEditorParams: {
        options: earningsCodes,
        addEmptyValue: true,
      },
      cellRenderer: agLookupRenderer,
      cellRendererParams: {
        options: earningsCodes,
        addEmptyValue: true,
        headerName: '',
      },
    },
    {
      field: 'id',
      headerName: '',
      suppressSizeToFit: true,
      editable: false,
      cellStyle: { textAlign: 'center' },
      cellRenderer: 'aGDeleteButtonRendererComponent',
      cellRendererParams: {
        clickHandler: onDeleteInputHours,
      },
      width: 165,
    },
  ]);

  const onGridReady = (ev: GridReadyEvent) => {
    ev.api.sizeColumnsToFit();
  };

  const defaultTaxValue: Tax[] = []; // why?
  const defaultRecurringEarnings: RecurringEarnings[] = [];
  
  const {
    ...formMethods
  } = useForm<CheckCalculateRequest>({
    defaultValues: {
      employee: {
        taxes: defaultTaxValue,
        recurringEarnings: defaultRecurringEarnings,
      },
      payrollParams: {
        clientNo: selEmp?.clientNo,
      },
    },
  });

  useEffect(() => {
    dispatch(loadCheckCodes());
    dispatch(loadCmTaxEntities());
    return () => {
      dispatch(clearCalculateCheckPdf());
    };
  }, []);

  useEffect(() => {
    notify();
  }, [nestedTabErrors]);

  useEffect(() => {
    if (!Object.keys(formMethods.errors)) {
      setNestedTabErrors([]);
      toast.dismiss();
    }
  }, [formMethods.errors]);

  useEffect(() => {
    if (
      (clientPayPeriod && selEmp?.payPeriod?.trim() === '') ||
      !selEmp?.payPeriod
    ) {
      formMethods.reset({
        employee: { payPeriod: clientPayPeriod?.charAt(0) },
      });
    } else {
      formMethods.reset({
        employee: { payPeriod: selEmp?.payPeriod },
      });
    }
  }, [selEmp]);

  // simulating componentWillUnmount
  useEffect(() => {
    return () => { toast.dismiss(); };
  }, []);

  useEffect(() => {
    if (toastId?.current) {
      toast.dismiss(toastId.current);
    }
    formMethods.clearErrors();
    const beginDate = DateTime.local()
      .startOf('year')
      .toFormat('MM-dd-yyyy');
    const endDate = DateTime.local().endOf('year').toFormat('MM-dd-yyyy');
    dispatch(
      loadPayroll({
        beginDate: beginDate,
        endDate: endDate,
        byCheckDate: true,
      }),
    );
    dispatch(loadCheckCalculator(protectedEmpNo));
  }, [protectedEmpNo]);

  useEffect(() => {
    if (!checkCalculator || !checkCalculator?.payRatesDropdown) return;

    const filteredDropdown = checkCalculator.payRatesDropdown?.filter((v, i, a) => {
      return (
        a.findIndex((o) => {
          return o.rateId === v.rateId;
        }) === i
      );
    });

    setPayRatesDropDown(filteredDropdown);
    setCheckCalculatorInfo({ ...checkCalculator });

    if (checkCalculator?.deductions) {
      setDeductions(
        checkCalculator.deductions
          .filter((a) => {
            return (
              a.deductionType.toLowerCase() === 'garnishment' ||
              a.deductionType.toLowerCase() === 'deduction'
            );
          })
          .map((ded: Deduction) => {
            return convToFormObject(ded, Deduction.convProps);
          }) as Deduction[],
      );
      setCompanyBenefits(
        checkCalculator.deductions.filter((a) => {
          return a.deductionType.toLowerCase() === 'companybenefit';
        }),
      );
    }
    if (checkCalculator?.recurringEarnings) {
      setRecurringEarnings(
        checkCalculator.recurringEarnings.map(
          (r: RecurringEarnings) => {
            return convToFormObject(r, RecurringEarnings.convProps);
          },
        ) as RecurringEarnings[],
      );

    }
    if (checkCalculator?.hoursDollars) {
      setHoursDollars(
        checkCalculator.hoursDollars.map((a) => {
          return {
            ...a,
            id: `id${Math.random()}`,
          };
        }),
      );
    }

    formMethods.setValue(
      'employee.taxes',
      orderBy(
        checkCalculator?.taxes,
        ['entityId', 'year'],
        ['asc', 'desc'],
      ),
    );
  }, [checkCalculator]);

  useEffect(() => {
    if (
      !(payRatesDropDown && payRatesDropDown.length > 0) ||
      !(hoursGridRef.current && hoursGridRef.current.api)
    ) {
      return;
    }

    const api = hoursGridRef.current.api;
    const newColumns = api.getColumnDefs() as ColDef[];

    if (!newColumns) return;

    const index = newColumns.findIndex((a) => {
      return a.field === 'rateId';
    });

    //TODO: These renderers are identical, but error is thrown if called as fn.
    newColumns[index] = {
      ...newColumns[index],
      editable: true,
      cellEditor: 'agSelectEditor',
      cellEditorParams: (params: ICellEditorParams) => {
        const { data } = params;
        const description = data?.tranDigit === 1 ? 'salaryDescription' : 'hourlyDescription';

        return {
          options: payRatesDropDown,
          valueField: 'rateId',
          labelField: description,
        };
      },
      cellRenderer: agLookupRenderer,
      cellRendererParams: (params: ICellRendererParams) => {
        const { data } = params;
        const description = data?.tranDigit === 1 ? 'salaryDescription' : 'hourlyDescription';

        return {
          options: payRatesDropDown,
          valueField: 'rateId',
          labelField: description,
        };
      },
    };

    setHoursColumnDefs(newColumns);
  }, [payRatesDropDown]);

  useEffect(() => {
    if (!hoursGridRef.current || !hoursGridRef.current.api) {
      return;
    }
    hoursGridRef.current.api.sizeColumnsToFit();
  }, [hoursColumnDefs]);

  useEffect(() => {
    const currentState = { ...checkCalculatorInfo };
    currentState.hoursDollars = hoursDollars;
    currentState.deductions = deductions;
    setCheckCalculatorInfo(currentState);
  }, [hoursDollars, deductions]);

  useEffect(() => {
    if (saveClicked.current && validForm) {
      saveClicked.current = false;
      setShowModal(true);
    }
  }, [checkCalculateResult]);

  /**
   * Function to check for errors in form and inform the user of the location. This will also focus the first tab with
   * errors by setting the active key. It uses the truthiness of the keySearch function result (see utilities) to
   * determine if the error is active and should be shown to the user.
   * 
   * @param {string} title The UI-displayed message. This will be used in place of the other two if any/all are the
   * same string.
   * @param {string} key The key to find in the object, which may be nested at a deeper level.
   * @param {string} propertyChain The path to the property in the object to remove the error once it's been resolved.
   * This will probably change in the future if/when the error object changes.  
 */
  const updateNestedErrors = (title: string, key: string = title, propertyChain: string = key) => {
    // is this key in the object?
    const validKey = !!keySearch(formMethods.errors, key);

    // if it's in there (and not already in the array for the UI), add it and jump to that tab
    if (validKey) {
      if (!nestedTabErrors?.includes(title)) {
        setNestedTabErrors((prevState) => { return [...new Set([...(prevState ?? []), title])]; });
      }
      setActiveKey(key ?? title);
      // if it isn't valid, take it out and update the toast message
    } else {
      const tempErrors = structuredClone(nestedTabErrors);

      if (!tempErrors || tempErrors.indexOf(title) < 0) return;

      tempErrors?.splice(tempErrors?.indexOf(title), 1);
      setNestedTabErrors(tempErrors);
      setActiveKey(tempErrors?.[0] ?? 'deductionItems');
      formMethods.clearErrors(propertyChain);
    }
  };

  const notify = () => {
    toast.dismiss();

    setTimeout(() => {
      if (nestedTabErrors?.length) {
        toastId.current = toast.error(`Error in tab(s): ${nestedTabErrors?.join(', ')}`,
          {
            autoClose: false,
            position: toast.POSITION.TOP_RIGHT,
          },
        );
      } else {
        toast.dismiss(toastId.current as Id);
      }
    }, 0);
  };

  const getHoursDollars = (): HoursDollar[] => {
    const hoursDollarsArr: HoursDollar[] = [];
    if (!hoursGridRef.current) {
      return hoursDollarsArr;
    }
    hoursGridRef.current.api.forEachNode((node) => {
      hoursDollarsArr.push({
        ...node.data,
        altRate: +node.data?.altRate || 0,
      });
    });
    setHoursDollars(hoursDollarsArr);
    return hoursDollarsArr;
  };

  const onDeductionChange = (dedId: number, deduction: Deduction, valid: boolean) => {
    const currentState = [...deductions];
    const index = currentState.findIndex((a) => { return a.dedId === dedId; });
    const currentDedType = currentState[index].deductionType;

    if (!deduction.checkCode) deduction.checkCode = '';
    if (!deduction.dateStart) deduction.dateStart = null;
    if (!deduction.dateExpire) deduction.dateExpire = null;
    if (!deduction.arrearWeekEnd) deduction.arrearWeekEnd = null;
    if (!deduction.arrearCheckDate) deduction.arrearCheckDate = null;

    const newDeduction = new Deduction(selEmp.clientNo, selEmp.empNo, selEmp.protectedEmpNo, dedId, currentDedType, deduction);
    currentState[index] = newDeduction;

    setValidForm(valid);
    setDeductions(currentState);
  };

  const onCompanyBenefitChange = (
    dedId: number,
    deduction: Deduction,
    valid: boolean,
  ) => {
    const currentState = [...companyBenefits];
    const index = currentState.findIndex((a) => {
      return a.dedId === dedId;
    });
    currentState[index] = new Deduction(
      selEmp.clientNo,
      selEmp.empNo,
      selEmp.protectedEmpNo,
      dedId,
      currentState[index].deductionType,
      deduction,
    );
    setValidForm(valid);
    setCompanyBenefits([...currentState]);
  };

  const onDailyRateCalculationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!(checkCalculator && checkCalculator?.deductions)) return;
    if (e.target.checked) {
      setDeductions([]);
    } else {
      setDeductions(checkCalculator.deductions);
    }
  };

  const onRecurringEarningsChange = (
    recurringId: number,
    recurringEarning: RecurringEarnings | { [x: string]: any; },
    valid: boolean,
  ) => {
    const currentState = [...recurringEarnings];
    const index = currentState.findIndex((a) => {
      return a.recurringId === recurringId;
    });
    if (!recurringEarning.startDate) recurringEarning.startDate = null;
    if (!recurringEarning.endDate) recurringEarning.endDate = null;
    
    currentState[index] = new RecurringEarnings(
      selEmp.empId,
      selEmp.clientNo,
      selEmp.empNo,
      protectedEmpNo,
      recurringEarning,
    );
    currentState[index].recurringId = recurringId;
    setValidForm(valid);
    setRecurringEarnings(currentState);
  };

  const onAddInputHours = () => {
    if (!hoursGridRef.current) return;
    hoursGridRef.current.api.applyTransaction({
      add: [
        {
          ...checkCalculatorInfo.hoursDollars[0],
          amount: 0,
          altRate: 0,
          shiftCode: 0,
          rateId: payRatesDropDown?.[0]?.rateId || -99,
          id: `id${Math.random()}`,
        },
      ],
    });
  };

  const onAddRecurringEarnings = () => {
    if (selEmp) {
      const newRecurringEarnings = convToFormObject(
        new RecurringEarnings(
          selEmp.empId,
          selEmp.clientNo,
          selEmp.empNo,
          protectedEmpNo,
        ),
        RecurringEarnings.convProps,
      ) as RecurringEarnings;
      newRecurringEarnings.recurringId = parseInt(
        (Math.random() * -10000).toString(),
      );
      setRecurringEarnings((prev: RecurringEarnings[]) => {
        return [...prev, { ...newRecurringEarnings }];
      });
    }
  };

  const onAddDeduction = () => {
    if (selEmp) {
      const newDed = new Deduction(selEmp.clientNo, selEmp.empNo, selEmp.protectedEmpNo, parseInt((Math.random() * -10000).toString()), 'Deduction');
      newDed.dedAmount = 0;

      setDeductions((prev: Deduction[]) => { return [...prev, newDed]; });
    }
  };

  const onAddGarnishment = () => {
    if (selEmp) {
      const newDeduction = new Deduction(
        selEmp.clientNo,
        selEmp.empNo,
        selEmp.protectedEmpNo,
        parseInt((Math.random() * -10000).toString()),
        'Garnishment',
      );
      newDeduction.dedNo = 6;
      newDeduction.freq = '0';
      setDeductions((prev: Deduction[]) => {
        return [...prev, newDeduction];
      });
    }
  };

  const onAddCompanyBenefit = () => {
    if (selEmp) {
      setCompanyBenefits((prev: Deduction[]) => {
        return [
          ...prev,
          new Deduction(
            selEmp.clientNo,
            selEmp.empNo,
            selEmp.protectedEmpNo,
            parseInt((Math.random() * -1000).toString()),
            'CompanyBenefit',
          ),
        ];
      });
    }
  };
  
  const onUpdateRecurringEarning = (rec: RecurringEarnings) => {
    const updateIndex = recurringEarnings.findIndex((x) => x.recurringId === rec.recurringId);
    if (updateIndex === -1) return dispatch(handleError('Error updating recurring earning'));
    
    const clone = structuredClone(recurringEarnings);
    clone.splice(updateIndex, 1, rec);
    setRecurringEarnings(clone);
  };
  
  const onUpdateDeductionGarnishment = (ded: Deduction) => {
    const updateIndex = deductions.findIndex((x) => x.dedId === ded.dedId);
    if (updateIndex === -1) return dispatch(handleError('Error updating recurring earning'));
    
    const clone = structuredClone(deductions);
    clone.splice(updateIndex, 1, ded);
    setDeductions(clone);
  };
  
  const onDeleteDeductionGarnishment = (ded: Deduction) => {
    setDeductions(
      deductions.filter((a) => {
        return a.dedId !== ded.dedId;
      }),
    );
  };

  const onDeleteRecurringEarning = (rec: RecurringEarnings) => {
    const newRecurringEarningsState = recurringEarnings.filter((a) => {
      return a.recurringId !== rec.recurringId;
    });
    
    setRecurringEarnings(newRecurringEarningsState);
    
    if (newRecurringEarningsState.length === 0) {
      const currentFormState = formMethods.getValues();
      delete (currentFormState as any).recurringEarnings;
      const newFormState: CheckCalculateRequest = {
        ...currentFormState,
        employee: {
          ...currentFormState.employee,
          recurringEarnings: [],
        },
      };
      formMethods.reset(newFormState);
    }
  };

  const onDeleteCompanyBenefit = (ded: Deduction) => {
    setCompanyBenefits(
      companyBenefits.filter((a) => {
        return a.dedId !== ded.dedId;
      }),
    );
  };

  const convDateTime = (date: string) => {
    return new Date(convDateTimeToDate(date)).toISOString();
  };

  //Returns true IF => the input dollars/hours grid contains only 0.00 amounts AND deductions contain no negative dedAmount PI-7694
  const NoInputAmountOrNegativeDeduction = (api: GridApi) => {
    let totalAmountInGrid = 0;

    //Get the total "amount" in the input dollars/hours grid
    api.forEachNode(({ data }: { data: InputHoursItem }) => {
      totalAmountInGrid += data.amount;
    });

    //determine if any negative deductions exist; 
    const negativeDedExists = deductions.filter(d => {return d.deductionType.toLowerCase() === 'deduction';}).some(x => {return x.dedAmount < 0;});

    return (totalAmountInGrid === 0 && !negativeDedExists);
  };

  const onSubmit = (data: CheckCalculateRequest) => {
    const confirmMsg = "No non-zero 'Input Hours/Dollars' amounts or negative deduction amounts were found: Continue?";
    const hoursGridApi = hoursGridRef?.current?.api;
    if (hoursGridApi && NoInputAmountOrNegativeDeduction(hoursGridApi) && !showModal && !window.confirm(confirmMsg)) return;

    const payrollFrequencyForm =
      payrollFrequencies.find((a) => {
        return a.value === data.employee.payrollFrequency?.value;
      }) ?? null;
    saveClicked.current = true;
    const currentState = { ...checkCalculatorInfo };
    currentState.hoursDollars = getHoursDollars();
    currentState.payPeriod = ddLookup(
      data.employee.payPeriod,
      payPeriodOpts,
    );
    currentState.taxes = data.employee.taxes;
    currentState.deductions = [...deductions, ...companyBenefits].map(
      (a) => {
        return new Deduction(
          a.clientNo,
          a.empNo,
          a.protectedEmpNo,
          a.dedId,
          a.deductionType,
          { ...a, status: a.status === '' ? 'ACTIVE' : a.status },
        );
      },
    );
    currentState.checkDate = convDateTime(data.employee.checkDate);
    currentState.recurringEarnings = recurringEarnings.map((a) => {
      return new RecurringEarnings(
        a.empId,
        a.clientNo,
        a.empNo,
        a.protectedEmpNo,
        a,
      );
    });
    currentState.payrollFrequency = payrollFrequencyForm;

    currentState.hoursDollars.forEach((a) => {
      if (a.shiftCode.toString() === '') {
        a.shiftCode = 0;
      }
    });
    const calculateCheckRequest: CheckCalculateRequest = {
      employee: currentState,
      payrollParams: {
        clientNo: selEmp?.clientNo,
        checkDate: currentState.checkDate,
        weekEnd: currentState.checkDate,
        registerSource: '1',
        empNo: selEmp?.empNo,
        payrollFrequency: payrollFrequencyForm,
      },
    };
    dispatch(
      calculateCheck({
        calculateCheck: calculateCheckRequest,
        protectedEmpNo: protectedEmpNo,
      }),
    );
  };

  return (
    <div>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <SelectGrp
              ref={formMethods.register}
              options={payrollFrequenciesCheckCalculator}
              valueField="value"
              id="payrollFrequency"
              name="employee.payrollFrequency.value"
              label="Pay Frequency"
              addEmptyText="Take 'Always' and 'One Time Only' Deductions"
            />
          </div>
          <div className="col">
            <SelectGrp
              options={payPeriodOpts}
              id="payPeriod"
              name="employee.payPeriod"
              label="Pay Period"
              ref={formMethods.register}
            />
          </div>
          <div className="col">
            <ControlDatePickerGrp
              id="checkDate"
              name="employee.checkDate"
              label="Check Date"
              control={formMethods.control}
              value={new Date()}
              required={true}
            />
          </div>
          <div className="col-auto">
            <CheckboxGrp
              id="dailyRateCalculation"
              name="employee.dailyRateCalculation"
              label="Daily Rate Calculation"
              ref={formMethods.register}
              onChange={onDailyRateCalculationChange}
            />
          </div>
          <div className="col-auto">
            <Button
              type="submit"
              className="orange-button"
              style={{ width: '167px' }}
              disabled={calculating}
            >
              {calculating ? (
                <>
                  <span className="mr-1">Calculating&nbsp;</span>
                  <Spinner
                    animation={'border'}
                    size="sm"
                  />
                </>
              ) : (
                <>
                  <Icon name="calculator" /> Calculate Check
                </>
              )}
            </Button>
          </div>
        </div>
        {payRateSectionAccess.visible && (
          <div className="dm-panel dm-panel-border">
            <PanelHeader title="Pay Rates"></PanelHeader>
            <div className="ag-theme-balham">
              <AgGridReact
                rowData={checkCalculatorInfo.payRates}
                onGridReady={onGridReady}
                gridOptions={gridOptionsPayRate}
                columnDefs={payRatesColumnDefs}
              ></AgGridReact>
            </div>
          </div>
        )}
        <div className="dm-panel dm-panel-border">
          <PanelHeader title="Input Hours and/or Dollars"></PanelHeader>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-link dm-grid-action-title pb-0 mr-2"
              onClick={onAddInputHours}
            >
              Insert Row <Icon
                name="plus-circle"
                className="fa-plus-circle"
              />
            </button>
          </div>
          <div className="ag-theme-balham">
            <AgGridReact
              onGridReady={onGridReady}
              columnDefs={hoursColumnDefs}
              rowData={hoursDollars}
              gridOptions={gridOptions}
              ref={hoursGridRef}
            ></AgGridReact>
          </div>
        </div>
        <div className="dm-panel dm-panel-border">
          <PanelHeader title="Payroll Details"></PanelHeader>
          <Tabs
            activeKey={activeKey}
            onSelect={(k) => {
              if (!k) return;
              setActiveKey(k);
            }}
            className="mb-3"
          >
            <Tab
              eventKey="deductionItems"
              title="Employee Deductions"
            >
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-link dm-grid-action-title pb-0 mr-2"
                  onClick={onAddDeduction}
                >
                  Add Deduction{' '}
                  <Icon
                    name="plus-circle"
                    className="fa-plus-circle"
                  />
                </button>
              </div>
              {empDeductions.map((ded: Deduction, index: number) => {
                return (
                  <div key={ded.dedId}>
                    <EmpDeductionItem
                      key={ded.dedNo}
                      deduction={ded}
                      index={index}
                      formMethods={formMethods}
                      control={formMethods.control}
                      onUpdateDeductionGarnishment={onUpdateDeductionGarnishment}
                      onDelete={onDeleteDeductionGarnishment}
                      onWatch={onDeductionChange}
                      isCheckCalculator={true}
                      updateNestedErrors={updateNestedErrors}
                    />
                    {index + 1 < deductions.length && (
                      <hr className="dm-panel-hr mt-2" />
                    )}
                  </div>
                );
              })}
            </Tab>
            <Tab
              eventKey="garnishmentItems"
              title="Garnishments"
            >
              <div className="d-flex justify-content-end mb-3">
                <button
                  type="button"
                  className="btn btn-link dm-grid-action-title pb-0 mr-2"
                  onClick={onAddGarnishment}
                >
                  Add Garnishment{' '}
                  <Icon
                    name="plus-circle"
                    className="fa-plus-circle"
                  />
                </button>
              </div>
              {empGarnishments.map((ded: Deduction, index: number) => {
                return (
                  <div key={ded.dedId}>
                    <EmployeeGarnishmentItem
                      key={ded.dedNo}
                      deduction={ded}
                      index={index}
                      formMethods={formMethods}
                      control={formMethods.control}
                      onDelete={onDeleteDeductionGarnishment}
                      onUpdateDeductionGarnishment={onUpdateDeductionGarnishment}
                      updateNestedErrors={updateNestedErrors}
                      showSaveButton={false}
                      onWatch={onDeductionChange}
                      isCheckCalculator={true}
                    />
                    {index + 1 < deductions.length && (
                      <hr className="dm-panel-hr mt-2" />
                    )}
                  </div>
                );
              })}
            </Tab>
            <Tab
              eventKey="recurringEarningItems"
              title="Recurring Earnings"
            >
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-link dm-grid-action-title pb-0 mr-2"
                  onClick={onAddRecurringEarnings}
                >
                  Add Recurring Earnings{' '}
                  <Icon
                    name="plus-circle"
                    className="fa-plus-circle"
                  />
                </button>
              </div>
              {recurringEarnings
                .filter((a) => {
                  return a;
                })
                .map((item: RecurringEarnings, index: number) => {
                  return (
                    <div key={item.recurringId}>
                      <RecurringEarningsItem
                        item={item}
                        index={index}
                        isCheckCalculator={true}
                        onDelete={onDeleteRecurringEarning}
                        formMethods={formMethods}
                        control={formMethods.control}
                        updateNestedErrors={updateNestedErrors}
                        onWatch={onRecurringEarningsChange}
                        onUpdateRecurringEarning={onUpdateRecurringEarning}
                      />
                      <hr className="dm-panel-hr mt-0" />
                    </div>
                  );
                })}
            </Tab>
            <Tab
              eventKey="taxes"
              title="Taxes"
            >
              <div className="mb-3">
                <div className=" dm-panel dm-panel-border d-flex flex-row flex-wrap">
                  <RadioGrp
                    name="hasFICA"
                    label="FICA"
                    defaultChecked={checkCalculatorInfo?.payrollInfo?.hasFica}
                    groupStyle={radioStyles}
                    disabled={true}
                    ref={formMethods.register}
                  />
                  <RadioGrp
                    name="hasSUI"
                    label="SUI"
                    defaultChecked={checkCalculatorInfo?.payrollInfo?.hasSui}
                    groupStyle={radioStyles}
                    disabled={true}
                    ref={formMethods.register}
                  />
                  <RadioGrp
                    name="hasFUTA"
                    label="FUTA"
                    defaultChecked={checkCalculatorInfo?.payrollInfo?.hasFuta}
                    groupStyle={radioStyles}
                    disabled={true}
                    ref={formMethods.register}
                  />
                </div>
                <TaxComponent
                  formStateErrors={formMethods.errors}
                  updateNestedErrors={updateNestedErrors}
                  hasSUI={checkCalculatorInfo?.payrollInfo?.hasSui}
                  control={formMethods.control}
                  register={formMethods.register}
                  errors={formMethods.errors}
                  getValues={formMethods.getValues}
                  setValue={formMethods.setValue}
                />
              </div>
            </Tab>
            <Tab
              eventKey="companyBenefitItems"
              title="Company Benefits"
            >
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-link dm-grid-action-title pb-0 mr-2"
                  onClick={onAddCompanyBenefit}
                >
                  Add Company Benefit{' '}
                  <Icon
                    name="plus-circle"
                    className="fa-plus-circle"
                  />
                </button>
              </div>
              {companyBenefits.map(
                (item: Deduction, index) => {
                  return (
                    <div key={item.dedId}>
                      <CompanyBenfitItem
                        item={item}
                        dedCodeOpts={dedCodeOpts}
                        dedStatusOpts={dedStatusOpts}
                        dedFrequencyOpts={dedFrequencyOpts}
                        unitOpts={dedUnitOpts}
                        onDelete={onDeleteCompanyBenefit}
                        showSaveButton={false}
                        onWatch={
                          onCompanyBenefitChange
                        }
                        formMethods={formMethods}
                        updateNestedErrors={updateNestedErrors}
                        control={formMethods.control}
                        index={index}
                        isCheckCalculator={true}
                      />
                      <hr className="dm-panel-hr mt-0" />
                    </div>
                  );
                },
              )}
            </Tab>
          </Tabs>
        </div>
      </form>
    
      {showModal && (
        <CheckCalculatorModal
          openPayrolls={openPayrolls}
          protectedEmpNo={protectedEmpNo}
          show={showModal}
          onHide={() => {
            return setShowModal(false);
          }}
          selEmp={selEmp}
        />
      )}
    </div>
  );
};

export default CheckCalculatorPage;
