import { EarningsCodeAutoCalculation } from '.';

export interface Payroll {
  payrollHistoryId: number;
  clientNo: number;
  protectedClientNo: string;
  weekEnd: string;
  checkDate: string;
  nextWeekEnd: string;
  nextCheckDate: string;
  prFrequency: string;
  status: string;
  firstAccess: string;
  lastAccess: string;
  totalDuration: number;
  numberOfAccesses: number;
  submitUser: string;
  submitDate: string;
  finishDate: string;
  weekBeginDate: string;
  hasMessage: boolean;
  useNewCheckRegister: boolean;
  hasSignedWireOnlyAgreement: boolean;
  controlTotals: PayrollControlTotal[];
  adjustmentTotals: PayrollAdjustmentTotal[];
}

export interface PayrollOptions {
  weekBegin: string;
  weekEnd: string;
  checkDate: string;
  paidEmployees: number;
  totalEmployees: number;
  singleWeekBeginDate: boolean;
  calculateAllShortages: boolean;
  weekBeginDates: WeekBeginDate[];
  deductionShortages: PayrollDeductionShortage[];
}

export interface WeekBeginDate {
  loc: number;
  dept: number;
  weekBeginDate: string;
}

export interface PayrollDeductionShortage {
  dedShortageId: number;
  dedNo: number;
  shortDesc: string;
  longDesc: string;
  employeesWithShortages: number;
  paidEmployeesWithShortages: number;
  process: boolean;
  shortages: PayrollEmployeeDeductionShortage[];
}

export interface PayrollEmployeeDeductionShortage {
  empDetailId: number;
  empNo: number;
  empId: number;
  employeeName: string;
  amount: number;
  dedNo: number;
  gettingPaid: false;
  process: boolean;
}

export interface PayrollPreview {
  clientNo: number;
  weekEnd: string;
  checkDate: string;
  nextWeekEnd: string;
  nextCheckDate: string;
  payrollFrequency: PayrollFrequency;
  payrollStatus: PayrollFrequency;
  payrollHistoryId: number;
  registerSource: number;
  empNo: number;
}

export enum PayrollErrorType {
  OutstandingAccrualRequests = 'OutstandingAccrualRequests',
  PendingAccrualRequests = 'PendingAccrualRequests',
  HighGross = 'HighGross',
  HighHours = 'HighHours',
  MissingACHInformation = 'MissingACHInformation',
  NoRateOnFile = 'NoRateonFile',
  NegativeAccrual = 'NegativeAccrual',
  PayingFutureEmployees = 'PayingFutureEmployees',
  PayingTerminatedEmployees = 'PayingTerminatedEmployees',
  FutureCheckDate = 'FutureCheckDate',
  PrenotedEmployeeAccounts = 'PrenotedEmployeeAccounts',
  NegativeNetPay = 'NegativeNetPay',
  NegativeGarnishmenmt = 'NegativeGarnishmenmt',
  MinimumWage = 'MinimumWage',
  ZeroAmountPayCheck = 'ZeroAmountPayCheck',
  CrossFoot = 'CrossFoot',
  PayRateChanges = 'PayRateChanges',
  BankAccountChanges = 'BankAccountChanges',
  FutureRatesUpdates = 'FutureRatesUpdates',
  WireOnlySubmission = 'WireOnlySubmission',
}

type excludeModals =
  | typeof PayrollErrorType.OutstandingAccrualRequests
  | typeof PayrollErrorType.PendingAccrualRequests
  | typeof PayrollErrorType.FutureRatesUpdates;

export type NonModals = Exclude<PayrollErrorType, excludeModals>;
export type ModalsOnly = Extract<PayrollErrorType, excludeModals>;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModalsOnly = {
  [PayrollErrorType.OutstandingAccrualRequests]: PayrollErrorType.OutstandingAccrualRequests,
  [PayrollErrorType.PendingAccrualRequests]: PayrollErrorType.PendingAccrualRequests,
  [PayrollErrorType.FutureRatesUpdates]: PayrollErrorType.FutureRatesUpdates,
  [PayrollErrorType.WireOnlySubmission]: PayrollErrorType.WireOnlySubmission,
} as const;
export interface PayrollFrequency {
  value: string;
  description: string;
  disabled?: boolean;
}

export interface PayrollStatus {
}


export interface DtoOptions {
  checkDate: string;
  weekEnd: string;
  payrollHistoryId: number;
  status?: string;
}

export type PayrollDatelineIndex = Omit<PayrollDateline, 'clientNo' | 'protectedClientNo' | 'populateTransmittal'>;

export interface PayrollMessage {
  payrollMessageId?: number;
  clientNo?: number;
  protectedClientNo?: string;
  messageId: number;
  description?: string;
  message?: string;
  createBy?: string;
  loc?: string;
  dept?: string;
  subDept?: string;
  subDept2?: string;
  employee?: string;
  dateStart?: string | Date;
  dateEnd?: string | Date;
  locationSelections?: MessageSelection[];
  departmentSelections?: MessageSelection[];
  subDeptSelections?: MessageSelection[];
  subDept2Selections?: MessageSelection[];
  employeeSelections?: MessageSelection[];
}

export interface PayrollControlTotal {
  controlTotalId: number;
  payrollHistoryId: number;
  loc: number;
  dept: number;
  subDept: number;
  balanced: boolean;
  description: string;
  empCount: number;
  checkCount: number;
  transmittalRegular: number;
  transmittalTimeHalf: number;
  transmittalDouble: number;
  transmittalOtherHours: number;
  transmittalOtherEarnings: number;
  transmittalSpecialDeductions: number;
  transmittalTaxAmounts: number;
  controlTotalRegular: number;
  controlTotalTimeHalf: number;
  controlTotalDouble: number;
  controlTotalOtherHours: number;
  controlTotalOtherEarnings: number;
  controlTotalSpecialDeductions: number;
  payControlTotal: boolean;
  currentUser: string;
  currentUserOpenedDate: string | null;
  lastUser: string;
  lastUserClosedDate: string;
  controlSequence: number;
  parentControlTotalId: number | null;
}

export interface PayrollAdjustmentTotal {
  adjustmentTotalId: number;
  payrollHistoryId: number;
  clientNo: number;
  weekEnd: string;
  checkDate: string;
  loc: number;
  dept: number;
  subDept: number;
  companyGross: number;
  federalGross: number;
  ficaRGross: number;
  ficaMGross: number;
  futaGross: number;
  suiGross: number;
  federalWh: number;
  ficaWh: number;
  ficaRWh: number;
  ficaMWh: number;
  stateWh: number;
  localWh: number;
  thirdPartySickCount: number;
  prePayCount: number;
  voidCount: number;
}

export interface MessageSelection {
  messageDetailId: number;
  value: string;
}

export interface TransmittalParams {
  payrollHistoryId: number;
  controlTotalId: number;
}

export type TransmittalSortOrder =
  | 'Loc Dept Alfa'
  | 'Department'
  | 'Alphabetically'
  | 'Employee No'
  | 'Sub-Department'
  | 'Sub-Department 2';

export interface TransmittalAutoFill {
  earningsCode: string;
  hours: number;
  earnings: number;
  useAutoFillOnInsert: boolean;
  overwriteExisting: boolean;
  includeSalaried: boolean;
}

export interface PayrollDateline {
  clientNo: number;
  protectedClientNo: string;
  weekEnd: string;
  checkDate: string;
  nextWeekEnd: string;
  nextCheckDate: string;
  prFrequency: string;
  populateTransmittal: boolean | string;
  useNewCheckRegister: boolean;
}

export interface UpdateAutoCalcsParams extends TransmittalParams {
  earningsCodeAutoCalculations: EarningsCodeAutoCalculation[];
}

export interface TransmittalEmployeeParams extends TransmittalParams {
  protectedEmpNo: string;
  blankCheck?: boolean; // TODO: we might want to move this when/if models change
}

export interface TransmittalEmployeeCheckParams extends TransmittalParams {
  protectedEmpNo: string;
  transmittalCheckId: number;
  blankCheck?: boolean;
}

export interface TransmittalEmployee {
  firstName: string;
  lastName: string;
  clientNo: number;
  empNo: number;
  loc?: number;
  dept?: number;
  subDept?: number;
  subDept2?: number;
  employeeShiftPremiumId?: number;
  employeePayPeriod: string;
  protectedClientNo?: string;
  protectedEmpNo: string;
  checks: TransmittalCheck[];
}

export interface TransmittalCheck {
  transmittalCheckId?: number;
  checkCode: string;
  taxFrequency: string;
  blockFica: boolean;
  federalWithholding: TransmittalFederalWithholding;
  stateWithholding: TransmittalFederalWithholding;
  localWithholding: TransmittalFederalWithholding;
  earnings: TransmittalEarning[];
  deductions: TransmittalDeduction[];
  memoItems: TransmittalOtherInfo[];
  payRateDropdown: PayRateDropdown[];
  checkErrors: TransmittalCheckErrors[];
}

export interface TransmittalCheckErrors {
  clientNo: number,
  empNo: number,
  transmittalCheckId: number,
  errorMessage: string,
}

export interface TransmittalDeduction {
  transmittalDeductionId?: number;
  dedNo: number;
  dedAmount: number;
  unit: string;
  dept: number;
  subDept: number;
  subDept2: number;
  categoryId: number | null;
  dedId: number | null;
  overridedDeduction: boolean;
}

export interface TransmittalEarning {
  transmittalEarningsId?: number;
  earningsCode: string;
  amount: number;
  hours: number;
  earnings: number;
  tranDigit: number;
  rateId: number | null;
  nonAdjustedRateId: number | null;
  tracked: boolean | string;
  altRate: number;
  dept: number;
  subDept: number;
  subDept2: number;
  shiftPremiumId?: number | null;
  autoCalcResultId?: number | null;
  cityTaxEntityId?: number | null;
  stateTaxEntityId: number;
  showAltRate?: boolean;
  recurringEarningId: number | null;
}

export interface clientRecurringEarning {
  amount: number;
  checkCode: string | null;
  clientNo: number;
  empId: number;
  empNo: number;
  startDate: string;
  endDate: string | null;
  frequency: string;
  jobNo: any;
  payType: string;
  protectedClientNo: string;
  protectedEmpNo: string;
  recurringId: number;
}

export interface TransmittalFederalWithholding {
  transmittalWithholdingId: number;
  fan: string;
  taxCode: string;
  amount: number;
}

export interface TransmittalOtherInfo {
  transmittalOtherInfoId: number;
  clientOtherInfoId: number;
  displayOrder: number;
  description: string;
  amount: number;
}

export interface PayRateDropdown {
  rateId: number;
  tranDigit: number;
  hourlyDescription: string;
  salaryDescription: string;
  empTranDigit: number;
}

export interface PayrollControllTotalUpdateRequest {
  payrollHistoryId: number;
  controlTotalId: number;
  data: PayrollControlTotal;
  preventPayrollsUpdate?: boolean;
  shouldBalance?: boolean;
}

export interface PayrollVoidSearchParams {
  beginDate: Date | string;
  endDate: Date | string;
  protectedEmpNo: string;
}

export interface PayrollAdjustmentTransaction {
  mescId: number;
  checkType: string;
  checkTypeDescription: string;
  empNo: number;
  protectedEmpNo: string;
  weekEnd: Date;
  checkDate: Date;
  quarter: number;
  checkNo: number;
  firstName: string;
  lastName: string;
  companyGross: number;
  void: boolean;
  addPrePaid: boolean;
  adjustmentId: number;
}

export interface PayrollAdjustmentVoidPrepayInfo {
  clientNo: number;
  empNo: number;
  weekEnd: Date | string | null;
  checkDate: Date | string | null;
}

export interface PayrollAdjustmentVoidTransaction {
  clientNo: number;
  adjustmentType: PayrollAdjustmentType;
  voidPrepayInfo: PayrollAdjustmentVoidPrepayInfo;
  transactions: PayrollAdjustmentTransaction[];
}

export enum PayrollAdjustmentType {
  ThirdPartySick = 'ThirdPartySick',
  PrePaid = 'PrePaid',
  VoidCash = 'VoidCash',
  VoidCorrection = 'VoidCorrection',
}

export interface PayrollValidateRequest {
  payrollHistoryId: number;
  registerType: string;
}

export interface PayrollValidate {
  warnings: ValidateDetails[];
  errors: ValidateDetails[];
}

export interface ValidateDetails {
  name: string;
  description: string;
  detailItems: DetailItem[];
}

export interface DetailItem {
  empNo: number;
  protectedEmpNo: string;
  employeeName: string;
  description: string;
  controlTotalId: number;
}

export interface PayrollTimeOffRequests {
  outstandingTimeOffRequests: OutstandingTimeOffRequest[];
  pendingTimeOffRequests: PendingTimeOffRequest[];
  unpaidTimeOffRequests: UnpaidTimeOffRequest[];
}

export interface PendingTimeOffRequest {
  payrollHistoryId: number;
  controlTotalId: number;
  empNo: number;
  protectedEmpNo: string;
  employeeName: string;
  timeOffRequestId: number;
  earningsCode: string;
  transmittalHours: number;
  requestedHours: number;
  supervisorEmpNo: number;
  supervisorName: string;
  secondSupervisorEmpNo: number;
  secondSupervisorName: string;
}

export interface OutstandingTimeOffRequest {
  payrollHistoryId: number;
  controlTotalId: number;
  empNo: number;
  protectedEmpNo: string;
  employeeName: string;
  timeOffRequestId: number;
  earningsCode: string;
  transmittalHours: number;
  requestedHours: number;
}

export interface UnpaidTimeOffRequest {
  payrollHistoryId: number;
  controlTotalId: number;
  empNo: number;
  protectedEmpNo: string;
  employeeName: string;
  timeOffRequestId: number;
  earningsCode: string;
  requestedHours: number;
  payrollYear: number;
}

export interface ApplyTimeOffRequest {
  payrollHistoryId: number;
  controlTotalId: number;
  empNo: number;
  protectedEmpNo: string;
  timeOffRequestId: number;
}

export interface MoveTimeOffRequest {
  oldPayrollHistoryId: number;
  oldControlTotalId: number;
  newPayrollHistoryId: number;
  timeOffRequestId: number;
  empNo: number;
  protectedEmpNo: string;
}

export interface PayrollPreviewResult {
  payrollReportData: PayrollPreviewPdf[];
  mustWireAgreement: WireOnlyAgreement | null;
}

export interface WireOnlyAgreement {
  mustWireId: number;
  payrollHistoryId: number;
  clientNo: number;
  clientName: string;
  weekEnd: string;
  checkDate: string;
  pullAmount: number;
  wiredAmount: number;
  dateWired: Date | string | null;
  agreedName: string;
  email: string;
  phone: string;
}

export interface PayrollPreviewPdf {
  data: string;
  excelData: string | null;
  name: string;
}

export type RecoverPayrollRequest = {
  payrollHistoryId: number;
  clientNo: number;
  chargeClientForRecovery: boolean
};

export type CanRecoverPayrollResponse = {
  clientNo: number;
  payrollHistoryId: number;
  payrollRecoverable: boolean
  errorMessage: string;
};

export type CreateControlTotalBookRequest = {
  payrollHistoryId: number;
  controlTotalId: number;
  loc: number;
  dept: number;
  subDept: number;
};

export type AdjustmentStateInfo = {
  adjustmentStateTaxId: number;
  taxEntityId: number;
  entity: string;
  withholding: number;
  gross: number;
  isSuiOrWhState: false;
  isSuiState: boolean;
  isWHState: boolean;
  addtlWithholdings: AdditionalStateWithholding[];
};

export type AdditionalStateWithholding = {
  additionTaxId: number;
  categoryId: number;
  description: string;
  withholding: number;
  gross: number;
  isEmployeeTax: true;
  taxFollowed: string;
};

export type TransmittalFuturePayRates = {
  empNo: number,
  payrollId: number,
  firstName: number,
  lastName: number,
  midName: number,
  futurePayRatesDetail: TransmittalFuturePayRatesDetails
};

export type TransmittalFuturePayRatesDetails = {
  prRateId: number,
  hrRateId: number,
  originalRateId: number | null,
  effectiveDate: Date,
  hrPayRate: number,
  hrTranDigit: number,
  prPayRate: number,
  prTranDigit: number,
  apply: boolean
};

export type UpdateCheckNoRequest = {
  clientNo: number,
  empNo: number,
  mescId: number,
  checkNo: number,
};