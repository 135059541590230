import { AxiosResponse } from 'axios';
import { IntegrationLog } from '../models/IntegrationLog.model';
import { API_EMPLOYEE_MASTER } from './API';
import { CommonService } from './common.service';
import { IntegrationEndpoints } from './URL';

const getClientIntegrationLogs = (): Promise<AxiosResponse<any>> => { return API_EMPLOYEE_MASTER.get<IntegrationLog[]>(IntegrationEndpoints.GET_CLIENT_INTEGRATION_LOGS(CommonService.getProtectedClientNo())); };

export const IntegrationService = {
    getClientIntegrationLogs
};
