import { OnTheClockRequest } from 'core/models/PayrollUpload.model';
import { EarningsRecordsRequest, PayrollVoidSearchParams, TransmittalFuturePayRates } from '../models';
import { HrEmployeeStatusEmergencyContactIdRequest } from '../models/HrEmployeeEmergencyContact.model';
import { PayrollControlTotalDetailType, PayrollControlTotalRequest } from '../models/PayrollControlTotalDetail';
import { CommonService } from './common.service';
import { TaxRequest } from './tax.service';

const API_VERSION = 'api-version=1.0';
const API_VERSION2 = 'api-version=2.0';

export const AuthEndPoints = {
  POST_TOKEN: `auth/token?${API_VERSION}`,
  POST_TOKEN_IDENTITY_SERVER: 'connect/token',
  REFRESH_TOKEN: 'auth/refresh',
  BFFUSER: 'bff/user',
  LOGIN: (returnUrl: string) => { return `bff/login?returnUrl=${returnUrl}`; },
  GET_USER: (userName: string) => { return `cm/users?username=${userName}&${API_VERSION}`; },
  GET_USER_ACESSS: () => { return 'cm/userAccess'; },
};

export const AppEndPoints = {
  POST_APP_EMP_SEARCH: (cn: string) => { return `payroll/${cn}/employees/listsearch?${API_VERSION}`; },
  DEFAULT_USER_PASSWORD: (secUserId: number) => { return `cm/users/${secUserId}/passwords?${API_VERSION}`; },
  POST_EMAIL_PDF_PASSWORD: (secUserId: number) => { return `cm/users/${secUserId}/passwords/emailReminder?${API_VERSION}`; },
  GET_CLIENTS_BY_ENTITY_ID: (cn: string) => { return `cm/${cn}/EntityId?${API_VERSION}`; },
};

export const ApplicantEndPoints = {
  APPLICANT: (cn: string) => { return `payroll/${cn}/applicanttracking/applicants?${API_VERSION}`; },
  APPLICANT_ID: (cn: string, applicantId:number) => { return `payroll/${cn}/applicanttracking/applicants/${applicantId}?${API_VERSION}`; },  
  APPLICANT_STATUS: (cn: string, applicantId:number) => { return `payroll/${cn}/applicanttracking/applicants/${applicantId}/updatestatus?${API_VERSION}`; },
  APPLICANT_OFFER: (cn: string, applicantId: number) => { return `payroll/${cn}/applicanttracking/applicants/${applicantId}/offer?${API_VERSION}`; },
  APPLICANT_INTERVIEWS: (cn: string, applicantId:number) => { return `payroll/${cn}/applicanttracking/applicants/${applicantId}/interviews?${API_VERSION}`; },
  APPLICANT_NOTES: (cn: string, applicantId: number) => { return `payroll/${cn}/applicanttracking/applicants/${applicantId}/notes?${API_VERSION}`; },
  APPLICANT_NOTE_REPORT: (cn: string, applicantId: number) => { return `payroll/${cn}/applicanttracking/applicants/${applicantId}/noteReport?${API_VERSION}`; },
  APPLICANT_SEND_RESUME: (cn: string, applicantId: number) => { return `payroll/${cn}/applicanttracking/applicants/${applicantId}/sendResume?${API_VERSION}`; },
  JOB: (cn: string) => { return `payroll/${cn}/applicanttracking/manageavailablejobs?${API_VERSION}`; },
  PUT_JOB_STATUS: (cn: string) => { return `payroll/${cn}/applicantTracking/manageAvailableJobs/updateStatus?${API_VERSION}`; },
  JOB_ID: (cn: string, jobId:number) => { return `payroll/${cn}/applicanttracking/manageavailablejobs/job/${jobId}?${API_VERSION}`; },  
  PAYROLL_USERS: (cn: string) => { return `payroll/${cn}/applicantTracking/payrollUsers`; },
  CUSTOM_INTERVIEW_EMAILS: (cn: string) => { return `payroll/${cn}/applicantTracking/interviews/customEmails`; },
  DELETE_APPLICANT_INTERVIEW: (cn: string, applicantId: number, interviewId: number) => { return `payroll/${cn}/applicantTracking/applicants/${applicantId}/interviews/${interviewId}?${API_VERSION}`; },
  SEND_APPLICANT_INTERVIEW_EMAILS: (cn: string, applicantId: number, interviewId: number) => { return `payroll/${cn}/applicantTracking/applicants/${applicantId}/interviews/${interviewId}/sendEmails`; },
  SEND_APPLICANT_OFFER_EMAIL: (cn: string, applicantId: number) => { return `payroll/${cn}/applicantTracking/applicants/${applicantId}/offer/sendEmails`; },
  INTERVIEW_QUESTIONS_REPORT: (cn: string, applicantId: number, interviewId: number) => { return `payroll/${cn}/applicantTracking/applicants/${applicantId}/interviews/${interviewId}/questionsReport`; },
  INTERVIEW_TEMPLATES: (cn: string) => { return `payroll/${cn}/applicantTracking/interviews/templates`; },
  TEMPLATE_ID: (cn: string, interviewTypeId:number, interviewTemplateId:number) => { return `payroll/${cn}/applicanttracking/designinterview/${interviewTypeId}/interviewtemplate/${interviewTemplateId}?${API_VERSION}`; }, 
  CREATE_INTERVIEW_FROM_PROCESS: (cn: string, applicantId: number, interviewProcessId: number) => { return `payroll/${cn}/applicantTracking/applicants/${applicantId}/interviews/${interviewProcessId}`; },
  CREATE_APPLICANT_INTERVIEW_FROM_TEMPLATE: (cn: string, applicantId: number) => { return `payroll/${cn}/applicantTracking/applicants/${applicantId}/interviews`; },
  UPDATE_APPLICANT_INTERVIEW: (cn: string,  applicantId: number, interviewId: number) => { return `payroll/${cn}/applicantTracking/applicants/${applicantId}/interviews/${interviewId}`; },
  INTERVIEW_PROCESSES: (cn: string) => { return `payroll/${cn}/applicanttracking/interviews/process?${API_VERSION}`; },
  INTERVIEW_PROCESS_ID: (cn: string, interviewProcessId: number) => { return `payroll/${cn}/applicanttracking/designinterview/process/${interviewProcessId}?${API_VERSION}`; },
  UPDATE_APPLICANT_EMAIL: (cn: string, applicantId: number) => { return `/payroll/${cn}/applicantTracking/applicants/${applicantId}/Email`; },
  INTERVIEW_TYPES: (cn: string) => { return `payroll/${cn}/applicanttracking/designinterview/interviewtype?${API_VERSION}`; },
  INTERVIEW_TYPE_ID: (cn: string, interviewTypeId:number) => { return `payroll/${cn}/applicanttracking/designinterview/interviewtype/${interviewTypeId}?${API_VERSION}`; },
  INTERVIEW_QUESTIONS: (cn: string) => { return `payroll/${cn}/applicanttracking/interviews/questions?${API_VERSION}`; },
  INTERVIEW_QUESTIONS_ID: (cn: string, interviewQuestionId: number) => { return `payroll/${cn}/applicanttracking/designinterview/questions/${interviewQuestionId}?${API_VERSION}`; },
  POST_INTERVIEW_QUESTIONS_ID: (cn: string, interviewQuestionId: number) => { return `payroll/${cn}/applicanttracking/designinterview/interviewquestion/${interviewQuestionId}?${API_VERSION}`; },
  INTERVIEW_LOCATIONS: (cn: string) => { return `payroll/${cn}/applicanttracking/interviews/locations?${API_VERSION}`; },
  INTERVIEW_LOCATIONS_ID: (cn: string, interviewLocationId: number) => { return `payroll/${cn}/applicanttracking/interviews/locations/${interviewLocationId}?${API_VERSION}`; },
  POST_INTERVIEW_LOCATIONS_ID: (cn: string, interviewLocationId: number) => { return `payroll/${cn}/applicanttracking/designinterview/interviewlocation/${interviewLocationId}?${API_VERSION}`; },
  SCORECARD_CATEGORIES: (cn: string) => { return `payroll/${cn}/applicanttracking/scorecards/categories?${API_VERSION}`; },
  SCORECARD_CATEGORIES_ID: (cn: string, scorecardCategoryId: number) => { return `payroll/${cn}/applicanttracking/scorecards/categories/${scorecardCategoryId}?${API_VERSION}`; },
  POST_SCORECARD_CATEGORY_ID: (cn: string, scorecardCategoryId: number) => { return `payroll/${cn}/applicanttracking/designinterview/category/${scorecardCategoryId}?${API_VERSION}`; },
  SCORECARD_TRAITS: (cn: string) => { return `payroll/${cn}/applicanttracking/scorecards/traits?${API_VERSION}`; },
  SCORECARD_TRAITS_ID: (cn: string, scorecardTraitId: number) => { return `payroll/${cn}/applicanttracking/scorecards/traits/${scorecardTraitId}?${API_VERSION}`; },
  GET_DESIGN_WEBSITE: (cn: string) => { return `payroll/${cn}/applicantTracking/website`; },
  PUT_DESIGN_WEBSITE: (cn: string, webSiteConfigId: string, pageId: number) => { return `payroll/${cn}/applicantTracking/webSite/${webSiteConfigId}/pages/${pageId}/applicantPageField`; },
  POST_SCORECARD_TRAIT_ID: (cn: string, scorecardCategoryId: number) => { return `payroll/${cn}/applicanttracking/designinterview/trait/${scorecardCategoryId}?${API_VERSION}`; },
  CUSTOM_EMAILS: (cn: string) => { return `payroll/${cn}/applicanttracking/interviews/customemails?${API_VERSION}`; },
  CUSTOM_EMAILS_ID: (cn: string, customEmailId: number) => { return `payroll/${cn}/applicanttracking/interviews/customemails/${customEmailId}?${API_VERSION}`; },
  POST_CUSTOM_EMAIL_ID: (cn: string, customEmailId: number) => { return `payroll/${cn}/applicanttracking/designinterview/customemail/${customEmailId}?${API_VERSION}`; }, 
  TEST_CUSTOM_EMAIL: (cn: string) => { return `payroll/${cn}/applicanttracking/designinterview/testemail?${API_VERSION}`; }, 
  POST_CUSTOM_FIELD: (cn: string, websiteConfigId: number, pageId:number, customFieldId:number) => { return `payroll/${cn}/applicanttracking/website/${websiteConfigId}/pages/${pageId}/customfields/${customFieldId}?${API_VERSION}`; },
  DELETE_CUSTOM_FIELD: (cn: string, websiteConfigId: number, customFieldId:number) => { return `payroll/${cn}/applicanttracking/website/${websiteConfigId}/customfields/${customFieldId}?${API_VERSION}`; },
  PUT_APPLICANT_PAGES_FIELD: (cn: string, websiteConfigId: number) => { return `payroll/${cn}/applicanttracking/website/${websiteConfigId}/pages/applicantpagefield?${API_VERSION}`; },
  PUT_APPLICANT_APPLICANTWAIVERS: (cn: string, websiteConfigId: number) => { return `payroll/${cn}/applicanttracking/website/${websiteConfigId}/applicantformwaivers?${API_VERSION}`; },
  PUT_WEBSITE_SIGNATURE: (cn: string, websiteConfigId: number) => { return `payroll/${cn}/applicanttracking/website/${websiteConfigId}/signaturedescription?${API_VERSION}`; },
  PUT_WEBSITE_IMAGES: (cn: string, websiteConfigId: number) => { return `payroll/${cn}/applicanttracking/website/${websiteConfigId}/websiteimages?${API_VERSION}`; },
  WEBSITE_CUSTOMWAIVER_ID: (cn: string, websiteConfigId: number, customWaiverId: number) => { return `payroll/${cn}/applicanttracking/website/${websiteConfigId}/customwaiver/${customWaiverId}?${API_VERSION}`; },
  WEBSITE_IMAGES: (cn: string, websiteConfigId: number) => { return `payroll/${cn}/applicanttracking/website/${websiteConfigId}/websiteimage?${API_VERSION}`; },
  WEBSITE_IMAGES_ID: (cn: string, websiteConfigId: number, websiteImageId:number) => { return `payroll/${cn}/applicanttracking/website/${websiteConfigId}/images/${websiteImageId}?${API_VERSION}`; },
  WEBSITE_CHECK_CONFIG: (cn: string) => { return `payroll/${cn}/applicanttracking/website/checkforconfig?${API_VERSION}`; },
  DELETE_CUSTOM_WAIVER: (cn: string, websiteConfigId: number, customWaiverId: number) => { return `payroll/${cn}/applicantTracking/webSite/${websiteConfigId}/customWaiver/${customWaiverId}?${API_VERSION}`; },
};

export const DashboardEndPoints = {
  GET_NEWFEATURES: (cn: string) => { return `payroll/${cn}/dashboard/welcome/newfeatures?${API_VERSION}`; },
  GET_REMINDERS: (cn: string) => { return `payroll/${cn}/dashboard/welcome/reminders?${API_VERSION}`; },
  GET_EMPPAIDBREAKDOWN: (cn: string) => { return `payroll/${cn}/dashboard/welcome/empspaidbreakdown?${API_VERSION}`; },
  GET_ISSUESTOBERESOLVED: (cn: string, year: string) => { return `payroll/${cn}/dashboard/welcome/issuestoberesolved?Year=${year}`; },
  GET_EMPGROSSPAYANALYSIS: (cn: string) => { return `payroll/${cn}/dashboard/welcome/empgrosspayanalysis?${API_VERSION}`; },
  GET_EMPAPPLICANTNEWHIRE: (cn: string) => { return `payroll/${cn}/dashboard/welcome/empapplicantnewhire?${API_VERSION}`; },
  GET_PAYROLLTOTALSBYTIMEPERIODSUMMARY: (cn: string) => { return `payroll/${cn}/dashboard/welcome/payrolltotalsbytimeperiod?${API_VERSION}`; },
  GET_PAYROLLTOTALSBYLOCDEPTSUB: (cn: string) => { return `payroll/${cn}/dashboard/welcome/payrolltotalsbylocdeptsub?${API_VERSION}`; },
};

export const DropdownEndPoints = {
  GET_ALL: (cn: string) => { return `cm/${cn}/dropdowns/employeemaster?${API_VERSION}`; },
  GET_ALL2: (cn: string) => { return `cm/${cn}/dropdowns/employeemaster?${API_VERSION2}`; },

  POST_DROPDOWN: (cn: string) => { return `cm/${cn}/dropdowns/employeemaster?${API_VERSION}`; },
  PUT_DROPDOWN: (cn: string) => { return `cm/${cn}/dropdowns/employeemaster?${API_VERSION}`; },
  DELETE_DROPDOWN: (id: string, dropdownName: string, cn: string) => { return `cm/${cn}/dropdowns/employeemaster/${dropdownName}/${id}?${API_VERSION}`; },

  GET_DROPDOWN_PAYGRADE: (cn: string) => { return `cm/${cn}/dropdowns/employeemaster/paygrade?${API_VERSION}`; },
  POST_DROPDOWN_PAYGRADE: (cn: string) => { return `cm/${cn}/dropdowns/employeemaster/paygrade?${API_VERSION}`; },
  PUT_DROPDOWN_PAYGRADE: (cn: string) => { return `cm/${cn}/dropdowns/employeemaster/paygrade?${API_VERSION}`; },

  GET_JOB_TITLES: (cn: string) => { return `cm/${cn}/dropdowns/jobtitles?${API_VERSION}`; },

  GET_LOCATIONS: (cn: string) => { return `cm/${cn}/locations?${API_VERSION}`; },
  GET_LOCATION: (locationId: number, cn: string) => { return `cm/${cn}/locations/${locationId}?${API_VERSION}`; },
  POST_LOCATION: (cn: string) => { return `cm/${cn}/locations?${API_VERSION}`; },
  PUT_LOCATION: (locationID: number, cn: string) => { return `cm/${cn}/locations/${locationID}?${API_VERSION}`; },

  SCHOOLS: (cn: string) => { return `cm/${cn}/dropdowns/employeemaster/schools?${API_VERSION}`; },
  SCHOOL: (schoolId: number, cn: string) => { return `cm/${cn}/dropdowns/employeemaster/schools/${schoolId}?${API_VERSION}`; },

  HR_QUESTIONS: (cn: string) => { return `cm/${cn}/dropdowns/employeemaster/questions?${API_VERSION}`; },
  HR_QUESTION: (exitInterviewQuestionId: number, cn: string) => { return `cm/${cn}/dropdowns/employeemaster/questions/${exitInterviewQuestionId}?${API_VERSION}`; },

  DEPARTMENTS: (cn: string) => { return `cm/${cn}/departments?${API_VERSION}`; },
  DEPARTMENT: (departmentId: number, cn: string) => { return `cm/${cn}/departments/${departmentId}?${API_VERSION}`; },

  SUBDEPARTMENTS: (cn: string) => { return `cm/${cn}/subdepartments?${API_VERSION}`; },
  SUBDEPARTMENT: (subDepartmentId: number, cn: string) => { return `cm/${cn}/subdepartments/${subDepartmentId}?${API_VERSION}`; },

  SUBDEPARTMENTS2: (cn: string) => { return `cm/${cn}/subdepartments2?${API_VERSION}`; },
  SUBDEPARTMENT2: (subDepartmentId2: number, cn: string) => { return `cm/${cn}/subdepartments2/${subDepartmentId2}?${API_VERSION}`; },

  GET_SHIFT_PREMIUMS: (cn: string) => { return `cm/${cn}/shiftpremiums?${API_VERSION2}`; },
  GET_SHIFT_PREMIUM: (shiftPremiumId: number, cn: string) => { return `cm/${cn}/shiftpremiums/${shiftPremiumId}?${API_VERSION2}`; },
  POST_SHIFT_PREMIUM: (cn: string) => { return `cm/${cn}/shiftpremiums?${API_VERSION}`; },
  PUT_SHIFT_PREMIUM: (shiftPremiumId: number, cn: string) => { return `cm/${cn}/shiftpremiums/${shiftPremiumId}?${API_VERSION}`; },
  DELETE_SHIFT_PREMIUM: (shiftPremiumId: number, cn: string) => { return `cm/${cn}/shiftpremiums/${shiftPremiumId}?${API_VERSION}`; },
  POST_SHIFT_PREMIUM_EARNINGS_CODE: (shiftPremiumId: number, cn: string) => { return `cm/${cn}/shiftpremiums/${shiftPremiumId}/earningscodes?${API_VERSION}`; },
  DELETE_SHIFT_PREMIUM_EARNINGS_CODE: (
    shiftPremiumId: number,
    shiftPremiumDetailId: number,
    cn: string,
  ) => { return `cm/${cn}/shiftpremiums/${shiftPremiumId}/earningsCodes/${shiftPremiumDetailId}?${API_VERSION}`; },

  GET_TAX_ENTITIES: (country: string, state: string, type: string) => { return `cm/taxEntities/${country}/${state}/${type}?${API_VERSION}`; },

  GET_EARNING_CODES: (cn: string) => { return `cm/${cn}/earningcodes?${API_VERSION}`; },

  GET_CHECK_CODES: `payroll/shared/checkcodes?${API_VERSION}`,
};

export const EmployeeEndPoints = {
  GET_EMPLOYEES: (cn: string) => { return `payroll/${cn}/employees?${API_VERSION}`; },
  GET_EMPLOYEE: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}?${API_VERSION}`; },
  VALIDATE_EMPLOYEE: (cn: string, empNo: string) => `payroll/${cn}/employees/${empNo}/validate?${API_VERSION}`,
  POST_EMPLOYEE: (cn: string) => { return `payroll/${cn}/employees?${API_VERSION}`; },
  PUT_EMPLOYEE: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}?${API_VERSION}`; },
  DELETE_EMPLOYEE: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}?${API_VERSION}`; },
  
  PUT_EMPLOYEE_PHOTO: (cn: string, protectedEmpNo: string | number) => { return `payroll/${cn}/employees/${protectedEmpNo}/photo?${API_VERSION}`; },
  PUT_EMPLOYEE_STATUS: (cn: string, protectedEmpNo: string | number) => { return `payroll/${cn}/employees/${protectedEmpNo}/updateEmpStatus?${API_VERSION}`; },

  GET_PAYROLL_INFO: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/payrollinfo?${API_VERSION}`; },
  PUT_PAYROLL_INFO: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/payrollinfo?${API_VERSION}`; },

  GET_PAY_RATES: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/payrates?${API_VERSION}`; },
  GET_EMPLOYEE_PAY_RATES_SCREEN: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/payrates/screen?${API_VERSION}`; },
  GET_PAY_RATE: (cn: string, empNo: string | number, rateId: number) => { return `payroll/${cn}/employees/${empNo}/payrates/${rateId}?${API_VERSION}`; },
  POST_PAY_RATE: (cn: string, empNo: string | number, skipRaiseDate: boolean) => { return `payroll/${cn}/employees/${empNo}/payrates?skipRaiseDate=${skipRaiseDate}&${API_VERSION}`; },
  POST_EMPLOYEE_PAY_RATES_SCREEN: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/payrates/screen?${API_VERSION}`; },
  PUT_PAY_RATE: (cn: string, empNo: string | number, rateId: number, skipRaiseDate: boolean) => { return `payroll/${cn}/employees/${empNo}/payrates/${rateId}?skipRaiseDate=${skipRaiseDate}&${API_VERSION}`; },
  DELETE_PAY_RATE: (cn: string, empNo: string | number, rateId: number) => { return `payroll/${cn}/employees/${empNo}/payrates/${rateId}?${API_VERSION}`; },
  POST_CALCULATE_PAY_RATES: (cn: string) => { return `payroll/${cn}/tools/calculatepayrates?${API_VERSION}`; },

  GET_PAY_RATES_HISTORY: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/payrates/historygrid?${API_VERSION}`; },

  GET_FUTURE_PAY_RATES: (
    cn: string,
    empNo: string | number,
    originalRateId: number,
  ) => { return `payroll/${cn}/employees/${empNo}/payrates/${originalRateId}/futurepayrates?${API_VERSION}`; },
  POST_FUTURE_PAY_RATE: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/payrates/futurepayrate?${API_VERSION}`; },
  PUT_FUTURE_PAY_RATE: (cn: string, empNo: string | number, rateId: number) => { return `payroll/${cn}/employees/${empNo}/payrates/futurepayrate/${rateId}?${API_VERSION}`; },
  DELETE_FUTURE_PAY_RATE: (
    cn: string,
    empNo: string | number,
    rateId: number,
  ) => { return `payroll/${cn}/employees/${empNo}/payrates/futurepayrate/${rateId}?${API_VERSION}`; },

  GET_CONTACT_INFO: (cn: string, empNo: string | number) => { return `payroll/${cn}/contactinfo/${empNo}?${API_VERSION}`; },
  PUT_CONTACT_INFO: (cn: string, empNo: string | number) => { return `payroll/${cn}/contactinfo/${empNo}?${API_VERSION}`; },

  GET_DEDUCTIONS: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/deductions?${API_VERSION}`; },
  GET_DEDUCTIONS_BY_TYPE: ( cn: string, empNo: string | number, type: string) => { return `payroll/${cn}/employees/${empNo}/deductions?type=${type}&${API_VERSION}`; },
  DELETE_DEDUCTION: (cn: string, empNo: string | number, dedId: number) => { return `payroll/${cn}/employees/${empNo}/deductions/${dedId}?${API_VERSION}`; },
  POST_DEDUCTION_SHORTAGE: (cn: string) => { return `payroll/${cn}/reports/deductions/deductionshortage?${API_VERSION}`; },
  POST_DEDUCTION: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/deductions?${API_VERSION}`; },    

  POST_EMPLOYEE_CHANGE: (cn: string) => { return `payroll/${cn}/reports/employees/employeechangereport?${API_VERSION}`; },
  GET_COMPANY_BENEFITS: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/deductions?deductionType=companybenefit&${API_VERSION}`; },

  GET_PAY_STUBS: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/paystubs?${API_VERSION}`; },
  GET_PAY_STUB_PDF: (
    cn: string,
    empNo: string | number,
    mescID: string,
    checkDate: string,
    weekEnd: string,
    checkType: string,
    netPay: string,
    ctrlTotalBy: string,
  ) => { return `payroll/${cn}/employees/${empNo}/paystubs/paystubPDF?mescID=${mescID}&checkDate=${checkDate}&weekEnd=${weekEnd}&checkType=${checkType}&netPay=${netPay}&ctrlTotalBy=${ctrlTotalBy}&${API_VERSION}`; },
  POST_PAY_STUBS_PDF_MULTIPLE: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/paystubs/paystubspdfs?${API_VERSION}`; },

  GET_TAX_DOCS: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/documents/tax?${API_VERSION}`; },
  GET_EMP_DOCS: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/documents?${API_VERSION}`; },
  GET_EMP_DOCS_PDF: (filePath: string) => { return `payroll/file?filePath=${filePath}&${API_VERSION}`; },
  MOVE_CLIENT_H2R: (cn: string) => { return `payroll/${cn}/file/folder/move?${API_VERSION}`; },

  GET_EMPLOYEE_TRANSFER: (cn: string) => { return `payroll/${cn}/tools/employeetransfer?${API_VERSION}`; },
  PUT_EMPLOYEE_TRANSFER_VERIFY: (cn: string) => { return `payroll/${cn}/tools/employeetransfer/verify?${API_VERSION}`; },
  PUT_EMPLOYEE_TRANSFER: (cn: string) => { return `payroll/${cn}/tools/employeetransfer/transfer?${API_VERSION}`; },

  GET_VISA_STATUS: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/visastatus?${API_VERSION}`; },
  PUT_VISA_STATUS: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/visastatus?${API_VERSION}`; },
  DELETE_VISA_STATUS: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/visastatus?${API_VERSION}`; },

  PAYROLL_UPLOAD: (cn: string) => { return `payroll/${cn}/payrollupload?${API_VERSION}`; },
  PAYROLL_UPLOAD_MAP_DEFAULT: (cn: string) => { return `payroll/${cn}/payrollupload/default?${API_VERSION}`; },

  PUT_PAYROLL_UPLOAD: (cn: string, mapId: number) => { return `payroll/${cn}/payrollupload/${mapId}?${API_VERSION}`; },
  PUT_PAYROLL_UPLOAD_FILE: (cn: string) => { return `payroll/${cn}/tools/payrollupload?${API_VERSION}`; },
  PUT_PAYROLL_UPLOAD_FILE_CHECK: (cn: string) => { return `payroll/${cn}/tools/payrollupload/uploadedFile?${API_VERSION}`; },
  PUT_PAYROLL_UPLOAD_MAPS_UPDATE_ACTIVE_STATUS: (cn: string) => { return `payroll/${cn}/payrollupload/manageClientMaps?${API_VERSION}`; },
  PAYROLL_UPLOAD_COPY_MAP: (cn: string, mapId: number) => { return `payroll/${cn}/payrollupload/${mapId}/copymap?${API_VERSION}`; },
  PAYROLL_UPLOAD_FIELD: (cn: string, mapId: number) => { return `payroll/${cn}/payrollupload/${mapId}/fields?${API_VERSION}`; },
  PAYROLL_UPLOAD_FIELD_ID: (
    cn: string,
    mapId: number,
    customUploadMapId: number,
  ) => { return `payroll/${cn}/payrollupload/${mapId}/fields/${customUploadMapId}?${API_VERSION}`; },
  PAYROLL_UPLOAD_FIELD_TRANSLATIONS: (
    cn: string,
    mapId: number,
    customUploadMapId: number,
  ) => { return `payroll/${cn}/payrollupload/${mapId}/fields/${customUploadMapId}/translations?${API_VERSION}`; },
  PAYROLL_UPLOAD_FIELD_TRANSLATIONS_ID: (
    cn: string,
    mapId: number,
    customUploadMapId: number,
    translationId: number,
  ) => { return `payroll/${cn}/payrollupload/${mapId}/fields/${customUploadMapId}/translations/${translationId}?${API_VERSION}`; },
  PAYROLL_UPLOAD_FIELD_EXCEPTIONS: (
    cn: string,
    mapId: number,
    customUploadMapId: number,
  ) => { return `payroll/${cn}/payrollupload/${mapId}/fields/${customUploadMapId}/earncodeexceptions?${API_VERSION}`; },
  PAYROLL_UPLOAD_FIELD_EXCEPTIONS_ID: (
    cn: string,
    mapId: number,
    customUploadMapId: number,
    exceptionMapId: number,
  ) => { return `payroll/${cn}/payrollupload/${mapId}/fields/${customUploadMapId}/earncodeexceptions/${exceptionMapId}?${API_VERSION}`; },
  PAYROLL_UPLOAD_FIELD_RATES: (
    cn: string,
    mapId: number,
    customUploadMapId: number,
  ) => { return `payroll/${cn}/payrollupload/${mapId}/fields/${customUploadMapId}/earncoderates?${API_VERSION}`; },
  PAYROLL_UPLOAD_FIELD_RATES_ID: (
    cn: string,
    mapId: number,
    customUploadMapId: number,
    earnCodeRateMapId: number,
  ) => { return `payroll/${cn}/payrollupload/${mapId}/fields/${customUploadMapId}/earncoderates/${earnCodeRateMapId}?${API_VERSION}`; },

  GET_NEXT_EMPLOYEE_NUMBER: (cn: string) => { return `payroll/${cn}/employees/nextemployeenumber?${API_VERSION}`; },
  POST_VALIDATE_EMPLOYEE_NO: (cn: string) => { return `payroll/${cn}/employees/validateemployeenumber?${API_VERSION}`; },
  GET_ONBOARD_PAYROLL_USERS: (cn: string) => { return `payroll/${cn}/onboarding/payrollUsers?${API_VERSION}`; },
  GET_ONBOARD_JAZZHR_APPLICANTS: (cn: string) => { return `payroll/${cn}/onboarding/jazzHR?${API_VERSION}`; },
  POST_ONBOARD_JAZZHR_INTEGRATION: (cn: string) => { return `payroll/${cn}/onboarding/import/jazzHR?${API_VERSION}`; },
  POST_ONBOARD_EMPLOYEE: (cn: string) => { return `payroll/${cn}/onboarding?${API_VERSION}`; },
  GET_ONBOARD_DOCS: (cn: string) => { return `payroll/${cn}/onboarding/documents?${API_VERSION}`; },
  GET_ONBOARD_PAGE_CONFIGS: (cn: string) => { return `payroll/${cn}/onboarding/pageConfigs?${API_VERSION}`; },
  PUT_ONBOARD_PAGE_CONFIGS: (cn: string) => { return `payroll/${cn}/onboarding/pageConfigs?${API_VERSION}`; },
  GET_ONBOARD_EMAIL_TEMPLATES: (cn: string) => { return `payroll/${cn}/onboarding/emailTemplates?${API_VERSION}`; },
  POST_ONBOARD_EMAIL_TEMPLATES: (cn: string) => { return `payroll/${cn}/onboarding/emailTemplates?${API_VERSION}`; },
  POST_RESEND_ONBOARD_EMAIL: (cn: string, protectedEmpNo: string ) => { return `payroll/${cn}/onboarding/resendEmail/${protectedEmpNo}/employee`; },
  DELETE_ONBOARD_EMAIL_TEMPLATE: (cn: string, credentialEmailID: number) => { return `payroll/${cn}/onboarding/emailTemplate/${credentialEmailID}?${API_VERSION}`; },
  POST_ONBOARD_UPDATES: (cn: string) => { return `payroll/${cn}/onboarding/onboardingUpdates?${API_VERSION}`; },
  POST_ONBOARD_RESEND: (cn: string, applicantId:number) => { return `payroll/${cn}/applicantTracking/applicants/${applicantId}/resendOnboardingEmail?${API_VERSION}`; },
  GET_EMPLOYEE_UPLOADS: (cn: string) => { return `payroll/${cn}/employeeupload?${API_VERSION}`; },
  POST_EMPLOYEE_UPLOAD: (cn: string) => { return `payroll/${cn}/employeeupload/clientFiles?${API_VERSION}`; },
  PUT_EMPLOYEE_UPLOAD: (cn: string, clientFileId: number) => { return `payroll/${cn}/employeeupload/clientFiles/${clientFileId}?${API_VERSION}`; },
  GET_ONTHECLOCK_DATA: (cn: string, data: OnTheClockRequest) => { return `payroll/${cn}/tools/payrollupload/onTheClockData?fromDate=${data.fromDate}&toDate=${data.toDate}&${API_VERSION}`; },
  GET_AOD_MAPS: (cn: string) => { return `payroll/${cn}/tools/payrollupload/aodUploadMaps?${API_VERSION}`; },
  GET_ONBOARDING_STEPS: (cn: string, protectedEmpNo: string) => { return `payroll/${cn}/onboarding/${protectedEmpNo}/stepStatus?${API_VERSION}`; },

  PUT_EMPLOYEE_UPLOAD_CLIENT_LIBRARY: (cn: string, clientFileId: number) => { return `payroll/${cn}/tools/employeeupload/2021?${API_VERSION}`; },

  POST_EMPLOYEE_UPLOAD_FIELD: (cn: string, clientFileId: number) => { return `payroll/${cn}/employeeupload/clientFiles/${clientFileId}/maps?${API_VERSION}`; },
  PUT_EMPLOYEE_UPLOAD_FIELD: (
    cn: string,
    clientFileId: number,
    mapId: number,
  ) => { return `payroll/${cn}/employeeupload/clientFiles/${clientFileId}/maps/${mapId}?${API_VERSION}`; },
  DELETE_EMPLOYEE_UPLOAD_FIELD: (
    cn: string,
    clientFileId: number,
    mapId: number,
  ) => { return `payroll/${cn}/employeeupload/clientFiles/${clientFileId}/maps/${mapId}?${API_VERSION}`; },

  POST_EMPLOYEE_UPLOAD_TRANSLATION: (
    cn: string,
    clientFileId: number,
    mapId: number,
  ) => { return `payroll/${cn}/employeeupload/clientFiles/${clientFileId}/maps/${mapId}/translations?${API_VERSION}`; },
  POST_ADD_UPDATE_EMPLOYEE_UPLOAD_TRANSLATION: (
    cn: string,
    clientFileId: number,
    mapId: number,
  ) => { return `payroll/${cn}/employeeupload/clientFiles/${clientFileId}/maps/${mapId}/addUpdate/translations?${API_VERSION}`; },
  PUT_EMPLOYEE_UPLOAD_TRANSLATION: (
    cn: string,
    clientFileId: number,
    mapId: number,
    translationId: number,
  ) => { return `payroll/${cn}/employeeupload/clientFiles/${clientFileId}/maps/${mapId}/translations/${translationId}?${API_VERSION}`; },
  DELETE_EMPLOYEE_UPLOAD_TRANSLATION: (
    cn: string,
    clientFileId: number,
    mapId: number,
    translationId: number,
  ) => { return `payroll/${cn}/employeeupload/clientFiles/${clientFileId}/maps/${mapId}/translations/${translationId}?${API_VERSION}`; },

  GET_GLOBAL_DEDUCTIONS: (cn: string, dedNo: number) => { return `payroll/${cn}/globalDeductions/${dedNo}?${API_VERSION}`; },
  POST_GLOBAL_DEDUCTIONS: (cn: string) => { return `payroll/${cn}/globalDeductions?${API_VERSION}`; },

  GET_DEFERRED_COMPENSATION: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/deferredcompensation?${API_VERSION}`; },
  PUT_DEFERRED_COMPENSATION: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/deferredcompensation?${API_VERSION}`; },

  GET_SPECIAL_INFO: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/specialinfo?${API_VERSION}`; },
  PUT_SPECIAL_INFO: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/specialinfo?${API_VERSION}`; },

  GET_EMP_DATE: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/date?${API_VERSION}`; },
  PUT_EMP_DATE: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/date?${API_VERSION}`; },
  POST_EMP_REHIRE_DATE: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/rehiredates?${API_VERSION}`; },

  GET_HIRE_HISTORIES: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/hireHistory?${API_VERSION}`; },
  GET_HIRE_HISTORY: (cn: string, empNo: string | number, empDateID: number) => { return `payroll/${cn}/employees/${empNo}/hireHistory/${empDateID}?${API_VERSION}`; },
  POST_HIRE_HISTORY: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/hireHistory?${API_VERSION}`; },
  PUT_HIRE_HISTORY: (cn: string, empNo: string | number, empDateID: number) => { return `payroll/${cn}/employees/${empNo}/hireHistory/${empDateID}?${API_VERSION}`; },
  DELETE_HIRE_HISTORY: (
    cn: string,
    empNo: string | number,
    empDateID: number,
  ) => { return `payroll/${cn}/employees/${empNo}/hireHistory/${empDateID}?${API_VERSION}`; },

  GET_OTHER_DATES: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/otherDates?${API_VERSION}`; },
  GET_OTHER_DATE: (cn: string, empNo: string | number, otherDateID: number) => { return `payroll/${cn}/employees/${empNo}/otherDates/${otherDateID}?${API_VERSION}`; },
  POST_OTHER_DATE: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/otherDates?${API_VERSION}`; },
  PUT_OTHER_DATE: (cn: string, empNo: string | number, otherDateID: number) => { return `payroll/${cn}/employees/${empNo}/otherDates/${otherDateID}?${API_VERSION}`; },
  DELETE_OTHER_DATE: (
    cn: string,
    empNo: string | number,
    otherDateID: number,
  ) => { return `payroll/${cn}/employees/${empNo}/otherDates/${otherDateID}?${API_VERSION}`; },
  GET_RECURRING_EARNINGS: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/recurringearnings?${API_VERSION}`; },
  POST_RECURRING_EARNING: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/recurringearnings?${API_VERSION}`; },
  DELETE_RECURRING_EARNGING: (
    cn: string,
    empNo: string | number,
    recurringId: number,
  ) => { return `payroll/${cn}/employees/${empNo}/recurringearnings/${recurringId}?${API_VERSION}`; },
  POST_CLIENT_RATE_RULE: (cn: string, rateRuleId: number) => { return `payroll/${cn}/raterules/${rateRuleId}?${API_VERSION}`; },
  DELETE_CLIENT_RATE_RULE: (cn: string, rateRuleId: number) => { return `payroll/${cn}/raterules/${rateRuleId}?${API_VERSION}`; },
  GET_SUPERVISORS: (cn: string) => { return `payroll/${cn}/employees/supervisors?${API_VERSION}`; },
  GET_SUPERVISED_EMPLOYEE: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/supervisedemployees?${API_VERSION}`; },
  PUT_SUPERVISED_EMPLOYEE: (cn: string) => { return `/payroll/${cn}/employees/realignsupervisedemployees`; },
  GET_ACCRUALS: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/accruals?${API_VERSION}`; },
  PUT_ACCRUAL: (cn: string, empNo: string | number, type: string) => { return `payroll/${cn}/employees/${empNo}/accruals/${type}?${API_VERSION}`; },
  PUT_ACCRUAL_BEGIN_BALANCE: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/accruals/beginbalance?${API_VERSION}`; },
  POST_ACCRUAL_MANUAL_ENTRY: (
    cn: string,
    empNo: string | number,
    type: string,
  ) => { return `payroll/${cn}/employees/${empNo}/accruals/manualEntry/${type}?${API_VERSION}`; },
  POST_DEFAULT_ACCRUALS: (
    cn: string,
    empNo: string,
    hireDate: string,
  ) => `payroll/${cn}/employees/${empNo}/accruals/createDefaults?hireDate=${hireDate}&${API_VERSION}`,
  GET_ACCRUAL_RULE: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/accruals/accrualRule?${API_VERSION}`; },
  PUT_ACCRUAL_RULE: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/accruals/accrualRule?${API_VERSION}`; },
  GET_TIME_OFF_REQUESTS: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/timeOffRequests?${API_VERSION}`; },
  GET_TIME_OFF_REQUEST: (
    cn: string,
    empNo: string | number,
    timeOffRequestId: number,
  ) => { return `payroll/${cn}/employees/${empNo}/timeOffRequests/${timeOffRequestId}?${API_VERSION}`; },
  PUT_MARK_TAKEN: (
    cn: string,
    empNo: string | number,
    timeOffRequestId: number,
  ) => { return `payroll/${cn}/employees/${empNo}/timeOffRequests/markTaken/${timeOffRequestId}?${API_VERSION}`; },
  GET_ACCRUAL_TIME_OFF_SUMMARY: (cn: string, empNo: string | number) => { return `payroll/${cn}/employees/${empNo}/timeOffRequests/summary?${API_VERSION}`; },

  GET_TIME_OFF_PAST_DUE: (cn: string) => { return `payroll/${cn}/timeOffRequests/pastdue?${API_VERSION}`; },

  GET_ACCRUAL_EARNINGS_CODES: (cn: string) => { return `cm/${cn}/accrualearningscodes?${API_VERSION}`; },
  GET_YEAR_TO_DATE: (cn: string, empNo: string | number, prYear: number) => { return `payroll/${cn}/employees/${empNo}/ytd/${prYear}?${API_VERSION}`; },
  GET_EARNINGS_RECORDS: (cn: string, request: EarningsRecordsRequest) => { return `payroll/${cn}/employees/${request.protectedEmpNo}/earnings?beginDate=${request.beginDate}&endDate=${request.endDate}&byCheckDate=${request.byCheckDate}&${API_VERSION}`; },
  GET_AP_CLIENT_VENDORS: (cn: string) => { return `payroll/apvendors/${cn}?${API_VERSION}`; },
  POST_AP_CLIENT_VENDOR: () => { return `payroll/apvendors?${API_VERSION}`; },
  PUT_AP_CLIENT_VENDOR: (apVendorId: number) => { return `payroll/apvendors/${apVendorId}?${API_VERSION}`; },
  GET_AOD: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/attendanceondemand?${API_VERSION}`; },
  PUT_AOD: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/attendanceondemand?${API_VERSION}`; },
  GET_CLIENT_AOD: (cn: string) => { return `cm/${cn}/attendanceondemand?${API_VERSION}`; },
  POST_AOD_WORKGROUP: (cn: string) => { return `cm/${cn}/attendanceondemand/workgroups?${API_VERSION}`; },
  PUT_AOD_WORKGROUP: (cn: string) => { return `cm/${cn}/attendanceondemand/workgroups?${API_VERSION}`; },
  GET_CONTRACTOR_OPTIONS: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/contractoroptions?${API_VERSION}`; },
  PUT_CONTRACTOR_OPTIONS: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/contractoroptions?${API_VERSION}`; },

  EMPLOYEES_ACA_TAB: (cn: string, empNo: string, prYear: number) => { return `payroll/${cn}/employees/${empNo}/aca/${prYear}?${API_VERSION}`; },
  EMPLOYEES_ACA_OVERRIDES: (cn: string, empNo: string, prYear: number) => { return `payroll/${cn}/employees/${empNo}/aca/${prYear}/overrides?${API_VERSION}`; },
  EMPLOYEES_ACA_OVERRIDES_OFFEROFCOVERAGE: (
    cn: string,
    empNo: string,
    prYear: number,
  ) => { return `payroll/${cn}/employees/${empNo}/aca/${prYear}/overrides/offerofcoverage?${API_VERSION}`; },
  EMPLOYEES_ACA_OVERRIDES_EMPLOYEESHARE: (
    cn: string,
    empNo: string,
    prYear: number,
  ) => { return `payroll/${cn}/employees/${empNo}/aca/${prYear}/overrides/employeeshare?${API_VERSION}`; },
  EMPLOYEES_ACA_OVERRIDES_SAFEHARBOR: (
    cn: string,
    empNo: string,
    prYear: number,
  ) => { return `payroll/${cn}/employees/${empNo}/aca/${prYear}/overrides/safeharbor?${API_VERSION}`; },
  EMPLOYEES_ACA_OVERRIDES_ZIPCODE: (
    cn: string,
    empNo: string,
    prYear: number,
  ) => { return `payroll/${cn}/employees/${empNo}/aca/${prYear}/overrides/zipcode?${API_VERSION}`; },
  EMPLOYEES_ACA_OVERRIDES_EMPINFO: (
    cn: string,
    empNo: string,
    prYear: number,
  ) => { return `payroll/${cn}/employees/${empNo}/aca/${prYear}/overrides/empinfo?${API_VERSION}`; },
  EMPLOYEES_ACA_COVEREDINDIVIDUAL: (
    cn: string,
    empNo: string,
    prYear: number,
  ) => { return `payroll/${cn}/employees/${empNo}/aca/${prYear}/coveredindividual?${API_VERSION}`; },
  EMPLOYEES_ACA_COVEREDINDIVIDUAL_ID: (
    cn: string,
    empNo: string,
    prYear: number,
    id: number,
  ) => { return `payroll/${cn}/employees/${empNo}/aca/${prYear}/coveredindividual/${id}?${API_VERSION}`; },
  EMPLOYEES_ACA_STATUS: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/aca/status?${API_VERSION}`; },
  PUT_EMPLOYEES_ACA_STATUS: (
    cn: string,
    empNo: string,
    effectiveDate: string,
  ) => { return `payroll/${cn}/employees/${empNo}/aca/status/${effectiveDate}?${API_VERSION}`; },
  DELETE_EMPLOYEES_ACA_STATUS: (
    cn: string,
    empNo: string,
    effectiveDate: string,
  ) => { return `payroll/${cn}/employees/${empNo}/aca/status/${effectiveDate}?${API_VERSION}`; },
  EMPLOYEES_ACA_1095B: (cn: string, empNo: string, prYear: number) => { return `payroll/${cn}/employees/${empNo}/aca/1095b/${prYear}?${API_VERSION}`; },
  EMPLOYEES_ACA_WAIVECOVERAGE: (cn: string, empNo: string, prYear: number) => { return `payroll/${cn}/employees/${empNo}/aca/waivecoverage/${prYear}?${API_VERSION}`; },
  EMPLOYEES_DOC_COMP: (cn: string, empNo?: string) => { return `payroll/${cn}/employees/${empNo}/doccomp?${API_VERSION}`; },
  EMPLOYEES_DOC_COMP_ID: (cn: string, empNo?: string, id?: number) => { return `payroll/${cn}/employees/${empNo}/doccomp/${id}?${API_VERSION}`; },
  EMPLOYEES_FTE: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/fte?${API_VERSION}`; },
  EMPLOYEES_FTE_ID: (cn: string, empNo?: string, id?: number) => { return `payroll/${cn}/employees/${empNo}/fte/${id}?${API_VERSION}`; },
  EMPLOYEES_GROUP_TERM: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/grouptermlife?${API_VERSION}`; },
  EMPLOYEES_I9: (cn: string) => { return `payroll/${cn}/employees/i9?${API_VERSION}`; },
  EMPLOYEE_I9_REPORT: (cn: string) => { return `payroll/${cn}/employees/i9/i9datareport?${API_VERSION}`; },
  EMPLOYEE_I9: (cn: string, empNo: string) => { return `payroll/${cn}/employees/i9/${empNo}?${API_VERSION}`; },

  EMPLOYEES_SELF_SERVICE: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/selfservice?${API_VERSION}`; },
  EMPLOYEES_MYINFOACCOUNT_UNLOCK: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/myinfoaccount/unlock?${API_VERSION}`; },
  EMPLOYEES_MYINFOACCOUNT_RESETPW: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/myinfoaccount/resetpassword?${API_VERSION}`; },
  EMPLOYEES_MYINFOACCOUNT_CHANGEEMAIL: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/myinfoaccount/changeemail?${API_VERSION}`; },
  EMPLOYEES_MYINFOACCOUNT_DELETE: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/myinfoaccount/delete?${API_VERSION}`; },

  EMPLOYEES_MYINFOCHANGES: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/myinfochanges?${API_VERSION}`; },
  EMPLOYEES_MYINFOCHANGES_HISTORY: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/myinfochanges/history?${API_VERSION}`; },
  EMPLOYEES_MYINFOCHANGES_UPDATE: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/myinfochanges?${API_VERSION}`; },

  EMPLOYEES_HR_FIELD_HISTORY: (
    cn: string,
    empNo: string,
    section: string,
    field: string,
  ) => { return `payroll/${cn}/employees/${empNo}/hr/history/${section}/${field}?${API_VERSION}`; },
  EMPLOYEES_HR_HIRE_GENERAL: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/hr/hiregeneral?${API_VERSION}`; },
  EMPLOYEES_HR_CUSTOM_FIELDS: (cn: string, empNo: string, empId: number) => { return `payroll/${cn}/employees/${empNo}/hr/custom?empId=${empId}&${API_VERSION}`; },
  EMPLOYEES_HR_CUSTOM_FIELDS_ID: (
    cn: string,
    empNo: string,
    masterCustomFieldsId: number,
  ) => { return `payroll/${cn}/employees/${empNo}/hr/custom?masterCustomFieldsId=${masterCustomFieldsId}&${API_VERSION}`; },
  EMPLOYEES_HR_CUSTOM_FIELDS_ID_PATH: (
    cn: string,
    empNo: string,
    masterCustomFieldsId: number,
  ) => { return `payroll/${cn}/employees/${empNo}/hr/custom/${masterCustomFieldsId}?${API_VERSION}`; },

  HR_HIRE_EMPLOYMENT: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/hr/employment?${API_VERSION}`; },

  HR_HIRE_EMPLOYMENT_ID: (cn: string, empNo: string, employementId: number) => { return `payroll/${cn}/employees/${empNo}/hr/employment/${employementId}?${API_VERSION}`; },

  HR_HIRE_EDUCATION: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/hr/education?${API_VERSION}`; },

  HR_HIRE_EDUCATION_ID: (cn: string, empNo: string, employementId: number) => { return `payroll/${cn}/employees/${empNo}/hr/education/${employementId}?${API_VERSION}`; },

  HR_HIRE_LICENSE: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/hr/license?${API_VERSION}`; },

  HR_HIRE_LICENSE_ID: (cn: string, empNo: string, empLicenseId: number) => { return `payroll/${cn}/employees/${empNo}/hr/license/${empLicenseId}?${API_VERSION}`; },

  HR_HIRE_TRAINING: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/hr/training?${API_VERSION}`; },

  HR_HIRE_TRAINING_ID: (cn: string, empNo: string, empTrainingId: number) => { return `payroll/${cn}/employees/${empNo}/hr/training/${empTrainingId}?${API_VERSION}`; },

  HR_HIRE_SKILLS: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/hr/skills?${API_VERSION}`; },

  HR_HIRE_SKILLS_ID: (cn: string, empNo: string, empSkillId: number) => { return `payroll/${cn}/employees/${empNo}/hr/skills/${empSkillId}?${API_VERSION}`; },

  HR_HIRE_SOURCE: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/hr/trainingSources?${API_VERSION}`; },

  HR_HIRE_SOURCE_ID: (cn: string, empNo: string, empSourceFeeId: number) => { return `payroll/${cn}/employees/${empNo}/hr/trainingSources/${empSourceFeeId}?${API_VERSION}`; },

  HR_EXIT_INTERVIEW: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/hr/exitInterview?${API_VERSION}`; },

  HR_EXIT_INTERVIEW_ID: (cn: string, empNo: string, exitInterviewId: number) => { return `payroll/${cn}/employees/${empNo}/hr/exitInterview/${exitInterviewId}?${API_VERSION}`; },

  HR_INCREASE: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/hr/performance/increase?${API_VERSION}`; },

  HR_INCREASE_ID: (cn: string, empNo: string, increaseId: number) => { return `payroll/${cn}/employees/${empNo}/hr/performance/increase/${increaseId}?${API_VERSION}`; },


  HR_REVIEW: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/hr/performancereview?${API_VERSION}`; },

  HR_REVIEW_ID: (cn: string, empNo: string, empReviewId: number) => { return `payroll/${cn}/employees/${empNo}/hr/performancereview/${empReviewId}?${API_VERSION}`; },

  HR_DEPENDENT: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/hr/dependent?${API_VERSION}`; },

  HR_DEPENDENT_ID: (cn: string, empNo: string, contactId: number) => { return `payroll/${cn}/employees/${empNo}/hr/dependent/${contactId}?${API_VERSION}`; },

  HR_VACCINATION: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/hr/vaccination?${API_VERSION}`; },

  HR_VACCINATION_ID: (cn: string, empNo: string, vaccinationId: number) => { return `payroll/${cn}/employees/${empNo}/hr/vaccination/${vaccinationId}?${API_VERSION}`; },

  HR_VACCINATION_FILE: (cn: string, empNo: string, vaccinationId: number) => { return `payroll/${cn}/employees/${empNo}/hr/vaccination/${vaccinationId}/file?${API_VERSION}`; },

  HR_VACCINATION_FILE_ID: (cn: string, empNo: string, vaccinationId: number, employeeFieldId: number) => {
    return `payroll/${cn}/employees/${empNo}/hr/vaccination/${vaccinationId}/file/${employeeFieldId}?${API_VERSION}`;
  },

  HIRE_INFO_CUSTOM_FIELDS: (cn: string) => { return `payroll/${cn}/hr/hireinfocustomfields?${API_VERSION}`; },

  PAYROLL_CONTROLTOTALS: (cn: string, payrollHistoryId: number) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/controltotals?${API_VERSION}`; },
  PAYROLL_CONTROLTOTALS_ID: (
    cn: string,
    payrollHistoryId: number,
    controlTotalId: number,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/controltotals/${controlTotalId}?${API_VERSION}`; },
  PAYROLL: (cn: string, beginDate: Date | string, endDate: Date | string, byCheckDate?: boolean) => {
    return `payroll/${cn}/payrolls?beginDate=${beginDate}&endDate=${endDate}&byCheckDate=${byCheckDate}&${API_VERSION}`;
  },


  POST_PAYROLL: (cn: string) => { return `payroll/${cn}/payrolls?${API_VERSION}`; },
  POST_VALIDATE_PAYROLL_BEFORE_CREATE: (cn: string) => { return `payroll/${cn}/payrolls/validate?${API_VERSION}`; },
  DELETE_PAYROLL: (cn: string, payrollHistoryId: number) => { return `payroll/${cn}/payrolls/${payrollHistoryId}?${API_VERSION}`; },
  PAYROLL_OPTIONS: (cn: string, payrollHistoryId: number) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/payrollOptions?${API_VERSION}`; },
  PAYROLL_WIRE_ONLY: (cn: string, payrollHistoryId: number) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/wireOnlyAgreement?${API_VERSION}`; },
  PAYROLL_WIRE_ONLY_REPORT: (cn: string, payrollHistoryId: number) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/wireOnlyAgreementReport?${API_VERSION}`; },
  PAYROLL_PREVIEW: (cn: string, payrollHistoryId: number) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/preview?${API_VERSION}`; },
  PAYROLL_REGISTER_DETAIL: () => { return ``; },
  PAYROLL_CHECK_REGISTER: (cn: string, payrollHistoryId: number) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/payrollReports`; },
  POST_PAYROLL_AUTOFILL: (
    cn: string,
    payrollHistoryId: number,
    controlTotalId: number,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/controltotals/${controlTotalId}/autofill?${API_VERSION}`; },
  
  UPDATE_PAYROLL_DATELINE: (cn: string, payrollHistoryId: number, removeRecurringEarnings?: boolean, addRecurringEarnings?: boolean) => {
    return `payroll/${cn}/payrolls/${payrollHistoryId}?removeRecurringEarnings=${removeRecurringEarnings}&addRecurringEarnings=${addRecurringEarnings}&${API_VERSION}`;
  },

  GET_CLIENT_RECURRING_EARNINGS: (cn: string) => { return `payroll/${cn}/recurringearnings?${API_VERSION}`; },  

  POST_PAYROLL_VALIDATE: (
    cn: string,
    payrollHistoryId: number,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/validate?${API_VERSION}`; },

  GET_PAYROLL_PAYRATES_VALIDATION: (
    cn: string,
    payrollHistoryId: number,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/validate/payRate?${API_VERSION}`; },

  PUT_PAYROLL_PAYRATES_VALIDATION: (
    cn: string,
    payrollHistoryId: number,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/validate/payRate?${API_VERSION}`; },

  PUT_SQLMESCMAIN_CHECKNO: (
    cn: string,
    mescId: number,
  ) => { return `payroll/${cn}/payrolls/sqlMescMain/${mescId}/updateCheckNo?${API_VERSION}`; },

  POST_PAYROLL_APPLY_TIMEOFF_REQUESTS: (
    cn: string,
    payrollHistoryId: number,
    empNo: string,
    timeOffRequestId: number,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/outstandingtimeoffrequest/${empNo}/${timeOffRequestId}/applytotransmittal?${API_VERSION}`; },

  POST_PAYROLL_MOVE_TIMEOFF_REQUEST: (
    cn: string,
    oldPayrollHistoryId: number,
    empNo: string,
    timeOffRequestId: number,
    newPayrollHistoryId: number,
  ) => { return `payroll/${cn}/payrolls/${oldPayrollHistoryId}/outstandingtimeoffrequest/${empNo}/${timeOffRequestId}/moveToTransmittal/${newPayrollHistoryId}?${API_VERSION}`; },

  POST_PAYROLL_ACRRUAL_REPORT: (
    cn: string,
  ) => { return `/payroll/${cn}/reports/payroll/timeOffReports`; },

  PUT_UPDATE_AUTO_CALCS: (
    cn: string,
    payrollHistoryId: number,
    controlTotalId: number,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/controltotals/${controlTotalId}/employees/updateautocalcs?${API_VERSION}`; },

  PUT_DEPTSUBSUB2_ON_EMP_CHANGE: (
    cn: string,
    empNo: string,
  ) => { return `payroll/${cn}/payrolls/${empNo}/updateDeptSubSub2?${API_VERSION}`; },

  PAYROLL_TRANSMITTAL_EMPLOYEE: (
    cn: string,
    payrollHistoryId: number,
    controlTotalId: number,
    empNo: string,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/controltotals/${controlTotalId}/employees/${empNo}?${API_VERSION}`; },

  PAYROLL_TRANSMITTAL_EMPLOYEES: (
    cn: string,
    payrollHistoryId: number,
    controlTotalId: number,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/controltotals/${controlTotalId}/employees?${API_VERSION}`; },

  PAYROLL_TRANSMITTAL_EMPLOYEE_CHECK: (
    cn: string,
    payrollHistoryId: number,
    controlTotalId: number,
    empNo: string,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/controltotals/${controlTotalId}/employees/${empNo}/checks?${API_VERSION}`; },

  PAYROLL_TRANSMITTAL_EMPLOYEE_CHECK_BLANK: (
    cn: string,
    payrollHistoryId: number,
    controlTotalId: number,
    empNo: string,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/controltotals/${controlTotalId}/employees/${empNo}/checks/blank?${API_VERSION}`; },

  PAYROLL_TRANSMITTAL_EMPLOYEE_CHECK_ID: (
    cn: string,
    payrollHistoryId: number,
    controlTotalId: number,
    empNo: string,
    transmittalCheckId: number,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/controltotals/${controlTotalId}/employees/${empNo}/checks/${transmittalCheckId}?${API_VERSION}`; },

  ADD_CONTROL_TOTAL_BOOK: (cn: string, payrollHistoryId: number, controlTotalId: number) => {
    return `payroll/${cn}/payrolls/${payrollHistoryId}/controlTotals/${controlTotalId}/addBook?${API_VERSION}`;
  },

  PAYROLL_CONTROL_TOTAL_DETAIL: (
    cn: string,
    payrollHistoryId: number,
    controlTotalId: number,
    type: PayrollControlTotalDetailType = PayrollControlTotalDetailType.All,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/controltotals/${controlTotalId}/details?type=${type}&${API_VERSION}`; },

  POST_PAYROLL_UNPAIDEMPLOYEES_REPORT: (
    cn: string,
    payrollHistoryId: number,
    controlTotalId: number,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/controltotals/${controlTotalId}/unpaidemployeesreport?${API_VERSION}`; },

  POST_PAYROLL_TRANSMITTAL_REPORT: (
    cn: string,
    payrollHistoryId: number,
    controlTotalId: number,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/controltotals/${controlTotalId}/transmittalreport?${API_VERSION}`; },

  PAYROLL_ADJUSTMENTS: (cn: string, payrollHistoryId: number) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/adjustments/employees?${API_VERSION}`; },

  PAYROLL_ADJUSTMENT_STATE_INFO: (cn: string, payrollHistoryId: number) => {
    return `payroll/${cn}/payrolls/${payrollHistoryId}/adjustments/stateInfo`;
  },
  
  PAYROLL_ADJUSTMENTS_EMPLOYEE: (
    cn: string,
    payrollHistoryId: number,
    empNo: string,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/adjustments/employees/${empNo}?${API_VERSION}`; },
  
  PAYROLL_ADJUSTMENTS_EMPLOYEE_VOIDS: (
    cn: string,
    payrollHistoryId: number,
    empNo: string,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/adjustments/employees/${empNo}/voids?${API_VERSION}`; },

  PAYROLL_ADJUSTMENTS_EMPLOYEE_NEWCHECK: (
    cn: string,
    payrollHistoryId: number,
    protectedEmpNo: string,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/adjustments/employees/${protectedEmpNo}/newcheck?${API_VERSION}`; },

  PAYROLL_ADJUSTMENTS_EMPLOYEE_CHECKS: (
    cn: string,
    payrollHistoryId: number,
    protectedEmpNo: string,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/adjustments/employees/${protectedEmpNo}/checks?${API_VERSION}`; },

  PAYROLL_ADJUSTMENTS_EMPLOYEE_CHECK_VALIDATE: (
    cn: string,
    payrollHistoryId: number,
    empNo: string,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/adjustments/employees/${empNo}/checks/validate?${API_VERSION}`; },

  PAYROLL_ADJUSTMENTS_PRINT: (
    cn: string,
    payrollHistoryId: number,
    protectedEmpNo: string,
    adjustmentId: number,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/adjustments/employees/${protectedEmpNo}/checks/${adjustmentId}/print?${API_VERSION}`; },

  PAYROLL_VERIFY_ADJUSTMENT_CHECKS: (
    cn: string,
    payrollHistoryId: number,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/adjustments/checks/verify?${API_VERSION}`; },

  PAYROLL_ADJUSTMENTS_EMPLOYEE_CHECKS_ID: (
    cn: string,
    payrollHistoryId: number,
    protectedEmpNo: string,
    adjustmentId: number,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/adjustments/employees/${protectedEmpNo}/checks/${adjustmentId}?${API_VERSION}`; },

  PAYROLL_EMPLOYEE_VOIDSEARCH: (
    cn: string,
    empNo: string,
    beginDate: Date | string,
    endDate: Date | string,
  ) => { return `payroll/${cn}/payrolls/employeeVoidSearch/${empNo}?beginDate=${beginDate}&endDate=${endDate}&${API_VERSION}`; },

  GET_PAYROLL_VOID_SEARCH: (cn: string, params: PayrollVoidSearchParams) => { return `payroll/${cn}/payrolls/employeevoidsearch/${params.protectedEmpNo}?beginDate=${params.beginDate}&endDate=${params.endDate}&${API_VERSION}`; },
  POST_PAYROLL_ADJUSTMENT_VOID: (
    cn: string,
    payrollHistoryId: number,
    protectedEmpNo: string,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/adjustments/employees/${protectedEmpNo}/void?${API_VERSION}`; },
  PAYROLL_MESSAGES: (cn: string) => { return `payroll/${cn}/payrolls/messages?${API_VERSION}`; },
  PAYROLL_MESSAGES_ID: (cn: string, id?: number) => { return `payroll/${cn}/payrolls/messages/${id}?${API_VERSION}`; },

  POST_PAYROLL_MARK_SUBMITTED: (cn: string,
    payrollHistoryId: number,
  ) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/markassubmitted?${API_VERSION}`; },

  CAN_RECOVER_PAYROLL: (cn: string, payrollHistoryId: number) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/canRecover`;},
  RECOVER_PROCESSED_PAYROLL: (cn: string, payrollHistoryId: number) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/recover`; },
  
  GET_PAYROLLS_USER_OPTIONS: (cn: string) => { return `payroll/${cn}/payrolls/useroptions?${API_VERSION}`; },
  PUT_PAYROLLS_USER_OPTIONS: (cn: string) => { return `payroll/${cn}/payrolls/useroptions?${API_VERSION}`; },
  MYINFO_CHANGES: (cn: string) => { return `payroll/${cn}/employees/myinfochanges?${API_VERSION}`; },
  MYINFO_CHANGES_HISTORY: (cn: string) => { return `payroll/${cn}/employees/myinfochanges/history?${API_VERSION}`; },

  CHECK_CALCULATOR: (cn: string, empNo: string) => { return `payroll/${cn}/tools/${empNo}/checkcalculator?${API_VERSION}`; },
  CHECK_CALCULATOR_REPORT: (cn: string, empNo: string) => { return `payroll/${cn}/tools/${empNo}/checkcalculator/report?${API_VERSION}`; },
  CHECK_CALCULATOR_RECORDPREPAY: (cn: string, empNo: string) => { return `payroll/${cn}/tools/${empNo}/checkcalculator/recordprepay?${API_VERSION}`; },
  CHECK_CALCULATOR_PRINT: (cn: string, empNo: string) => { return `payroll/${cn}/tools/${empNo}/checkcalculator/printcheck?${API_VERSION}`; },
  CHECK_CALCULATOR_PRINT_VERIFY: (cn: string, empNo: string) => { return `payroll/${cn}/tools/${empNo}/checkcalculator/printcheck/verify?${API_VERSION}`; },
  CHECK_PAYROLLS: (
    cn: string,
    beginDate: Date | string,
    endDate: Date | string,
  ) => { return `payroll/${cn}/payrolls?beginDate=${beginDate}&endDate=${endDate}&${API_VERSION}`; },
  PRINT_CHECK_PAYROLLS: (
    cn: string,
    weekEnding: Date | string,
    checkDate: Date | string,
  ) => { return `payroll/${cn}/payrolls?weekEnding=${weekEnding}&checkDate=${checkDate}&${API_VERSION}`; },

  PAYROLL_DEDUCTION_STATUSES: (cn: string, payrollHistoryId: number) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/deductionstatuses?${API_VERSION}`; },

  PAYROLL_OUTSTANDING_TIMEOFF_REQUESTS: (cn: string, payrollHistoryId: number) => { return `payroll/${cn}/payrolls/${payrollHistoryId}/outstandingTimeOffRequests?${API_VERSION}`; },
  // SSO
  SSO: (cn: string) => { return `payroll/${cn}/sso?${API_VERSION}`; },
  // TAX
  TAX: (request: TaxRequest) => {
    let url = `payroll/${request.clientNo}/employees/${request.protectedEmpNo}/tax`;
    if (request.year !== undefined) {
      url += `/${request.year}/${request.entityId}`;
    }
    return url + `?${API_VERSION}`;
  },
  TAX_GENERATETAXFROM: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/tax/generatetaxform?${API_VERSION}`; },
  TAX_W4INFO: (cn: string, empNo: string) => { return `payroll/${cn}/employees/${empNo}/tax/w4Info?${API_VERSION}`; },
  POST_EARNINGS_REPORTS_STANDARD_REPORTS: (cn: string) => { return `payroll/${cn}/reports/earnings/standardreports?${API_VERSION}`; },
  POST_ACCRUAL_REPORTS_STANDARD_REPORTS: (cn: string) => { return `payroll/${cn}/reports/accrual/standardreports?${API_VERSION}`; },
  POST_ACCRUAL_REPORTS_ACCRUALTAB: (cn: string, protectedEmpNo: string) => { return `payroll/${cn}/reports/accrual/accrualtab/${protectedEmpNo}?${API_VERSION}`; },
  POST_EMPLOYEE_WAGE_DETAIL_REPORT: (cn: string) => { return `/payroll/${cn}/reports/earnings/employeewagedetailreport`; },
  GET_HELP_REFERENCE_GUIDE: (cn: string ) => { return `/payroll/${cn}/referenceGuide?${API_VERSION}`; },
};

export const AccountingReportsEndpoints = {
  POST_BANK_DISBURSEMENTS_REPORT: (cn: string) => { return `/payroll/${cn}/reports/accountingreports/bankdisbursementsreport`; },
  HOURS_EARNINGS_REPORT: (cn: string) => { return `/payroll/${cn}/reports/accountingreports/hoursandearningsreport`; },
  OVERTIME_SETTINGS: (cn: string) => { return `/payroll/${cn}/reports/accountingreports/overtimesettings`; },
  OVERTIME_SETTINGS_ID: (cn: string, otid: number) => { return `/payroll/${cn}/reports/accountingreports/overtimesettings/${otid}`; },
  POST_NEW_JOB_TRAINING_REPORT: (cn: string) => { return `/payroll/${cn}/reports/accountingreports/newjobtrainingcreditreport`; },
  POST_GENERAL_LEDGER_REPORT: (cn: string) => { return `/payroll/${cn}/reports/accountingreports/generalledgerreport`; },
  POST_GENERAL_LEDGER_REPORT_ERRORS: (cn: string) => { return `/payroll/${cn}/reports/accountingreports/generalledgerreport/errors`; },
  POST_GENERAL_LEDGER_DETAIL_REPORT: (cn: string) => { return `/payroll/${cn}/reports/accountingreports/generalledgerdetailreport`; },
  POST_GENERAL_LEDGER_EXPORT_REPORT: (cn: string) => { return `/payroll/${cn}/reports/accountingreports/generalledgerexportreport`; },
  POST_CARES_SBA_PPP_REPORT: (cn: string) => { return `payroll/${cn}/reports/accountingReports/caresSBAPPP`; },
};

export const CorrectionEndpoints = {
  GET_EEO_INFO_CORRECTIONS: (cn: string, empNos: string, beginDate: string, endDate: string) => {
    return `payroll/${cn}/corrections/eeoinfo?empNos=${empNos}&beginDate=${beginDate}&endDate=${endDate}`;
  },
  PUT_EEO_INFO_CORRECTIONS: (cn: string) => { return `payroll/${cn}/corrections/eeoinfo`; },
  
  GET_WORKERS_COMP_CORRECTIONS: (cn: string, empNos: string, beginDate: string, endDate: string) => {
    return `payroll/${cn}/corrections/workersComp?empNos=${empNos}&beginDate=${beginDate}&endDate=${endDate}`;
  },
  PUT_WORKERS_COMP_CORRECTIONS: (cn: string) => { return `payroll/${cn}/corrections/workersComp`; },

  GET_DEPT_ALLOCATION_EMPLOYEES: (cn: string) => { return `payroll/${cn}/corrections/deptAlloc/employees`; },
  
  GET_DEPT_ALLOCATION_CORRECTIONS: (cn: string, empNo: string, weekEnd: string, checkDate: string) => {
    return `payroll/${cn}/corrections/${empNo}/deptAlloc?weekEnd=${weekEnd}&checkDate=${checkDate}`;
  },
  POST_DEPT_ALLOCATION_CORRECTIONS: (cn: string, empNo: string) => {
    return `payroll/${cn}/corrections/${empNo}/deptAlloc`;
  },
  
  POST_MESC_EARN_CORRECTION: (cn: string, empNo: string, mescId: number) => {
    return `payroll/${cn}/corrections/${empNo}/${mescId}/earnCorrection`;
  },
  DELETE_MESC_EARN_CORRECTION: (cn: string, empNo: string, mescId: number, earnId: number) => {
    return `payroll/${cn}/corrections/${empNo}/${mescId}/earnCorrection/${earnId}`;
  },
  
  POST_CORRECTIONS_REPORT: (cn: string) => {
    return `payroll/${cn}/reports/employees/currentAllocationReport`;
  },
};

export const HumanCapitalReportEndpoints = {
  GET_CLIENT_REPORT_OPTIONS: (cn: string) => { return `/payroll/${cn}/reports/payroll/clientReportOptions`; },
  POST_FULLTIME_EQUIVALENT_REPORT: (cn: string) => { return `/payroll/${cn}/reports/humancapitalreports/fulltimeequivalentreport`; },
  POST_CUMULATIVE_SENIORITY_REPORT: (cn: string) => { return `/payroll/${cn}/reports/employees/employeeseniorityreport`; },
  MYINFO_DOC_STATUS: (cn: string) => { return `/payroll/${cn}/reports/humancapitalreports/myinfodocstatus`; },
  POST_DEMOGRPAPHIC_AND_TURNOVER_REPORT: (cn: string) => { return `/payroll/${cn}/reports/humancapitalreports/demographicandturnoverreport`; },
  DEMOGRPAPHIC_AND_TURNOVER_EMPLOYEES: (cn: string) => { return `/payroll/${cn}/reports/humancapitalreports/employees`; },
  DEMOGRPAPHIC_AND_TURNOVER_EMPLOYEES_ID: (cn: string, empNo: string) => { return `/payroll/${cn}/reports/humancapitalreports/employees/${empNo}`; },
  POST_EMPLOYEE_NAVIGATOR_AUDIT_REPORT: (cn: string) => { return `/payroll/${cn}/reports/humancapitalreports/empnavigatorauditreport`; },
  POST_DEPARTMENT_OF_LABOR_REPORT: (cn: string) => { return `/payroll/${cn}/reports/humancapitalreports/deptoflaborreport`; },
  //delete, post, update, get for settings
  LOAD_DEPARTMENT_OF_LABOR_SETTINGS_ID: (cn: string, settingId: number) => {return `/payroll/${cn}/reports/humanCapitalReports/deptOfLaborSettings/${settingId}}`;},
  DEPARTMENT_OF_LABOR_SETTINGS: (cn: string) => {return `/payroll/${cn}/reports/humanCapitalReports/deptOfLaborSettings`;},
  GET_UNION_DUES_SETTINGS: (cn: string) => {return `/payroll/${cn}/reports/Payroll/unionDueSettings`;},
  ADD_DEPARTMENT_OF_LABOR_SETTINGS: (cn: string) => {return `/payroll/${cn}/reports/humanCapitalReports/deptOfLaborSettings`;},
  PUT_DEPARTMENT_OF_LABOR_SETTINGS: (cn: string, settingId: number) => {return `/payroll/${cn}/reports/humanCapitalReports/deptOfLaborSettings/${settingId}`;},
  DELETE_DEPARTMENT_OF_LABOR_SETTINGS: (cn: string, settingId: number) => {return `/payroll/${cn}/reports/humanCapitalReports/deptOfLaborSettings/${settingId}`;},
  POST_ACAPROOFLIST_REPORT: (cn: string, payrollYear: number) => {return `/payroll/${cn}/reports/humancapitalreports/acaprooflist/${payrollYear}`;},
  GET_ACA_DATES_PER_YEAR: (cn: string, year: number) => {
    return `cm/${cn}/aca/dates/${year}`;
  },
  GET_ACA_YEAR: (cn: string, year: number) => {
    return `cm/${cn}/aca/${year}`;
  },
  UPDATE_ACA_DATES: (cn: string, year: number) => {
    return `cm/${cn}/aca/dates/${year}`;
  },
  GET_FULL_TIME_CALCULATION_REPORT: (cn: string, prYear: number) => {
    return `payroll/${cn}/reports/aca/fullTimeCalculationReport/${prYear}`;
  },
  POST_FULL_TIME_CALCULATION_REPORT: (cn: string) => {
    return `payroll/${cn}/reports/aca/fullTimeCalculationReport`;
  },
  MESC_ACA_HOURS: (cn: string, empNo: string, beginDate: string, endDate: string) => {
    return `payroll/${cn}/employees/${empNo}/aca/mescAcaHours?beginDate=${beginDate}&endDate=${endDate}`;
  },
  PUT_MESC_ACA_HOURS: (cn: string, empNo: string) => {
    return `payroll/${cn}/employees/${empNo}/aca/mescAcaHours`;
  },
  GET_EEO_REPORT_SETTINGS: (cn: string) => { return `/payroll/${cn}/reports/humancapitalreports/eeoreportsetting`; },
  GET_IS_BAMBOO_CLIENT: (cn: string) => { return `/payroll/${cn}/reports/humanCapitalReports/isBambooClient`; },
  GET_BAMBOO_HR: (cn: string, showTerminated:boolean, duplicatesOnly:boolean, clientNos:number[]) => { 
    return `/payroll/${cn}/reports/humancapitalreports/bamboohrdemographicaudit?showTerminated=${showTerminated}&duplicatesOnly=${duplicatesOnly}&clientNos=${clientNos}&${API_VERSION}`; 
  },
  POST_BAMBOO_HR: (cn: string) => { return `/payroll/${cn}/reports/humancapitalreports/bamboohrdemographicaudit?${API_VERSION}`; },
  PUT_EEO_REPORT_SETTINGS: (cn: string, settingId: number) => {return `/payroll/${cn}/reports/humancapitalreports/eeoreportsetting/${settingId}`;},
  GET_EEO_NO_SETTINGS: (cn: string) => {return `cm/${cn}/eeo/nosettings`;},
  POST_EEO_REPORT: (cn: string) => {return `payroll/${cn}/reports/humancapitalreports/eeoreport`;},
  POST_WOTC_REPORT: (cn: string) => {return `payroll/${cn}/reports/humancapitalreports/wotc`;},

  GET_EEO_PAYROLL_DATES: (cn: string, payrollYear: number) => {return `/payroll/${cn}/reports/humanCapitalReports/eeoPayrollDates/${payrollYear}?${API_VERSION}`;},

  GET_EEO_UNIT_SETTINGS: (cn: string) => {return `cm/${cn}/eeo/unitsettings?${API_VERSION}`;},
  POST_EEO_UNIT_SETTINGS: (cn: string) => {return `cm/${cn}/eeo/unitsettings`;},
  PUT_EEO_UNIT_SETTINGS: (cn: string, ruleID: number) => {return `cm/${cn}/eeo/unitsettings/${ruleID}`;},
  DELETE_EEO_UNIT_SETTINGS: (cn: string, ruleID: number) => { return `/cm/${cn}/dropdowns/employeemaster/eeounitsetting/${ruleID}`;},
  //{return `cm/${cn}/eeo/unitsettings/${ruleID}`;},
  ///cm/{{protected clientNo here}}/dropdowns/employeemaster/EEoUnitSetting/
  DELETE_EEO_NO_SETTINGS: (cn: string, id: number) => {return `cm/${cn}/dropdowns/employeemaster/eeonosetting/${id}`;},
  POST_EEO_NO_SETTINGS: (cn: string) => {return `cm/${cn}/eeo/nosettings`;},
  PUT_EEO_NO_SETTINGS: (cn: string, ruleID: number) => {return `cm/${cn}/eeo/nosettings/${ruleID}`;},
  GET_LOOKUP_EEO_UNITS: (cn: string) => { return `cm/${cn}/lookupEEOUnits?${API_VERSION}`; },
  GET_LOOKUP_EEO_UNIT: (cn: string, eeoUnitID: number) => { return `cm/${cn}/lookupEEOUnits/${eeoUnitID}?${API_VERSION}`; },
  POST_LOOKUP_EEO_UNITS: (cn: string) => { return `cm/${cn}/lookupEEOUnits?${API_VERSION}`; },
  PUT_LOOKUP_EEO_UNITS: (cn: string, eeoUnitID: number) => { return `cm/${cn}/lookupEEOUnits/${eeoUnitID}?${API_VERSION}`; },
  DELETE_LOOKUP_EEO_UNITS: (cn: string, eeoUnitID: number) => { return `cm/${cn}/lookupEEOUnits/${eeoUnitID}?${API_VERSION}`; },

  GET_LOOKUP_EEO_CERTIFY_OFFICIALS: (cn: string) => { return `cm/${cn}/lookupEEOCertifyOfficials?${API_VERSION}`; },
  GET_LOOKUP_EEO_CERTIFY_OFFICIAL: (cn: string, certifyOfficialID: number) => { return `cm/${cn}/lookupEEOCertifyOfficials/${certifyOfficialID}?${API_VERSION}`; },
  POST_LOOKUP_EEO_CERTIFY_OFFICIAL: (cn: string) => { return `cm/${cn}/lookupEEOCertifyOfficials?${API_VERSION}`; },
  PUT_LOOKUP_EEO_CERTIFY_OFFICIAL: (cn: string, certifyOfficialID: number) => { return `cm/${cn}/lookupEEOCertifyOfficials/${certifyOfficialID}?${API_VERSION}`; },
  DELETE_LOOKUP_EEO_CERTIFY_OFFICIAL: (
    cn: string,
    certifyOfficialID: number,
  ) => { return `cm/${cn}/lookupEEOCertifyOfficials/${certifyOfficialID}?${API_VERSION}`; },
};

export const PayrollEndpoints = {};

export const PayrollReportsEndpoints = {
  POST_PAYROLL_401K_REPORT: (cn: string) => { return `/payroll/${cn}/reports/payroll/401kcontribution`; },
  POST_PAYROLL_4070TIP_REPORT: (cn: string) => { return `/payroll/${cn}/reports/payroll/employee470tipsreport`; },
  POST_CASH_FLOW_REPORT: (cn: string) => { return `/payroll/${cn}/reports/payroll/cashflowreport`; },
  POST_ANNUAL_FICA_TIP_CREDIT_REPORT: (cn: string) => { return `/payroll/${cn}/reports/payroll/annualficatipcreditreport`; },
  POST_SCHEDULED_DEDUCTIONS_REPORT: (cn: string) => { return `/payroll/${cn}/reports/deductions/scheduleddeductions`; },
  POST_WORKERS_COMPENSATION_REPORT: (cn: string) => { return `/payroll/${cn}/reports/payroll/workercompensationreport`; },

  PAYROLL_PENSION_REPORT: (cn: string) => { return `/payroll/${cn}/reports/payroll/profitsharingreport`; },
  PAYROLL_PENSION_SETTING: (cn: string) => { return `/payroll/${cn}/reports/payroll/profitsharingsettings`; },
  PAYROLL_PENSION_SETTING_ID: (cn: string, settingId: number) => { return `/payroll/${cn}/reports/payroll/profitsharingsettings/${settingId}`; },

  PAYROLL_DEDUCTION_OPTIONS_REPORT: (cn: string) => { return `/payroll/${cn}/reports/payroll/deductionoptionsreport`; },

  PAYROLL_TAX_REPORT: (cn: string) => { return `/payroll/${cn}/reports/payroll/taxreport`; },
  POST_MEMO_CASH_FLOW_REPORT: (cn: string) => { return `/payroll/${cn}/reports/payroll/memocashflowreport`; },
  POST_EMPLOYEE_LAST_CHECK_REPORT: (cn: string) => { return `/payroll/${cn}/reports/payroll/employeelastcheckreport`; },
  POST_GROSS_TO_NET_REPORT: (cn: string) => { return `/payroll/${cn}/reports/payroll/payrollgrosstonet`; },
  GET_UNION_DUES_SETTINGS: (cn: string) => { return `/payroll/${cn}/reports/Payroll/unionDueSettings`; },
  ADD_UNION_DUES_SETTINGS: (cn: string) => { return `/payroll/${cn}/reports/Payroll/unionDueSettings`; },
  PUT_UNION_DUES_SETTINGS: (cn: string, settingId: number) => { return `/payroll/${cn}/reports/Payroll/unionDueSettings/${settingId}`; },
  DELETE_UNION_DUES_SETTINGS: (cn: string, settingId: number) => { return `/payroll/${cn}/reports/Payroll/unionDueSettings/${settingId}`; },
  POST_UNION_DUES_REPORT: (cn: string) => { return `/payroll/${cn}/reports/Payroll/unionDueReport`; },
  POST_DEDUCTION_SHORTAGES_REPORT: (cn: string) => { return `/payroll/${cn}/reports/deductions/deductionshortage`; },
  POST_MONTHLY_CASHFLOW_ANALYSIS_DOWNLOAD_REPORT: (cn: string) => { return `/payroll/${cn}/reports/payroll/monthlycashflowreport`; },
  POST_CARES_CREDIT_REPORT: (cn: string) => { return `/payroll/${cn}/reports/payroll/carescredit`; },

};

export const EmployeeReportsEndPoints = {
  EMPLOYEE_DEDUCTIONS_SHORTAGE: (cn: string) => { return `payroll/${cn}/reports/deductions/deductionshortage`; },
  GET_EMPLOYEE_CHANGE_REPORT: (
    cn: string,
    beginDate: string,
    endDate: string,
  ) => { return `payroll/${cn}/reports/employees/employeechangereport?beginDate=${beginDate}&endDate=${endDate}&${API_VERSION}`; },
  POST_EMPLOYEE_CHANGE_REPORT: (cn: string) => { return `payroll/${cn}/reports/employees/employeechangereport?${API_VERSION}`; },
  POST_EMPLOYEE_MASTER_REPORT: (cn: string, en: string) => { return `payroll/${cn}/reports/employees/${en}/employeemasterreport?${API_VERSION}`; },
  POST_EMP_CHANGE_REPORT: (cn: string) => { return `payroll/${cn}/reports/employees/employeechangereport`; },
  POST_PAY_CATEGORY_HISTORY: (cn: string) => { return `/payroll/${cn}/reports/employees/paycategoryhistory`; },
};
export const ClientLibraryEndpoints = {
  GET_CLIENT_LIBRARY: (cn:string, year: number) =>  { return `/payroll/${cn}/library/${year}`;},
  GET_CLIENT_FOLDER: (cn:string, folderPath: string) =>  { return `payroll/${cn}/library/folderFiles?folderPath=${folderPath}`;},
};

export const IntegrationEndpoints = {
  GET_CLIENT_INTEGRATION_LOGS: (cn:string) =>  { return `/payroll/${cn}/integrations/logs?${API_VERSION}`;},
};

export const IntuitEndpoints = {
  GET_CLIENT_INTUIT_AUTH: (cn: string) => { return `/payroll/${cn}/integrations/intuit/auth?${API_VERSION}`; },
  PUT_MANAGE_QUICKBOOKS_CONNECTION: (cn: string) => { return `/payroll/${cn}/integrations/intuit/quickBooksConnection?${API_VERSION}`; },
  POST_EXPORT_TO_QUICKBOOKS: (cn: string) => { return `/payroll/${cn}/integrations/intuit/exportToQuickBooks?${API_VERSION}`; },
  POST_CHART_OF_ACCOUNTS: (cn: string) => { return `/payroll/${cn}/integrations/intuit/chartOfAccounts?${API_VERSION}`; },
};

export const ClientMasterEndPoints = {
  CLIENT_ACA: (cn: string, year: number) => {return `cm/${cn}/aca/${year}?${API_VERSION}`;},
  GET_CLIENT: (cn: string) => {return `cm/${cn}?${API_VERSION}`;},
  GET_CLIENT_OPTIONS: (cn: string) => {return `cm/${cn}/options?${API_VERSION}`;},
  GET_CLIENT_OPTION: (cn: string, optionId: number) => { return `cm/${cn}/options/${optionId}?${API_VERSION}`; },
  GET_MODULE_ACCESS: (cn: string) => { return `payroll/${cn}/security?${API_VERSION}`; },
  GET_CLIENT_DEDUCTION: (cn: string) => {return `cm/${cn}/deductions?${API_VERSION}`;},

  GET_401K_OPTIONS: (cn: string) => { return `cm/${cn}/401kOptions?${API_VERSION}`; },

  GET_CLIENT_ENUM_OPTIONS: `cm/enums/options?${API_VERSION}`,

  GET_IS_NOVA_CLIENT: (cn: string) => { return `cm/${cn}/isNOVATimeClient?${API_VERSION}`; },
  GET_NOVA_TIME_SETTINGS: (cn: string) => { return `cm/${cn}/novatime/settings?${API_VERSION}`; },
  GET_NOVA_TIME: (cn: string) => { return `cm/${cn}/novatime?${API_VERSION}`; },
  GET_NOVA_TIME_SHIFT_NUMBERS: (cn: string) => { return `cm/${cn}/novatime/shiftnumbers?${API_VERSION}`; },
  GET_NOVA_TIME_RULES: (cn: string) => { return `cm/${cn}/novatime/rules?${API_VERSION}`; },

  GET_WC_EFFECTIVE_DATES: (cn: string) => { return `cm/${cn}/workerCompensations/effectiveDates?${API_VERSION}`; },
  GET_WC_EFFECTIVE_DATE: (cn: string, stateOfficerLimitID: number) => { return `cm/${cn}/workerCompensations/effectiveDates/${stateOfficerLimitID}?${API_VERSION}`; },
  POST_WC_EFFECTIVE_DATE: (cn: string) => { return `cm/${cn}/workerCompensations/effectiveDates?${API_VERSION}`; },
  PUT_WC_EFFECTIVE_DATE: (cn: string, stateOfficerLimitID: number) => { return `cm/${cn}/workerCompensations/effectiveDates/${stateOfficerLimitID}?${API_VERSION}`; },
  DELETE_WC_EFFECTIVE_DATE: (cn: string, stateOfficerLimitID: number) => { return `cm/${cn}/workerCompensations/effectiveDates/${stateOfficerLimitID}?${API_VERSION}`; },
  DELETE_WC_RATE: (cn: string, wcRuleID: number) => { return `cm/${cn}/workerCompensations/rates/${wcRuleID}?${API_VERSION}`; },

  PUT_ADD_PASSWORD_TO_PDF: `cm/tools/pdf/passwordonpdf?${API_VERSION}`,

  GET_RATE_RULES: (cn: string) => { return `cm/${cn}/raterules?${API_VERSION}`; },
  GET_RATE_RULE: (cn: string, rateId: number) => { return `cm/${cn}/raterules/${cn}?${API_VERSION}`; },
  POST_RATE_RULE: (cn: string) => { return `cm/${cn}/raterules?${API_VERSION}`; },
  PUT_RATE_RULE: (cn: string, rateId: number) => { return `cm/${cn}/raterules/${rateId}?${API_VERSION}`; },
  DELETE_RATE_RULE: (cn: string, rateId: number) => { return `cm/${cn}/raterules/${rateId}?${API_VERSION}`; },

  GET_EARNING_CODES: (cn: string) => { return `cm/${cn}/earningcodes?${API_VERSION}`; },
  EARNINGS_CODES_AUTOCALCULATIONS: (cn: string) => { return `cm/${cn}/earningcodes/autocalculations?${API_VERSION}`; },
  TAX_ENTITIES: (cn: string) => { return `cm/${cn}/taxEntities?${API_VERSION}`; },
  TAX_ENTITIES_FIPS: (cn: string) => { return `cm/taxEntities/fips?${API_VERSION}`; },
  GET_GARNISHMENT_ENTITIES: (cn: string) => { return `cm/${cn}/garnishmentEntities?${API_VERSION}`; },

  USERS: () => { return `cm/${CommonService.getProtectedClientNo()}/users?${API_VERSION}`; },
  HR360_USERS: () => { return `cm/userAccess/${CommonService.getProtectedClientNo()}/hr360?${API_VERSION}`; },
  HR360_USER_ID: (secUserId: number) => { return `cm/userAccess/${CommonService.getProtectedClientNo()}/hr360/${secUserId}?${API_VERSION}`; },
  PUT_DEFAULT_CLIENT: () => `cm/userAccess/${CommonService.getProtectedClientNo()}/updateDefaultClient?${API_VERSION}`,
};

export const SalesTaxTransmittalEndPoints = {
  GET_SALES_TAX_TRANSMITTAL: (cn: string, year: number) => { return `payroll/${cn}/${year}/salestax?${API_VERSION}`; },
  GET_SALES_TAX_TRANSMITTAL_ENTITY_AND_MONTH_ID: (
    cn: string,
    month: number,
    year: number,
    entityId: number,
    salesTaxMonthId: number,
  ) => { return `payroll/${cn}/${year}/salestax/${month}/${entityId}/${salesTaxMonthId}?${API_VERSION}`; },
  PUT_SALES_TAX_TRANSMITTAL_ENTITY_ID: (
    cn: string,
    month: number,
    year: number,
    entityId: number,
    salesTaxMonthId: number,
  ) => { return `payroll/${cn}/${year}/salestax/${month}/${entityId}?salesTaxMonthId=${salesTaxMonthId}&${API_VERSION}`; },
  POST_SALES_TAX_TRANSMITTAL_ENTITY_ID_REPORT: (
    cn: string,
    month: number,
    year: number,
    entityId: number,
  ) => { return `payroll/${cn}/${year}/salestax/${month}/${entityId}/report?${API_VERSION}`; },
  POST_SALES_TAX_TRANSMITTAL_ADD: (cn: string, month: number, year: number) => { return `payroll/${cn}/${year}/salestax/${month}?${API_VERSION}`; },
  POST_SALES_TAX_TRANSMITTAL_CLOSE: (
    cn: string,
    month: number,
    year: number,
  ) => { return `payroll/${cn}/${year}/salestax/${month}/close?${API_VERSION}`; },
};

export const HrEndpoints = {
  HR_EMPLOYEE_STATUS: (protectedEmpNo: string) => { return `/payroll/${CommonService.getProtectedClientNo()}/employees/${protectedEmpNo}/hr/status`; },
  HR_EMPLOYEE_EMERGENCY_CONTACTS: (protectedEmpNo: string) => { return `/payroll/${CommonService.getProtectedClientNo()}/employees/${protectedEmpNo}/hr/emergencycontacts`; },
  HR_EMPLOYEE_EMERGENCY_CONTACTS_CONTACT_ID: (
    request: HrEmployeeStatusEmergencyContactIdRequest,
  ) => {
    return `${HrEndpoints.HR_EMPLOYEE_EMERGENCY_CONTACTS(
      request.protectedEmpNo,
    )}/${request.contactId}`;
  },
  HR_EMP_CUSTOM_FIELDS: (protectedEmpNo: string) => { return `/payroll/${CommonService.getProtectedClientNo()}/employees/${protectedEmpNo}/hr/custom`; },
  HR_EMP_CUSTOM_FIELDS_ID: (protectedEmpNo: string, customFieldsId: number) => { return `/payroll/${CommonService.getProtectedClientNo()}/employees/${protectedEmpNo}/hr/custom/${customFieldsId}`; },
  HR_EMPLOYEE_COMPANY_PROPERTY: (protectedEmpNo: string) => { return `/payroll/${CommonService.getProtectedClientNo()}/employees/${protectedEmpNo}/hr/companyproperty`; },
  HR_EMPLOYEE_COMPANY_PROPERTY_ID: (
    protectedEmpNo: string,
    propertyId: number,
  ) => { return `/payroll/${CommonService.getProtectedClientNo()}/employees/${protectedEmpNo}/hr/companyproperty/${propertyId}`; },
};

export const FileEndPoints = {
  FILE: (filePath: string) => { return `payroll/file/?filePath=${filePath}&${API_VERSION}`; },
  FILE_ID: (fileRecordId: number) => { return `payroll/${CommonService.getProtectedClientNo()}/file/${fileRecordId}?${API_VERSION}`; },
  USER_FILES: (displayType: string) => { return `payroll/${CommonService.getProtectedClientNo()}/file/userfiles?displayType=${displayType}&${API_VERSION}`; },
  FILE_UPLOAD: () => { return `payroll/${CommonService.getProtectedClientNo()}/file/upload?${API_VERSION}`; },
  FILE_FOLDER: () => { return `payroll/${CommonService.getProtectedClientNo()}/file/folder?${API_VERSION}`; },
  FILE_FOLDER_ID: (fileCategoryId: number) => { return `payroll/${CommonService.getProtectedClientNo()}/file/folder/${fileCategoryId}?${API_VERSION}`; },
  FILE_ASSIGN_FILE: () => { return `payroll/${CommonService.getProtectedClientNo()}/file/assign?${API_VERSION}`; },
  DOWNLOAD_MULTIPLE_FILES: () => { return `payroll/${CommonService.getProtectedClientNo()}/multipleFiles?${API_VERSION}`; },
};

export const ReportsEndpoints = {
  HR_VACCINATION_REPORT: () => { return `/payroll/${CommonService.getProtectedClientNo()}/reports/hrreports/vaccinationreport`; },
  POST_EMPLOYEE_AVERAGE_HOURS_REPORT: () => { return `/payroll/${CommonService.getProtectedClientNo()}/reports/accountingreports/employeeaveragehoursreport`; },
};

export const DownloaderEndPoints = {
  DOWNLOADER: () => { return `/payroll/${CommonService.getProtectedClientNo()}/downloader?${API_VERSION}`; },
  USERMAPS: () => { return `/payroll/${CommonService.getProtectedClientNo()}/downloader/usermaps?${API_VERSION}`; },
  USERMAPS_ID: (userMapId: number) => { return `/payroll/${CommonService.getProtectedClientNo()}/downloader/usermaps/${userMapId}?${API_VERSION}`; },
  COPYMAP: (userMapId: number) => { return `/payroll/${CommonService.getProtectedClientNo()}/downloader/usermaps/${userMapId}/copymap?${API_VERSION}`; },
  EMAILS: (userMapId: number) => { return `/payroll/${CommonService.getProtectedClientNo()}/downloader/usermaps/${userMapId}/emails?${API_VERSION}`; },
  FORMAT_EXPORT: (userMapId: number) => { return `/payroll/${CommonService.getProtectedClientNo()}/downloader/usermaps/${userMapId}/formatexport?${API_VERSION}`; },
  SCHEDULE_DOWNLOAD: (userMapId: number) => { return `/payroll/${CommonService.getProtectedClientNo()}/downloader/usermaps/${userMapId}/scheduledownload?${API_VERSION}`; },
  AVAILABLE_FONTS: () => {return `/payroll/shared/availableFonts?${API_VERSION}`;},
  DOWNLOADER_FIELDS:  () => {return `/payroll/${CommonService.getProtectedClientNo()}/downloader/fields?${API_VERSION}`;},
};

export const PayRateReportEndpoints = {
  POST_EMPLOYEE_PAY_RATE_DOWNLOAD: () => { return `/payroll/${CommonService.getProtectedClientNo()}/reports/payrate/employeepayratereport`; },
  FUTURE_PAY_RATE_REPORT: () => { return `/payroll/${CommonService.getProtectedClientNo()}/reports/payrate/futurepayratereport`; },
  POST_EMPLOYEE_3YEAR_PAY_HISTORY: () => { return `/payroll/${CommonService.getProtectedClientNo()}/reports/payrate/threeyearpayhistory`; },
};

export const ContractorReportEndpoints = {
  POST_OHIO_I29: (cn: string) => { return `/payroll/${cn}/reports/contractor/ohioi29`; },
  POST_WH347_Certified_Report: (cn: string) => { return `/payroll/${cn}/reports/contractor/CertifiedJobDetail`; },
  POST_WEEK_MONTH_JTD_Job_Cost_Report: (cn: string) => { return `/payroll/${cn}/reports/contractor/weekmonthjtd`; },
  POST_JOB_DETAIL_REPORT: (cn: string) => { return `/payroll/${cn}/reports/contractor/jobdetail`; },
  POST_APPROVED_JOB_REPORT: (cn: string) => { return `/payroll/${cn}/reports/contractor/approvejobreport`; },
  GET_BRICK_LAYER_RATES: (cn: string) => { return `payroll/${cn}/reports/contractor/brickLayerRates`; },
  POST_ELECTRICAL_FRINGE_REPORT: (cn: string) => { return `/payroll/${cn}/reports/contractor/electricalfringe`; },
  POST_FRINGE_REPORT: (cn: string) => { return `/payroll/${cn}/reports/contractor/fringereport`; },
  POST_NATIONAL_AGREEMENT_REPORT: (cn: string) => { return `/payroll/${cn}/reports/contractor/nationalagreement`; },
  POST_WORKERS_COMPENSATION_REPORT: (cn: string) => { return `/payroll/${cn}/reports/contractor/contractorworkerscomp`; },
  POST_EEO_CC2257_REPORT: (cn: string) => { return `/payroll/${cn}/reports/contractor/eeocc257`; },
  POST_JOB_LABOR_REPORT: (cn: string) => { return `/payroll/${cn}/reports/contractor/JobLabor`; },

  POST_JOB_GROSS_SUMMARY_REPORT: (cn: string) => { return `/payroll/${cn}/reports/contractor/jobGrossSummary`; },
  POST_JOB_TO_DATE_GROSS_SUMMARY_REPORT: (cn: string) => { return `/payroll/${cn}/reports/contractor/JobToDate`; },
  POST_EMPLOYEE_TIME_CARD: (cn: string) => { return `/payroll/${cn}/reports/contractor/employeetimecard`; },
  RATE_MASTER: (cn: string) => { return `/payroll/${cn}/reports/contractor/ratemaster`; },
  POST_OCIP_REPORT: (cn: string) => { return `/payroll/${cn}/reports/contractor/ocip`; },
  POST_EMPLOYEE_GROSS_SUMMARY_REPORT: (cn: string) => { return `/payroll/${cn}/reports/contractor/EmpGrossSummary`; },
  GET_CONTRACTOR_OVERVIEW: (cn: string) => { return `/payroll/${cn}/reports/contractor/overview`; },
  POST_CONTRACTOR_OVERVIEW: (cn: string) => { return `/payroll/${cn}/reports/contractor/overview`; },
  GET_CONTRACTOR_FRINGE: (cn: string) => {return `/payroll/${cn}/reports/contractor/fringeDownloadMaps`;},
  POST_CONTRACTOR_FRINGE_DOWNLOAD: (cn: string) => {return `/payroll/${cn}/reports/contractor/fringeDownload`;},
  POST_CONTRACTOR_FRINGE: (cn: string) => {return `/payroll/${cn}/reports/contractor/fringeDownloadMaps`;},
  PUT_CONTRACTOR_FRINGE: (cn: string, mapKey: string) => {return `/payroll/${cn}/reports/contractor/fringeDownloadMaps/${mapKey}`;},
  DELETE_CONTRACTOR_FRINGE: (cn: string, mapKey: string) => {
    return `/payroll/${cn}/reports/contractor/fringeDownloadMaps/${mapKey}`;
  },
};

export const ContractorEndpoints = {
  // job setup
  GET_ALL_JOBS_INFO: (cn: string) => `payroll/${cn}/timecard/jobInfos?${API_VERSION}`,
  GET_JOB_INFO: (cn: string, jobNo: string) => `payroll/${cn}/timecard/jobInfo/${jobNo}?${API_VERSION}`,
  ADD_UPDATE_JOB_INFO: (cn: string, jobNo: string) => `payroll/${cn}/timecard/jobInfo/${jobNo}?${API_VERSION}`,
  ADD_JOB_INFO_FROM_FILE: (cn: string) => `payroll/${cn}/timecard/jobInfoFile?${API_VERSION}`,
  DELETE_JOB_INFO: (cn: string, jobNo: string) => `payroll/${cn}/timecard/jobInfo/${jobNo}?${API_VERSION}`,
  // sub setup
  GET_ALL_SUBS_INFO: (cn: string) => `payroll/${cn}/timecard/subInfos?${API_VERSION}`,
  GET_SUB_INFO: (cn: string, subNo: string) => `payroll/${cn}/timecard/subInfo/${subNo}?${API_VERSION}`,
  ADD_UPDATE_SUB_INFO: (cn: string, subNo: string) => `payroll/${cn}/timecard/subInfo/${subNo}?${API_VERSION}`,
  DELETE_SUB_INFO: (cn: string, subNo: string) => `payroll/${cn}/timecard/subInfo/${subNo}?${API_VERSION}`,
  // cost code setup
  GET_ALL_COST_CODES: (cn: string) => `payroll/${cn}/timecard/costCodeInfos?${API_VERSION}`,
  GET_COST_CODE_INFO: (cn: string, costCode: string) => `payroll/${cn}/timecard/costCodeInfo/${costCode}?${API_VERSION}`,
  ADD_UPDATE_COST_CODE_INFO: (cn: string, costCode: string) => `payroll/${cn}/timecard/costCodeInfo/${costCode}?${API_VERSION}`,
  DELETE_COST_CODE_INFO: (cn: string, costCode: string) => `payroll/${cn}/timecard/costCodeInfo/${costCode}?${API_VERSION}`,
  // diagnostic rules
  GET_DIAGNOSTIC_RULE: (cn: string) => `payroll/${cn}/timecard/diagnosticRules?${API_VERSION}`,
  POST_DIAGNOSTIC_RULE: (cn: string) => `payroll/${cn}/timecard/diagnosticRules?${API_VERSION}`,
  // local tax entities for dropdown options in job details
  GET_LOCAL_TAX_ENTITIES: (cn: string, includeBlank?: boolean, includeNA?: boolean) =>
    `cm/${cn}/localTaxEntities?includeBlank=${!!includeBlank}&includeNA=${!!includeNA}&${API_VERSION}`,
  // time sheet entry
  GET_ALL_TIME_CARDS: (cn: string, payrollHistoryId: number) => `payroll/${cn}/timecard/${payrollHistoryId}/timecards`,
  GET_TIME_CARD: (cn: string, payrollHistoryId: number, transmittalTimeCardId: number) => `payroll/${cn}/timecard/${payrollHistoryId}/timecards/${transmittalTimeCardId}?${API_VERSION}`,
  GET_TIME_CARDS_BY_DAY: (cn: string, payrollHistoryId: number) => `payroll/${cn}/timecard/${payrollHistoryId}/timecardsByDay`,
  GET_TIME_CARD_DATES: (cn: string, weekEnd: string | Date) => `payroll/${cn}/timecard/dates?weekEnd=${weekEnd}&${API_VERSION}`,
  GET_TIME_CARDS_BY_JobRoster: (cn: string, payrollHistoryId: number) => `payroll/${cn}/timecard/${payrollHistoryId}/timecardsByJobRoster`,
  ADD_TIME_CARD: (cn: string, payrollHistoryId: number, empNo: string, nextCheckCode: string) => `payroll/${cn}/timecard/${payrollHistoryId}/timecards/${empNo}/${nextCheckCode}?${API_VERSION}`,
  UPDATE_TIME_CARD: (cn: string, payrollHistoryId: number, empNo: string, transmittalTimeCardId: number) => `payroll/${cn}/timecard/${payrollHistoryId}/timecards/${empNo}/${transmittalTimeCardId}?${API_VERSION}`,
  ADD_UPDATE_TIME_CARDS: (cn: string, payrollHistoryId: number) => `payroll/${cn}/timecard/${payrollHistoryId}/timecards?${API_VERSION}`,
  DELETE_TIME_CARD: (cn: string, payrollHistoryId: number, transmittalTimeCardId: number) => `payroll/${cn}/timecard/${payrollHistoryId}/timecards/${transmittalTimeCardId}?${API_VERSION}`,
  GET_TIME_CARD_CREW_SHEET_COLUMNS: (cn: string) => `payroll/${cn}/timecard/crewSheetColumns?${API_VERSION}`,
  GET_TIME_CARD_CONTROL_TOTAL_DETAILS: (cn: string, request: PayrollControlTotalRequest) => `payroll/${cn}/timecard/${request.payrollHistoryId}/controlTotalDetails?type=${request.type}&${API_VERSION}`,
  ADD_UPDATE_TIME_CARD_CREW_SHEET_COLUMNS: (cn: string) => `payroll/${cn}/timecard/crewSheetColumns?${API_VERSION}`,
  GET_HOME_ATS_INFO: (cn: string, empNo: string, effectiveDate: string) => `payroll/${cn}/timecard/homeAtsInfo/${empNo}?effectiveDate=${effectiveDate}&${API_VERSION}`,
};

export const WorkFlowEndpoints = {
  ADD_WORK_FLOW_TASK: (cn: string) => `payroll/${cn}/workFlow/workFlowTask?${API_VERSION}`,
  UPDATE_WORK_FLOW_TASK_STATUS: (cn: string, workFlowTaskId: number) => `payroll/${cn}/workFlow/workFlowTaskStatus/${workFlowTaskId}?${API_VERSION}`,
  GET_WORK_FLOW_TASK_LIST: (cn: string) => `payroll/${cn}/workFlow/workFlowTask?${API_VERSION}`,
  CREATE_DEFAULT_LEVEL_USER: (cn: string) => `payroll/${cn}/workFlow/defaultLevelUser?${API_VERSION}`,

};

