import React, { useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import EmployeeInformation from 'core/components/shared/EmployeeInformation';
import EarningsRecordsPage from '../emp-detail/earnings-records/EarningsRecordsPage';
import EmployeeInformationSidebar from './Sidebar';
import { convToDateISOString } from 'utilities/utilities';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import { ControlDatePickerGrp, RadioGrpInLine } from 'core/components/form-controls';
import { RadioOptions } from 'core/components/form-controls/RadioGrp';
import { useAppDispatch } from 'utilities/hooks';
import { storeBeginDate, storeCheckDate, storeEndDate } from 'core/store/actions/earnings-reports.action';

const byCheckDateOptions: RadioOptions[] = [
  {
    value: 'true',
    label: 'By Check Date',
  },
  {
    value: 'false',
    label: 'By Week End',
  },
];

type FormProps = {
  beginDate: Date;
  endDate: Date;
  byCheckDate: string;
};

const EmployeeInformationEarnings = () => {
  const dispatch = useAppDispatch();
  
  const [protectedEmpNo, setProtectedEmpNo] = useState('');
  const [beginDate, setBeginDate] = useState<Date | string>(DateTime.local().startOf('year').toISO());
  const [endDate, setEndDate] = useState<Date | string>(DateTime.local().endOf('year').toISO());
  const [byCheckDate, setByCheckDate] = useState<boolean>(true);

  const { control, handleSubmit, register, errors, watch } = useForm<FormProps>({
    defaultValues: {
      beginDate: DateTime.local().startOf('year').toISO(),
      endDate: DateTime.local().endOf('year').toISO(),
      byCheckDate: 'true',
    },
  });

  const formData = watch();

  useEffect(() => {
    dispatch(storeBeginDate(convToDateISOString(formData.beginDate)));
    dispatch(storeEndDate(convToDateISOString(formData.endDate)));
    dispatch(storeCheckDate(formData.byCheckDate === 'true'));
  }, [formData]);

  const onSelect = (_protectedEmpNo: string) => {
    setProtectedEmpNo(_protectedEmpNo);
  };

  const onSubmit = (data: FormProps) => {
    setBeginDate(data.beginDate);
    setEndDate(data.endDate);
    setByCheckDate(data.byCheckDate === 'true');
  };

  return (
    <Row className="no-gutters">
      <EmployeeInformationSidebar onSelect={onSelect} />
      <Col className="m-3 shadow p-3 border">
        {protectedEmpNo ? (
          <>
            <EmployeeInformation protectedEmpNo={protectedEmpNo} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col className="dm-card-title">Filter</Col>
              </Row>
              <Row className="no-gutters mb-3">
                <Col
                  xs="auto"
                  className="mr-2"
                >
                  Between
                </Col>
                <Col xs="auto">
                  <ControlDatePickerGrp
                    control={control}
                    errors={errors.beginDate}
                    name="beginDate"
                    rules={{
                      required: {
                        value: true,
                        message: 'Begin Date Required',
                      },
                    }}
                  />
                </Col>
                <Col
                  xs="auto"
                  className="mx-2"
                >
                  and
                </Col>
                <Col xs="auto">
                  <ControlDatePickerGrp
                    control={control}
                    name="endDate"
                    errors={errors.endDate}
                    rules={{
                      required: {
                        value: true,
                        message: 'End Date Required',
                      },
                    }}
                  />
                </Col>
                <Col
                  xs="auto"
                  className="mr-2"
                >
                  <RadioGrpInLine
                    name="byCheckDate"
                    ref={register}
                    radioOptions={byCheckDateOptions}
                    defaultChecked={'true'}
                    labelWidth={0}
                  />
                </Col>
                <Col>
                  <button className="btn btn-primary">
                    Filter
                  </button>
                </Col>
              </Row>
              <Row></Row>
            </form>
            <EarningsRecordsPage
              protectedEmpNo={protectedEmpNo}
              beginDate={convToDateISOString(beginDate)}
              endDate={convToDateISOString(endDate)}
              byCheckDate={byCheckDate}
            />
          </>
        ) : (
          <Alert variant="warning">
            Please select an employee to view their earnings.
          </Alert>
        )}
      </Col>
    </Row>
  );
};

export default EmployeeInformationEarnings;
