import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PanelHeader from 'core/components/shared/PanelHeader';
import { EarningsRecord, EarningsRecordQtr } from 'core/models';
import { loadEarningsRecords } from 'core/store/actions';
import { getClient, getEarningsRecords } from 'core/store/selectors';
import { useAppDispatch } from 'utilities/hooks';
import EarningsDetail from './EarningsDetail';
import styles from 'core/components/form-controls/form-controls.module.scss';

type Props = {
  protectedEmpNo: string;
  beginDate?: string;
  endDate?: string;
  byCheckDate: boolean;
};

const EarningsRecordsPage: React.FC<Props> = ({
  protectedEmpNo,
  beginDate,
  endDate,
  byCheckDate = false,
}) => {
  const dispatch = useAppDispatch();

  const [key, setKey] = useState('process-payroll');
  
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const [totalCompToNet, setTotalCompToNet] = useState<EarningsRecord[]>([]);
  const [totalCompToNetQuarterly, setTotalCompToNetQuarterly] = useState<EarningsRecordQtr[]>([]);
 
  const [earnings, setEarnings] = useState<EarningsRecord[]>([]);
  const [earningsQuarterly, setEarningsQuarterly] = useState<EarningsRecordQtr[]>([]);

  const [hours, setHours] = useState<EarningsRecord[]>([]);
  const [hoursQuarterly, setHoursQuartly] = useState<EarningsRecordQtr[]>([]);

  const [deductions, setDeductions] = useState<EarningsRecord[]>([]);
  const [deductionsQuarterly, setDeductionsQuarterly] = useState<EarningsRecordQtr[]>([]);

  const [companyPaidBenefits, setCompanyPaidBenefits] = useState<EarningsRecord[]>([]);
  const [companyPaidBenefitsQuarterly, setCompanyPaidBenefitsQuarterly] = useState<EarningsRecordQtr[]>([]);

  const [gross, setGross] = useState<EarningsRecord[]>([]);
  const [grossQuarterly, setGrossQuarterly] = useState<EarningsRecordQtr[]>([]);

  const earningsRecords = useSelector(getEarningsRecords);
  const client = useSelector(getClient);

  const getYears = () => {
    if (client && client.startDate) {
      const startYear = new Date(client.startDate).getFullYear();
      const lastYear = new Date().getFullYear() + 1;

      return Array(lastYear - (startYear - 1))
        .fill('')
        .map((_, i) => {return lastYear - i;}) as Array<number>;
    }
  };

  const years = getYears() || [];
  const selectOptions = years.map((year) => (
    <option key={year}>{year}</option>
  ));

  useEffect(() => {
    if (!protectedEmpNo) return;
    dispatch(
      loadEarningsRecords({
        protectedEmpNo,
        beginDate: beginDate ?? new Date(`01/01/${selectedYear}`).toISOString(),
        endDate: endDate ?? new Date(`12/31/${selectedYear}`).toISOString(),
        byCheckDate,
      }),
    );
  }, [protectedEmpNo, beginDate, endDate, byCheckDate, selectedYear]);

  useEffect(() => {
    if (earningsRecords && earningsRecords.totalCompToNet) {
      setTotalCompToNet(
        earningsRecords.totalCompToNet.map((item) => {
          return {
            ...item,
            amounts: [...item.amounts],
            ...item.amounts.reduce(
              (acc, i) => {return { ...acc, [i.description]: i.amount };},
              {},
            ),
          };
        }),
      );
      setTotalCompToNetQuarterly(
        earningsRecords.totalCompToNetByQuarter.map((item) => {
          return {
            ...item,
            amounts: [...item.amounts],
            ...item.amounts.reduce(
              (acc, i) => {return { ...acc, [i.description]: i.amount };},
              {},
            ),
          };
        }),
      );

      setEarnings(
        earningsRecords.earnings.map((item) => {
          return {
            ...item,
            amounts: [...item.amounts.filter((x) => x.amount > 0)],
            ...item.amounts.reduce(
              (acc, i) => {return { ...acc, [i.description]: i.amount };},
              {},
            ),
          };
        }),
      );
      setEarningsQuarterly(
        earningsRecords.earningsByQuarter.map(
          (item: EarningsRecordQtr) => {
            return {
              ...item,
              amounts: [...item.amounts],
              ...item.amounts.reduce(
                (acc, i) => {return { ...acc, [i.description]: i.amount };},
                {},
              ),
            };
          },
        ),
      );

      setHours(
        earningsRecords.hours.map((item: EarningsRecord) => {
          return {
            ...item,
            amounts: [...item.amounts.filter((x) => x.amount > 0)],
            ...item.amounts.reduce(
              (acc, i) => {return { ...acc, [i.description]: i.amount };},
              {},
            ),
          };
        }),
      );
      setHoursQuartly(
        earningsRecords.hoursByQuarter.map(
          (item: EarningsRecordQtr) => {
            return {
              ...item,
              amounts: [...item.amounts],
              ...item.amounts.reduce(
                (acc, i) => {return { ...acc, [i.description]: i.amount };},
                {},
              ),
            };
          },
        ),
      );

      setDeductions(
        earningsRecords.deductions.map((item: EarningsRecord) => {
          return {
            ...item,
            amounts: [...item.amounts],
            ...item.amounts.reduce(
              (acc, i) => {return { ...acc, [i.description]: i.amount };},
              {},
            ),
          };
        }),
      );
      setDeductionsQuarterly(
        earningsRecords.deductionsByQuarter.map(
          (item: EarningsRecordQtr) => {
            return {
              ...item,
              amounts: [...item.amounts],
              ...item.amounts.reduce(
                (acc, i) => {return { ...acc, [i.description]: i.amount };},
                {},
              ),
            };
          },
        ),
      );

      setCompanyPaidBenefits(
        earningsRecords.companyPaidBenefits.map(
          (item: EarningsRecord) => {
            return {
              ...item,
              amounts: [...item.amounts],
              ...item.amounts.reduce(
                (acc, i) => {return { ...acc, [i.description]: i.amount };},
                {},
              ),
            };
          },
        ),
      );
      setCompanyPaidBenefitsQuarterly(
        earningsRecords.companyPaidBenefitsByQuarter.map(
          (item: EarningsRecordQtr) => {
            return {
              ...item,
              amounts: [...item.amounts],
              ...item.amounts.reduce(
                (acc, i) => {return { ...acc, [i.description]: i.amount };},
                {},
              ),
            };
          },
        ),
      );

      setGross(
        earningsRecords.gross.map((item: EarningsRecord) => {
          return {
            ...item,
            amounts: [...item.amounts],
            ...item.amounts.reduce(
              (acc, i) => {return { ...acc, [i.description]: i.amount };},
              {},
            ),
          };
        }),
      );
      setGrossQuarterly(
        earningsRecords.grossByQuarter.map(
          (item: EarningsRecordQtr) => {
            return {
              ...item,
              amounts: [...item.amounts],
              ...item.amounts.reduce(
                (acc, i) => {return { ...acc, [i.description]: i.amount };},
                {},
              ),
            };
          },
        ),
      );
    }
  }, [earningsRecords]);

  return (
    <div className="dm-panel dm-panel-border">
      <PanelHeader title="Earnings Records" />
      <div className="mb-3"></div>
      <div>
        <div>Select year: </div>
        <select
          className={styles['dm-form-control']}
          style={{ width: '100px',
            marginBottom: '10px' }}
          defaultValue={selectedYear}
          onChange={(e) => { setSelectedYear(parseInt(e.target.value)); }
          }
        >
          {selectOptions}
        </select>
      </div>
      <Tabs
        defaultActiveKey="totalCompToNet"
        id="uncontrolled-tab-example"
        onSelect={(k: any) => {
          setKey(k);
        }}
      >
        <Tab
          eventKey="totalCompToNet"
          title="Total Comp To Net"
        >
          {totalCompToNet && (
            <EarningsDetail
              items={totalCompToNet}
              totals={totalCompToNetQuarterly}
              type="totalCompToNet"
              visible={key}
            />
          )}
        </Tab>
        <Tab
          eventKey="earnings"
          title="Earnings"
        >
          {earnings && (
            <EarningsDetail
              items={earnings}
              totals={earningsQuarterly}
              type="earnings"
              visible={key}
            />
          )}
        </Tab>
        <Tab
          eventKey="hours"
          title="Hours"
        >
          {hours && (
            <EarningsDetail
              items={hours}
              totals={hoursQuarterly}
              type="hours"
              visible={key}
            />
          )}
        </Tab>
        <Tab
          eventKey="deductions"
          title="Deductions"
        >
          {deductions && (
            <EarningsDetail
              items={deductions}
              totals={deductionsQuarterly}
              type="deductions"
              visible={key}
            />
          )}
        </Tab>
        <Tab
          eventKey="companyPaidBenefits"
          title="Company Paid Benefits"
        >
          {companyPaidBenefits && (
            <EarningsDetail
              items={companyPaidBenefits}
              totals={companyPaidBenefitsQuarterly}
              type="companyPaidBenefits"
              visible={key}
            />
          )}
        </Tab>
        <Tab
          eventKey="gross"
          title="Gross"
        >
          {gross && (
            <EarningsDetail
              items={gross}
              totals={grossQuarterly}
              type="gross"
              visible={key}
            />
          )}
        </Tab>
      </Tabs>
    </div>
  );
};

export default EarningsRecordsPage;
