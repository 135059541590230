import React, { useEffect, useState } from 'react';
import Modal from 'core/components/modals/Modal';
import { HireHistory } from 'core/models';
import { useAppDispatch, useAppSelector } from 'utilities/hooks';
import SaveButton from 'core/components/shared/SaveButton';
import { getSelectedEmp, getTermCode } from 'core/store/selectors';
import { useSelector } from 'react-redux';
import { handleError } from 'core/store/actions';
import { EmpHireHistoryWorkFlow, WorkFlowTask } from 'core/models/WorkFlow.model';
import { postWorkFlowTask } from 'core/store/actions/work-flow.action';
import { CheckboxGrpInLine, InputGrp, SelectGrp } from 'core/components/form-controls';
import { getAccess } from 'utilities/utilities';
import { DatePickerGrp } from 'core/components/form-controls/DatePickerGrp';

type PropTypes = {
  show: boolean;
  onHide: () => void;
  selectedHireHistory: HireHistory;
  notEligible: boolean;
  ignoreTermWhenPRProcessed: boolean;
};

const TerminationWorkFlowModal: React.FC<PropTypes> = ({ show, onHide, selectedHireHistory, notEligible, ignoreTermWhenPRProcessed }) => {

    const [hireHistory, setHireHistory] = useState<HireHistory>(selectedHireHistory);
    const [notEligibleForRehire, setNotEligibleForRehire] = useState<boolean>(notEligible);
    const [ignoreTermWhenProcessed, setIgnoreTermWhenProcessed] = useState<boolean>(ignoreTermWhenPRProcessed);

    const showWorkTaskSuccessModal = useAppSelector((state) => { return state.workFlow.showWorkTaskSuccessModal; });
    const selEmp = useSelector(getSelectedEmp);
    const client = useAppSelector((state) => { return state.client.client; });
    const sectionAccess = useAppSelector((state) => {return state.app.moduleAccess?.employeeMasterSections;});
    const termCodeOpts = useSelector(getTermCode);
    const dispatch = useAppDispatch();
    
    const updateFieldsOnHireHistory = (updatedFields: Partial<HireHistory>) => {
        const clone = structuredClone(hireHistory);
        
        for (const [key, val] of Object.entries(updatedFields)) {
          if (key && key in clone)
            (clone as any)[key] = val as HireHistory[keyof HireHistory];
        }
        
        setHireHistory(clone);
      };

    //If the success modal is shown hide the termination modal.
    useEffect(() => {
      if(showWorkTaskSuccessModal) return onHide();
    }, [showWorkTaskSuccessModal]);
      
    const onSubmit = () => {
      if (!(selEmp && client?.clientNo && client?.clientPayrollFreq)) return dispatch(handleError('Error with employee/client when saving'));
      
      if (!hireHistory) return dispatch(handleError('Error saving termination'));
      if (!hireHistory.termDate || !hireHistory.hireDate) return dispatch(handleError('Error Hire Date and Term Date both required'));

      hireHistory.empDateID = (hireHistory.empDateID > 0) ? hireHistory.empDateID : 0;
      const termCodeDesc = termCodeOpts.find(x => +x.id === hireHistory.termCodeID)?.description || '';

      const change: EmpHireHistoryWorkFlow = {
        EmpDateID: hireHistory.empDateID,
        ClientNo: selEmp.clientNo,
        EmpNo: selEmp.empNo,
        EmpId: selEmp.empId,
        TermCodeDesc: termCodeDesc,
        TermCodeId: hireHistory.termCodeID,
        NotEligibleForRehire: notEligibleForRehire,
        IgnoreTermWhenPrProcessed: ignoreTermWhenProcessed,
        TermDate: hireHistory.termDate,
        HireDate: hireHistory.hireDate,
        Notes: hireHistory.notes,
      }
      const changeJson = JSON.stringify(change);

      const request: WorkFlowTask = {
        workFlowType: 2,
        targetEmpId: selEmp.empId,
        change: changeJson,
        protectedEmpNo: selEmp.protectedEmpNo
      };
      
      dispatch(postWorkFlowTask(request));
    };

    return (
    <div onClick={e => { return e.stopPropagation(); }}>
      <Modal
        show={show}
        onHide={onHide}
        title="Manage Workgroups Termination"
        size='lg'
      >
        <div className="d-flex flex-wrap mt-3">
         <DatePickerGrp
            name={'hireDate'}
            label={'HIRE DATE'}
            groupClass='groupClass12 mr-3'
            {...getAccess(sectionAccess, 7)}
            value={hireHistory.hireDate}
            disabled={true}
            onChange={() => {}}
          />
          <DatePickerGrp
            name={'termDate'}
            label={'TERM DATE'}
            groupClass='groupClass12 mr-3'
            {...getAccess(sectionAccess, 7)}
            value={hireHistory.termDate}
            onChange={(newVal) => {updateFieldsOnHireHistory({ termDate: newVal });}}
          />
          <SelectGrp
            {...getAccess(sectionAccess, 7)}
            name={'termCodeId'}
            groupClass="groupClass12 mr-3"
            addEmptyText={true}
            label={'TERM REASON'}
            dropdownName="TermCode"
            options={termCodeOpts}
            value={hireHistory.termCodeID}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateFieldsOnHireHistory({ termCodeID: +(e.target.value) });
            }}
          />
          <span style={{marginTop: '25px'}}>
          <CheckboxGrpInLine
            {...getAccess(sectionAccess, 7)}
            name="notEligibleForRehire"
            label="Not Eligible for Rehire"
            labelFirst={false}
            value={notEligibleForRehire}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setNotEligibleForRehire(e.target.checked);
            }}
          />
          </span>
          <CheckboxGrpInLine
           {...getAccess(sectionAccess, 7)}
           name= 'ignoreTermWhenPRProcessed'
           label= 'Do not show warning message for this Terminated Employee when Payroll is processed'
           labelFirst= {false}
           labelWidth= {100}
           labelWidthUnit= '%'
           groupClass= 'groupClass25 pt-3 ml-3'
           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setIgnoreTermWhenProcessed(e.target.checked);
            }}
          />
        </div>
        <InputGrp
          name={''}
          label={'NOTES'}
          groupClass={'gc100'}
          value={hireHistory.notes}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            updateFieldsOnHireHistory({ notes: e.target.value });
          }}
        />
        <div className="text-right mt-3">
          <SaveButton
            saving={false}
            classes="btn orange-button mr-3"
            onClick={onSubmit}
            disabled={false}
          />
        </div>
      </Modal>
    </div >
  );
};

export default TerminationWorkFlowModal;
